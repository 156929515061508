var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "background justify-center align-center mt-6",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4", xl: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "ma-3 pa-4", attrs: { flat: "" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-3",
                          attrs: { justify: "center", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c("v-img", {
                                    staticClass: "ml-auto mr-auto",
                                    attrs: {
                                      src: require("../assets/logo.svg"),
                                      width: "229",
                                    },
                                  }),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("Register"),
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mt-0",
                                              attrs: { justify: "center" },
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass: "mb-5",
                                                  attrs: { justify: "center" },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("login.or"))
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mt-0",
                                              attrs: { justify: "center" },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "title",
                                                  attrs: {
                                                    text: "",
                                                    to: { name: "auth-Login" },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("register.login")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }