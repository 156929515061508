var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-text-field",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u(
            [
              _vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
                return {
                  key: slot,
                  fn: function (scope) {
                    return [_vm._t(slot, null, null, scope)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        "v-text-field",
        _vm.$props,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }