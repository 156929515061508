import config from './config';
import locales from './locales';
import views from './views';

export { config };
export { locales };
export { views };

export default {
  config,
  locales,
  views,
};
