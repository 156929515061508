<template>
  <div>
    <v-btn color="primary" v-if="selected.length > 0" class="mr-2" @click="openDialog">
      Mass Stop ({{ selected.length }})
    </v-btn>

    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text class="d-flex flex-column">
          <div class="text-h6">Reason:</div>
          <v-select
            data-cy="StopCandidates-reason"
            clearable
            :items="reasons"
            v-model="form.reasonId"
            item-text="name"
            item-value="id"
          />
          <div class="text-h6">Explanation:</div>
          <v-select
            data-cy="StopCandidates-explanation"
            clearable
            :items="explanations"
            v-model="form.explanationId"
            item-text="name"
            item-value="id"
          />
          The following candidates will be stopped:
          <ul>
            <li v-for="item in selected" :key="item.id">
              {{ item.candidate.name }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text @click="closeDialog">Cancel</v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="updateCandidates"
            :loading="isSaving">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'StopCandidates',
  mixins: [loading],
  data() {
    return {
      dialog: false,
      message: '',
      form: {
        explanationId: undefined,
        reasonId: undefined,
      },
    };
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('stages', ['orderedStages']),
    ...mapGetters('tasks', ['getTasksByStageId']),
    ...mapState('explanations', ['explanations']),
    ...mapState('reasons', ['reasons']),
    dialogTitle() {
      return `Stopping ${this.selected.length} candidate${this.selected.length > 1 ? 's' : ''}`;
    },
  },
  methods: {
    ...mapActions('jobListingCandidates', [
      'UPDATE_jobListingCandidate',
    ]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.message = '';
      this.valid = false;
    },
    sendMessage() {
      if (this.$refs.form.validate()) {
        // Send message logic here
        this.closeDialog();
      }
    },
    async updateCandidates() {
      this.$log.debug('updateCandidates');
      this.isSaving = true;
      const { explanationId, reasonId } = this.form;
      // eslint-disable-next-line max-len
      const promises = this.selected.map((jobListingCandidate) => this.UPDATE_jobListingCandidate({
        id: jobListingCandidate.id,
        isStop: true,
        reasonId,
        explanationId,
      }));
      await Promise.all(promises);
      this.isSaving = false;
      this.closeDialog();
      this.$emit('refresh');
    },
  },
};
</script>
