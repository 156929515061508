<template>
  <v-container fluid>
    <DataTable
      name="Job Listings"
      :headers="headers"
      :items="items"
      class="elevation-1"
      :loading="isLoading"
      :slots="[
        { name: 'actions' },
        { name: 'name' },
        { name: 'client.name' },
        { name: 'teamChannelId' },
        { name: 'created' },
        { name: 'createdBy' },
        { name: 'favourite' },
        { name: 'status' },
        { name: 'responsibleUserId' },
      ]"
      @refresh="refresh"
      sort-by="created"
      :sort-desc="true"
    >
      <template v-slot:new>
        <v-btn
          class="ml-2"
          color="success"
          data-cy="JobListing-button-new"
          @click="$router.push({
            name: 'clients-EditJobListing',
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
      </template>
      <template v-slot:filters>
        <v-row>
          <v-col cols="3">
            <v-checkbox
              label="Favourites"
              v-model="filterFavourites" />
          </v-col>
          <v-col cols="3">
            <v-checkbox
              label="Archived"
              v-model="filterArchived"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              :items="sortedResponsibleUserList"
              v-model="filterResponsibleUserId"
              :label="$$t('responsibleUserId')"
              clearable
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:favourite="{ item }">
        <Favourite name="JobListing" :identifier="`${userId}-${item.id}`" />
        <v-btn icon @click="toggleArchive(item)">
          <v-icon v-if="item.isActive">{{ mdiArchive }}</v-icon>
          <v-icon v-else>{{ mdiArchiveCancel }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:created="{ item }">
        {{ formatDate(item.created) }}
      </template>
      <template v-slot:teamChannelId="{ item }">
        <TeamLink :teamChannelId="item.teamChannelId" :teamId="item.teamId" />
      </template>
      <template v-slot:createdBy="{ item }">
        <DisplayName :user="item.createdBy" />
      </template>
      <template v-slot:responsibleUserId="{ item }">
        <DisplayName :user="item.responsibleUserId" />
      </template>
      <template v-slot:status="{ item }">
        <v-edit-dialog
          :return-value.sync="item.status"
          large
          persistent
          @save="updateStatus(item)"
        >
          {{ item.status }}
          <template v-slot:input>
            <v-select
              :items="jobListingStatuses"
              item-text="name"
              item-value="name"
              required
              data-cy="ListJobListing-status"
              :label="$$t('status')"
              v-model="item.status"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:name="{ item, index }">
        <router-link
          :data-cy="`JobListing-table-name-${index}`"
          :to="{
            name: 'clients-ViewJobListing',
            query: {
              jobListingId: item.id,
            },
          }"
        >
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:client.name="{ item, index }">
        <router-link
          :data-cy="`JobListing-table-client-name-${index}`"
          :to="{
            name: 'clients-EditClient',
            query: {
              id: item.client?.id,
            },
          }"
        >
          {{ item.client?.name }}
        </router-link>
      </template>
      <template v-slot:actions="{ item }">
        <TableBasicButtons
          :editLink="{
            name: 'clients-EditJobListing',
            query: { id: item.id },
          }"
          @delete="remove(item)"
        />
      </template>
    </DataTable>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiPlus, mdiArchive, mdiArchiveCancel } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import TeamLink from '../../../components/TeamLink.vue';
import refresh from '../../../mixins/refresh';

export default {
  name: 'ListJobListing',
  module: 'JobListings',
  components: {
    TableBasicButtons,
    TeamLink,
  },
  mixins: [loading, refresh],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.jobListings',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiArchive,
      mdiArchiveCancel,
      createdByList: [],
      filterArchived: false,
      filterResponsibleUserId: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('jobListings', ['jobListings']),
    ...mapState('jobListingStatuses', ['jobListingStatuses']),
    ...mapState('auth', ['userId']),
    ...mapState('app', ['favouritesOnly']),
    /**
     * Computed property for the favouritesOnly vuex property
     */
    filterFavourites: {
      get() {
        return this.favouritesOnly;
      },
      set(newValue) {
        this.SET_favouritesOnly(newValue);
      },
    },
    items() {
      let result = this.jobListings;
      if (this.filterArchived) {
        result = this.jobListings.filter((jobListing) => !jobListing.isActive);
      } else {
        result = this.jobListings.filter((jobListing) => jobListing.isActive);
      }
      if (this.filterFavourites) {
        result = result.filter((jobListing) => {
          const identifier = `${this.userId}-${jobListing.id}`;
          return this.$store.getters['favourites/isFavourite']('JobListing', identifier);
        });
      }
      if (this.filterResponsibleUserId) {
        result = result
          .filter((jobListing) => jobListing.responsibleUserId === this.filterResponsibleUserId);
      }
      return result;
    },
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: '',
          value: 'favourite',
        },
        {
          text: this.$$t('projectNumber'),
          value: 'projectNumber',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('status'),
          value: 'status',
          width: '120px',
        },
        {
          text: this.$$t('contact'),
          value: 'client.name',
        },
        {
          text: this.$$t('responsibleUserId'),
          value: 'responsibleUserId',
        },
        {
          text: this.$t('App.common.createdBy'),
          value: 'createdBy',
        },
        {
          text: this.$t('App.common.created'),
          value: 'created',
          width: '120px',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    sortedResponsibleUserList() {
      const { createdByList } = this;
      return createdByList
        .filter((i) => i.text !== '' && i.text !== null && i.text !== undefined)
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  async mounted() {
    await this.refresh();
    this.$root.$on('page.refresh', () => {
      this.$log.debug('refresh event received');
      if (this.refresh) {
        this.refresh();
      }
    });
  },
  destroyed() {
    this.$root.$off('page.refresh');
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('favourites', ['LOAD_favourites']),
    ...mapActions('jobListings', ['LOAD_jobListings', 'DELETE_jobListing', 'UPDATE_jobListing']),
    ...mapActions('jobListingStatuses', ['LOAD_jobListingStatuses']),
    ...mapMutations('app', ['SET_favouritesOnly']),
    /** Archive JobListing
     */
    async toggleArchive(jobListing) {
      this.isLoading = true;
      const { id } = jobListing;
      await this.UPDATE_jobListing({
        id,
        isActive: !jobListing.isActive,
      });
      this.isLoading = false;
    },
    /** Delete JobListing from the collection
     */
    async remove(jobListing) {
      this.isLoading = true;
      await this.DELETE_jobListing(jobListing.id);
      this.isLoading = false;
    },
    /** Refresh the collection
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_favourites();
      await this.LOAD_jobListings();
      await this.LOAD_jobListingStatuses();
      const responsibleUsers = this.jobListings.map((j) => j.responsibleUserId);
      responsibleUsers.forEach(async (responsibleUserId) => {
        if (!this.createdByList.map((c) => c.value).includes(responsibleUserId)) {
          const displayName = await this.getDisplayName(responsibleUserId);
          this.createdByList.push({
            text: displayName,
            value: responsibleUserId,
          });
        }
      });
      this.isLoading = false;
    },
    async getDisplayName(username) {
      if (!username) {
        return '';
      }
      try {
        const { data } = await this.$http.get(`/api/profile/DisplayName?email=${username}`);
        return data;
      } catch (error) {
        this.$log.error(error);
        return username;
      }
    },
    async updateStatus(item) {
      this.isLoading = true;
      await this.UPDATE_jobListing({
        id: item.id,
        status: item.status,
      });
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# JobListing example

'''js
<JobListing />
'''
</docs>
