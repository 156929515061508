var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "" } }, on),
                [
                  _c("v-icon", [
                    _vm._v(" " + _vm._s(_vm.mdiFileUploadOutline) + " "),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" " + _vm._s(_vm.label) + " ")]),
          _c(
            "v-card-text",
            [
              _c("ClipboardTextField", {
                attrs: { value: _vm.uploadUrl, label: "Name" },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }