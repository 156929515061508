<template>
  <span>
    {{ displayName }}
  </span>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'DisplayName',
  props: {
    user: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      displayName: this.user,
    };
  },
  computed: {
    ...mapState('displayNames', ['displayNames']),
  },
  watch: {
    user(newValue) {
      this.displayName = newValue;
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapMutations('displayNames', ['ADD_displayName']),
    async refresh() {
      if (!this.user) {
        return;
      }
      if (!this.user.includes('@')) {
        this.displayName = this.user;
        return;
      }
      const displayNames = this.displayNames.filter((dn) => dn.email === this.user);
      if (displayNames.length > 0) {
        this.displayName = displayNames[0].displayName;
        return;
      }
      try {
        const { data } = await this.$http.get(`/api/profile/DisplayName?email=${this.user}`);
        this.displayName = data;
        this.ADD_displayName({ email: this.user, displayName: data });
      } catch (error) {
        this.$log.error(error);
      }
    },
  },
};
</script>
