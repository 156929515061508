<template>
  <div>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-timeline
      align-top
      dense
      v-else
    >
      <v-timeline-item
        v-for="comment in jobListingCandidateComments"
        :key="comment.id"
        small
      >
        <div>
          <div class="font-weight-normal">
            <strong>{{ comment.createdBy }}</strong> | {{ formatRelative(comment.created) }}
          </div>
          <div>{{ comment.message }}</div>
        </div>
      </v-timeline-item>
    </v-timeline>
    <v-divider />
    <v-textarea
      v-model="message"
      placeholder="Add a comment"
      counter
      limit="2000"
      outlined
      rows="2">
      >
      <template v-slot:append>
        <v-btn
          color="primary"
          @click="addComment"
          :loading="isSaving"
          :disabled="message?.length > 2000"
        >
          Add
        </v-btn>
      </template>
    </v-textarea>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default {
  name: 'JobListingCandidateComments',
  components: {
    ProgressLinearTimeout,
  },
  mixins: [loading],
  props: {
    jobListingCandidate: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      message: '',
    };
  },
  computed: {
    ...mapState('jobListingCandidateComments', ['jobListingCandidateComments']),
  },
  watch: {
    jobListingCandidate() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('jobListingCandidateComments', [
      'LOAD_jobListingCandidateComments',
      'CREATE_jobListingCandidateComment',
    ]),
    ...mapMutations('jobListingCandidateComments', [
      'SET_jobListingCandidateComments',
    ]),
    formatRelative(dt) {
      return dayjs(dt).fromNow();
    },
    async addComment() {
      this.isSaving = true;
      await this.CREATE_jobListingCandidateComment({
        jobListingCandidateId: this.jobListingCandidate.id,
        message: this.message,
      });
      this.message = '';
      this.isSaving = false;
    },
    async refresh() {
      if (this.jobListingCandidate) {
        this.isLoading = true;
        await this.LOAD_jobListingCandidateComments(this.jobListingCandidate.id);
        this.isLoading = false;
      }
    },
  },
};
</script>
