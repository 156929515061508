var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          attrs: { "data-cy": _vm.dataCy },
          on: {
            mouseover: function ($event) {
              _vm.hover = true
            },
            mouseleave: function ($event) {
              _vm.hover = false
            },
          },
        },
        "v-btn",
        _vm.$props,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._t("icon", function () {
        return [
          _c("v-icon", { class: { "mr-2": _vm.hover } }, [
            _vm._v(_vm._s(_vm.labelIcon)),
          ]),
        ]
      }),
      _vm.hover
        ? _vm._t("label", function () {
            return [
              _c("span", { class: { "is-visible": _vm.hover } }, [
                _vm._v(_vm._s(_vm.label)),
              ]),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }