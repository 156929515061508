var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "mb-10": _vm.isLoading } },
    [
      _vm.isLoading
        ? _c("ProgressLinearTimeout")
        : _c(
            "v-row",
            _vm._l(_vm.items, function (item) {
              return _c(
                "v-col",
                { key: item.id, attrs: { cols: "6" } },
                [
                  _c("v-simple-checkbox", {
                    staticStyle: { display: "inline-block" },
                    attrs: { dense: "", value: item.completed },
                    on: {
                      input: function ($event) {
                        return _vm.update(item, $event)
                      },
                    },
                  }),
                  _c("div", { staticClass: "checkbox-label" }, [
                    _vm._v(" " + _vm._s(item.name) + " "),
                  ]),
                ],
                1
              )
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }