import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('jobListingCandidateComment', 'jobListingCandidateComments');

const customActions = {
  async LOAD_jobListingCandidateCommentsByCandidate({ commit, rootGetters }, candidateId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListingCandidateComments')
      .query(
        {
          $filter: `IsDeleted eq false AND JobListingCandidate/CandidateId eq ${candidateId}`,
          $expand: 'JobListingCandidate($expand=JobListing,Stage)',
          $orderby: 'Created desc',
        },
      );
    commit('SET_jobListingCandidateComments', data);
    return data;
  },
  async LOAD_jobListingCandidateComments({ commit, rootGetters }, jobListingCandidateId) {
    const token = await auth.getAccessToken();
    commit('SET_jobListingCandidateComments', []);
    const data = await odata.o(rootGetters, token)
      .get('jobListingCandidateComments')
      .query(
        {
          $filter: `IsDeleted eq false AND JobListingCandidateId eq ${jobListingCandidateId}`,
          $orderby: 'Created desc',
        },
      );
    commit('SET_jobListingCandidateComments', data);
    return data;
  },
};

export default { ...actions, ...customActions };
