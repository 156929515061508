var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", { staticClass: "text-center text--primary" }, [
      _vm._v("Process Stage"),
    ]),
    _c("div", { ref: "funnel", attrs: { id: `Funnel-${_vm.jobId}` } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }