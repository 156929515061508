<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-skeleton-loader
          :loading="isLoading"
          transition="expand-transition"
          type="article"
        >
          <FormCandidate
            :Candidate="editCandidate"
            :isSaving="isSaving"
            @save="save"
            @cancel="cancel" />
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormCandidate from '../components/FormCandidate.vue';

export default {
  components: {
    FormCandidate,
  },
  name: 'EditCandidate',
  module: 'Candidates',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.candidates',
        to: { name: 'candidates-ListCandidate' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing Candidate. Default to {}.
      */
      editCandidate: {},
      /**
       * Query Id for Candidate
       */
      candidateId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('candidates', ['candidates', 'candidate']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.candidateId) {
        return 'edit';
      }
      return 'add';
    },
  },
  created() {
    if (this.$route.query.jobListingId) {
      this.$route.meta.breadcrumb = [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: this.$route.query.jobListingName,
        to: {
          name: 'clients-ViewJobListing',
          query: { jobListingId: this.$route.query.jobListingId },
        },
      },
      {
        text: 'Candidate',
      },
      {
        text: 'menu.edit',
      },
      ];
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_industries();
    if (this.mode === 'edit') {
      await this.LOAD_candidate(this.candidateId);
      this.editCandidate = this.candidate;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('candidates', ['LOAD_candidate', 'CREATE_candidate', 'UPDATE_candidate']),
    ...mapActions('industries', ['LOAD_industries']),
    /** Method used to save the item
     */
    async save(candidate) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_candidate(candidate);
      } else {
        await this.UPDATE_candidate(candidate);
      }
      this.isSaving = false;
      if (this.$route.query.jobListingId) {
        this.$router.push({
          name: 'clients-ViewJobListing',
          query: { jobListingId: this.$route.query.jobListingId },
        });
      } else {
        this.$router.push({ name: 'candidates-ListCandidate' });
      }
    },
    cancel() {
      if (this.$route.query.jobListingId) {
        this.$router.push({
          name: 'clients-ViewJobListing',
          query: { jobListingId: this.$route.query.jobListingId },
        });
      } else {
        this.$router.push({ name: 'candidates-ListCandidate' });
      }
    },
  },
};
</script>

<docs>
# EditCandidate example

'''js
<EditCandidate :Candidate="Candidate" />
'''
</docs>
