<template>
  <div>
    <v-btn color="primary" v-if="selected.length > 0" class="mr-2" @click="openDialog">
      Message ({{ selectedWithEmail.length }})
    </v-btn>

    <v-dialog v-model="dialog" fullscreen>
      <v-card>
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <div class="text-h5">
                Compose your message
              </div>
              <v-form>
                <v-text-field
                  v-model="form.subject"
                  label="Subject"
                />
                <v-textarea
                  v-model="form.message"
                  label="Message"
                  rows="10"
                >
                  <template v-slot:append>
                    <v-menu>
                      <template v-slot:activator="{ on }">
                        <v-btn small color="success" v-on="on">
                          <v-icon>{{ mdiAppleKeyboardCommand }}</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="insert('{{ firstName }}')">
                          <v-list-item-title>First Name</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="insert('{{ lastName }}')">
                          <v-list-item-title>Last Name</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="insert('{{ email }}')">
                          <v-list-item-title>Email Address</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-textarea>
                <v-btn @click="sendMail">
                  Send
                </v-btn>
              </v-form>
            </v-col>
            <v-col cols="6">
              <EmailPreview :form="form" :candidates="selectedWithEmail" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiAppleKeyboardCommand } from '@mdi/js';
import EmailPreview from '@/components/EmailPreview.vue';

export default {
  name: 'SendMessages',
  components: {
    EmailPreview,
  },
  data() {
    return {
      dialog: false,
      message: '',
      valid: false,
      mdiAppleKeyboardCommand,
      form: {
        email: '',
        subject: '',
        message: '',
        phoneNumber: '',
      },
    };
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dialogTitle() {
      const { selectedWithEmail, selectedWithNoEmail } = this;
      if (selectedWithEmail.length === 0) {
        return 'No candidates with email';
      }
      if (selectedWithNoEmail.length === 0) {
        return `Email to ${this.selectedWithEmail.length} candidate${this.selectedWithEmail.length > 1 ? 's' : ''}`;
      }
      return `Email to ${this.selectedWithEmail.length} candidate${this.selectedWithEmail.length > 1 ? 's' : ''} and ${this.selectedWithNoEmail.length} candidate${this.selectedWithNoEmail.length > 1 ? 's' : ''} without email`;
    },
    selectedWithEmail() {
      return this.selected.filter((jobListing) => jobListing.candidate.email);
    },
    selectedWithNoEmail() {
      return this.selected.filter((jobListing) => !jobListing.candidate.email);
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.message = '';
      this.valid = false;
    },
    sendMessage() {
      if (this.$refs.form.validate()) {
        // Send message logic here
        this.closeDialog();
      }
    },
    sendMail() {
      const { subject, message } = this.form;
      const { selectedWithEmail } = this;
      const mail = {
        subject,
        message,
        selected: selectedWithEmail,
      };
      this.$emit('email', mail);
      this.dialog = false;
    },
    insert(text) {
      this.form.message += text;
    },
  },
};
</script>
