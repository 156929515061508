<template>
  <TableModalForm
    :headers="headers"
    :items="completedTasks"
    dense
    :loading="isLoading"
    :showNew="false"
    name="Completed Tasks"
    ref="table"
    :slots="[{ name: 'actions' }, { name: 'order' }, { name: 'created' }]"
    sort-by="created"
    :sort-desc="true"
    @creating="creating"
    @refresh="refresh"
    @item-selected="itemSelected"
  >
    <template v-slot:form>
      <FormCompletedTask
        :Task="editCompletedTask"
        :isSaving="isSaving"
        @save="save"
        @cancel="$refs.table.dialog = false" />
    </template>
    <template v-slot:created="{ item }">
      {{ formatDateTime(item.created) }}
    </template>
    <template v-slot:actions="{ item }">
      <v-btn-toggle>
        <v-btn color="default" small @click="edit(item)">
          {{ $t('common.buttons.edit')}}
        </v-btn>
      </v-btn-toggle>
    </template>
  </TableModalForm>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiArrowUpBold, mdiArrowDownBold } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import TableModalForm from '@/components/TableModalForm.vue';
import FormCompletedTask from '../components/FormCompletedTask.vue';

export default {
  name: 'ListCompletedTask',
  module: 'Tasks',
  components: {
    FormCompletedTask,
    TableModalForm,
  },
  props: {
    Stage: {
      type: Object,
      default: undefined,
    },
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiArrowUpBold,
      mdiArrowDownBold,
      /**
      * Get/Set editing CompletedTask. Default to {}.
      */
      editCompletedTask: {},
      mode: 'add',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('completedTasks', ['completedTasks']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('completed'),
          value: 'created',
        },
        {
          text: this.$$t('taskName'),
          value: 'task.name',
        },
        {
          text: this.$$t('candidate'),
          value: 'jobListingCandidate.candidate.name',
        },
        {
          text: this.$$t('completedBy'),
          value: 'createdBy',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  watch: {
    Stage() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('completedTasks', [
      'LOAD_completedTask',
      'CREATE_completedTask',
      'UPDATE_completedTask',
      'LOAD_completedTasksByStage',
      'DELETE_completedTask',
    ]),
    creating() {
      this.editCompletedTask = {
        order: this.completedTasks.length + 1,
        stageId: this.Stage.id,
      };
      this.mode = 'add';
    },
    async edit(item) {
      this.editCompletedTask = { ...item };
      this.mode = 'edit';
      this.$refs.table.dialog = true;
    },
    /**
     * Executes refresh
     */
    async refresh() {
      if (this.Stage) {
        this.isLoading = true;
        await this.LOAD_completedTasksByStage(this.Stage.id);
        this.isLoading = false;
      }
    },
    /** Delete CompletedTask from the collection
     */
    async remove(task) {
      this.isLoading = true;
      await this.DELETE_task(task.id);
      this.isLoading = false;
    },
    /** Vuex methods
     */
    /** Method used to save the item
     */
    async save(task) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_task(task);
      } else {
        await this.UPDATE_task(task);
      }
      this.isSaving = false;
      this.$refs.table.dialog = false;
    },
    itemSelected(item) {
      this.$emit('selected', item);
    },
  },
};
</script>

<docs>
# CompletedTask example

'''js
<CompletedTask />
'''
</docs>
