class ODataQueryBuilder {
  constructor(baseUrl, entitySet, headers) {
    this.baseUrl = baseUrl;
    this.entitySet = entitySet;
    this.headers = headers;
  }

  buildUrl(pagination) {
    let url = `${this.baseUrl}/${this.entitySet}`;

    const {
      top, page, sortDesc, sortBy = [], filter, search,
    } = pagination;
    if (top) {
      url += `?$top=${top}`;
    }
    if (page) {
      url += `&$skip=${top * (page - 1)}`;
    }

    if (filter?.length > 1 && (search ?? '').length < 1) {
      url += `&$filter=${filter}`;
    }

    if (sortBy.length > 0) {
      // const orderBy = orderby
      //   .map((item) => (item.direction ? `${item.field} ${item.direction}` : item.field))
      //   .join(',');

      url += `&$orderby=${sortBy.join(',')} ${sortDesc ? 'desc' : 'asc'}`;
    }

    if (search?.length > 1) {
      const searchFields = this.headers
        .filter((header) => header.searchable)
        .map((header) => `contains(${header.value}, '${search}')`);
      url += `&$filter=(${searchFields.join(' OR ')})`;
      if (filter?.length > 1) {
        url += ` AND (${filter})`;
      }
    }

    url += '&$count=true';
    return url;
  }
}

export default ODataQueryBuilder;
