var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    attrs: { label: _vm.label, disabled: "", outlined: "" },
    scopedSlots: _vm._u([
      {
        key: "append",
        fn: function () {
          return [
            _c(
              "v-dialog",
              {
                attrs: { width: "500" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              { attrs: { icon: "", small: "" } },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v(_vm._s(_vm.mdiPencil))])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.dialog,
                  callback: function ($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c("v-card-title", { attrs: { "primary-title": "" } }, [
                      _vm._v(" Edit "),
                    ]),
                    _c(
                      "v-card-text",
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            label: _vm.label,
                            items: _vm.items,
                            outlined: "",
                          },
                          model: {
                            value: _vm.localValue,
                            callback: function ($$v) {
                              _vm.localValue = $$v
                            },
                            expression: "localValue",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: { click: _vm.updateValue },
                          },
                          [_vm._v(" Save ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }