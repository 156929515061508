var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.selected.length > 0
        ? _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { color: "primary" },
              on: { click: _vm.openDialog },
            },
            [_vm._v(" Message (" + _vm._s(_vm.selectedWithEmail.length) + ") ")]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.dialogTitle)),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("div", { staticClass: "text-h5" }, [
                            _vm._v(" Compose your message "),
                          ]),
                          _c(
                            "v-form",
                            [
                              _c("v-text-field", {
                                attrs: { label: "Subject" },
                                model: {
                                  value: _vm.form.subject,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "subject", $$v)
                                  },
                                  expression: "form.subject",
                                },
                              }),
                              _c("v-textarea", {
                                attrs: { label: "Message", rows: "10" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-menu",
                                          {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color: "success",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.mdiAppleKeyboardCommand
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.insert(
                                                          "{{ firstName }}"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v("First Name"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.insert(
                                                          "{{ lastName }}"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v("Last Name"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.insert(
                                                          "{{ email }}"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v("Email Address"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.form.message,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "message", $$v)
                                  },
                                  expression: "form.message",
                                },
                              }),
                              _c("v-btn", { on: { click: _vm.sendMail } }, [
                                _vm._v(" Send "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("EmailPreview", {
                            attrs: {
                              form: _vm.form,
                              candidates: _vm.selectedWithEmail,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }