<template>
  <GChart
    type="ColumnChart"
    :data="chartData"
    :options="chartOptions"
    :resizeDebounce="500"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { GChart } from 'vue-google-charts/legacy';

export default {
  name: 'GraphJobListing',
  props: {
    jobListingCandidates: {
      type: Array,
      default: () => [],
    },
    stoppedCandidates: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    GChart,
  },
  computed: {
    ...mapGetters('stages', ['orderedStages']),
    chartData() {
      const data = [['Stage', 'Amount', { role: 'annotation' }, { role: 'style' }]];
      this.orderedStages.forEach((stage) => {
        const amount = this.jobListingCandidates.filter(
          (jobListingCandidate) => jobListingCandidate.stageId === stage.id,
        ).length;
        data.push([stage.name, amount, amount.toString(), '#1e0ca9']);
      });
      data.push(['Stopped', this.stoppedCandidates.length, this.stoppedCandidates.length.toString(), '#1e0ca9']);
      return data;
    },
    chartOptions() {
      return {
        title: `Total Candidates (${this.jobListingCandidates.length + this.stoppedCandidates.length})`,
        vAxis: {
          minValue: this.maxSeries,
          title: '',
        },
        width: '100%',
        legend: 'none',
        annotations: {
          alwaysOutside: true, // This ensures labels are always outside the columns (optional)
          textStyle: {
            fontSize: 12, // You can adjust the font size
            auraColor: 'none', // Removes the background color around the text
            color: '#555', // You can set the text color
          },
          // This is necessary to actually show the annotations
          stem: {
            length: 0,
          },
        },
        // Enable the annotations for the series you want, assuming it's the first series (index 0)
        series: {
          0: {
            annotations: {
              textStyle: {
                fontSize: 12,
                color: '#000',
              },
            },
          },
        },
      };
    },
    maxSeries() {
      if (this.chartData?.length > 1) {
        const amounts = this.chartData
          .map((data) => data[1])
          .slice(1);
        const highestAmount = Math.max(...amounts);
        return (highestAmount + 1);
      }
      return 100;
    },
  },
};
</script>
