var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-text-field",
    _vm._g(
      _vm._b(
        {
          ref: "textField",
          attrs: { "data-cy": _vm.dataCy },
          on: { focus: _vm.selectAllText },
          scopedSlots: _vm._u(
            [
              _vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
                return {
                  key: slot,
                  fn: function (scope) {
                    return [_vm._t(slot, null, null, scope)]
                  },
                }
              }),
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    { on: { click: _vm.copyToClipboard } },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.mdiContentCopy) + " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [_vm._v(" " + _vm._s(_vm.title) + " ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "v-text-field",
        _vm.$props,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }