import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('jobListingCandidate', 'jobListingCandidates');

const customActions = {
  async LOAD_jobListingCandidatesByJobListingId({ commit, rootGetters }, jobListingId) {
    let expand = 'Candidate($select=id,name,currentEmployer,title,linkedInUrl,createdBy,email,jobListingCandidates,phone,imageUrl,dateOfBirth,location,country;$expand=JobListingCandidates($select=id;$count=true;$filter=IsDeleted eq false))';
    expand += ',Stage($select=name,order)';
    expand += ',Task($select=name,order)';
    expand += ',Comments($count=true;$top=2;$orderby=Created desc)';
    expand += ',Messages($count=true;$filter=IsRead ne true;$top=10)';
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListingCandidates')
      .query({
        $filter: `IsDeleted eq false AND JobListingId eq ${jobListingId}`,
        $expand: expand,
        // $count: 'Comments',
        // eslint-disable-next-line max-len
        $select: 'Id,Candidate,Stage,Task,CandidateId,Created,CreatedBy,Updated,StageId,TaskId,ExplanationId,ReasonId,IsStop,PrintData',
      });

    const result = data.map((item) => {
      const newItem = { ...item };
      if (!newItem.candidate) {
        newItem.candidate = {
          jobListingCandidates: [],
        };
      }
      newItem.candidate.jobListingCount = newItem.candidate.jobListingCandidates
        .filter((c) => c.candidateId === item?.candidate?.id)
        .length;
      return newItem;
    });
    commit('SET_jobListingCandidates', result);
    return data;
  },
};

export default { ...actions, ...customActions };
