<template>
  <EditPage :title="mode === 'edit' ? 'Edit Template' : 'New Template'">
    <v-skeleton-loader
      :loading="isLoading"
      transition="expand-transition"
      type="article"
    >
      <FormDocumentTemplate
        :document-template="editDocumentTemplate"
        :is-saving="isSaving"
        @save="save"
        @cancel="$router.push({ name: from.name })"
      />
    </v-skeleton-loader>
    <ListFields v-if="editDocumentTemplate.id" :documentTemplateId="documentTemplateId" />
  </EditPage>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import EditPage from '../layouts/EditPage.vue';
import FormDocumentTemplate from '../components/FormDocumentTemplate.vue';
import ListFields from '../components/ListFields.vue';

export default {
  name: 'EditDocumentTemplate',
  components: {
    EditPage,
    FormDocumentTemplate,
    ListFields,
  },
  module: 'Admin',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing DocumentTemplate. Default to {}.
      */
      editDocumentTemplate: {},

      /**
       * Query Id for DocumentTemplate
       */
      documentTemplateId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('documentTemplates', ['documentTemplates', 'documentTemplate']),

    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.documentTemplateId) {
        return 'edit';
      }

      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_documentTemplate(this.documentTemplateId);
      this.editDocumentTemplate = this.documentTemplate;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('documentTemplates', ['LOAD_documentTemplate', 'CREATE_documentTemplate', 'UPDATE_documentTemplate']),

    /** Method used to save the item
     */
    async save(documentTemplate) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_documentTemplate(documentTemplate);
      } else {
        await this.UPDATE_documentTemplate(documentTemplate);
      }
      this.isSaving = false;
      this.$router.push({ name: 'clients-ListDocumentTemplate' });
    },
  },
};
</script>

<docs>
# EditDocumentTemplate example

'''js
<EditDocumentTemplate :DocumentTemplate="DocumentTemplate" />
'''
</docs>
