import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('documentTemplateField', 'documentTemplateFields');

const customActions = {
  async LOAD_documentTemplateFields({ commit, rootGetters }, documentTemplateId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('documentTemplateFields')
      .query({ $filter: `IsDeleted eq false AND documentTemplateId eq ${documentTemplateId}` });
    commit('SET_documentTemplateFields', data);
    return data;
  },
};

export default { ...actions, ...customActions };
