<template>
  <v-app>
    <AzureAuth />
    <FavouriteService v-if="isLoggedIn" />
    <v-navigation-drawer
      v-if="isLoggedIn"
      v-model="drawer"
      color="#FAF5EC"
      app
    >
      <v-divider />
      <MenuDrawer />
      <v-divider />
      <v-spacer />
      <div class="logo-left">
        <img src="../assets/numaH_Logo_New.png" contain alt="logo" />
      </div>
    </v-navigation-drawer>

    <v-app-bar
      v-if="isLoggedIn"
      :color="$vuetify.theme.dark ? '' : '#FAF5EC'"
      dark
      app
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="#1e0b4c" />
      <router-link :to="{ name: 'app-Home' }">
        <v-img src="../assets/claim-logo.svg" contain :height="120" alt="logo" />
      </router-link>
      <ProgressCircularTimeout v-if="isSaving" color="secondary" />
      <div v-if="isSaving" class="text-subtitle-1 ml-2 primary--text">Saving...</div>
      <v-spacer />
      <OrganizationMenu v-if="organization" />
      <div class="mr-2 ml-2 hidden-sm-and-down">
        <Account />
      </div>
      <!-- <div class="mr-2 ml-2 hidden-sm-and-down">
        <ThemeSwitcher />
      </div> -->
      <Feedback />
      <v-btn
        color="#1e0b4c"
        class="ml-2"
        title="Install Chrome Extension"
        target="_blank"
        rel="noopener noreferrer"
        href="https://chrome.google.com/webstore/detail/bright-future-chrome-exte/pjecpfligbeaonappgcdcncbodbdcble"
      >
        <v-icon>{{ mdiPuzzleOutline }}</v-icon>
      </v-btn>
      <v-menu v-if="isInRole('Administrators')" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" text outlined v-bind="attrs" v-on="on">
            <v-icon color="#1e0b4c">{{ mdiCog }}</v-icon>
          </v-btn>
        </template>
        <AdminMenuDrawer />
      </v-menu>
    </v-app-bar>

    <v-main>
      <Notifications />
      <Breadcrumbs />
      <keep-alive max="1">
        <router-view :key="$route.fullPath" />
      </keep-alive>
      <ToastSave />
      <!--  -->
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { mdiHome, mdiCog, mdiPuzzleOutline } from '@mdi/js';
import menuItems from '../menuItems';
import Account from '../components/Account.vue';
import AzureAuth from '../components/AzureAuth.vue';
import Breadcrumbs from '../components/Breadcrumbs.vue';
import FavouriteService from '../components/FavouriteService.vue';
import Feedback from '../components/Feedback.vue';
import MenuDrawer from '../components/MenuDrawer.vue';
import AdminMenuDrawer from '../components/AdminMenuDrawer.vue';
import OrganizationMenu from '../components/OrganizationMenu.vue';
import ToastSave from '../components/ToastSave.vue';
import Notifications from '../components/Notifications.vue';
// import ThemeSwitcher from '../components/ThemeSwitcher.vue';

export default {
  name: 'Content',
  mixins: [menuItems],
  data() {
    return {
      drawer: true,
      mdiHome,
      mdiCog,
      mdiPuzzleOutline,
    };
  },
  components: {
    Account,
    AdminMenuDrawer,
    AzureAuth,
    Notifications,
    // eslint-disable-next-line vue/no-unused-components
    Breadcrumbs,
    FavouriteService,
    Feedback,
    MenuDrawer,
    OrganizationMenu,
    // OrganizationSelect,
    ToastSave,
    // ThemeSwitcher,
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapState('app', ['isSaving']),
    ...mapState('organizations', ['organization']),
    ...mapState('notifications', ['notificationMessage']),
    topMenuItems() {
      return this.menuItems.filter((m) => m.topMenu === true);
    },
  },
  methods: {
    ...mapMutations('notifications', ['SET_notificationMessage']),
  },
};
</script>
