<template>
  <v-menu offset-y v-if="organizations.length > 0">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="#1e0b4c"
        v-bind="attrs"
        v-on="on"
        text
        outlined
        :loading="isLoading"
      >
        <v-icon left>{{ mdiOfficeBuilding }}</v-icon>
        <v-icon color="#1e0b4c">{{ mdiMenuDown }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="org in organizations"
        :key="org.id"
        @click="organizationChanged(org)"
      >
        <v-list-item-title>{{ org.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiMenuDown, mdiOfficeBuilding } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import organizationSelectors from './organizationSelectors';

export default {
  name: 'OrganizationSelect',
  mixins: [loading, organizationSelectors],
  data() {
    return {
      /** icons */
      mdiMenuDown,
      mdiOfficeBuilding,
    };
  },
  computed: {
    title() {
      return this.localOrganization?.name ?? this.$t('common.organization.select');
    },
  },
};
</script>
