import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('client', 'clients');

const customActions = {
  async LOAD_clients({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('clients')
      .query({ $filter: 'IsDeleted eq false', $orderby: 'Name' });
    commit('SET_clients', data);
    return data;
  },
};

export default { ...actions, ...customActions };
