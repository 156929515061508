<template>
  <v-autocomplete
    v-model="selectedTeam"
    :loading="isLoading"
    :items="teams"
    item-text="displayName"
    item-value="id"
    filled
    label="Select a Team"
  >
    <template v-slot:append-outer>
      <v-btn
        icon
        @click="refresh"
      >
        <v-icon>
          {{ mdiRefresh }}
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:prepend-inner>
      <v-dialog
        v-model="dialog"
        fullscreen
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            small
            v-bind="attrs"
            v-on="on"
            @click="checkAccess"
          >
            <v-icon>
              {{ mdiPlus }}
            </v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            New Team
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Team Name"
              v-model="name"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="dialog = false" text>Cancel</v-btn>
            <v-spacer />
            <v-btn :loading="isSaving" @click="create" color="success">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-autocomplete>
</template>

<script>
import { mdiPlus, mdiRefresh } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { useAuth } from '../use';
import teams from '../mixins/teams';

const auth = useAuth();

export default {
  name: 'SelectTeam',
  mixins: [loading, teams],
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      dialog: false,
      mdiPlus,
      mdiRefresh,
      teams: [],
      name: '',
      selectedTeam: null, // The selected team's id will be stored in this property
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedTeam = this.value;
      },
    },
    selectedTeam: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.selectedTeam);
      },
    },
  },
  mounted() {
    this.selectedTeam = this.value;
  },
  async created() {
    await this.refresh();
  },
  methods: {
    async checkAccess() {
      const scopes = ['https://graph.microsoft.com/Team.Create'];
      try {
        await auth.getScopedAccessToken(scopes);
      } catch (error) {
        this.$root.$emit('toast:error', 'You do not have permission to create a team');
        this.$log.error('You do not have permission to create a team:', error);
      }
    },
    async refresh() {
      this.isLoading = true;
      try {
        this.$log.info('Getting teams');
        this.teams = await this.fetchGraph(
          'https://graph.microsoft.com/v1.0/me/joinedTeams',
          [
            'https://graph.microsoft.com/Team.ReadBasic.All',
          ],
        );
      } catch (error) {
        this.$root.$emit('toast:error', 'Unable to get teams');
        this.$log.error('Unable to get teams:', error);
      }
      this.isLoading = false;
    },
    /**
     * Create team
     */
    async create() {
      this.isSaving = true;
      try {
        const data = {
          'template@odata.bind': "https://graph.microsoft.com/v1.0/teamsTemplates('standard')",
          displayName: this.name,
          description: '',
        };
        await this.postGraph(
          'https://graph.microsoft.com/v1.0/teams',
          [
            'https://graph.microsoft.com/Team.Create',
          ],
          data,
        );
        this.dialog = false;
      } catch (error) {
        this.$root.$emit('toast:error', 'Unable to get teams');
        this.$log.error('Unable to get teams:', error);
      }
      this.isSaving = false;
      await this.refresh();
    },
  },
};
</script>
