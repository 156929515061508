var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          staticClass: "confirm-button",
          class: { "is-confirming": _vm.isConfirm },
          attrs: {
            "data-cy": _vm.dataCy,
            title: _vm.isConfirm ? "Click again to confirm" : _vm.title,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onClick.apply(null, arguments)
            },
          },
        },
        "v-btn",
        _vm.$props,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.isConfirm
        ? _vm._t("confirm", function () {
            return [
              _c(
                "v-progress-circular",
                {
                  class: { "mr-2": !_vm.icon },
                  attrs: { value: _vm.progressValue, size: _vm.icon ? 26 : 20 },
                },
                [_vm.icon ? _c("span", [_vm._v("?")]) : _vm._e()]
              ),
              !_vm.icon
                ? _c("span", [_vm._v(_vm._s(_vm.confirmationText))])
                : _vm._e(),
            ]
          })
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }