<template>
  <div class="fill-width">
    <slot />
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Column</span>
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="newColumn" label="Column Name" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="addColumn">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-simple-table class="table-document-template">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="index"
              :class="{ 'small-column': columnWidths[column] === 'small' }">
              <div class="d-flex justify-space-between align-center">
                <div class="text-h5">
                  {{ column }}
                </div>
                <div class="d-flex flex-column">
                  <ResizeColumnButton
                    small
                    color="secondary"
                    @resize="resizeColumn(column, $event)"
                  />
                  <ConfirmButton
                    small
                    icon
                    title="Delete column"
                    color="error"
                    @confirm-click="deleteColumn(index)"
                  >
                    <v-icon>
                      {{ mdiTrashCan }}
                    </v-icon>
                  </ConfirmButton>
                </div>
              </div>
            </th>
            <th class="text-center">
              <v-btn
                small
                color="secondary"
                data-cy="FieldTable-addColumn"
                title="Add a new column"
                @click="dialog = true"
              >
                <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
                Add a Column
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
            <td
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              :class="{
                'small-column': columnWidths[column] === 'small',
                'medium-column': columnWidths[column] === 'medium',
                'large-column': columnWidths[column] === 'large',
              }">
              <v-textarea
                dense
                v-model="row[column]"
                rows="1"
                auto-grow
                @input="$emit('input', localValue)"
                @change="$emit('change', localValue)"
              />
            </td>
            <td>
              <ConfirmButton
                class="mt-2"
                small
                color="error"
                @confirm-click="deleteRow(rowIndex)"
                title="Delete this row"
              >
                <v-icon class="mr-2">{{ mdiTrashCan }}</v-icon>
                Delete Row
              </ConfirmButton>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="columns.length > 0">
          <tr>
            <td :colspan="columns.length" />
            <td>
              <v-btn small @click="addRow" color="secondary">
                <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
                Add a Row
              </v-btn>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>

  </div>
</template>

<script>
import { mdiTrashCan, mdiPlus } from '@mdi/js';
import ResizeColumnButton from './ResizeColumnButton.vue';

export default {
  name: 'FieldTable',
  components: {
    ResizeColumnButton,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        rows: [],
        columns: [],
      }),
    },
  },
  data() {
    return {
      mdiTrashCan,
      mdiPlus,
      columns: [],
      rows: [],
      dialog: false,
      newColumn: '',
      columnWidths: {}, // Store individual column widths
    };
  },
  computed: {
    localValue() {
      return {
        rows: this.rows,
        columns: this.columns,
        columnWidths: this.columnWidths,
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.columns = this.value?.columns ?? [];
        this.rows = this.value?.rows ?? [];
        this.columnWidths = this.value?.columnWidths ?? {};
      },
    },
  },
  methods: {
    addColumn() {
      if (this.newColumn) {
        this.columns.push(this.newColumn);
        this.newColumn = '';
        this.dialog = false;
      }
    },
    deleteColumn(index) {
      this.columns.splice(index, 1);
    },
    addRow() {
      const newRow = {};
      this.columns.forEach((column) => {
        newRow[column] = '';
      });
      this.rows.push(newRow);
    },
    deleteRow(index) {
      this.rows.splice(index, 1);
    },
    resizeColumn(column, size) {
      this.columnWidths = { ...this.columnWidths, [column]: size };
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
  },
};
</script>
