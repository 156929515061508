import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('feedbackComment', 'feedbackComments');

const customActions = {
  async LOAD_feedbackComments({ commit, rootGetters }, feedbackItemId) {
    const data = await odata.o(rootGetters)
      .get('feedbackComments')
      .query({
        $filter: `IsDeleted eq false AND FeedbackItemId eq ${feedbackItemId}`,
        $orderby: 'Created desc',
      });
    commit('SET_feedbackComments', data);
    return data;
  },
};

export default { ...actions, ...customActions };
