<template>
  <v-card class="mx-auto" :loading="isLoading" flat>
    <v-card-text v-if="jobListingCandidate">
      <SendMessage
        :jobListingCandidate="jobListingCandidate"
        @comment="comment"
        @email="sendEmail"
        @text="sendText"
        @whatsApp="sendWhatsApp"
        @phoneCall="sendPhoneCall"
      />
    </v-card-text>
    <v-divider />
    <v-list two-line v-if="!isLoading">
      <template v-for="(item, index) in feedItems">
        <v-list-item
          :key="index"
        >
          <v-list-item-content>
            <v-divider v-if="index !== 0" inset class="mb-5" />
            <div v-if="item.attachments?.length > 0" class="mb-3">
              <v-chip
                v-for="(attachment, index) in item.attachments"
                :key="index"
                class="mr-2 mb-2"
                @click="downloadAttachment(attachment)"
                title="Attachment, click to download"
              >
                {{ attachment.name }}
              </v-chip>
            </div>
            <div v-if="item.messageType === 'email'" class="d-flex justify-space-between align-center">
              <PreviewEmail
                :message="item"
                label="Show more..."
                @click="dialog = true"
              />
              <span class="ml-2 d-block">Subject: {{ item.title }}</span>
            </div>
            <div
              v-else-if="item.messageType === 'whatsApp' || item.messageType === 'text'"
              class="d-flex justify-start align-center"
            >
              <MessageHistory
                :message="item"
                @click="dialog = true"
              />
              <span class="ml-2" v-html="item.subtitle" />
            </div>
            <div v-else v-html="item.subtitle" />
            <div class="text-left d-flex justify-space-between mt-3" style="font-size: smaller;">
              <span>
                {{ item.formattedMessageType }}
                <span v-if="item.from?.length > 0">
                  from <DisplayName :user="item.from" />
                </span>
                <span v-else-if="item.createdBy">
                  by <strong><DisplayName :user="item.createdBy" /></strong>
                </span>
                <span
                  v-if="item.messageType === 'whatsApp' || item.messageType === 'text'"
                  class="mr-1"
                >
                  ({{ item.status }})
                </span>
              </span>
              <div class="d-flex align-center">
                <v-icon class="mr-1" small>{{ mdiClockOutline }}</v-icon>
                <span v-html="item.created" />
              </div>
            </div>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon
              v-if="item.isRead || item.messageType === 'comment'"
              color="grey lighten-1"
            >
              {{ item.icon}}
            </v-icon>
            <v-icon
              v-else
              color="yellow darken-3"
              @click="markAsRead(item)"
            >
              {{ item.icon}}
            </v-icon>
          </v-list-item-action>

        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  mdiComment, mdiEmail, mdiMessageProcessing, mdiWhatsapp, mdiPhone, mdiClockOutline, mdiRefresh,
} from '@mdi/js';
import axios from 'axios';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useAuth } from '../../../use';
import teams from '../../../mixins/teams';
import feedItems from '../mixins/feedItems';
import MessageHistory from './MessageHistory.vue';
import PreviewEmail from './PreviewEmail.vue';
import SendMessage from './SendMessage.vue';

dayjs.extend(relativeTime);

const auth = useAuth();

export default {
  name: 'JobListingCandidateFeed',
  components: {
    MessageHistory,
    PreviewEmail,
    SendMessage,
  },
  mixins: [loading, teams, feedItems],
  props: {
    jobListingCandidate: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      mdiClockOutline,
      mdiMessageProcessing,
      mdiWhatsapp,
      mdiPhone,
      mdiComment,
      mdiEmail,
      mdiRefresh,
    };
  },
  computed: {
    ...mapState('auth', ['username']),
  },
  watch: {
    jobListingCandidate() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('jobListingCandidateComments', [
      'LOAD_jobListingCandidateComments',
      'CREATE_jobListingCandidateComment',
    ]),
    ...mapActions('messages', [
      'LOAD_jobListingCandidateMessages',
      'UPDATE_message',
    ]),
    ...mapActions('attachments', ['CREATE_attachment']),
    ...mapMutations('jobListingCandidateComments', [
      'SET_jobListingCandidateComments',
    ]),
    ...mapMutations('messages', [
      'SET_messages',
    ]),
    formatRelative(dt) {
      return dayjs(dt).fromNow();
    },
    formatMessageType(messageType) {
      switch (messageType) {
        case 'email':
          return 'Email';
        case 'text':
          return 'Text';
        case 'whatsApp':
          return 'WhatsApp';
        case 'phoneCall':
          return 'Phone Call';
        default:
          return 'Message';
      }
    },
    getIcon(messageType) {
      switch (messageType) {
        case 'email':
          return mdiEmail;
        case 'text':
          return mdiMessageProcessing;
        case 'whatsApp':
          return mdiWhatsapp;
        case 'phoneCall':
          return mdiPhone;
        default:
          return mdiEmail;
      }
    },
    async addComment() {
      this.isSaving = true;
      await this.CREATE_jobListingCandidateComment({
        jobListingCandidateId: this.jobListingCandidate.id,
        message: this.message,
      });
      this.message = '';
      this.isSaving = false;
      this.$emit('refresh');
    },
    async markAsRead(item) {
      await this.UPDATE_message({
        id: item.id,
        isRead: true,
      });
      await this.refresh();
      this.$emit('refresh');
    },
    async refresh() {
      this.SET_jobListingCandidateComments([]);
      this.SET_messages([]);
      if (this.jobListingCandidate) {
        this.isLoading = true;
        await this.LOAD_jobListingCandidateComments(this.jobListingCandidate.id);
        await this.LOAD_jobListingCandidateMessages(this.jobListingCandidate.id);
        this.isLoading = false;
      }
    },
    async comment(form) {
      await this.CREATE_jobListingCandidateComment({
        jobListingCandidateId: this.jobListingCandidate.id,
        message: form.message,
      });
      await this.refresh();
      this.$emit('refresh');
    },
    async sendMessage(message) {
      const token = await auth.getAccessToken();
      const msg = await axios.post('api/messages/send', message, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.$root.$emit('toast:notify', 'Message queued successfully');
      await this.refresh();
      return msg.data;
    },
    async sendEmail(form) {
      const attachments = (form.attachments || [])
        .map((a) => ({
          contentBytes: a.contentBytes,
          contentType: a.contentType,
          name: a.name,
        }));
      const msg = await this.sendMessage({
        jobListingCandidateId: this.jobListingCandidate.id,
        recipient: form.email, // Add cc here
        from: this.username,
        subject: form.subject,
        body: form.message,
        messageType: 'email',
      });
      for (let i = 0; i < attachments.length; i += 1) {
        const attachment = {
          ...attachments[i],
          messageId: msg.id,
        };
        this.CREATE_attachment(attachment);
      }
      if (!form.saveOnly) {
        try {
          const token = await auth.getScopedAccessToken(['Mail.Send']);
          const endpoint = 'https://graph.microsoft.com/v1.0/me/sendMail';
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const data = {
            message: {
              attachments: form.attachments || [],
              subject: form.subject,
              body: {
                contentType: 'HTML',
                content: form.message,
              },
              toRecipients: [
                {
                  emailAddress: {
                    address: form.email,
                  },
                },
              ],
              ccRecipients: [
                {
                  emailAddress: {
                    address: 'system@aims-platform.com',
                  },
                },
              ],
            },
          };
          await axios.post(endpoint, data, config);
          this.$root.$emit('toast:notify', 'Email sent successfully');
        } catch (error) {
          this.$log.error(error);
          this.$root.$emit('toast:error', 'Unable to send email');
        }
      }
    },
    async sendText(form) {
      await this.sendMessage({
        jobListingCandidateId: this.jobListingCandidate.id,
        from: form.from,
        recipient: form.recipient,
        body: form.message,
        messageType: 'text',
      });
    },
    async sendWhatsApp(form) {
      await this.sendMessage({
        jobListingCandidateId: this.jobListingCandidate.id,
        from: form.from,
        recipient: form.recipient,
        subject: form.subject,
        body: form.message,
        messageType: 'whatsApp',
      });
    },
    async sendPhoneCall(form) {
      await this.sendMessage({
        jobListingCandidateId: this.jobListingCandidate.id,
        recipient: form.phone,
        subject: form.subject,
        body: form.message,
        messageType: 'phoneCall',
      });
    },
    async downloadAttachment(attachment) {
      const token = await auth.getAccessToken();
      const response = await axios.get(`odata/attachments(${attachment.id})?$select=contentBytes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const dataUrl = `data:${attachment.contentType};base64,${response.data.contentBytes}`;
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.name);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
