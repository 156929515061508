<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
  >
    <v-container class="text-center">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="localDocumentTemplate.name"
            filled
            data-cy="FormDocumentTemplate-name"
            :label="$$t('name')"
          />
        </v-col>
        <v-col cols="12" class="text-center">
          <FileUpload
            v-model="localDocumentTemplate.fileUrl"
            data-cy="FormDocumentTemplate-fileUrl"
            label="File"
            @input="fileUploaded"
          />
          <v-btn
            v-if="localDocumentTemplate.fileUrl"
            :href="localDocumentTemplate.fileUrl"
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            class="mt-5">
            <v-icon left>
              {{ mdiCloudDownload }}
            </v-icon>
            {{ localDocumentTemplate.filename }}
          </v-btn>
        </v-col>
      </v-row>
      <div class="d-flex justify-end mb-6 mt-5">
        <v-btn
          color="default"
          outlined
          class="ml-4 btn-cancel"
          data-cy="FormDocumentTemplate-button-cancel"
          @click="cancel"
        >
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          class="ml-4"
          :loading="isSaving"
          data-cy="FormDocumentTemplate-button-save"
        >
          {{ $t('common.buttons.save') }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import { mdiCloudDownload } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FileUpload from '../../../components/FileUpload.vue';

export default {
  name: 'FormDocumentTemplate',
  module: 'Admin',
  components: {
    FileUpload,
  },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    documentTemplate: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,

      /**
       * Icons
       */
      mdiCloudDownload,

      /**
      * Local value used for property localDocumentTemplate
      */
      localDocumentTemplate: {},
    };
  },
  computed: {
    ...mapState('documentTemplates', ['templateTypes']),
  },
  watch: {
    DocumentTemplate() {
      this.localDocumentTemplate = this.documentTemplate;
    },
  },
  created() {
    this.localDocumentTemplate = this.documentTemplate;
  },
  methods: {
    fileUploaded(file) {
      this.$log.debug(file);
      this.localDocumentTemplate.fileUrl = file.url;
      this.localDocumentTemplate.filename = file.uploadedFile.fileName;
      this.localDocumentTemplate.contentType = file.uploadedFile.contentType;
      this.localDocumentTemplate.fileSize = file.contentLength;
      this.localDocumentTemplate.uploadFileId = file.uploadedFile.id;
    },

    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localDocumentTemplate);
        this.isSaving = true;
      }
    },

    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormDocumentTemplate example

'''js
<FormDocumentTemplate :DocumentTemplate="DocumentTemplate" />
'''
</docs>
