var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "EditPage",
    {
      attrs: { title: _vm.mode === "edit" ? "Edit Template" : "New Template" },
    },
    [
      _c(
        "v-skeleton-loader",
        {
          attrs: {
            loading: _vm.isLoading,
            transition: "expand-transition",
            type: "article",
          },
        },
        [
          _c("FormDocumentTemplate", {
            attrs: {
              "document-template": _vm.editDocumentTemplate,
              "is-saving": _vm.isSaving,
            },
            on: {
              save: _vm.save,
              cancel: function ($event) {
                return _vm.$router.push({ name: _vm.from.name })
              },
            },
          }),
        ],
        1
      ),
      _vm.editDocumentTemplate.id
        ? _c("ListFields", {
            attrs: { documentTemplateId: _vm.documentTemplateId },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }