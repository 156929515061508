<template>
  <v-btn
    v-bind="$props"
    :data-cy="dataCy"
    v-on="$listeners"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <slot name="icon">
      <v-icon :class="{ 'mr-2': hover }">{{ labelIcon }}</v-icon>
    </slot>
    <slot v-if="hover" name="label">
      <span :class="{ 'is-visible': hover }">{{ label }}</span>
    </slot>
  </v-btn>
</template>

<script>
import { VBtn } from 'vuetify/lib';
import { mdiPlus } from '@mdi/js';

export default {
  name: 'ExpandingButton',
  extends: VBtn,
  props: {
    labelIcon: {
      type: String,
      default: mdiPlus,
    },
    label: {
      type: String,
      default: 'Add',
    },
    dataCy: {
      type: String,
      default: 'ExpandingButton',
    },
  },
  data() {
    return {
      mdiPlus,
      hover: false,
    };
  },
};
</script>
