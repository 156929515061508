<template>
  <v-data-table
    :items="exchangeEmails"
    :headers="headers"
    :loading="isLoading"
    item-key="id"
    :items-per-page="25"
    :footer-props="{
      itemsPerPageOptions: [25, 50, 100, 250, 500, -1],
    }"
    sort-by="dateTimeReceived"
    sort-desc
  >
    <template v-slot:item.to="{ item }">
      <v-chip
        v-for="(to, index) in item.to"
        :key="index"
        class="mr-2"
        color="accent"
      >
        <a :href="`mailto:${to.address}`">
          {{ to.name }}
        </a>
      </v-chip>
    </template>
    <template v-slot:item.dateTimeReceived="{ item }">
      {{ formatDateTime(item.dateTimeReceived) }}
    </template>
    <template v-slot:actions="{ item }">
      {{ item.messageId }}
      <MessageHistory
        v-if="item.messageId"
        :label="`History for ${item.subject}`"
        :message="{
          id: item.messageId,
        }"
        @click="dialog = true"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import MessageHistory from '../modules/clients/components/MessageHistory.vue';

export default {
  name: 'ExchangeMails',
  components: {
    MessageHistory,
  },
  mixins: [loading],
  computed: {
    ...mapState('exchangeEmails', ['exchangeEmails']),
    headers() {
      return [
        {
          text: 'To',
          value: 'to',
        },
        {
          text: 'Subject',
          value: 'subject',
        },
        {
          text: 'Date Time Received',
          value: 'dateTimeReceived',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('exchangeEmails', ['LOAD_exchangeEmails']),
    async refresh() {
      this.isLoading = true;
      await this.LOAD_exchangeEmails();
      this.isLoading = false;
    },
  },
};
</script>
