<template>
  <div>
    <v-alert
      v-for="notification in allNotifications"
      :key="notification.id"
      :type="notification.notificationType"
      :value="true"
      dense
      @input="remove(notification)"
      class="notification-message"
      dismissible
    >
      <span v-html="notification.message" />
    </v-alert>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { mdiCheckCircle, mdiAlertCircle } from '@mdi/js';

export default {
  name: 'NotificationComponent',
  data() {
    return {
      mdiCheckCircle,
      mdiAlertCircle,
      notifications: [],
    };
  },
  computed: {
    ...mapState('auth', ['userId']),
    ...mapState('notifications', ['previewNotifications']),
    allNotifications() {
      return [...this.notifications, ...this.previewNotifications];
    },
  },
  watch: {
    userId() {
      this.refresh();
    },
    $route() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('notificationReceipts', ['CREATE_notificationReceipt']),
    ...mapActions('notifications', ['LOAD_notificationsForUser']),
    async remove(notification) {
      await this.CREATE_notificationReceipt({
        notificationId: notification.id,
        userId: this.userId,
      });
      await this.refresh();
    },
    async refresh() {
      if (this.userId) {
        this.notifications = await this.LOAD_notificationsForUser(this.userId);
      }
    },
    startNotificationTimer(index) {
      setTimeout(() => {
        this.removeNotification(index);
      }, 2000);
    },
  },
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
