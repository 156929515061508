<template>
  <v-data-table v-bind="$props" v-on="$listeners" :search="localSearch">
    <template v-slot:progress>
      <ProgressLinearTimeout />
    </template>
    <template v-for="slot in slots" v-slot:[`item.${slot.name}`]="{ item, index }">
      <slot :name="slot.name" :item="item" :index="index" />
    </template>
    <template v-slot:top>
      <v-row>
        <v-col cols="2">
          <slot name="new" />
        </v-col>
        <v-col cols="6">
          <slot name="filters" />
        </v-col>
        <v-col cols="3">
          <v-text-field
            clearable
            data-cy="TableBasic-search"
            class="mr-2"
            v-model="localSearch"
            :append-icon="mdiMagnify"
            :label="$t('common.search')"
            single-line
            hide-details
          />
        </v-col>
        <v-col cols="1">
          <v-btn
            class="ml-2 mt-3"
            color="primary"
            icon
            data-cy="TableBasic-button-refresh"
            @click="$emit('refresh')"
          >
            <v-icon class="mr-2">
              {{ mdiRefresh }}
            </v-icon>
          </v-btn>
          <ImportExport v-if="showImportExport" :name="name" />
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ name }}</span>
          </v-card-title>
          <v-card-text>
            <slot name="form" :dialog="dialog" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-data-table>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { VDataTable } from 'vuetify/lib';
import { mdiPlus, mdiMagnify, mdiRefresh } from '@mdi/js';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import ImportExport from './ImportExport.vue';

export default {
  name: 'DataTable',
  extends: VDataTable,
  components: {
    ImportExport,
    ProgressLinearTimeout,
  },
  props: {
    name: {
      type: String,
      default: 'Table',
    },
    showNew: {
      type: Boolean,
      default: true,
    },
    showImportExport: {
      type: Boolean,
      default: false,
    },
    slots: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // icons
      mdiPlus,
      mdiMagnify,
      mdiRefresh,
      // data
      dialog: false,
    };
  },
  computed: {
    ...mapState('app', ['tables']),
    /**
     * Computed property for the localSearch vuex property
     */
    localSearch: {
      get() {
        const { tables } = this;
        const table = tables?.find((t) => t.name === this.name);
        return table?.search ?? '';
      },
      set(newValue) {
        const { tables } = this;
        const table = tables?.find((t) => t.name === this.name);
        if (table) {
          table.search = newValue;
          this.SET_table(table);
        } else {
          this.ADD_table({
            name: this.name,
            search: newValue ?? '',
          });
        }
      },
    },
  },
  methods: {
    ...mapMutations('app', ['SET_table', 'ADD_table']),
    /**
     * Executes create action
     */
    create() {
      this.$emit('creating');
      this.dialog = true;
    },
  },
};
</script>
