var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-data-table",
    _vm._g(
      _vm._b(
        {
          attrs: { search: _vm.localSearch },
          scopedSlots: _vm._u(
            [
              {
                key: "progress",
                fn: function () {
                  return [_c("ProgressLinearTimeout")]
                },
                proxy: true,
              },
              _vm._l(_vm.slots, function (slot) {
                return {
                  key: `item.${slot.name}`,
                  fn: function ({ item, index }) {
                    return [
                      _vm._t(slot.name, null, { item: item, index: index }),
                    ]
                  },
                }
              }),
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      [
                        _vm.showNew
                          ? _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      color: "success",
                                      "data-cy": "TableBasic-button-new",
                                    },
                                    on: { click: _vm.create },
                                  },
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.buttons.new")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("v-col", { attrs: { cols: "4" } }, [
                          _c("h2", [_vm._v(" " + _vm._s(_vm.name) + " ")]),
                        ]),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_vm._t("filters")],
                          2
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              staticClass: "mr-2",
                              attrs: {
                                clearable: "",
                                "data-cy": "TableBasic-search",
                                "append-icon": _vm.mdiMagnify,
                                label: _vm.$t("common.search"),
                                "single-line": "",
                                "hide-details": "",
                              },
                              model: {
                                value: _vm.localSearch,
                                callback: function ($$v) {
                                  _vm.localSearch = $$v
                                },
                                expression: "localSearch",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2 mt-3",
                                attrs: {
                                  color: "primary",
                                  icon: "",
                                  "data-cy": "TableBasic-button-refresh",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("refresh")
                                  },
                                },
                              },
                              [
                                _c("v-icon", { staticClass: "mr-2" }, [
                                  _vm._v(" " + _vm._s(_vm.mdiRefresh) + " "),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "500px" },
                        model: {
                          value: _vm.dialog,
                          callback: function ($$v) {
                            _vm.dialog = $$v
                          },
                          expression: "dialog",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", [
                              _c("span", { staticClass: "headline" }, [
                                _vm._v(_vm._s(_vm.name)),
                              ]),
                            ]),
                            _c(
                              "v-card-text",
                              [_vm._t("form", null, { dialog: _vm.dialog })],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "v-data-table",
        _vm.$props,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }