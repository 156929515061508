<template>
  <div>
    <ProgressCircularTimeout v-if="isLoading" />
    <div v-else>
      <a v-if="channel && team" :href="teamsUrl" target="_blank" rel="noopener noreferrer">
        {{ team.displayName }} / {{ channel.displayName }}
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { InteractiveBrowserCredential } from '@azure/identity';

export default {
  name: 'TeamLink',
  props: {
    teamId: {
      type: String,
      default: null,
    },
    teamChannelId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      channel: undefined,
      team: undefined,
    };
  },
  computed: {
    teamsUrl() {
      const { teamChannelId, channel } = this;
      return `https://teams.microsoft.com/l/channel/${teamChannelId}/${channel?.displayName}?groupId=${channel?.id}&tenantId=${channel?.tenantId}`;
    },
  },
  mounted() {
    this.getChannelData();
  },
  methods: {
    async getChannelData() {
      if (this.teamId === null || this.teamChannelId === null) {
        return;
      }
      const credential = new InteractiveBrowserCredential({
        clientId: process.env.VUE_APP_AZURE_IDENTITY_CLIENT_ID,
        tenantId: process.env.VUE_APP_AZURE_IDENTITY_TENANT_ID,
      });
      const scope = 'https://graph.microsoft.com/Team.ReadBasic.All'; // replace with your scope
      const tokenResponse = await credential.getToken(scope);
      const { accessToken } = tokenResponse;

      try {
        const teamResponse = await axios.get(`https://graph.microsoft.com/v1.0/teams/${this.teamId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.team = teamResponse.data;

        const channelResponse = await axios.get(`https://graph.microsoft.com/v1.0/teams/${this.teamId}/channels/${this.teamChannelId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.$log.debug(channelResponse);
        this.channel = channelResponse.data;
      } catch (error) {
        this.$log.error(error);
      }
    },
  },
};
</script>
