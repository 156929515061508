<template>
  <v-container fluid>
    <DataTable
      :headers="headers"
      :items="clients"
      class="elevation-1"
      :loading="isLoading"
      :slots="[
        { name: 'website' },
        { name: 'email' },
        { name: 'phone' },
        { name: 'actions' },
        { name: 'name' },
        { name: 'imageUrl' },
      ]"
      @refresh="refresh"
    >
      <template v-slot:new>
        <v-btn
          class="ml-2"
          color="success"
          data-cy="Client-button-new"
          href="https://app.hubspot.com/contacts/1883769/objects/0-2/views/all/list"
          rel="noopener noreferrer"
          target="_blank"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
      </template>
      <template v-slot:name="{ item }">
        <router-link
          :to="{
            name: 'clients-EditClient',
            query: { id: item.id },
          }">
          {{ item.name }}
        </router-link>
        <v-btn
          v-if="item.website"
          small
          icon
          :href="item.website"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon small>{{ mdiOpenInNew }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:imageUrl="{ item }">
        <v-img :src="item.imageUrl" width="128" contain />
      </template>
      <template v-slot:email="{ item }">
        <a :href="`mailto:${item.email}`">
          {{ item.email }}
        </a>
      </template>
      <template v-slot:phone="{ item }">
        <a :href="`tel:${item.phone}`" rel="noopener noreferrer">
          {{ item.phone }}
        </a>
      </template>
      <template v-slot:website="{ item }">
        <a :href="item.website" target="_blank" rel="noopener noreferrer">
          {{ item.website }}
        </a>
      </template>
      <template v-slot:actions="{ item }">
        <TableBasicButtons
          :editLink="{
            name: 'clients-EditClient',
            query: { id: item.id },
          }"
          @delete="remove(item)"
        />
      </template>
    </DataTable>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiOpenInNew } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import refresh from '../../../mixins/refresh';

export default {
  name: 'ListClient',
  module: 'Clients',
  components: {
    TableBasicButtons,
  },
  mixins: [loading, refresh],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.contacts',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiOpenInNew,
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('clients', ['clients']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: '',
          value: 'imageUrl',
          width: '128px',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('email'),
          value: 'email',
        },
        {
          text: this.$$t('phone'),
          value: 'phone',
        },
        {
          text: this.$$t('location'),
          value: 'location',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    await this.refresh();
    this.$root.$on('page.refresh', () => {
      this.$log.debug('refresh event received');
      if (this.refresh) {
        this.refresh();
      }
    });
  },
  destroyed() {
    this.$root.$off('page.refresh');
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('clients', ['LOAD_clients', 'DELETE_client']),
    /** Refresh the list
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_clients();
      this.isLoading = false;
    },
    /** Delete Client from the collection
     */
    async remove(client) {
      this.isLoading = true;
      await this.DELETE_client(client.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Client example

'''js
<Client />
'''
</docs>
