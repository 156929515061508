<template>
  <div>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 sm8 offset-sm2>
          <v-card>
            <v-card-title>
              <span class="headline">Profile</span>
            </v-card-title>
            <v-card-text class="black--text">
              <v-form v-if="userProfile">
                <v-text-field
                  v-model="email"
                  class="black-disabled-input"
                  label="Email"
                  disabled
                />
                <v-text-field
                  v-model="userId"
                  class="black-disabled-input"
                  label="User ID"
                  disabled
                />
                <ClipboardTextField
                  v-model="apiKey"
                  class="black-disabled-input"
                  label="API Key"
                  disabled
                />
                <v-text-field
                  v-model="userProfile.displayName"
                  class="black-disabled-input"
                  label="Display Name"
                  required
                />
                <div class="text-h6 d-flex align-center justify-space-between mb-5">
                  <span>
                    Email Signature
                  </span>
                  <PreviewEmail
                    label="Preview"
                    title="Preview an example email message"
                    :message="emailMessagePreview"
                  />
                </div>
                <vue-editor
                  v-model="userProfile.emailTemplate"
                  label="Email Template"
                />
                <div class="d-flex justify-space-between">
                  <v-btn
                    class="mt-5"
                    color="error"
                    :loading="isSaving"
                    @click="resetSignature"
                  >
                    Reset Signature
                  </v-btn>
                  <v-btn
                    class="mt-5"
                    color="success"
                    :loading="isSaving"
                    @click="saveProfile"
                  >
                    Save
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { useAuth } from '../use';
import emailTemplate from './EmailTemplate.txt';
import PreviewEmail from '../modules/clients/components/PreviewEmail.vue';
import baseTemplate from '../modules/clients/components/EmailTemplate.txt';
import lorem from './LoremIpsum.txt';

const auth = useAuth();

export default {
  name: 'Profile',
  components: {
    PreviewEmail,
  },
  mixins: [loading],
  data() {
    return {
      email: '',
      userId: '',
      apiKey: '',
      user: undefined,
      tokenExpires: undefined,
      userProfile: undefined,
    };
  },
  computed: {
    emailMessagePreview() {
      const htmlMessage = baseTemplate
        .replace('{{ body }}', lorem)
        .replace('{{ signature }}', this.userProfile?.emailTemplate);
      return {
        title: 'This is an example of what your email signature will look like.',
        subtitle: htmlMessage,
      };
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.user = await auth.userAgentApplication.getAccount();
      const { data } = await this.$http.get('/api/org');
      this.email = data.user;
      this.userId = data.userId;
      this.apiKey = data.apiKey;
      await this.loadProfile();
      if (this.userProfile.displayName !== this.user.name) {
        this.userProfile.displayName = this.user.name;
        await this.saveProfile();
      }
    },
    async resetSignature() {
      this.userProfile.emailTemplate = emailTemplate
        .replace('{{ name }}', this.user.name)
        .replace('{{ email }}', this.user.userName)
        .replace('{{ email }}', this.user.userName);
    },
    async loadProfile() {
      const { data } = await this.$http.get('/api/profile');
      this.userProfile = data;
    },
    async saveProfile() {
      this.isSaving = true;
      try {
        this.userProfile.email = this.email;
        const profile = {
          ...this.userProfile,
        };
        const { data } = await this.$http.put('/api/profile', profile);
        this.userProfile = data;
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error updating profile');
      }
      this.isSaving = false;
    },
  },
};
</script>
