<template>
  <TableModalForm
    :headers="headers"
    :items="stages"
    class="elevation-1"
    :loading="isLoading"
    name="Phases"
    ref="table"
    :slots="[{ name: 'actions' }, { name: 'order' }]"
    show-select
    single-select
    sort-by="order"
    @creating="creating"
    @refresh="refresh"
    @item-selected="itemSelected"
  >
    <template v-slot:form>
      <FormStage
        :Stage="editStage"
        :isSaving="isSaving"
        @save="save"
        @cancel="$refs.table.dialog = false" />
    </template>
    <template v-slot:order="{ item, index }">
      <v-btn icon @click="moveUp(item)">
        <v-icon v-if="index > 0" class="mr-2">{{ mdiArrowUpBold }}</v-icon>
      </v-btn>
      {{ item.order }}
      <v-btn icon @click="moveDown(item)">
        <v-icon v-if="index < (stages.length - 1)" class="mr-2">{{ mdiArrowDownBold }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:actions="{ item }">
      <v-btn-toggle>
        <v-btn color="default" small @click="edit(item)">
          {{ $t('common.buttons.edit')}}
        </v-btn>
        <v-btn color="error" small @click="remove(item)">
          {{ $t('common.buttons.delete')}}
        </v-btn>
      </v-btn-toggle>
    </template>
  </TableModalForm>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiArrowUpBold, mdiArrowDownBold } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import TableModalForm from '@/components/TableModalForm.vue';
import FormStage from '../components/FormStage.vue';

export default {
  name: 'ListStage',
  module: 'Stages',
  components: {
    FormStage,
    TableModalForm,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiArrowUpBold,
      mdiArrowDownBold,
      /**
      * Get/Set editing Stage. Default to {}.
      */
      editStage: {},
      mode: 'add',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('stages', ['stages']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('order'),
          value: 'order',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('stages', [
      'LOAD_stage',
      'CREATE_stage',
      'UPDATE_stage',
      'LOAD_stages',
      'DELETE_stage',
    ]),
    creating() {
      this.editStage = {
        order: this.stages.length + 1,
      };
      this.mode = 'add';
    },
    async edit(item) {
      this.editStage = { ...item };
      this.mode = 'edit';
      this.$refs.table.dialog = true;
    },
    /**
     * Moves an item up in the list
     */
    async moveDown(item) {
      const { stages } = this;
      const next = stages.find((s) => s.order === (item.order + 1));
      const current = stages.find((s) => s.order === item.order);
      next.order = item.order;
      current.order = item.order + 1;
      await this.UPDATE_stage(next);
      await this.UPDATE_stage(current);
      await this.refresh();
    },
    /**
     * Moves an item down in the list
     */
    async moveUp(item) {
      const { stages } = this;
      const prev = stages.find((s) => s.order === (item.order - 1));
      const current = stages.find((s) => s.order === item.order);
      prev.order = item.order;
      current.order = item.order - 1;
      await this.UPDATE_stage(prev);
      await this.UPDATE_stage(current);
      await this.refresh();
    },
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_stages();
      this.isLoading = false;
    },
    /** Delete Stage from the collection
     */
    async remove(stage) {
      this.isLoading = true;
      await this.DELETE_stage(stage.id);
      this.isLoading = false;
    },
    /** Vuex methods
     */
    /** Method used to save the item
     */
    async save(stage) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_stage(stage);
      } else {
        await this.UPDATE_stage(stage);
      }
      this.isSaving = false;
      this.$refs.table.dialog = false;
    },
    itemSelected(item) {
      this.$emit('selected', item);
    },
  },
};
</script>

<docs>
# Stage example

'''js
<Stage />
'''
</docs>
