<template>
  <v-container fluid>
    <ListChecklistTemplate @selected="checklistTemplateSelected" />
    <div class="mt-10" />
    <ListChecklist
      v-if="selectedChecklistTemplate"
      :ChecklistTemplate="selectedChecklistTemplate"
    />
  </v-container>
</template>

<script>
import ListChecklistTemplate from './ListChecklistTemplate.vue';
import ListChecklist from './ListChecklist.vue';

export default {
  name: 'Checklists',
  components: {
    ListChecklistTemplate,
    ListChecklist,
  },
  data() {
    return {
      selectedChecklistTemplate: undefined,
    };
  },
  methods: {
    /**
     * Executes ChecklistTemplate Selected event.
     */
    checklistTemplateSelected(checklistTemplate) {
      if (checklistTemplate.value) {
        this.selectedChecklistTemplate = checklistTemplate.item;
      } else {
        this.selectedChecklistTemplate = undefined;
      }
      this.$log.debug(checklistTemplate);
    },
  },
};
</script>
