var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            small: "",
                            color:
                              _vm.jobListingCandidate.printData?.length > 0
                                ? "success"
                                : "default",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.dialog = true
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiPrinter))])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { loading: _vm.isLoading } },
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v(_vm._s(_vm.mdiPrinter)),
                  ]),
                  _vm._v(" Document Templates "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-auto",
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.mdiClose))]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {},
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.documentTemplates,
                          "item-text": "name",
                          "item-value": "id",
                          label: "Template",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.loadDocumentTemplateFields(
                              _vm.templateId
                            )
                          },
                        },
                        model: {
                          value: _vm.templateId,
                          callback: function ($$v) {
                            _vm.templateId = $$v
                          },
                          expression: "templateId",
                        },
                      }),
                      _vm.templateId
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-flx", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Name",
                                              value:
                                                _vm.jobListingCandidate
                                                  .candidate.name,
                                              disabled: "",
                                              outlined: "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("ModalInlineTextField", {
                                            attrs: { label: "Email" },
                                            on: {
                                              input: function ($event) {
                                                return _vm.updateCandidate(
                                                  _vm.localJobListingCandidate
                                                    .candidate,
                                                  "email",
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.localJobListingCandidate
                                                  .candidate.email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.localJobListingCandidate
                                                    .candidate,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localJobListingCandidate.candidate.email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("ModalInlineTextField", {
                                            attrs: { label: "Phone" },
                                            on: {
                                              input: function ($event) {
                                                return _vm.updateCandidate(
                                                  _vm.localJobListingCandidate
                                                    .candidate,
                                                  "phone",
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.localJobListingCandidate
                                                  .candidate.phone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.localJobListingCandidate
                                                    .candidate,
                                                  "phone",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localJobListingCandidate.candidate.phone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("ModalInlineTextField", {
                                            attrs: { label: "Date of Birth" },
                                            on: {
                                              input: function ($event) {
                                                return _vm.updateCandidate(
                                                  _vm.localJobListingCandidate
                                                    .candidate,
                                                  "dateOfBirth",
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.localJobListingCandidate
                                                  .candidate.dateOfBirth,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.localJobListingCandidate
                                                    .candidate,
                                                  "dateOfBirth",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localJobListingCandidate.candidate.dateOfBirth",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("ModalInlineTextArea", {
                                            attrs: { label: "Location" },
                                            on: {
                                              input: function ($event) {
                                                return _vm.updateCandidate(
                                                  _vm.localJobListingCandidate
                                                    .candidate,
                                                  "location",
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.localJobListingCandidate
                                                  .candidate.location,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.localJobListingCandidate
                                                    .candidate,
                                                  "location",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localJobListingCandidate.candidate.location",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("ModalInlineSelect", {
                                            attrs: {
                                              label: "Country",
                                              items: _vm.countries,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.updateCandidate(
                                                  _vm.localJobListingCandidate
                                                    .candidate,
                                                  "country",
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.localJobListingCandidate
                                                  .candidate.country,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.localJobListingCandidate
                                                    .candidate,
                                                  "country",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localJobListingCandidate.candidate.country",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "candidates-EditCandidate",
                                                  query: {
                                                    id: _vm
                                                      .localJobListingCandidate
                                                      .candidate.id,
                                                    jobListingId:
                                                      _vm.jobListing.id,
                                                    jobListingName:
                                                      _vm.jobListing.name,
                                                  },
                                                },
                                              },
                                            },
                                            [_vm._v(" Candidate Photo: ")]
                                          ),
                                          _c("v-img", {
                                            attrs: {
                                              height: "128",
                                              contain: "",
                                              src: _vm.localJobListingCandidate
                                                .candidate.imageUrl,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "clients-EditClient",
                                                  query: {
                                                    id: _vm.jobListing.client
                                                      .id,
                                                    jobListingId:
                                                      _vm.jobListing.id,
                                                    jobListingName:
                                                      _vm.jobListing.name,
                                                  },
                                                },
                                              },
                                            },
                                            [_vm._v(" Client Logo: ")]
                                          ),
                                          _vm.jobListing.client
                                            ? _c("v-img", {
                                                attrs: {
                                                  height: "128",
                                                  contain: "",
                                                  src: _vm.jobListing.client
                                                    .imageUrl,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.documentTemplateFields,
                                function (documentTemplateField) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: documentTemplateField.id,
                                      staticClass: "d-flex",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      documentTemplateField.fieldType === "Text"
                                        ? _c("v-textarea", {
                                            attrs: {
                                              label:
                                                documentTemplateField.description,
                                              hint: documentTemplateField.hint,
                                              "auto-grow": "",
                                              outlined: "",
                                            },
                                            on: { change: _vm.updatePrintData },
                                            model: {
                                              value:
                                                _vm.printData[
                                                  documentTemplateField.name
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.printData,
                                                  documentTemplateField.name,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "printData[documentTemplateField.name]",
                                            },
                                          })
                                        : documentTemplateField.fieldType ===
                                          "Select"
                                        ? _c("v-select", {
                                            attrs: {
                                              items:
                                                documentTemplateField.options,
                                              label:
                                                documentTemplateField.description,
                                              hint: documentTemplateField.hint,
                                              outlined: "",
                                            },
                                            on: { change: _vm.updatePrintData },
                                            model: {
                                              value:
                                                _vm.printData[
                                                  documentTemplateField.name
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.printData,
                                                  documentTemplateField.name,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "printData[documentTemplateField.name]",
                                            },
                                          })
                                        : documentTemplateField.fieldType ===
                                          "Table"
                                        ? _c(
                                            "FieldTable",
                                            {
                                              on: {
                                                change: _vm.updatePrintData,
                                              },
                                              model: {
                                                value:
                                                  _vm.printData[
                                                    documentTemplateField.name
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.printData,
                                                    documentTemplateField.name,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "printData[documentTemplateField.name]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    documentTemplateField.description
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("div", { staticClass: "ml-2" }),
                                      _c("ImportPrintData", {
                                        attrs: {
                                          documentTemplateField:
                                            documentTemplateField,
                                        },
                                        on: { import: _vm.importData },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _vm.templateId ? _c("v-spacer") : _vm._e(),
                  _vm.templateId
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", href: _vm.url },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v(" Print ")]
                      )
                    : _vm._e(),
                  _vm.templateId ? _c("v-spacer") : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }