var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "70vw" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      icon: _vm.label.length === 0,
                      title: _vm.title,
                      small: "",
                    },
                  },
                  on
                ),
                [
                  _c("v-icon", { class: { "mr-2": _vm.label.length > 0 } }, [
                    _vm._v(" " + _vm._s(_vm.mdiEmailOpen) + " "),
                  ]),
                  _vm._v(" " + _vm._s(_vm.label) + " "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("h3", [_vm._v(_vm._s(_vm.message.title))]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              ),
            ],
            1
          ),
          _c("v-card-text", { staticStyle: { height: "80vh" } }, [
            _c("iframe", {
              ref: "emailIframe",
              attrs: { width: "100%", height: "100%" },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }