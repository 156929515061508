import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('applicationSetting', 'applicationSettings');

const customActions = {
  async LOAD_applicationSettingsByNameSpace({ rootGetters }, nameSpace) {
    const data = await odata.o(rootGetters)
      .get('applicationSettings')
      .query({ $filter: `IsDeleted eq false AND NameSpace eq '${nameSpace}'` });
    return data;
  },
};

export default { ...actions, ...customActions };
