<template>
  <v-container
    fluid
    grid-list-xl
    fill-height
  >
    <v-layout
      justify-center
      align-start
    >
      <v-flex xs12>
        <v-skeleton-loader
          :loading="isLoading"
          transition="scale-transition"
          type="list-item-two-line"
        >
          <v-card>
            <v-card-text>
              <v-form
                :lazy-validation="false"
              >
                <v-textarea label="Welcome Message" v-model="welcomeMessage" outlined />
                <label for="gdprMessage">GDPR Message</label>
                <vue-editor
                  id="gdprMessage"
                  v-model="gdprMessage"
                  label="GDPR Message"
                />
                <label for="moreMessage">More Message</label>
                <vue-editor
                  id="moreMessage"
                  v-model="moreMessage"
                  label="More Message"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn :loading="isSaving" @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-skeleton-loader>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/**
  * View for managing candidate settings
*/
import applicationSettings from '@/mixins/applicationSettings';

export default {
  name: 'CandidateUploadSettings',
  mixins: [applicationSettings],
  data() {
    return {
      nameSpace: 'candidate.upload.settings',
      gdprMessage: '',
      welcomeMessage: '',
      settings: [
        { name: 'moreMessage' },
        { name: 'gdprMessage' },
        { name: 'welcomeMessage' },
      ],
    };
  },
  async mounted() {
    await this.refresh();
  },
};

</script>
