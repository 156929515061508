/* eslint-disable camelcase */
import qs from 'qs';

export default {
  // eslint-disable-next-line no-unused-vars
  async REGISTER_auth({ state }, data) {
    const { username, email, password } = data;
    const result = await this.$http.post('/api/account/register', qs.stringify({
      Username: username,
      Email: email,
      Password: password,
    }));
    return result;
  },
};
