import client from '../../axiosClient';

export default {
  async CREATE_organization({ commit }, organization) {
    const dbOrganization = await client.post('/api/organization', organization);
    commit('SET_organization', dbOrganization);
    return dbOrganization;
  },
  async UPDATE_organization({ commit }, organization) {
    await client.put('/api/organization', organization);
    commit('SET_organization', organization);
  },
  async LOAD_organizations({ commit, state }) {
    const result = await client.get('/api/organization');
    commit('SET_organizations', result.data);
    if (!state.organization || result.data.length === 1) {
      commit('SET_organization', result.data[0]);
    }
    return result.data;
  },
  async LOAD_allOrganizations({ commit }) {
    const response = await client.get('/api/organization/all');
    commit('SET_allOrganizations', response.data);
  },
};
