<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="industries"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="Industry-button-new"
            @click="$router.push({
              name: 'clients-EditIndustry',
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons
          :editLink="{
            name: 'clients-EditIndustry',
            query: { id: item.id },
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListIndustry',
  module: 'Industries',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('industries', ['industries']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_industries();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('industries', ['LOAD_industries', 'DELETE_industry']),
    /** Delete Industry from the collection
     */
    async remove(industry) {
      this.isLoading = true;
      await this.DELETE_industry(industry.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Industry example

'''js
<Industry />
'''
</docs>
