import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  mixins: [loading],
  computed: {
    ...mapState('applicationSettings', ['applicationSettings']),
  },
  methods: {
    ...mapActions('applicationSettings', [
      'LOAD_applicationSettings',
      'CREATE_applicationSetting',
      'UPDATE_applicationSetting',
      'DELETE_applicationSetting',
    ]),
    async refresh() {
      this.isLoading = true;
      await this.LOAD_applicationSettings();
      this.setApplicationSettings();
      this.isLoading = false;
    },
    async save() {
      this.isSaving = true;
      try {
        const { settings } = this;

        await Promise.all(settings
          .map((setting) => this.saveApplicationSetting(setting.name, this[setting.name])));
        this.$root.$emit('toast:notify', 'Saved successfully');
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:notify', 'Failed saving settings');
      }
      this.isSaving = false;
      await this.refresh();
    },
    async setApplicationSettings() {
      const { applicationSettings, settings } = this;
      this.$log.debug('Setting application settings');
      settings.forEach((setting) => {
        const applicationSetting = applicationSettings
          .find((c) => c.name === setting.name && c.nameSpace === this.nameSpace);
        if (applicationSetting) {
          this[setting.name] = applicationSetting.value;
        }
      });
    },
    async saveApplicationSetting(name, value) {
      const { nameSpace } = this;
      const existingApplicationSetting = this.applicationSettings.find(
        (setting) => setting.name === name && setting.nameSpace === nameSpace,
      );
      if (existingApplicationSetting) {
        await this.UPDATE_applicationSetting({
          id: existingApplicationSetting.id,
          name,
          value,
        });

        return;
      }
      await this.CREATE_applicationSetting({
        nameSpace,
        name,
        value,
      });
    },
  },
};
