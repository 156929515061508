var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab",
            { attrs: { ripple: "" } },
            [
              _c("v-icon", [_vm._v(_vm._s(_vm.mdiClockOutline))]),
              _vm._v(" Scheduled Jobs "),
            ],
            1
          ),
          _c(
            "v-tab",
            { attrs: { ripple: "" } },
            [
              _c("v-icon", [_vm._v(_vm._s(_vm.mdiCalendarTextOutline))]),
              _vm._v(" Events "),
            ],
            1
          ),
          _c("v-tab-item", [_c("HangfireListRecurringJob")], 1),
          _c(
            "v-tab-item",
            [
              _c("SourceEventLog", {
                attrs: {
                  label: "History",
                  filter: `(Source eq 'Mail' OR Source eq 'HubSpot') AND EventType ne 'Verbose'`,
                },
              }),
              _c("EventLog", {
                attrs: {
                  "default-source": "Mail",
                  "hide-logical-operation-stack": "",
                  "hide-related-activity-id": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }