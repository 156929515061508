<template>
  <v-row class="text-center">
    <v-col cols="12">
      <v-progress-linear :indeterminate="true" />
      Logging you in...
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Init',
  async mounted() {
    this.$log.info('logged in, loading organizations');
    try {
      await this.LOAD_organizations();
      // const { data } = await this.$http.get('/api/org');
      // this.$log.debug(data);
      await this.$nextTick();
      await new Promise((r) => setTimeout(r, 1500));
      this.$router.push({ name: 'app-Home' });
    } catch (error) {
      this.$log.error(error);
    }
  },
  methods: {
    ...mapActions('organizations', ['LOAD_organizations']),
  },
};
</script>
