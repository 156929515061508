import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('exchangeEmail', 'exchangeEmails');

const customActions = {
  async LOAD_exchangeEmails({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('exchangeEmails')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'To',
      });
    commit('SET_exchangeEmails', data);
    return data;
  },
};

export default { ...actions, ...customActions };
