import { mapState } from 'vuex';
import { sortBy } from 'lodash';
import { mdiComment, mdiEmail, mdiPhone } from '@mdi/js';

export default {
  data() {
    return {
      mdiComment,
      mdiEmail,
      mdiPhone,
    };
  },
  computed: {
    ...mapState('jobListingCandidateComments', ['jobListingCandidateComments']),
    ...mapState('messages', ['messages']),
    feedItems() {
      const messageItems = this.messages.map((message) => ({
        icon: this.getIcon(message.messageType),
        created: `${this.formatRelative(message.created)}`,
        createdBy: message.createdBy,
        createdDate: new Date(message.created),
        formattedMessageType: this.formatMessageType(message.messageType),
        from: message.from,
        title: message.subject,
        subtitle: message.body,
        id: message.id,
        isRead: message.isRead,
        messageType: message.messageType,
        attachments: message.attachments,
        status: message.status,
      }));
      const comments = this.jobListingCandidateComments.map((comment) => ({
        icon: mdiComment,
        created: `${this.formatRelative(comment.created)}`,
        createdDate: new Date(comment.created),
        formattedMessageType: 'Comment',
        createdBy: comment.createdBy,
        subtitle: comment.message,
        messageType: 'comment',
        attachments: [],
      }));
      return sortBy([...messageItems, ...comments], 'createdDate')
        .reverse();
    },
  },
};
