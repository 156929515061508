import Vue from 'vue';
import axios from 'axios';
import { components } from '@codehq/aurora-app-core/src';
import LocaleSwitcher from '@codehq/aurora-app-core/src/components/LocaleSwitcher.vue';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import ProgressCircularTimeout from '@codehq/aurora-app-core/src/components/ProgressCircularTimeout.vue';
import VueMask from 'v-mask';
import Vue2Editor from 'vue2-editor';
import VuetifyConfirm from 'vuetify-confirm';

// Configure Open Telemetry
import './open-telemetry';
import auroraModule from './plugins/auroraModule';

import App from './App.vue';
import AutoGrowTextArea from './components/AutoGrowTextArea.vue';
import AutoSelect from './components/AutoSelect.vue';
import ClipboardTextField from './components/ClipboardTextField.vue';
import ConfirmButton from './components/ConfirmButton.vue';
import DateTimePicker from './components/DateTimePicker.vue';
import DatePickerSmall from './components/DatePickerSmall.vue';
import DateTable from './components/DataTable.vue';
import DisplayName from './components/DisplayName.vue';
import ExpandingButton from './components/ExpandingButton.vue';
import HoverButton from './components/HoverButton.vue';
import Favourite from './components/Favourite.vue';
import vuetify from './plugins/vuetify';
import formatting from './plugins/formatting';
import store from './store';
import { router, routes } from './router';
import { i18n, messages } from './i18n';
import MicrosoftClient from './MicrosoftClient';
import MicrosoftClientPlugin from './MicrosoftClientPlugin';

Vue.config.productionTip = false;

// register global components
Vue.use(MicrosoftClientPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  apiClient: 'axios',
  timeout: 30000,
});
Vue.use(VuetifyConfirm, { vuetify });

const { FileUpload, ImageUpload } = components;

Vue.use(VueMask);

// register app components
Vue.component(AutoGrowTextArea.name, AutoGrowTextArea);
Vue.component(AutoSelect.name, AutoSelect);
Vue.component(ClipboardTextField.name, ClipboardTextField);
Vue.component(ConfirmButton.name, ConfirmButton);
Vue.component(DateTimePicker.name, DateTimePicker);
Vue.component(DatePickerSmall.name, DatePickerSmall);
Vue.component(DateTable.name, DateTable);
Vue.component(DisplayName.name, DisplayName);
Vue.component(ExpandingButton.name, ExpandingButton);
Vue.component(Favourite.name, Favourite);
Vue.component(HoverButton.name, HoverButton);

// register aurora components and plugin
Vue.component(LocaleSwitcher.name, LocaleSwitcher);
Vue.component(TableBasicButtons.name, TableBasicButtons);
Vue.component(FileUpload.name, FileUpload);
Vue.component(ImageUpload.name, ImageUpload);
Vue.component(ProgressCircularTimeout.name, ProgressCircularTimeout);

Vue.use(Vue2Editor);

// setup store
const client = new MicrosoftClient({
  baseUrl: process.env.VUE_APP_APIURL,
  apiClient: 'axios',
  timeout: 30000,
});
store.$http = client;

// setup app plugins
Vue.use(auroraModule, {
  baseUrl: process.env.VUE_APP_APIURL,
  store,
  timeout: 30000,
});
Vue.use(formatting);

// Setup API
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),

  i18n,
  mounted() {
    this.$log.debug('Locales:');
    this.$log.debug(messages);
    this.$log.debug('Routes:');
    this.$log.debug(routes);
  },
}).$mount('#app');
