<template>
  <div>
    <v-btn color="primary" icon small :loading="isLoading" @click="takeScreenshot">
      <v-icon>{{ mdiMonitorScreenshot }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
// import * as rasterizeHTML from 'rasterizehtml';
import { mdiMonitorScreenshot } from '@mdi/js';

export default {
  name: 'ScreenshotButton',
  props: {
    elementId: {
      type: String,
      default: undefined,
    },
    selector: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      mdiMonitorScreenshot,
    };
  },
  methods: {
    async takeScreenshot() {
      this.isLoading = true;
      let element;
      if (this.selector) {
        element = document.querySelector(this.selector);
      } else {
        element = document.getElementById(this.elementId);
      }

      if (!element) {
        this.$root.$emit('toast:error', 'Element not found');
        return;
      }
      setTimeout(async () => {
        const canvas = await html2canvas(element);
        this.downloadCanvas(canvas, 'screenshot.png');
        this.isLoading = false;
      }, 200);
    },
    downloadCanvas(canvas, filename) {
      const link = document.createElement('a');
      link.download = filename;
      link.href = canvas.toDataURL('image/png');
      this.screenshot = link.href;
      link.click();
    },
  },
};
</script>
