var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-breadcrumbs", {
    attrs: { items: _vm.items, color: "#1e0b4c" },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "v-breadcrumbs-item",
              { attrs: { to: item.to, disabled: !item.to, color: "#1e0b4c" } },
              [_vm._v(" " + _vm._s(_vm.$t(item.text)) + " ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }