<template>
  <v-menu
    :close-on-content-click="true"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    left
    min-width="290px"
    v-if="items.length > 0"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        dark
        v-on="on"
      >
        <v-icon>{{ mdiSignLanguage }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="response in items"
        :key="response.message"
        @click="$emit('input', response.message)"
      >
        <v-list-item-title>{{ response.message }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiSignLanguage } from '@mdi/js';

export default {
  name: 'AutoResponse.vue',
  data() {
    return {
      mdiSignLanguage,
    };
  },
  computed: {
    ...mapState('auth', ['userId']),
    ...mapState('automatedResponses', ['automatedResponses']),
    items() {
      const favouriteItems = this.automatedResponses.filter((automatedResponse) => {
        const identifier = `${this.userId}-${automatedResponse.id}`;
        return this.$store.getters['favourites/isFavourite']('AutoResponse', identifier);
      });
      if (favouriteItems.length === 0) {
        return this.automatedResponses;
      }
      return favouriteItems;
    },
  },
  methods: {
    ...mapActions('automatedResponses', ['LOAD_automatedResponses']),
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_automatedResponses();
    this.isLoading = false;
  },
};
</script>
