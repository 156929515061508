import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('completedTask', 'completedTasks');

const customActions = {
  async LOAD_completedTasksByStage({ commit, rootGetters }, stageId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('completedTasks')
      .query({
        $filter: `IsDeleted eq false AND stageId eq ${stageId}`,
        $expand: 'JobListingCandidate($expand=Candidate),Task',
        $orderby: 'created desc',
      });
    commit('SET_completedTasks', data);
    return data;
  },
  async LOAD_completedTasksByCandidate({ commit, rootGetters }, candidateId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('completedTasks')
      .query({
        $filter: `IsDeleted eq false AND JobListingCandidate/CandidateId eq ${candidateId}`,
        $expand: 'JobListingCandidate($expand=Candidate),Task($expand=Stage),Stage',
        $orderby: 'created desc',
      });
    commit('SET_completedTasks', data);
    return data;
  },
};

export default { ...actions, ...customActions };
