var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-img", {
        staticClass: "mx-auto",
        attrs: {
          src: require("../assets/logo.svg"),
          contain: "",
          "max-height": "100",
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { offset: "4", cols: "4" } },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.acceptInvite.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: [_vm.rules.required],
                      label: "Invite Code",
                      required: "",
                    },
                    model: {
                      value: _vm.inviteCode,
                      callback: function ($$v) {
                        _vm.inviteCode = $$v
                      },
                      expression: "inviteCode",
                    },
                  }),
                  _vm.loggedIn
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.isSaving,
                            type: "submit",
                            color: "primary",
                          },
                        },
                        [_vm._v(" Accept Invite ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: {
                            block: "",
                            color: "success",
                            loading: _vm.isLoading,
                          },
                          on: { click: _vm.getToken },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(_vm.mdiMicrosoftOffice)),
                          ]),
                          _vm._v(" Microsoft Login "),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm.errors.length > 0
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-10",
                      attrs: { type: "error", dismissible: "" },
                    },
                    [
                      _c(
                        "ul",
                        _vm._l(_vm.errors, function (error) {
                          return _c("li", { key: error }, [
                            _vm._v(_vm._s(error)),
                          ])
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }