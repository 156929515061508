<template>
  <v-dialog width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Merge
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Merge these contacts together?
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="(candidate, index) in localValue"
            :key="candidate.id"
            :draggable="true"
            @dragstart="onDragStart(index)"
            @dragover="onDragOver(index)"
            @drop="onDrop(index)">
            <v-list-item-content>
              <v-list-item-title>{{ candidate?.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ candidate?.email }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-icon>{{ mdiArrowDown }}</v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="$emit('input', [])">Cancel</v-btn>
        <v-spacer />
        <v-btn color="success" @click="$emit('refresh')">Merge</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiArrowDown } from '@mdi/js';

export default {
  name: 'DialogMerge',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mdiArrowDown,
      localValue: this.value,
    };
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
  },
  methods: {
    onDragStart(index) {
      this.draggedIndex = index;
    },
    onDragOver(index) {
      this.localValue.splice(index, 0, this.localValue.splice(this.draggedIndex, 1)[0]);
    },
    onDrop(index) {
      this.localValue.splice(index, 0, this.localValue.splice(this.draggedIndex, 1)[0]);
    },
  },
};
</script>
