<template>
  <v-container fluid>
    <ListStage @selected="stageSelected" />
    <div class="mt-10" />
    <ListTask v-if="selectedStage" :Stage="selectedStage" />
  </v-container>
</template>

<script>
import ListStage from './ListStage.vue';
import ListTask from './ListTask.vue';

export default {
  name: 'Stage',
  components: {
    ListStage,
    ListTask,
  },
  data() {
    return {
      selectedStage: undefined,
    };
  },
  methods: {
    /**
     * Executes Stage Selected event.
     */
    stageSelected(stage) {
      if (stage.value) {
        this.selectedStage = stage.item;
      } else {
        this.selectedStage = undefined;
      }
      this.$log.debug(stage);
    },
  },
};
</script>
