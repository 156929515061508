<template>
  <v-text-field v-bind="$props" v-on="$listeners">
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
      @input="onInput"
    >
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: 'AutoSelect',
  extends: VTextField,
  methods: {
    /**
     * Executes onInput to set the value to the first item in the items list
     */
    onInput(val) {
      const { items } = this;
      if (!val && items.length) {
        this.$emit('input', items[0]);
      }
    },
  },
};
</script>
