import { render, staticRenderFns } from "./MoveCandidate.vue?vue&type=template&id=5f041de1"
import script from "./MoveCandidate.vue?vue&type=script&lang=js"
export * from "./MoveCandidate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f041de1')) {
      api.createRecord('5f041de1', component.options)
    } else {
      api.reload('5f041de1', component.options)
    }
    module.hot.accept("./MoveCandidate.vue?vue&type=template&id=5f041de1", function () {
      api.rerender('5f041de1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/clients/components/MoveCandidate.vue"
export default component.exports