import { useAuth } from '@/use';

const auth = useAuth();

export default {
  addTask({ commit }, task) {
    commit('addTask', task);
  },
  addTaskList({ commit }, taskList) {
    commit('addTaskList', taskList);
  },

  async getTaskLists({ commit }) {
    const scopes = ['https://graph.microsoft.com/Tasks.ReadWrite'];
    const token = await auth.getScopedAccessToken(scopes);
    const response = await fetch('https://graph.microsoft.com/v1.0/me/todo/lists', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    commit('setTaskLists', data.value);
  },

  async getTasks({ commit }, taskListId) {
    const scopes = ['https://graph.microsoft.com/Tasks.ReadWrite'];
    const token = await auth.getScopedAccessToken(scopes);
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/me/todo/lists/${taskListId}/tasks`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await response.json();
    commit('setTasks', data.value);
  },

  async getTask({ commit }, { taskId, taskListId }) {
    const scopes = ['https://graph.microsoft.com/Tasks.ReadWrite'];
    const token = await auth.getScopedAccessToken(scopes);
    const response = await fetch(`https://graph.microsoft.com/v1.0/me/todo/lists/${taskListId}/tasks/${taskId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    commit('setTask', data);
    return data;
  },

  async createTaskList({ commit }, taskList) {
    const scopes = ['https://graph.microsoft.com/Tasks.ReadWrite'];
    const token = await auth.getScopedAccessToken(scopes);
    const response = await fetch('https://graph.microsoft.com/v1.0/me/todo/lists', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(taskList),
    });
    const data = await response.json();
    commit('addTaskList', data);
  },

  async createTask({ commit, dispatch }, { taskListId, task, jobListingId }) {
    const scopes = ['https://graph.microsoft.com/Tasks.ReadWrite'];
    const token = await auth.getScopedAccessToken(scopes);
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/me/todo/lists/${taskListId}/tasks`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(task),
      },
    );
    const data = await response.json();
    commit('addTask', data);
    await dispatch('getTasks', taskListId);
    await dispatch('jobListingTasks/CREATE_jobListingTask', {
      jobListingId,
      taskId: data.id,
      title: task.title,
      taskListId,
    }, { root: true });
  },

  completeTask({ dispatch }, { taskListId, taskId }) {
    const scopes = ['https://graph.microsoft.com/Tasks.ReadWrite'];
    auth.getScopedAccessToken(scopes).then((token) => {
      fetch(`https://graph.microsoft.com/v1.0/me/todo/lists/${taskListId}/tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 'completed',
        }),
      });
    });
    dispatch('getTasks', taskListId);
  },
};
