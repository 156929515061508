<template>
  <div :class="{ 'mb-10': isLoading }">
    <ProgressLinearTimeout v-if="isLoading" />
    <v-row v-else>
      <v-col
        cols="6"
        v-for="item in items"
        :key="item.id"
      >
        <v-simple-checkbox
          dense
          :value="item.completed"
          @input="update(item, $event)"
          style="display: inline-block"
        />
        <div class="checkbox-label">
          {{ item.name }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.checkbox-label {
  display: inline;
  vertical-align: top;
  font-size: larger;
}
</style>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default {
  name: 'JobListingCandidateHistory',
  components: {
    ProgressLinearTimeout,
  },
  mixins: [loading],
  props: {
    jobListingCandidate: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      checklists: [],
      completedCheckLists: [],
    };
  },
  computed: {
    items() {
      return this.checklists.map((checklist) => {
        const completedChecklist = this.completedCheckLists
          .find((item) => item.checklistId === checklist.id);
        return {
          id: checklist.id,
          name: checklist.name,
          completed: completedChecklist !== undefined,
          completedDate: completedChecklist ? dayjs(completedChecklist.completedDate).fromNow() : '',
          completedChecklist,
        };
      });
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('checklists', [
      'LOAD_joblistingChecklists',
    ]),
    ...mapActions('completedChecklists', [
      'LOAD_completedChecklists',
      'CREATE_completedChecklist',
      'DELETE_completedChecklist',
    ]),
    async refresh() {
      if (this.jobListingCandidate) {
        this.isLoading = true;
        this.checklists = await this
          .LOAD_joblistingChecklists();
        this.completedCheckLists = await this
          .LOAD_completedChecklists(this.jobListingCandidate.id);
        this.isLoading = false;
      }
    },
    async update(item) {
      if (!item.completed) {
        await this.CREATE_completedChecklist({
          checklistId: item.id,
          jobListingCandidateId: this.jobListingCandidate.id,
        });
      } else {
        await this.DELETE_completedChecklist(item.completedChecklist.id);
      }
      await this.refresh();
    },
  },
};
</script>
