import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('message', 'messages');

const customActions = {
  async LOAD_textMessages({ rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('messages')
      .query({ $filter: 'IsDeleted eq false AND MessageType eq \'text\'' });
    return data;
  },
  async LOAD_whatsAppMessages({ rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('messages')
      .query({ $filter: 'IsDeleted eq false AND MessageType eq \'whatsApp\'' });
    return data;
  },
  async LOAD_candidateMessages({ commit, rootGetters }, candidateId) {
    commit('SET_messages', []);
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('messages')
      .query({ $filter: `IsDeleted eq false AND JobListingCandidate/CandidateId eq ${candidateId}` });
    commit('SET_messages', data);
    return data;
  },
  async LOAD_jobListingCandidateMessages({ commit, rootGetters }, jobListingCandidateId) {
    commit('SET_messages', []);
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('messages')
      .query({
        $filter: `IsDeleted eq false AND JobListingCandidateId eq ${jobListingCandidateId}`,
        $expand: 'Attachments($select=Id,Name,ContentType)',
      });
    commit('SET_messages', data);
    return data;
  },
};

export default { ...actions, ...customActions };
