<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-center">
      <v-row>
        <v-col cols="12">
          <v-select
            :items="languages"
            v-model="localAutomatedResponse.language"
            :label="$$t('language')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            filled
            data-cy="FormAutomatedResponse-message"
            :label="$$t('message')"
            v-model="localAutomatedResponse.message"
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-end mb-6">
        <v-btn
          color="default"
          outlined
          class="ml-4 btn-cancel"
          @click="cancel"
          data-cy="FormAutomatedResponse-button-cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          class="ml-4"
          :loading="isSaving"
          data-cy="FormAutomatedResponse-button-save">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import languages from '../mixins/languages';

export default {
  name: 'FormAutomatedResponse',
  module: 'Tasks',
  mixins: [loading, languages],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    AutomatedResponse: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localAutomatedResponse
      */
      localAutomatedResponse: {},
    };
  },
  watch: {
    AutomatedResponse() {
      this.localAutomatedResponse = this.AutomatedResponse;
    },
  },
  created() {
    this.localAutomatedResponse = this.AutomatedResponse;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localAutomatedResponse);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormAutomatedResponse example

'''js
<FormAutomatedResponse :AutomatedResponse="AutomatedResponse" />
'''
</docs>
