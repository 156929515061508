import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('checklist', 'checklists');

const customActions = {
  async LOAD_joblistingChecklists({ rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('checklists')
      .query({ $filter: 'IsDeleted eq false AND ChecklistTemplateId eq 77a5b106-d1a2-ed11-8100-00155d97e129' });
    return data;
  },
};

export default { ...actions, ...customActions };
