import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('candidateDocument', 'candidateDocuments');

const customActions = {
  async LOAD_candidateDocumentsByCandidate({ commit, rootGetters }, { candidateId }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('candidateDocuments')
      .query({ $filter: `IsDeleted eq false AND CandidateId eq ${candidateId}` });
    commit('SET_candidateDocuments', data);
    return data;
  },
};

export default { ...actions, ...customActions };
