var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "text-center" },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-progress-linear", { attrs: { indeterminate: true } }),
          _vm._v(" Logging you in... "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }