var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    attrs: {
      items: _vm.messages,
      headers: _vm.headers,
      loading: _vm.isLoading,
      "item-key": "id",
      "items-per-page": 25,
      "footer-props": {
        itemsPerPageOptions: [25, 50, 100, 250, 500, -1],
      },
      "sort-by": "created",
      "sort-desc": "",
    },
    scopedSlots: _vm._u([
      {
        key: "item.to",
        fn: function ({ item }) {
          return _vm._l(item.to, function (to, index) {
            return _c(
              "v-chip",
              { key: index, staticClass: "mr-2", attrs: { color: "accent" } },
              [
                _c("a", { attrs: { href: `mailto:${to.address}` } }, [
                  _vm._v(" " + _vm._s(to.name) + " "),
                ]),
              ]
            )
          })
        },
      },
      {
        key: "item.created",
        fn: function ({ item }) {
          return [_vm._v(" " + _vm._s(_vm.formatDateTime(item.created)) + " ")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }