const countries = require('i18n-iso-countries/langs/en.json');
const { isArray } = require('lodash');

const getCountryName = (name) => {
  if (isArray(name)) {
    return name[0];
  }
  return name;
};

export default {
  getToken: (state) => state.token,
  isSaving: (state) => state.isSaving,
  getUsername: (state) => state.username,
  isLoggedIn: (state) => state.username?.length > 0,
  isInRole: (state) => (role) => state.roles.some((r) => r === role),
  // eslint-disable-next-line no-unused-vars
  countries: () => Object.entries(countries.countries).map(([code, name]) => ({
    text: getCountryName(name),
    value: getCountryName(name),
  })),
};
