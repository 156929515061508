<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormAutomatedResponse
      :AutomatedResponse="editAutomatedResponse"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({ name: 'tasks-ListAutomatedResponse' })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormAutomatedResponse from '../components/FormAutomatedResponse.vue';

export default {
  components: {
    FormAutomatedResponse,
  },
  name: 'EditAutomatedResponse',
  module: 'Tasks',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.responses',
        to: { name: 'tasks-ListAutomatedResponse' },
        disabled: false,
      },
      {
        text: 'common.buttons.edit',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing AutomatedResponse. Default to {}.
      */
      editAutomatedResponse: {},
      /**
       * Query Id for AutomatedResponse
       */
      automatedResponseId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('automatedResponses', ['automatedResponses', 'automatedResponse']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.automatedResponseId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_automatedResponse(this.automatedResponseId);
      this.editAutomatedResponse = this.automatedResponse;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('automatedResponses', ['LOAD_automatedResponse', 'CREATE_automatedResponse', 'UPDATE_automatedResponse']),
    /** Method used to save the item
     */
    async save(automatedResponse) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_automatedResponse(automatedResponse);
      } else {
        await this.UPDATE_automatedResponse(automatedResponse);
      }
      this.isSaving = false;
      this.$router.push({ name: 'tasks-ListAutomatedResponse' });
    },
  },
};
</script>

<docs>
# EditAutomatedResponse example

'''js
<EditAutomatedResponse :AutomatedResponse="AutomatedResponse" />
'''
</docs>
