<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-center">
      <v-row>
        <v-col cols="12">
          <v-select
            data-cy="FormJobListingCandidate-candidateId"
            filled
            :label="$$t('candidateId')"
            :items="items"
            v-model="localJobListingCandidate.candidateId"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            data-cy="FormJobListingCandidate-jobListingId"
            filled
            :label="$$t('jobListingId')"
            :items="items"
            v-model="localJobListingCandidate.jobListingId"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            data-cy="FormJobListingCandidate-stageId"
            filled
            :label="$$t('stageId')"
            :items="items"
            v-model="localJobListingCandidate.stageId"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            data-cy="FormJobListingCandidate-taskId"
            filled
            :label="$$t('taskId')"
            :items="items"
            v-model="localJobListingCandidate.taskId"
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-end mb-6">
        <v-btn
          color="default"
          outlined
          class="ml-4 btn-cancel"
          @click="cancel"
          data-cy="FormJobListingCandidate-button-cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          class="ml-4"
          :loading="isSaving"
          data-cy="FormJobListingCandidate-button-save">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormJobListingCandidate',
  module: 'Candidates',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    JobListingCandidate: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localJobListingCandidate
      */
      localJobListingCandidate: {},
    };
  },
  watch: {
    JobListingCandidate() {
      this.localJobListingCandidate = this.JobListingCandidate;
    },
  },
  created() {
    this.localJobListingCandidate = this.JobListingCandidate;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localJobListingCandidate);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormJobListingCandidate example

'''js
<FormJobListingCandidate :JobListingCandidate="JobListingCandidate" />
'''
</docs>
