<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="items"
          class="elevation-1"
          :loading="isLoading"
          :slots="[
            { name: 'actions' },
            { name: 'created' },
            { name: 'favourite' },
            { name: 'language' },
          ]"
        >
          <template v-slot:new>
            <v-btn
              color="success"
              class="ml-2"
              data-cy="AutomatedResponse-button-new"
              @click="$router.push({
                name: 'tasks-EditAutomatedResponse',
              })"
            >
              <v-icon class="mr-2">
                {{ mdiPlus }}
              </v-icon>
              {{ $t('common.buttons.new') }}
            </v-btn>
          </template>
          <template v-slot:filters>
            <v-select
              v-model="filterLanguage"
              :items="languages"
              :label="$$t('language')"
              clearable
            />
          </template>
          <template v-slot:language="{ item }">
            {{ getLanguage(item.language) }}
          </template>
          <template v-slot:favourite="{ item }">
            <Favourite name="AutoResponse" :identifier="`${userId}-${item.id}`" />
          </template>
          <template v-slot:created="{ item }">
            {{ formatDate(item.created) }}
          </template>
          <template v-slot:actions="{ item }">
            <TableBasicButtons
              :editLink="{
                name: 'tasks-EditAutomatedResponse',
                query: { id: item.id },
              }"
              @delete="remove(item)"
            />
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import languages from '../mixins/languages';

export default {
  name: 'ListAutomatedResponse',
  module: 'Tasks',
  components: {
    TableBasicButtons,
  },
  mixins: [loading, languages],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.responses',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      filterLanguage: undefined,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('auth', ['userId']),
    ...mapState('automatedResponses', ['automatedResponses']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: '',
          value: 'favourite',
        },
        {
          text: this.$$t('message'),
          value: 'message',
        },
        {
          text: this.$$t('language'),
          value: 'language',
        },
        {
          text: this.$t('App.common.createdBy'),
          value: 'createdBy',
        },
        {
          text: this.$t('App.common.created'),
          value: 'created',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    items() {
      return this.automatedResponses.filter((item) => {
        if (this.filterLanguage) {
          return item.language === this.filterLanguage;
        }
        return true;
      });
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_automatedResponses();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('automatedResponses', ['LOAD_automatedResponses', 'DELETE_automatedResponse']),
    /** Delete AutomatedResponse from the collection
     */
    async remove(automatedResponse) {
      this.isLoading = true;
      await this.DELETE_automatedResponse(automatedResponse.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# AutomatedResponse example

'''js
<AutomatedResponse />
'''
</docs>
