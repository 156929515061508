var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "background justify-center align-center mt-6",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6" } },
            [
              _vm.isLoggingIn
                ? _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm.account
                                    ? _c(
                                        "div",
                                        { staticClass: "text-h4 d-block" },
                                        [
                                          _vm._v(
                                            " Welcome " +
                                              _vm._s(_vm.account.name) +
                                              ", we're just "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-h5 text-h4 d-block" },
                                    [_vm._v(_vm._s(_vm.loginText) + "...")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12" },
                                },
                                [_c("ProgressLinearTimeout")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    { staticClass: "ma-3 pa-4", attrs: { flat: "" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-3",
                              attrs: { justify: "center", "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c("v-img", {
                                        staticClass: "ml-auto mr-auto",
                                        attrs: {
                                          src: require("../assets/numaH_Logo_New.png"),
                                          width: "229",
                                        },
                                      }),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                block: "",
                                                color: "success",
                                                loading: _vm.isLoading,
                                              },
                                              on: { click: _vm.login },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.mdiMicrosoftOffice
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" Microsoft Login "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }