<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-skeleton-loader
          :loading="isLoading"
          transition="expand-transition"
          type="article"
        >
          <FormClient
            :Client="editClient"
            :isSaving="isSaving"
            @save="save"
            @cancel="cancel" />
        </v-skeleton-loader>
      </v-card-text>

      <v-card-text>
        <v-container>
          <v-card class="mx-auto" :loading="isLoading">
            <v-card-title primary-title>
              Existing Job Listings
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item-content v-for="(jobListing, index) in jobListings" :key="index">
                  <router-link
                    :data-cy="`JobListing-link-${index}`"
                    :to="{
                      name: 'clients-ViewJobListing',
                      query: {
                        jobListingId: jobListing.id,
                      },
                    }"
                  >
                    {{ jobListing.name }} ({{ jobListing.status }})
                  </router-link>
                </v-list-item-content>
              </v-list>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormClient from '../components/FormClient.vue';

export default {
  components: {
    FormClient,
  },
  name: 'EditClient',
  module: 'Clients',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.contacts',
        to: { name: 'clients-ListClient' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing Client. Default to {}.
      */
      editClient: {},
      /**
       * Query Id for Client
       */
      clientId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('clients', ['clients', 'client']),
    ...mapState('jobListings', ['jobListings']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.clientId) {
        return 'edit';
      }
      return 'add';
    },
  },
  created() {
    if (this.$route.query.jobListingId) {
      this.$route.meta.breadcrumb = [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: this.$route.query.jobListingName,
        to: {
          name: 'clients-ViewJobListing',
          query: { jobListingId: this.$route.query.jobListingId },
        },
      },
      {
        text: 'Client',
      },
      {
        text: 'menu.edit',
      },
      ];
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_industries();
    if (this.mode === 'edit') {
      await this.LOAD_client(this.clientId);
      this.editClient = this.client;
      await this.LOAD_jobListingsByClient(this.clientId);
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('clients', ['LOAD_client', 'CREATE_client', 'UPDATE_client']),
    ...mapActions('industries', ['LOAD_industries']),
    ...mapActions('jobListings', ['LOAD_jobListingsByClient']),

    cancel() {
      if (this.$route.query.jobListingId) {
        this.$router.push({
          name: 'clients-ViewJobListing',
          query: { jobListingId: this.$route.query.jobListingId },
        });
      } else {
        this.$router.push({ name: 'clients-ListClient' });
      }
    },
    /** Method used to save the item
     */
    async save(client) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_client(client);
      } else {
        await this.UPDATE_client(client);
      }
      this.isSaving = false;
      if (this.$route.query.jobListingId) {
        this.$router.push({
          name: 'clients-ViewJobListing',
          query: { jobListingId: this.$route.query.jobListingId },
        });
      } else {
        this.$router.push({ name: 'clients-ListClient' });
      }
    },
  },
};
</script>

<docs>
# EditClient example

'''js
<EditClient :Client="Client" />
'''
</docs>
