var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("TableModalForm", {
                ref: "table",
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.reasons,
                  loading: _vm.isLoading,
                  name: "Reasons",
                  slots: [{ name: "actions" }, { name: "order" }],
                  "show-select": "",
                  "single-select": "",
                  "sort-by": "order",
                },
                on: {
                  creating: _vm.creating,
                  refresh: _vm.refresh,
                  "item-selected": _vm.itemSelected,
                },
                scopedSlots: _vm._u([
                  {
                    key: "form",
                    fn: function () {
                      return [
                        _c("FormReason", {
                          attrs: {
                            Reason: _vm.editReason,
                            isSaving: _vm.isSaving,
                          },
                          on: {
                            save: _vm.save,
                            cancel: function ($event) {
                              _vm.$refs.table.dialog = false
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "order",
                    fn: function ({ item, index }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.moveUp(item)
                              },
                            },
                          },
                          [
                            index > 0
                              ? _c("v-icon", { staticClass: "mr-2" }, [
                                  _vm._v(_vm._s(_vm.mdiArrowUpBold)),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" " + _vm._s(item.order) + " "),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.moveDown(item)
                              },
                            },
                          },
                          [
                            index < _vm.reasons.length - 1
                              ? _c("v-icon", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.mdiArrowDownBold) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn-toggle",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "default", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common.buttons.edit")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "error", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.remove(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common.buttons.delete")) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }