import { useAuth } from '../use';

const auth = useAuth();

export default {
  methods: {
    async fetchGraph(url, scopes) {
      const accessToken = await auth.getScopedAccessToken(scopes);
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.value;
    },

    async postGraph(url, scopes, data) {
      const accessToken = await auth.getScopedAccessToken(scopes);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.status > 200) {
        return null;
      }
      const responseData = await response.json();
      return responseData;
    },
  },
};
