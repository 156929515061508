<template>
  <v-container fluid>
    <v-tabs
      v-model="tab"
    >
      <v-tab ripple>
        <v-icon>{{ mdiClockOutline }}</v-icon>
        Scheduled Jobs
      </v-tab>
      <v-tab ripple>
        <v-icon>{{ mdiCalendarTextOutline }}</v-icon>
        Events
      </v-tab>
      <v-tab-item>
        <HangfireListRecurringJob />
      </v-tab-item>
      <v-tab-item>
        <SourceEventLog
          label="History"
          :filter="`(Source eq 'Mail' OR Source eq 'HubSpot') AND EventType ne 'Verbose'`"
        />
        <EventLog
          default-source="Mail"
          hide-logical-operation-stack
          hide-related-activity-id
        />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { mdiClockOutline, mdiCalendarTextOutline } from '@mdi/js';
import SourceEventLog from '../components/SourceEventLog.vue';
import HangfireListRecurringJob from '../components/HangfireListRecurringJobs.vue';

export default {
  name: 'ScheduledJobs',
  components: {
    SourceEventLog,
    HangfireListRecurringJob,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.jobs',
      },
      ],
  },
  data() {
    return {
      mdiClockOutline,
      mdiCalendarTextOutline,
      tab: 0,
    };
  },
};
</script>
