<template>
  <v-container fluid>
    <DataTable
      name="Candidates"
      :headers="headers"
      :items="items"
      class="elevation-1"
      :loading="isLoading"
      :slots="[
        { name: 'email' },
        { name: 'phone' },
        { name: 'actions' },
        { name: 'name' },
        { name: 'created' },
        { name: 'createdBy' },
        { name: 'updated' },
        { name: 'favourite' },
      ]"
      :custom-filter="customFilter"
      show-select
      v-model="selected"
      @refresh="refresh"
      sort-by="created"
      sort-desc
    >
      <template v-slot:new>
        <v-btn
          class="ml-2 mr-2"
          color="success"
          data-cy="Candidate-button-new"
          @click="$router.push({
            name: 'candidates-EditCandidate',
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
        <DialogMerge
          v-model="selected"
          v-if="selected.length > 0"
          @refresh="refresh"
        />
      </template>
      <template v-slot:filters>
        <v-row>
          <v-col cols="3">
            <v-checkbox
              label="LinkedIn Only"
              v-model="filter.hasLinkedIn"
              clearable
            />
          </v-col>
          <v-col cols="3">
            <v-select
              :items="createdByList"
              v-model="filter.createdBy"
              label="Created by"
              clearable
            />
          </v-col>
          <v-col cols="3">
            <v-select
              :items="locationList"
              v-model="filter.location"
              label="Location"
              clearable
            />
          </v-col>
          <v-col cols="3">
            <v-checkbox
              label="Favourites"
              v-model="filterFavourites" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:created="{ item }">
        {{ formatDate(item.created) }}
      </template>
      <template v-slot:createdBy="{ item }">
        {{ item.createdBy }}
      </template>
      <template v-slot:favourite="{ item }">
        <Favourite name="JobListing" :identifier="`${userId}-${item.id}`" />
      </template>
      <template v-slot:updated="{ item }">
        <span v-if="item.updated">{{ formatDate(item.updated) }}</span>
      </template>
      <template v-slot:name="{ item }">
        <router-link
          :to="{
            name: 'candidates-EditCandidate',
            query: { id: item.id },
          }">
          {{ item.name }}
        </router-link>
        <v-btn
          v-if="item.linkedInUrl"
          small
          icon
          :href="item.linkedInUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon>{{ mdiLinkedin }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:email="{ item }">
        <a :href="`mailto:${item.email}`">
          {{ item.email }}
        </a>
      </template>
      <template v-slot:phone="{ item }">
        <a :href="`tel:${item.phone}`">
          {{ item.phone }}
        </a>
      </template>
      <template v-slot:actions="{ item }">
        <TableBasicButtons
          :editLink="{
            name: 'candidates-EditCandidate',
            query: { id: item.id },
          }"
          @delete="remove(item)"
        />
      </template>
    </DataTable>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiPlus, mdiLinkedin } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DialogMerge from '../components/DialogMerge.vue';
import refresh from '../../../mixins/refresh';

export default {
  name: 'ListCandidate',
  module: 'Candidates',
  components: {
    DialogMerge,
    TableBasicButtons,
  },
  mixins: [loading, refresh],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.candidates',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiLinkedin,
      filterFavourites: false,
      filter: {
        createdBy: '',
        hasLinkedIn: false,
        location: '',
      },
      selected: [],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('auth', ['userId']),
    ...mapState('candidates', ['candidates']),
    createdByList() {
      return [...new Set(this.candidates.map((candidate) => candidate.createdBy))].sort();
    },
    locationList() {
      return [...new Set(this.candidates.map((candidate) => candidate.location))].sort();
    },
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: '',
          value: 'favourite',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('email'),
          value: 'email',
        },
        {
          text: this.$$t('phone'),
          value: 'phone',
        },
        {
          text: this.$t('common.created'),
          value: 'created',
        },
        {
          text: this.$t('common.createdBy'),
          value: 'createdBy',
        },
        {
          text: this.$t('common.lastUpdated'),
          value: 'updated',
        },
        {
          text: this.$$t('location'),
          value: 'location',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    items() {
      let result = this.candidates.filter((candidate) => {
        if (this.filter.createdBy && candidate.createdBy !== this.filter.createdBy) {
          return false;
        }
        if (this.filter.hasLinkedIn && !candidate.linkedInUrl) {
          return false;
        }
        if (this.filter.location && candidate.location !== this.filter.location) {
          return false;
        }
        return true;
      });

      if (this.filterFavourites) {
        result = result.filter((jobListing) => {
          const identifier = `${this.userId}-${jobListing.id}`;
          return this.$store.getters['favourites/isFavourite']('JobListing', identifier);
        });
      }
      return result;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('candidates', ['LOAD_candidatesMin', 'DELETE_candidate']),
    ...mapMutations('app', ['SET_favouritesOnly']),
    /** Delete Candidate from the collection
     */
    async remove(candidate) {
      this.isLoading = true;
      await this.DELETE_candidate(candidate.id);
      this.isLoading = false;
    },
    /** Refresh the collection
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_candidatesMin();
      this.isLoading = false;
    },
    /**
     * Custom filter to ignore all accents
     */
    customFilter(value, search, item) {
      const val = `${item.name}-${item.email}`.toLowerCase();
      const srch = search?.toString().toLowerCase();
      if (val.includes(srch)) {
        return true;
      }
      return val?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(srch);
    },
  },
};
</script>

<docs>
# Candidate example

'''js
<Candidate />
'''
</docs>
