import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('jobListing', 'jobListings');

const customActions = {

  async LOAD_jobListingExpanded({ commit, rootGetters }, id) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get(`jobListings(${id})`)
      .query({ $expand: 'Client' });
    commit('SET_jobListing', data);
    return data;
  },

  async LOAD_jobListing({ commit, rootGetters }, id) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get(`jobListings(${id})`)
      .query();
    commit('SET_jobListing', data);
    return data;
  },
  async LOAD_jobListings({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListings')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Industry,Client',
        $orderby: 'Created desc',
      });
    commit('SET_jobListings', data);
    return data;
  },
  async LOAD_jobListingsByClient({ commit, rootGetters }, clientId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListings')
      .query({
        $filter: `IsDeleted eq false AND ClientId eq ${clientId}`,
        $orderby: 'Created desc',
      });
    commit('SET_jobListings', data);
    return data;
  },
  async LOAD_jobListingDashboard({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListings')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'JobListingCandidates,Client',
        $orderby: 'Created desc',
      });
    commit('SET_jobListings', data);
    return data;
  },
  async LOAD_jobListingMin({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListings')
      .query({
        $select: 'Id,Name',
        $filter: 'IsDeleted eq false',
        $expand: 'JobListingCandidates($select=id,printData;$expand=Candidate($select=id,name))',
        $orderby: 'Name',
      });
    commit('SET_jobListings', data);
    return data;
  },
  async LOAD_jobListingPrintData({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('jobListings')
      .query({
        $select: 'Id,Name',
        $filter: 'IsDeleted eq false',
        $expand: 'JobListingCandidates($expand=Candidate($select=id,name,linkedInUrl,createdBy,email,phone;$orderBy=name))',
        $orderby: 'Name',
      });
    commit('SET_jobListings', data);
    return data;
  },
};

export default { ...actions, ...customActions };
