var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("h1", [_vm._v("LinkedIn")]),
              !_vm.profile
                ? _c(
                    "v-btn",
                    { staticClass: "ml-5", attrs: { href: _vm.url } },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiLinkedin))])],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                { staticClass: "ml-5", on: { click: _vm.refresh } },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiRefresh))])],
                1
              ),
            ],
            1
          ),
          _vm.profile
            ? _c("v-card-text", [
                _c("p", [_vm._v("ID: " + _vm._s(_vm.profile.id))]),
                _c("p", [
                  _vm._v(
                    "First Name: " +
                      _vm._s(_vm.profile.firstName.localized.en_US)
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Last Name: " + _vm._s(_vm.profile.lastName.localized.en_US)
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }