<template>
  <v-autocomplete
    :items="channels"
    :loading="isLoading"
    item-text="displayName"
    item-value="id"
    label="Select a Channel"
    v-model="selectedChannel"
    filled
  >
    <template v-slot:append-outer>
      <v-btn
        icon
        @click="refresh(teamId)"
      >
        <v-icon>
          {{ mdiRefresh }}
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:prepend-inner>
      <v-dialog
        v-model="dialog"
        fullscreen
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            small
            v-bind="attrs"
            v-on="on"
            @click="checkAccess"
          >
            <v-icon>
              {{ mdiPlus }}
            </v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            New Channel
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Channel Name"
              v-model="name"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="dialog = false" text>Cancel</v-btn>
            <v-spacer />
            <v-btn :loading="isSaving" @click="create" color="success">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-autocomplete>
</template>

<script>
import { mdiPlus, mdiRefresh } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { useAuth } from '../use';
import teams from '../mixins/teams';

const auth = useAuth();

export default {
  name: 'SelectTeamChannel',
  mixins: [loading, teams],
  props: {
    value: {
      type: String,
      default: undefined,
    },
    teamId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiPlus,
      mdiRefresh,
      dialog: false,
      name: '',
      channels: [],
      selectedChannel: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedChannel = this.value;
      },
    },
    selectedChannel: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.selectedChannel);
      },
    },
    teamId: {
      immediate: true,
      handler(newValue) {
        this.refresh(newValue);
        setTimeout(() => {
          this.refresh(newValue);
        }, 1000);
      },
    },
  },
  async mounted() {
    this.selectedChannel = this.value;
    if (this.teamId) {
      await this.refresh(this.teamId);
    }
  },
  methods: {
    async checkAccess() {
      const scopes = ['https://graph.microsoft.com/Team.Create'];
      try {
        await auth.getScopedAccessToken(scopes);
      } catch (error) {
        this.$root.$emit('toast:error', 'You do not have permission to create a team');
        this.$log.error('You do not have permission to create a team:', error);
      }
    },
    /**
     * Create channel
     */
    async create() {
      this.isSaving = true;
      try {
        const endpoint = `https://graph.microsoft.com/v1.0/teams/${this.teamId}/channels`;
        const data = {
          displayName: this.name,
          description: '',
        };
        await this.postGraph(
          endpoint,
          [
            'https://graph.microsoft.com/Channel.Create',
          ],
          data,
        );
        this.dialog = false;
      } catch (error) {
        this.$root.$emit('toast:error', 'Unable to get teams');
        this.$log.error('Unable to get teams:', error);
      }
      this.isSaving = false;
      await this.refresh();
    },
    async refresh(teamId) {
      this.isLoading = true;
      try {
        const endpoint = `https://graph.microsoft.com/v1.0/teams/${teamId}/channels`;
        this.channels = await this.fetchGraph(endpoint, ['Channel.ReadBasic.All']);
      } catch (error) {
        this.$log.error('Unable to get channels:', error);
      }
      this.isLoading = false;
    },
  },
};
</script>
../mixins/teams
