var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("DataTable", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  loading: _vm.isLoading,
                  slots: [
                    { name: "actions" },
                    { name: "created" },
                    { name: "favourite" },
                    { name: "language" },
                  ],
                },
                scopedSlots: _vm._u([
                  {
                    key: "new",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              color: "success",
                              "data-cy": "AutomatedResponse-button-new",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  name: "tasks-EditAutomatedResponse",
                                })
                              },
                            },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.$t("common.buttons.new")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "filters",
                    fn: function () {
                      return [
                        _c("v-select", {
                          attrs: {
                            items: _vm.languages,
                            label: _vm.$$t("language"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.filterLanguage,
                            callback: function ($$v) {
                              _vm.filterLanguage = $$v
                            },
                            expression: "filterLanguage",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "language",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.getLanguage(item.language)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "favourite",
                    fn: function ({ item }) {
                      return [
                        _c("Favourite", {
                          attrs: {
                            name: "AutoResponse",
                            identifier: `${_vm.userId}-${item.id}`,
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "created",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.formatDate(item.created)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "actions",
                    fn: function ({ item }) {
                      return [
                        _c("TableBasicButtons", {
                          attrs: {
                            editLink: {
                              name: "tasks-EditAutomatedResponse",
                              query: { id: item.id },
                            },
                          },
                          on: {
                            delete: function ($event) {
                              return _vm.remove(item)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }