var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mb-5" },
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.task.title))]),
      _c("v-card-subtitle", { staticClass: "d-flex justify-space-between" }, [
        _c("span", [_vm._v("Status: "), _c("b", [_vm._v(_vm._s(_vm.status))])]),
        _vm.task.dueDateTime
          ? _c("span", [
              _vm._v(" Due: "),
              _c("b", [
                _vm._v(
                  _vm._s(
                    new Date(_vm.task.dueDateTime.dateTime).toLocaleDateString()
                  )
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("v-card-text", {
        domProps: { innerHTML: _vm._s(_vm.task.body?.content) },
      }),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm.canComplete
            ? _c(
                "v-btn",
                {
                  attrs: { color: "success", loading: _vm.isSaving },
                  on: { click: _vm.complete },
                },
                [_vm._v(" Complete ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }