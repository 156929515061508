var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab",
            { attrs: { ripple: "" } },
            [
              _c("v-icon", [_vm._v(_vm._s(_vm.mdiCalendarTextOutline))]),
              _vm._v(" Events "),
            ],
            1
          ),
          _c(
            "v-tab",
            { attrs: { ripple: "" } },
            [
              _c("v-icon", [_vm._v(_vm._s(_vm.mdiMailbox))]),
              _c("span", { staticClass: "mr-2" }, [_vm._v(" Inbound Email ")]),
              _c("ExchangeHistory"),
            ],
            1
          ),
          _c(
            "v-tab",
            { attrs: { ripple: "" } },
            [
              _c("v-icon", [_vm._v(_vm._s(_vm.mdiPhone))]),
              _vm._v(" Text Messages "),
            ],
            1
          ),
          _c(
            "v-tab",
            { attrs: { ripple: "" } },
            [
              _c("v-icon", [_vm._v(_vm._s(_vm.mdiWhatsapp))]),
              _vm._v(" WhatsApp Messages "),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "SourceEventLog",
                {
                  attrs: { "hide-search": false, filter: _vm.filter },
                  scopedSlots: _vm._u([
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                title: _vm.includeVerbose
                                  ? "Hide Verbose"
                                  : "Show Verbose",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.includeVerbose = !_vm.includeVerbose
                                },
                              },
                            },
                            [
                              _vm.includeVerbose
                                ? _c("v-icon", [
                                    _vm._v(_vm._s(_vm.mdiScriptTextPlay)),
                                  ])
                                : _c("v-icon", [
                                    _vm._v(_vm._s(_vm.mdiScriptTextOutline)),
                                  ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_vm._v(" > ")]
              ),
            ],
            1
          ),
          _c("v-tab-item", [_c("ExchangeMails")], 1),
          _c("v-tab-item", [_c("TextMessages")], 1),
          _c("v-tab-item", [_c("WhatsAppMessages")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }