<template>
  <div>
    <v-file-input
      ref="fileInput"
      v-model="file"
      label="Add attachment"
      @change="filesChanged"
      style="min-width: 400px"
    />
    <v-chip
      v-for="(attachment, index) in attachments"
      :key="index"
      class="mr-2"
      close
      @click:close="remove(attachment)"
    >
      {{ attachment.name }}
    </v-chip>
  </div>
</template>

<script>
import { mdiAttachment } from '@mdi/js';

export default {
  name: 'EmailAttachments',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mdiAttachment,
      file: null,
      attachments: this.value,
    };
  },
  watch: {
    value() {
      this.attachments = this.value;
    },
  },
  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        // eslint-disable-next-line func-names
        reader.onload = function () { resolve(reader.result); };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async filesChanged(file) {
      if (!file) {
        return;
      }
      this.$refs.fileInput.reset();

      if (file.size > 3 * 1024 * 1024) {
        this.$emit('toast:error', 'File size must be less than 3MB');
        return;
      }
      // eslint-disable-next-line no-await-in-loop
      const fileData = await this.getBase64(file);
      this.attachments.push({
        '@odata.type': '#microsoft.graph.fileAttachment',
        name: file.name,
        contentType: file.type,
        contentBytes: fileData.split(',')[1],
      });
      this.$emit('input', this.attachments);
    },
    remove(attachment) {
      this.attachments = this.attachments.filter((a) => a !== attachment);
      this.$emit('input', this.attachments);
    },
  },
};
</script>
