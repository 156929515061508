<template>
  <v-list
    nav
    dense
  >
    <div
      v-for="menuItem in items"
      :key="menuItem.name"
    >
      <v-list-item
        v-if="!menuItem.subLinks"
        class="mb-2"
        color="#1e0b4c"
        link
        :to="{ name: menuItem.pathName }"
        :data-cy="menuItem.name"
      >
        <v-list-item-icon>
          <v-icon color="#1e0b4c" v-if="menuItem.icon">{{ menuItem.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="#1e0b4c">{{ $t(menuItem.name) }}</v-list-item-title>
      </v-list-item>
      <v-list-group
        v-else
        :key="menuItem.name"
        no-action
        :prepend-icon="menuItem.icon"
      >
        <template v-slot:activator>
          <v-list-item-title color="#1e0b4c">{{ $t(menuItem.name) }}</v-list-item-title>
        </template>

        <v-list-item
          color="#1e0b4c"
          v-for="subLink in menuItem.subLinks"
          :to="{ name: subLink.pathName }"
          :key="subLink.name"
          link
        >
          <v-list-item-title color="#1e0b4c">{{ $t(subLink.name) }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </div>
  </v-list>
</template>

<script>
// import { mdiHome } from '@mdi/js';
import menuItems from '../menuItems';

export default {
  mixins: [menuItems],
  computed: {
    items() {
      const items = this.menuItems;
      return items;
    },
  },
};
</script>

<style scoped>
.v-list-item__content > a {
  justify-content: left;
}
</style>
