var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "date-time-picker" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "max-width": _vm.menuWidth,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            readonly: "",
                            color: _vm.color,
                            counter: _vm.counter,
                            dark: _vm.dark,
                            disabled: _vm.disabled,
                            filled: _vm.filled,
                            "hide-details": _vm.hideDetails,
                            label: _vm.label,
                            name: _vm.name,
                            required: _vm.required,
                            rules: _vm.rules,
                            value: _vm.fieldState,
                          },
                          on: { change: _vm.onFieldChange },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _vm.time
            ? _c(
                "v-time-picker",
                {
                  attrs: {
                    dark: _vm.dark,
                    value: _vm.pickerState,
                    width: _vm.menuWidth,
                  },
                  on: { change: _vm.onPickerChange },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "date-time-action",
                      on: { click: _vm.closeMenu },
                    },
                    [_vm._v("DONE")]
                  ),
                ],
                1
              )
            : _c(
                "v-date-picker",
                {
                  attrs: {
                    dark: _vm.dark,
                    value: _vm.pickerState,
                    "header-color": "#b82720",
                    color: "#b82720",
                    width: _vm.menuWidth,
                  },
                  on: { change: _vm.onPickerChange },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "date-time-action",
                      on: { click: _vm.closeMenu },
                    },
                    [_vm._v("DONE")]
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }