var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "70vw" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  { attrs: { icon: _vm.label.length === 0, title: _vm.title } },
                  on
                ),
                [
                  _c("v-icon", { class: { "mr-2": _vm.label.length > 0 } }, [
                    _vm._v(" " + _vm._s(_vm.mdiHistory) + " "),
                  ]),
                  _vm._v(" " + _vm._s(_vm.label) + " "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c("SourceEventLog", {
        attrs: { title: "History", filter: _vm.filter },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      icon: "",
                      title: _vm.includeVerbose
                        ? "Hide Verbose"
                        : "Show Verbose",
                    },
                    on: {
                      click: function ($event) {
                        _vm.includeVerbose = !_vm.includeVerbose
                      },
                    },
                  },
                  [
                    _vm.includeVerbose
                      ? _c("v-icon", [_vm._v(_vm._s(_vm.mdiScriptTextPlay))])
                      : _c("v-icon", [
                          _vm._v(_vm._s(_vm.mdiScriptTextOutline)),
                        ]),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", title: "Close" },
                    on: {
                      click: function ($event) {
                        _vm.dialog = false
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }