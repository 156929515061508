import authHub from '@codehq/aurora-app-core/src/authHub';

export default {
  data() {
    return {
      lastRefresh: new Date(),
    };
  },
  created() {
    this.$log.debug(`refresh mixin mounted: ${this.name}`);
    authHub.$on('page.refresh', () => {
      if (this.refresh) {
        this.refresh();
      }
    });
    this.$root.$on('page.refresh', () => {
      if (this.refresh) {
        this.refresh();
      }
    });
  },
  destroyed() {
    this.$root.$off('page.refresh');
  },
};
