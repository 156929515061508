<template>
  <div class="date-time-picker">
    <v-menu
      :close-on-content-click="false"
      v-model="showMenu"
      :max-width="menuWidth"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          readonly
          :color="color"
          :counter="counter"
          :dark="dark"
          :disabled="disabled"
          :filled="filled"
          :hide-details="hideDetails"
          :label="label"
          :name="name"
          :required="required"
          :rules="rules"
          :value="fieldState"
          @change="onFieldChange"
        />
      </template>
      <v-time-picker
        v-if="time"
        :dark="dark"
        @change="onPickerChange"
        :value="pickerState"
        :width="menuWidth"
      >
        <v-btn class="date-time-action" @click="closeMenu">DONE</v-btn>
      </v-time-picker>
      <v-date-picker
        v-else
        :dark="dark"
        @change="onPickerChange"
        :value="pickerState"
        header-color="#b82720"
        color="#b82720"
        :width="menuWidth"
      >
        <v-btn class="date-time-action" @click="closeMenu">DONE</v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'DateTimePicker',
  props: {
    color: String,
    counter: Number,
    dark: Boolean,
    disabled: Boolean,
    filled: Boolean,
    hideDetails: Boolean,
    label: String,
    name: String,
    model: String,
    required: Boolean,
    rules: Array,
    time: Boolean,
    value: String,
  },
  emits: ['change'],
  data() {
    return {
      fieldState: this.value,
      pickerState: this.value,
      showMenu: false,
    };
  },
  computed: {
    menuWidth() {
      return 280;
    },
  },
  methods: {
    onPickerChange(newValue) {
      this.pickerState = newValue;
    },
    onFieldChange(newValue) {
      this.onDateTimeChange(newValue);
      this.fieldState = newValue;
      this.$emit('change', newValue);
    },
    closeMenu() {
      this.showMenu = false;
      this.fieldState = this.pickerState;
      this.$emit('change', this.pickerState);
    },
  },
  watch: {
    value(newValue) {
      if (this.fieldState !== newValue) {
        this.fieldState = newValue;
        this.pickerState = newValue;
      }
    },
  },
};
</script>

<style>
/* #region Date picker */
.v-picker--date .v-picker__title,
.v-picker--date .v-date-picker-header button {
  color: #bdbdbd !important;
}

.v-picker--date .v-picker__title .v-date-picker-title__year {
  font-weight: 500;
}

.v-picker--date .v-picker__title .v-date-picker-title__date {
  font-size: 2rem;
  font-weight: bold;
}

.v-picker--date .v-picker__body {
  background-color: #1e1e1e;
}

.v-picker--date .v-date-picker-table th {
  color: #bdbdbdde;
}

.v-picker--date .v-date-picker-table .v-btn {
  color: #bdbdbd;
}

.v-picker--date .v-date-picker-table .v-btn--active {
  color: #1e1e1e;
}
/* #endregion */
/* #region Time picker */
.v-picker.v-picker--time
  .v-time-picker-title__time
  .v-picker__title__btn--active {
  color: #b82720;
}

.v-picker.v-picker--time .v-time-picker-title,
.v-picker.v-picker--time .v-time-picker-clock {
  color: #bdbdbd;
}

.v-picker.v-picker--time .v-picker__body {
  background-color: #bdbdbd;
}

.v-picker.v-picker--time .v-time-picker-clock {
  background-color: #1e1e1e;
  margin-bottom: 30px;
}

.v-picker.v-picker--time .v-time-picker-clock__hand,
.v-picker.v-picker--time .v-time-picker-clock__hand:before,
.v-picker.v-picker--time .v-time-picker-clock__item--active {
  background-color: #33b5e5 !important;
  border-color: #33b5e5 !important;
}

.v-picker.v-picker--time .v-time-picker-clock__hand:before {
  outline: 15px solid #33b5e533;
}

.v-picker.v-picker--time
  .v-time-picker-clock--indeterminate
  .v-time-picker-clock__hand {
  background-color: #33b5e533 !important;
  border-color: #33b5e533 !important;
}

.v-picker.v-picker--time .v-time-picker-clock__container .v-picker__title__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 42px;
  height: 42px;
  border-radius: 100%;
}

.v-picker.v-picker--time
  .v-time-picker-clock__container
  .v-picker__title__btn--active {
  color: #bdbdbd;
  background-color: #1e1e1e;
}
/* #endregion */
/* #region Date & Time */
.v-picker .v-picker__actions {
  padding: 0;
}

.v-picker .v-picker__actions .date-time-action {
  flex: 1 1 0;
  font-size: 0.875rem;
  font-weight: bold;
  height: 52px;
  border-radius: 0;
}
/* #endregion */
</style>
