<template>
  <v-container
    fluid
    grid-list-xl
    fill-height
  >
    <v-layout
      justify-center
      align-start
    >
      <v-flex xs12>
        <v-skeleton-loader
          :loading="isLoading"
          transition="scale-transition"
          type="list-item-two-line"
        >
          <v-card>
            <v-card-title>
              Extension Settings
            </v-card-title>
            <v-card-text class="flex flex-column justify-space-evenly">
              <v-form
                :lazy-validation="false"
              >
                <v-text-field
                  v-model="titleQuery"
                  label="Full name"
                  outlined
                />
                <v-text-field
                  v-model="jobQuery"
                  label=" Job title"
                  outlined
                />
                <v-text-field
                  v-model="email"
                  label="Email"
                  outlined
                />
                <v-text-field
                  v-model="profileImg"
                  label="Profile image"
                  outlined
                />
                <v-text-field
                  v-model="location"
                  label="Location"
                  outlined
                />
                <v-text-field
                  v-model="currentEmployer"
                  label="Current employer"
                  outlined
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn :loading="isSaving" @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-skeleton-loader>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/**
  * View for managing candidate settings
*/
import applicationSettings from '@/mixins/applicationSettings';

export default {
  name: 'AdminExtension',
  mixins: [applicationSettings],
  data() {
    return {
      nameSpace: 'extension.settings',
      gdprMessage: '',
      welcomeMessage: '',
      settings: [
        { name: 'titleQuery' },
        { name: 'jobQuery' },
        { name: 'email' },
        { name: 'phone' },
        { name: 'profileImg' },
        { name: 'location' },
        { name: 'currentEmployer' },
      ],
    };
  },
  async mounted() {
    await this.refresh();
  },
};

</script>
