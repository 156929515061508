<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-center">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormChecklistTemplate-name"
            :label="$$t('name')"
            v-model="localChecklistTemplate.name"
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-end mb-6">
        <v-btn
          color="default"
          outlined
          class="ml-4 btn-cancel"
          @click="cancel"
          data-cy="FormChecklistTemplate-button-cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          class="ml-4"
          :loading="isSaving"
          data-cy="FormChecklistTemplate-button-save">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>

export default {
  name: 'FormChecklistTemplate',
  module: 'ChecklistTemplates',
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    ChecklistTemplate: {
      type: Object,
      default: undefined,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localChecklistTemplate
      */
      localChecklistTemplate: {},
    };
  },
  watch: {
    ChecklistTemplate() {
      this.localChecklistTemplate = this.ChecklistTemplate;
    },
  },
  created() {
    this.localChecklistTemplate = this.ChecklistTemplate;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localChecklistTemplate);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormChecklistTemplate example

'''js
<FormChecklistTemplate :ChecklistTemplate="ChecklistTemplate" />
'''
</docs>
