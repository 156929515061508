var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { nav: "", dense: "" } },
    _vm._l(_vm.items, function (menuItem) {
      return _c(
        "div",
        { key: menuItem.name },
        [
          !menuItem.subLinks
            ? _c(
                "v-list-item",
                {
                  staticClass: "mb-2",
                  attrs: {
                    color: "#1e0b4c",
                    link: "",
                    to: { name: menuItem.pathName },
                    "data-cy": menuItem.name,
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      menuItem.icon
                        ? _c("v-icon", { attrs: { color: "#1e0b4c" } }, [
                            _vm._v(_vm._s(menuItem.icon)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-list-item-title", { attrs: { color: "#1e0b4c" } }, [
                    _vm._v(_vm._s(_vm.$t(menuItem.name))),
                  ]),
                ],
                1
              )
            : _c(
                "v-list-group",
                {
                  key: menuItem.name,
                  attrs: { "no-action": "", "prepend-icon": menuItem.icon },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function () {
                          return [
                            _c(
                              "v-list-item-title",
                              { attrs: { color: "#1e0b4c" } },
                              [_vm._v(_vm._s(_vm.$t(menuItem.name)))]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                _vm._l(menuItem.subLinks, function (subLink) {
                  return _c(
                    "v-list-item",
                    {
                      key: subLink.name,
                      attrs: {
                        color: "#1e0b4c",
                        to: { name: subLink.pathName },
                        link: "",
                      },
                    },
                    [
                      _c("v-list-item-title", { attrs: { color: "#1e0b4c" } }, [
                        _vm._v(_vm._s(_vm.$t(subLink.name))),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }