var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "align-start": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-skeleton-loader",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    transition: "scale-transition",
                    type: "list-item-two-line",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v(" Extension Settings ")]),
                      _c(
                        "v-card-text",
                        {
                          staticClass: "flex flex-column justify-space-evenly",
                        },
                        [
                          _c(
                            "v-form",
                            { attrs: { "lazy-validation": false } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Full name", outlined: "" },
                                model: {
                                  value: _vm.titleQuery,
                                  callback: function ($$v) {
                                    _vm.titleQuery = $$v
                                  },
                                  expression: "titleQuery",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: { label: " Job title", outlined: "" },
                                model: {
                                  value: _vm.jobQuery,
                                  callback: function ($$v) {
                                    _vm.jobQuery = $$v
                                  },
                                  expression: "jobQuery",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: { label: "Email", outlined: "" },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: { label: "Profile image", outlined: "" },
                                model: {
                                  value: _vm.profileImg,
                                  callback: function ($$v) {
                                    _vm.profileImg = $$v
                                  },
                                  expression: "profileImg",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: { label: "Location", outlined: "" },
                                model: {
                                  value: _vm.location,
                                  callback: function ($$v) {
                                    _vm.location = $$v
                                  },
                                  expression: "location",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Current employer",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.currentEmployer,
                                  callback: function ($$v) {
                                    _vm.currentEmployer = $$v
                                  },
                                  expression: "currentEmployer",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { loading: _vm.isSaving },
                              on: { click: _vm.save },
                            },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }