export default {
  addTask(state, task) {
    state.tasks.push(task);
  },
  addTaskList(state, taskList) {
    state.taskLists.push(taskList);
  },
  setTaskLists(state, taskLists) {
    state.taskLists = taskLists;
  },
  setTasks(state, tasks) {
    state.tasks = tasks;
  },
  setTask(state, task) {
    state.task = task;
  },
};
