<template>
  <v-dialog v-model="dialog" max-width="70vw">
    <template v-slot:activator="{ on }">
      <v-btn :icon="label.length === 0" :title="title" v-on="on">
        <v-icon :class="{ 'mr-2': label.length > 0 }">
          {{ mdiHistory }}
        </v-icon>
        {{ label }}
      </v-btn>
    </template>
    <SourceEventLog
      title="History"
      :filter="filter"
    >
      <template v-slot:buttons>
        <v-btn
          icon
          :title="includeVerbose ? 'Hide Verbose' : 'Show Verbose'"
          @click="includeVerbose = !includeVerbose"
        >
          <v-icon v-if="includeVerbose">{{ mdiScriptTextPlay }}</v-icon>
          <v-icon v-else>{{ mdiScriptTextOutline }}</v-icon>
        </v-btn>
        <v-btn icon @click="dialog = false" title="Close">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </template>
    </SourceEventLog>
  </v-dialog>
</template>

<script>
import {
  mdiClose, mdiHistory, mdiScriptTextOutline, mdiScriptTextPlay,
} from '@mdi/js';
import SourceEventLog from '@/components/SourceEventLog.vue';

export default {
  name: 'PreviewEmail',
  components: {
    SourceEventLog,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    message: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: 'View History',
    },
  },
  data() {
    return {
      dialog: false,
      includeVerbose: false,
      mdiClose,
      mdiHistory,
      mdiScriptTextOutline,
      mdiScriptTextPlay,
    };
  },
  computed: {
    filter() {
      const filter = `ActivityId eq ${this.message?.id} AND Source eq 'Message'`;
      if (!this.includeVerbose) {
        return `${filter} AND EventType ne 'Verbose'`;
      }
      return filter;
    },
  },
};
</script>
