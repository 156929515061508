<template>
  <v-data-table
    :items="messages"
    :headers="headers"
    :loading="isLoading"
    item-key="id"
    :items-per-page="25"
    :footer-props="{
      itemsPerPageOptions: [25, 50, 100, 250, 500, -1],
    }"
    sort-by="created"
    sort-desc
  >
    <template v-slot:item.to="{ item }">
      <v-chip
        v-for="(to, index) in item.to"
        :key="index"
        class="mr-2"
        color="accent"
      >
        <a :href="`mailto:${to.address}`">
          {{ to.name }}
        </a>
      </v-chip>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDateTime(item.created) }}
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'mdiWhatsapp',
  mixins: [loading],
  data() {
    return {
      messages: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'From',
          value: 'from',
        },
        {
          text: 'To',
          value: 'recipient',
        },
        {
          text: 'Message',
          value: 'body',
        },
        {
          text: 'Sent',
          value: 'created',
          width: '200px',
        },
        {
          text: 'Status',
          value: 'status',
          width: '200px',
        },
      ];
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('messages', ['LOAD_whatsAppMessages']),
    async refresh() {
      this.isLoading = true;
      this.messages = await this.LOAD_whatsAppMessages();
      this.isLoading = false;
    },
  },
};
</script>
