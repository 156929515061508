<template>
  <div>
    <v-btn outlined @click="openModal">Move Candidate to another Project</v-btn>
    <v-dialog v-model="modalOpen" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Move Candidate</span>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedProject"
            :items="jobListings"
            item-text="name"
            item-value="id"
            label="Select Job Listing"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeModal">Cancel</v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :loading="isSaving"
            @click="moveCandidate"
            :disabled="!selectedProject">Move</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'MoveCandidate',
  mixins: [loading],
  props: {
    jobListingCandidate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modalOpen: false,
      selectedProject: null,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('jobListings', ['jobListings']),
  },
  mounted() {
    this.LOAD_jobListings();
  },
  methods: {
    ...mapActions('jobListings', ['LOAD_jobListings']),
    ...mapActions('jobListingCandidates', ['UPDATE_jobListingCandidate']),
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    async moveCandidate() {
      // Perform the API call to move the candidate using the selected project
      // You can replace this console.log statement with your actual API call
      const updatedJobListingCandidate = { ...this.jobListingCandidate };
      updatedJobListingCandidate.candidate = undefined;
      updatedJobListingCandidate.comments = undefined;
      updatedJobListingCandidate.stage = undefined;
      updatedJobListingCandidate.task = undefined;
      updatedJobListingCandidate.messages = undefined;

      updatedJobListingCandidate.jobListingId = this.selectedProject;

      this.$log.debug(updatedJobListingCandidate);
      this.isSaving = true;
      await this.UPDATE_jobListingCandidate({
        id: this.jobListingCandidate.id,
        jobListingId: this.selectedProject,
      });
      this.isSaving = false;

      // Close the modal after the candidate is moved
      this.closeModal();
      this.$emit('refresh');
    },
  },
};
</script>
