var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("DataTable", {
        staticClass: "elevation-1",
        attrs: {
          name: "Candidates",
          headers: _vm.headers,
          items: _vm.items,
          loading: _vm.isLoading,
          slots: [
            { name: "email" },
            { name: "phone" },
            { name: "actions" },
            { name: "name" },
            { name: "created" },
            { name: "createdBy" },
            { name: "updated" },
            { name: "favourite" },
          ],
          "custom-filter": _vm.customFilter,
          "show-select": "",
          "sort-by": "created",
          "sort-desc": "",
        },
        on: { refresh: _vm.refresh },
        scopedSlots: _vm._u([
          {
            key: "new",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2 mr-2",
                    attrs: {
                      color: "success",
                      "data-cy": "Candidate-button-new",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "candidates-EditCandidate",
                        })
                      },
                    },
                  },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [
                      _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("common.buttons.new")) + " "),
                  ],
                  1
                ),
                _vm.selected.length > 0
                  ? _c("DialogMerge", {
                      on: { refresh: _vm.refresh },
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "filters",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("v-checkbox", {
                          attrs: { label: "LinkedIn Only", clearable: "" },
                          model: {
                            value: _vm.filter.hasLinkedIn,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter, "hasLinkedIn", $$v)
                            },
                            expression: "filter.hasLinkedIn",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.createdByList,
                            label: "Created by",
                            clearable: "",
                          },
                          model: {
                            value: _vm.filter.createdBy,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter, "createdBy", $$v)
                            },
                            expression: "filter.createdBy",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.locationList,
                            label: "Location",
                            clearable: "",
                          },
                          model: {
                            value: _vm.filter.location,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter, "location", $$v)
                            },
                            expression: "filter.location",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Favourites" },
                          model: {
                            value: _vm.filterFavourites,
                            callback: function ($$v) {
                              _vm.filterFavourites = $$v
                            },
                            expression: "filterFavourites",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "created",
            fn: function ({ item }) {
              return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")]
            },
          },
          {
            key: "createdBy",
            fn: function ({ item }) {
              return [_vm._v(" " + _vm._s(item.createdBy) + " ")]
            },
          },
          {
            key: "favourite",
            fn: function ({ item }) {
              return [
                _c("Favourite", {
                  attrs: {
                    name: "JobListing",
                    identifier: `${_vm.userId}-${item.id}`,
                  },
                }),
              ]
            },
          },
          {
            key: "updated",
            fn: function ({ item }) {
              return [
                item.updated
                  ? _c("span", [_vm._v(_vm._s(_vm.formatDate(item.updated)))])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "name",
            fn: function ({ item }) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "candidates-EditCandidate",
                        query: { id: item.id },
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                ),
                item.linkedInUrl
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          small: "",
                          icon: "",
                          href: item.linkedInUrl,
                          target: "_blank",
                          rel: "noopener noreferrer",
                        },
                      },
                      [_c("v-icon", [_vm._v(_vm._s(_vm.mdiLinkedin))])],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "email",
            fn: function ({ item }) {
              return [
                _c("a", { attrs: { href: `mailto:${item.email}` } }, [
                  _vm._v(" " + _vm._s(item.email) + " "),
                ]),
              ]
            },
          },
          {
            key: "phone",
            fn: function ({ item }) {
              return [
                _c("a", { attrs: { href: `tel:${item.phone}` } }, [
                  _vm._v(" " + _vm._s(item.phone) + " "),
                ]),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item }) {
              return [
                _c("TableBasicButtons", {
                  attrs: {
                    editLink: {
                      name: "candidates-EditCandidate",
                      query: { id: item.id },
                    },
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.remove(item)
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }