export default {
  data() {
    return {

      languages: [
        { text: 'English', value: 'en' },
        { text: 'French', value: 'fr' },
        { text: 'Spanish', value: 'es' },
        { text: 'German', value: 'de' },
        { text: 'Italian', value: 'it' },
        { text: 'Portuguese', value: 'pt' },
        { text: 'Russian', value: 'ru' },
        { text: 'Chinese', value: 'zh' },
        { text: 'Japanese', value: 'ja' },
        { text: 'Korean', value: 'ko' },
        { text: 'Arabic', value: 'ar' },
        { text: 'Dutch', value: 'nl' },
        { text: 'Polish', value: 'pl' },
        { text: 'Turkish', value: 'tr' },
        { text: 'Swedish', value: 'sv' },
        { text: 'Danish', value: 'da' },
        { text: 'Finnish', value: 'fi' },
        { text: 'Norwegian', value: 'no' },
        { text: 'Czech', value: 'cs' },
        { text: 'Hungarian', value: 'hu' },
        { text: 'Romanian', value: 'ro' },
        { text: 'Slovak', value: 'sk' },
        { text: 'Slovenian', value: 'sl' },
        { text: 'Greek', value: 'el' },
        { text: 'Bulgarian', value: 'bg' },
        { text: 'Ukrainian', value: 'uk' },
        { text: 'Hebrew', value: 'he' },
        { text: 'Indonesian', value: 'id' },
        { text: 'Malay', value: 'ms' },
        { text: 'Vietnamese', value: 'vi' },
        { text: 'Thai', value: 'th' },
        { text: 'Bengali', value: 'bn' },
        { text: 'Persian', value: 'fa' },
        { text: 'Hindi', value: 'hi' },
        { text: 'Marathi', value: 'mr' },
        { text: 'Tamil', value: 'ta' },
        { text: 'Telugu', value: 'te' },
        { text: 'Urdu', value: 'ur' },
        { text: 'Kannada', value: 'kn' },
        { text: 'Malayalam', value: 'ml' },
        { text: 'Gujarati', value: 'gu' },
      ],
    };
  },
  methods: {
    getLanguage(value) {
      return this.languages.find((language) => language.value === value)?.text;
    },
  },
};
