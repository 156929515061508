var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  { attrs: { text: "" }, on: { click: _vm.openDialog } },
                  on
                ),
                [_vm._v(" " + _vm._s(_vm.CandidateProp?.name) + " ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c("FormCandidate", {
        attrs: { Candidate: _vm.editCandidate, isSaving: _vm.isSaving },
        on: { save: _vm.save, cancel: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }