<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormTask
      :Task="editTask"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({ name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormTask from '../components/FormTask.vue';

export default {
  components: {
    FormTask,
  },
  name: 'EditTask',
  module: 'Tasks',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Task. Default to {}.
      */
      editTask: {},
      /**
       * Query Id for Task
       */
      taskId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('tasks', ['tasks', 'task']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.taskId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_task(this.taskId);
      this.editTask = this.task;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('tasks', ['LOAD_task', 'CREATE_task', 'UPDATE_task']),
    /** Method used to save the item
     */
    async save(task) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_task(task);
      } else {
        await this.UPDATE_task(task);
      }
      this.isSaving = false;
      this.$router.push({ name: 'task-ListTask' });
    },
  },
};
</script>

<docs>
# EditTask example

'''js
<EditTask :Task="Task" />
'''
</docs>
