var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _vm.showLanguage
                ? _c("v-select", {
                    attrs: {
                      items: ["English", "French", "German", "Italian"],
                      label: "Language",
                    },
                    model: {
                      value: _vm.language,
                      callback: function ($$v) {
                        _vm.language = $$v
                      },
                      expression: "language",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _vm.showLanguage
                ? _c("v-select", {
                    attrs: {
                      items: ["CV", "Certificate", "Other"],
                      label: "Language",
                    },
                    model: {
                      value: _vm.documentType,
                      callback: function ($$v) {
                        _vm.documentType = $$v
                      },
                      expression: "documentType",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("FileUpload", {
                attrs: {
                  accept: _vm.documentAccept,
                  label: _vm.label,
                  icon: _vm.mdiFileDocumentOutline,
                },
                on: { input: _vm.save },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isLoading
        ? _c("ProgressLinearTimeout")
        : _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-left", attrs: { cols: "12" } }, [
                _c("table", { staticClass: "table-candidate-documents" }, [
                  _c(
                    "tbody",
                    _vm._l(_vm.documents, function (document) {
                      return _c("tr", { key: document.id }, [
                        _c("td", [_vm._v(_vm._s(document.language))]),
                        _c("td", [_vm._v(_vm._s(document.documentType))]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: document.documentUrl,
                                color: "primary",
                                target: "_blank",
                                rel: "noopener noreferrer",
                              },
                            },
                            [_vm._v(" " + _vm._s(document.fileName) + " ")]
                          ),
                        ]),
                        _c(
                          "td",
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: { small: "", color: "error" },
                                on: {
                                  click: function ($event) {
                                    return _vm.remove(document)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.mdiTrashCanOutline) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }