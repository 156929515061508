<template>
  <v-dialog
    v-model="dialog"
    fullscreen
  >
    <template v-slot:activator="{ on }">
      <v-btn
        @click="openDialog"
        v-on="on"
        text
      >
        {{ CandidateProp?.name }}
      </v-btn>
    </template>
    <FormCandidate
      :Candidate="editCandidate"
      :isSaving="isSaving"
      @save="save"
      @cancel="cancel" />
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormCandidate from './FormCandidate.vue';

export default {
  components: {
    FormCandidate,
  },
  name: 'CandidateModal',
  props: {
    CandidateProp: {
      type: Object,
      required: true,
    },
  },
  module: 'Candidates',
  data() {
    return {
      dialog: false,
      /**
      * Get/Set editing Candidate. Default to {}.
      */
      editCandidate: {},
      /**
       * Query Id for Candidate
       */
      candidateId: undefined,
      isSaving: false,
      isLoading: false,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('candidates', ['candidates', 'candidate']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.candidateId) {
        return 'edit';
      }
      return 'add';
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('candidates', ['LOAD_candidate', 'CREATE_candidate', 'UPDATE_candidate']),
    ...mapActions('industries', ['LOAD_industries']),
    async openDialog() {
      this.isLoading = true;
      this.candidateId = this.CandidateProp.id;
      if (this.mode === 'edit') {
        await this.LOAD_candidate(this.candidateId);
        this.editCandidate = this.candidate;
      }
      this.isLoading = false;
      this.dialog = true;
    },
    /** Method used to save the item
     */
    async save(candidate) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_candidate(candidate);
      } else {
        await this.UPDATE_candidate(candidate);
      }
      this.isSaving = false;
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
};
</script>
<style>
.v-dialog__content--active {
  background: white;
}
</style>
