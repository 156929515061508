<template>
  <v-dialog v-model="dialog" max-width="70vw">
    <template v-slot:activator="{ on }">
      <v-btn :icon="label.length === 0" :title="title" v-on="on" small>
        <v-icon :class="{ 'mr-2': label.length > 0 }">
          {{ mdiEmailOpen }}
        </v-icon>
        {{ label }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h3>{{ message.title }}</h3>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 80vh">
        <iframe ref="emailIframe" width="100%" height="100%" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiEmailOpen } from '@mdi/js';

export default {
  name: 'PreviewEmail',
  props: {
    label: {
      type: String,
      default: '',
    },
    message: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: 'View Email',
    },
  },
  data() {
    return {
      dialog: false,
      mdiClose,
      mdiEmailOpen,
    };
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.emailIframe.srcdoc = this.message.subtitle;
        });
      }
    },
  },
};
</script>
