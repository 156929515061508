<template>
  <v-menu bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
        :color="color"
        :small="small"
        title="Resize column"
      >
        <v-icon>{{ mdiArrowSplitVertical }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="$emit('resize', 'small')">
        <v-list-item-title>Small Column</v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('resize', 'medium')">
        <v-list-item-title>Medium Column</v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('resize', 'large')">
        <v-list-item-title>Large Column</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiArrowSplitVertical } from '@mdi/js';

export default {
  name: 'ResizeColumnButton',
  props: {
    color: {
      type: String,
      default: 'default',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiArrowSplitVertical,
    };
  },
};
</script>
