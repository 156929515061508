<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn :loading="isLoading" color="primary" v-on="on">
          <v-icon>{{ mdiFileExcel }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="dialog = true">
          <v-list-item-title>Import</v-list-item-title>
        </v-list-item>
        <v-list-item @click="exportFile">
          <v-list-item-title>Export</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="dialog" max-width="300px">
      <v-card>
        <v-card-text>
          <v-file-input
            v-model="file"
            accept=".xlsx"
            label="Import"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false;" text>Cancel</v-btn>
          <v-spacer />
          <v-btn :loading="isSaving" color="primary" @click="importFile">
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mdiFileExcel } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import axios from 'axios';
import { useAuth } from '../use';

const auth = useAuth();

export default {
  name: 'ImportExport',
  mixins: [loading],
  props: {
    name: {
      type: String,
      default: 'export',
    },
    url: {
      type: String,
      default: '/odata',
    },
  },
  data() {
    return {
      mdiFileExcel,
      file: null,
      dialog: false,
    };
  },
  methods: {
    async importFile() {
      // code to import file
      this.$log.debug(this.file);
      this.isSaving = true;
      try {
        const formData = new FormData();
        formData.append('file', this.file);
        await axios.post(`${this.url}/import`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error importing file, please try again later');
      } finally {
        this.isSaving = false;
        this.dialog = false;
        this.$emit('refresh');
      }
    },
    async exportFile() {
      this.isLoading = true;
      try {
        const token = await auth.getAccessToken();
        const response = await axios({
          url: `${this.url}/export`,
          method: 'GET',
          responseType: 'blob', // important
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.name}.xlsx`;
        link.click();
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error exporting file, please try again later');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
