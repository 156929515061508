var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auto-grow-textarea-container" }, [
    _c(
      "textarea",
      _vm._b(
        {
          style: { height: _vm.height, width: _vm.width },
          domProps: { value: _vm.value },
          on: { input: _vm.handleInput, change: _vm.handleChange },
        },
        "textarea",
        _vm.$attrs,
        false
      )
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }