var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _vm.isLoading
            ? _c("ProgressLinearTimeout")
            : _c(
                "v-timeline",
                { attrs: { "align-top": "", dense: "" } },
                _vm._l(_vm.feedbackComments, function (comment) {
                  return _c(
                    "v-timeline-item",
                    { key: comment.id, attrs: { small: "" } },
                    [
                      _c("div", [
                        _c("div", { staticClass: "font-weight-normal" }, [
                          _c("strong", [_vm._v(_vm._s(comment.createdBy))]),
                          _vm._v(
                            " | " +
                              _vm._s(_vm.formatRelative(comment.created)) +
                              " "
                          ),
                        ]),
                        _c("div", [_vm._v(_vm._s(comment.content))]),
                      ]),
                    ]
                  )
                }),
                1
              ),
          _c("v-divider"),
          _c(
            "v-textarea",
            {
              attrs: { placeholder: "Add a comment", outlined: "", rows: "2" },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            color: "secondary",
                            loading: _vm.isSaving,
                          },
                          on: { click: _vm.addComment },
                        },
                        [_vm._v(" Add ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.content,
                callback: function ($$v) {
                  _vm.content = $$v
                },
                expression: "content",
              },
            },
            [_vm._v(" > ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }