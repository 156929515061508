<template>
  <v-container fill-height fluid>
    <v-row class="background justify-center align-center mt-6" no-gutters>
      <v-col cols="12" lg="4" xl="4">
        <v-card flat class="ma-3 pa-4">
          <v-container>
            <v-row class="mt-3" justify="center" no-gutters>
              <v-col cols="12">
                <v-card flat>
                  <v-img class="ml-auto mr-auto" src="../assets/logo.svg" width="229" />
                  <v-card-text>
                    <Register />
                    <v-container>
                      <v-row class="mt-0" justify="center">
                        <h4 class="mb-5" justify="center">
                          <span>{{ $t('login.or') }}</span>
                        </h4>
                      </v-row>
                      <v-row class="mt-0" justify="center">
                        <v-btn text class="title" :to="{ name: 'auth-Login' }">
                          {{ $t('register.login') }}
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js';
import Register from '@codehq/aurora-app-core/src/components/Register.vue';

export default {
  name: 'register',
  components: {
    Register,
  },
  data() {
    return {
      mdiOpenInNew,
      counterInterval: undefined,
      backgroundName: 'main-background1',
    };
  },
};
</script>
