var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoggedIn
    ? _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: { color: "#1e0b4c", text: "", outlined: "" },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c("v-icon", { attrs: { color: "#1e0b4c" } }, [
                          _vm._v(_vm._s(_vm.mdiAccount)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "hidden-md-and-down" },
                          [
                            _c("DisplayName", {
                              attrs: { user: _vm.username },
                            }),
                          ],
                          1
                        ),
                        _c("v-icon", { attrs: { color: "#1e0b4c" } }, [
                          _vm._v(_vm._s(_vm.mdiChevronDown)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            2850006889
          ),
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "tasks-ListAutomatedResponse",
                      })
                    },
                  },
                },
                [_c("v-list-item-title", [_vm._v("Auto Responses")])],
                1
              ),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "app-Profile" })
                    },
                  },
                },
                [_c("v-list-item-title", [_vm._v("Profile")])],
                1
              ),
              _c(
                "v-list-item",
                { on: { click: _vm.logout } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("common.logoff"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }