<template>
  <v-btn
    class="ml-2"
    color="success"
    v-show="data || sheets"
    @click="exportToExcel"
    :loading="isLoading"
  >
    <v-icon>{{ mdiFileExcel }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiFileExcel } from '@mdi/js';
import { json2excel } from 'js2excel';

export default {
  name: 'ExportToExcelButton',
  props: ['data', 'name'],
  data() {
    return {
      mdiFileExcel,
      isLoading: false,
    };
  },
  methods: {
    async exportToExcel() {
      this.isLoading = true;
      // eslint-disable-next-line no-unused-vars
      const { data, name } = this;
      await json2excel({
        data,
        name,
        formateDate: 'yyyy/mm/dd',
      });
      this.isLoading = false;
    },
  },
};
</script>
