var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        color: "primary",
                        dark: "",
                        "data-cy": "btn-add-candidate",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" Add a candidate ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Select Candidate ")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "data-cy": "select-candidate",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.candidates,
                              multiple: "",
                              label: "Select Candidates",
                            },
                            model: {
                              value: _vm.selectedUsers,
                              callback: function ($$v) {
                                _vm.selectedUsers = $$v
                              },
                              expression: "selectedUsers",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-btn", { attrs: { text: "" }, on: { click: _vm.cancel } }, [
                _vm._v("Cancel"),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "add-candidate-submit",
                    color: "success",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v("Submit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }