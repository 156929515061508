var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isLoading ? _c("ProgressLinearTimeout") : _vm._e(),
      _c(
        "v-card",
        { staticClass: "mb-10" },
        [
          _c(
            "v-card-title",
            { attrs: { "primary-title": "" } },
            [
              _vm._v(" " + _vm._s(_vm.$$t("title")) + " "),
              _c("v-spacer"),
              _c("v-checkbox", {
                attrs: { label: "Favourites", value: _vm.filterFavourites },
                on: { change: _vm.onFilterFavouritesChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-iterator", {
        attrs: {
          items: _vm.items,
          "items-per-page": 4,
          loading: _vm.isLoading,
          "footer-props": { itemsPerPageOptions: [4, 8, 12, 16, 20] },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ items }) {
              return [
                _c(
                  "v-row",
                  _vm._l(items, function (item, index) {
                    return _c(
                      "v-col",
                      {
                        key: index,
                        attrs: { cols: "12", md: "6", lg: "4", xl: "3" },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("ScreenShot", {
                              attrs: { "element-id": item.id },
                            }),
                            _c(
                              "div",
                              { attrs: { id: item.id } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-h5 text-center d-block",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.client?.name) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "text-h5 text-center d-block",
                                    attrs: {
                                      to: {
                                        name: "clients-ViewJobListing",
                                        query: { jobListingId: item.id },
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                                !_vm.isLoading
                                  ? _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-tabs",
                                          {
                                            model: {
                                              value: item.tab,
                                              callback: function ($$v) {
                                                _vm.$set(item, "tab", $$v)
                                              },
                                              expression: "item.tab",
                                            },
                                          },
                                          [
                                            _c("v-tab", [_vm._v(" Pipeline ")]),
                                            _c("v-tab", [_vm._v(" Active ")]),
                                            _c("v-tab", [_vm._v(" Issues ")]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tabs-items",
                                          {
                                            staticClass: "mt-5",
                                            model: {
                                              value: item.tab,
                                              callback: function ($$v) {
                                                _vm.$set(item, "tab", $$v)
                                              },
                                              expression: "item.tab",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-tab-item",
                                              [
                                                _c("GraphFunnel", {
                                                  attrs: {
                                                    jobId: `${item.id}`,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-tab-item",
                                              [
                                                _c("GraphJobListingHome", {
                                                  attrs: {
                                                    jobListingCandidates:
                                                      item.jobListingCandidates,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-tab-item",
                                              [
                                                _c("GraphJobListingIssues", {
                                                  attrs: {
                                                    jobId: `${item.id}`,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }