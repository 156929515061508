import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('task', 'tasks');

const customActions = {
  async LOAD_tasksByStage({ commit, rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get('tasks')
      .query({ $filter: `IsDeleted eq false AND StageId eq ${id}` });
    commit('SET_tasks', data);
    return data;
  },
};

export default { ...actions, ...customActions };
