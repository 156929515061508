var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "400" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", dark: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" Merge ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
            _vm._v(" Merge these contacts together? "),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-list",
                _vm._l(_vm.localValue, function (candidate, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: candidate.id,
                      attrs: { draggable: true },
                      on: {
                        dragstart: function ($event) {
                          return _vm.onDragStart(index)
                        },
                        dragover: function ($event) {
                          return _vm.onDragOver(index)
                        },
                        drop: function ($event) {
                          return _vm.onDrop(index)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(candidate?.name)),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(_vm._s(candidate?.email)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiArrowDown))])],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("input", [])
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("refresh")
                    },
                  },
                },
                [_vm._v("Merge")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }