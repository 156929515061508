var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.allNotifications,
          loading: _vm.isLoading,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-dialog",
                      {
                        attrs: {
                          "max-width": "70vw",
                          transition: "dialog-transition",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        color: "success",
                                        "data-cy": "Notification-button-new",
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.buttons.new")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.dialog,
                          callback: function ($$v) {
                            _vm.dialog = $$v
                          },
                          expression: "dialog",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-title",
                              { attrs: { "primary-title": "" } },
                              [_vm._v(" Notification ")]
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-form",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "6",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.alertIcons,
                                                label:
                                                  _vm.$$t("notificationType"),
                                              },
                                              model: {
                                                value:
                                                  _vm.editNotification
                                                    .notificationType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editNotification,
                                                    "notificationType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editNotification.notificationType",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "6",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("DatePicker", {
                                              attrs: {
                                                filled: false,
                                                label: _vm.$$t("publishDate"),
                                              },
                                              model: {
                                                value:
                                                  _vm.editNotification
                                                    .publishDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editNotification,
                                                    "publishDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editNotification.publishDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("vue-editor", {
                                      attrs: { label: _vm.$$t("message") },
                                      model: {
                                        value: _vm.editNotification.message,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editNotification,
                                            "message",
                                            $$v
                                          )
                                        },
                                        expression: "editNotification.message",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.dialog = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "success" },
                                    on: { click: _vm.save },
                                  },
                                  [_vm._v(" Save ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      { attrs: { icon: "" }, on: { click: _vm.refresh } },
                      [_c("v-icon", [_vm._v(_vm._s(_vm.mdiRefresh))])],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.publishDate",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDate(item.publishDate)) + " "),
              ]
            },
          },
          {
            key: "item.message",
            fn: function ({ item }) {
              return [
                _c("div", {
                  staticClass: "notification-message",
                  domProps: { innerHTML: _vm._s(item.message) },
                }),
              ]
            },
          },
          {
            key: "item.created",
            fn: function ({ item }) {
              return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")]
            },
          },
          {
            key: "item.receipts",
            fn: function ({ item }) {
              return [
                item.receipts?.length > 0
                  ? _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "primary",
                                            small: "",
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.receipts.length) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-list",
                                  { attrs: { "two-line": "" } },
                                  [
                                    _vm._l(
                                      item.receipts,
                                      function (receipt, index) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            { key: index },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "text-left" },
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getUserName(
                                                            receipt.userId
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatDateTime(
                                                            receipt.created
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn-toggle",
                  [
                    _vm.previewNotifications.includes(
                      _vm.notifications.find((n) => n.id === item.id)
                    )
                      ? _c(
                          "v-btn",
                          {
                            attrs: { color: "default", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.removePreview(item)
                              },
                            },
                          },
                          [_vm._v(" Remove Preview ")]
                        )
                      : _c(
                          "v-btn",
                          {
                            attrs: { color: "default", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.preview(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("common.buttons.preview")) +
                                " "
                            ),
                          ]
                        ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "default", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("common.buttons.edit")) + " "
                        ),
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "error", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.remove(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("common.buttons.delete")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }