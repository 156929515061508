<template>
  <TableModalForm
    :headers="headers"
    :items="tasks"
    class="elevation-1"
    :loading="isLoading"
    name="Tasks"
    ref="table"
    :slots="[{ name: 'actions' }, { name: 'order' }]"
    show-select
    single-select
    sort-by="order"
    @creating="creating"
    @refresh="refresh"
    @item-selected="itemSelected"
  >
    <template v-slot:form>
      <FormTask
        :Task="editTask"
        :isSaving="isSaving"
        @save="save"
        @cancel="$refs.table.dialog = false" />
    </template>
    <template v-slot:order="{ item, index }">
      <v-btn icon @click="moveUp(item)">
        <v-icon v-if="index > 0" class="mr-2">{{ mdiArrowUpBold }}</v-icon>
      </v-btn>
      {{ item.order }}
      <v-btn icon @click="moveDown(item)">
        <v-icon v-if="index < (tasks.length - 1)" class="mr-2">{{ mdiArrowDownBold }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:actions="{ item }">
      <v-btn-toggle>
        <v-btn color="default" small @click="edit(item)">
          {{ $t('common.buttons.edit')}}
        </v-btn>
        <v-btn color="error" small @click="remove(item)">
          {{ $t('common.buttons.delete')}}
        </v-btn>
      </v-btn-toggle>
    </template>
  </TableModalForm>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiArrowUpBold, mdiArrowDownBold } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import TableModalForm from '@/components/TableModalForm.vue';
import FormTask from '../components/FormTask.vue';

export default {
  name: 'ListTask',
  module: 'Tasks',
  components: {
    FormTask,
    TableModalForm,
  },
  props: {
    Stage: {
      type: Object,
      default: undefined,
    },
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiArrowUpBold,
      mdiArrowDownBold,
      /**
      * Get/Set editing Task. Default to {}.
      */
      editTask: {},
      mode: 'add',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('tasks', ['tasks']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('order'),
          value: 'order',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('defaultDueDate'),
          value: 'defaultDueDate',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    sortedTasks() {
      const { tasks } = this;
      return tasks.sort((a, b) => a.order - b.order);
    },
  },
  watch: {
    Stage() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('tasks', [
      'LOAD_task',
      'CREATE_task',
      'UPDATE_task',
      'LOAD_tasksByStage',
      'DELETE_task',
    ]),
    creating() {
      this.editTask = {
        order: this.tasks.length + 1,
        stageId: this.Stage.id,
      };
      this.mode = 'add';
    },
    async edit(item) {
      this.editTask = { ...item };
      this.mode = 'edit';
      this.$refs.table.dialog = true;
    },
    /**
     * Moves an item up in the list
     */
    async moveDown(item) {
      const { sortedTasks } = this;
      const current = sortedTasks.find((s) => s.id === item.id);
      const currentIndex = sortedTasks.indexOf(current);
      // check if this is not the last item
      if (currentIndex < sortedTasks.length - 1) {
        const next = sortedTasks[currentIndex + 1];
        next.order = currentIndex + 1;
        await this.UPDATE_task(next);
      }
      current.order = currentIndex + 2;
      await this.UPDATE_task(current);
      await this.refresh();
    },
    /**
     * Moves an item down in the list
     */
    async moveUp(item) {
      const { sortedTasks } = this;
      const current = sortedTasks.find((s) => s.id === item.id);
      const currentIndex = sortedTasks.indexOf(current);
      if (currentIndex > 0) {
        const prev = sortedTasks[currentIndex - 1];
        prev.order = currentIndex + 1;
        await this.UPDATE_task(prev);
      }
      current.order = currentIndex;
      await this.UPDATE_task(current);
      await this.refresh();
    },
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_tasksByStage(this.Stage.id);
      this.isLoading = false;
    },
    /** Delete Task from the collection
     */
    async remove(task) {
      this.isLoading = true;
      await this.DELETE_task(task.id);
      this.isLoading = false;
    },
    /** Vuex methods
     */
    /** Method used to save the item
     */
    async save(task) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_task(task);
      } else {
        await this.UPDATE_task(task);
      }
      this.isSaving = false;
      this.$refs.table.dialog = false;
    },
    itemSelected(item) {
      this.$emit('selected', item);
    },
  },
};
</script>

<docs>
# Task example

'''js
<Task />
'''
</docs>
