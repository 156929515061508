import {
  mdiHome,
  mdiOfficeBuilding,
  mdiAccountSearch,
  mdiBriefcaseOutline,
  mdiLinkedin,
} from '@mdi/js';

export default {
  computed: {
    menuItems() {
      return [
        {
          name: 'menu.home', pathName: 'app-Home', icon: mdiHome, topMenu: false,
        },
        {
          name: 'menu.contacts', pathName: 'clients-ListClient', icon: mdiOfficeBuilding, topMenu: false,
        },
        {
          name: 'menu.jobListings', pathName: 'clients-ListJobListing', icon: mdiBriefcaseOutline, topMenu: false,
        },
        {
          name: 'menu.candidates', pathName: 'candidates-ListCandidate', icon: mdiAccountSearch, topMenu: false,
        },
        {
          name: 'menu.linkedIn', pathName: 'candidates-LinkedIn', icon: mdiLinkedin, topMenu: false,
        },
      ];
    },
  },
};
