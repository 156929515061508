import { render, staticRenderFns } from "./FormExplanation.vue?vue&type=template&id=182256c4"
import script from "./FormExplanation.vue?vue&type=script&lang=js"
export * from "./FormExplanation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./FormExplanation.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('182256c4')) {
      api.createRecord('182256c4', component.options)
    } else {
      api.reload('182256c4', component.options)
    }
    module.hot.accept("./FormExplanation.vue?vue&type=template&id=182256c4", function () {
      api.rerender('182256c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/tasks/components/FormExplanation.vue"
export default component.exports