<template>
  <div>
    <v-btn color="primary" v-if="selected.length > 0" class="mr-2" @click="openDialog">
      Mass Update ({{ selected.length }})
    </v-btn>

    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-select
              data-cy="UpdateJoblistingCandidates-stage"
              :items="orderedStages"
              :disabled="orderedStages.length === 0"
              v-model="form.stageId"
              item-text="name"
              item-value="id"
            />
            <v-select
              data-cy="UpdateJoblistingCandidates-task"
              :items="getTasksByStageId(form.stageId)"
              v-model="form.taskId"
              item-text="name"
              item-value="id"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text @click="closeDialog">Cancel</v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="updateTasks"
            :loading="isSaving"
            :disabled="!valid">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'UpdateJoblistingCandidates',
  mixins: [loading],
  data() {
    return {
      dialog: false,
      message: '',
      valid: false,
      form: {
        stageId: undefined,
        taskId: undefined,
      },
    };
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('stages', ['orderedStages']),
    ...mapGetters('tasks', ['getTasksByStageId']),
    dialogTitle() {
      return `Updating ${this.selected.length} candidate${this.selected.length > 1 ? 's' : ''}`;
    },
  },
  methods: {
    ...mapActions('jobListingCandidates', [
      'UPDATE_jobListingCandidate',
    ]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.message = '';
      this.valid = false;
    },
    sendMessage() {
      if (this.$refs.form.validate()) {
        // Send message logic here
        this.closeDialog();
      }
    },
    async updateTasks() {
      if (this.$refs.form.validate()) {
        this.$log.debug('updateTasks', this.form);
        this.isSaving = true;
        const { taskId, stageId } = this.form;
        // eslint-disable-next-line max-len
        const promises = this.selected.map((jobListingCandidate) => this.UPDATE_jobListingCandidate({
          id: jobListingCandidate.id,
          taskId,
          stageId,
        }));
        await Promise.all(promises);
        this.isSaving = false;
        this.closeDialog();
      }
    },
  },
};
</script>
