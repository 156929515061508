<template>
  <v-container fluid>
    <v-img src="../assets/logo.svg" contain max-height="100" class="mx-auto" />
    <v-row>
      <v-col offset="4" cols="4" class="text-center">
        <v-form @submit.prevent="acceptInvite">
          <v-text-field
            v-model="inviteCode"
            :rules="[rules.required]"
            label="Invite Code"
            required
          />
          <v-btn
            v-if="loggedIn"
            :loading="isSaving"
            type="submit"
            color="primary"
          >
            Accept Invite
          </v-btn>
          <v-btn v-else block color="success" @click="getToken" :loading="isLoading">
            <v-icon class="mr-2">{{ mdiMicrosoftOffice }}</v-icon>
            Microsoft Login
          </v-btn>
        </v-form>
        <v-alert class="mt-10" type="error" v-if="errors.length > 0" dismissible>
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { InteractiveBrowserCredential } from '@azure/identity';
import { mdiMicrosoftOffice } from '@mdi/js';

export default {
  name: 'AcceptInvite',
  mixins: [loading],
  data() {
    return {
      mdiMicrosoftOffice,
      loggedIn: false,
      inviteCode: this.$route.query.inviteCode || '',
      errors: [],
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      try {
        const credential = new InteractiveBrowserCredential({
          clientId: process.env.VUE_APP_AZURE_IDENTITY_CLIENT_ID,
        });
        const scope = `api://${process.env.VUE_APP_AZURE_IDENTITY_CLIENT_ID}/BrightFuture.Read`; // replace with your scope
        const accessToken = await credential.getToken(scope);
        window.localStorage.setItem('auth-token', JSON.stringify({ access_token: accessToken.token }));
        this.loggedIn = true;
      } catch (error) {
        this.loggedIn = false;
      }
    },
    async acceptInvite() {
      try {
        this.errors = [];
        this.isSaving = true;
        await axios.post('/api/invite/accept', { inviteCode: this.inviteCode });
        // Do something with response, e.g. redirect user
        this.$router.push({ name: 'app-Init' });
      } catch (error) {
        this.errors.push(error.response.data.message);
      } finally {
        this.isSaving = false;
      }
    },
    async getToken() {
      this.isLoading = true;
      try {
        const credential = new InteractiveBrowserCredential({
          clientId: process.env.VUE_APP_AZURE_IDENTITY_CLIENT_ID,
        });
        const scope = 'api://c2998be1-330b-4ca2-9074-4647d3889113/BrightFuture.Read'; // replace with your scope
        const accessToken = await credential.getToken(scope);
        window.localStorage.setItem('auth-token', JSON.stringify({ access_token: accessToken.token }));
        this.loggedIn = true;
        window.location.reload();
      } catch (error) {
        this.loggedIn = false;
        this.$root.$emit('toast:error', 'Unable to login');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
