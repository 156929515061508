var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-right": 40,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "290px",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####-##-##",
                            expression: "'####-##-##'",
                          },
                        ],
                        ref: "dateField",
                        attrs: {
                          "data-cy": "DatePicker-input",
                          label: _vm.label,
                          rules: _vm.rules,
                          filled: _vm.filled,
                          id: _vm.id,
                          dense: _vm.dense,
                        },
                        on: {
                          click: function ($event) {
                            _vm.localOpenModal = true
                          },
                          "click:clear": function ($event) {
                            return _vm.$emit("input", undefined)
                          },
                          input: _vm.offClick,
                        },
                        model: {
                          value: _vm.localDate,
                          callback: function ($$v) {
                            _vm.localDate = $$v
                          },
                          expression: "localDate",
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.localOpenModal,
            callback: function ($$v) {
              _vm.localOpenModal = $$v
            },
            expression: "localOpenModal",
          },
        },
        [
          _c("v-date-picker", {
            on: { input: _vm.inputDate },
            model: {
              value: _vm.UpdatedDate,
              callback: function ($$v) {
                _vm.UpdatedDate = $$v
              },
              expression: "UpdatedDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }