var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.index === 0 },
              on: {
                click: function ($event) {
                  _vm.index -= 1
                },
              },
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiArrowLeft))])],
            1
          ),
          _c("div", { staticClass: "text-h4 ml-5 mr-5" }, [
            _vm._v(
              " " +
                _vm._s(_vm.index + 1) +
                " / " +
                _vm._s(_vm.candidates.length) +
                " "
            ),
          ]),
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.index === _vm.candidates.length - 1 },
              on: {
                click: function ($event) {
                  _vm.index += 1
                },
              },
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiArrowRight))])],
            1
          ),
        ],
        1
      ),
      _c("v-container", [
        _c("div", { staticClass: "text-h5" }, [_vm._v(" Preview: ")]),
        _c("div", { staticClass: "preview-message" }, [
          _vm._v(" " + _vm._s(_vm.candidate.candidate.email) + " "),
        ]),
        _c("div", { staticClass: "preview-message" }, [
          _vm._v(" " + _vm._s(_vm.form.subject) + " "),
        ]),
        _c("div", {
          staticClass: "preview-message",
          domProps: { innerHTML: _vm._s(_vm.parsedMessage) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }