var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organizations.length > 0
    ? _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              color: "#1e0b4c",
                              text: "",
                              outlined: "",
                              loading: _vm.isLoading,
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(_vm._s(_vm.mdiOfficeBuilding)),
                        ]),
                        _c("v-icon", { attrs: { color: "#1e0b4c" } }, [
                          _vm._v(_vm._s(_vm.mdiMenuDown)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            3743987609
          ),
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.organizations, function (org) {
              return _c(
                "v-list-item",
                {
                  key: org.id,
                  on: {
                    click: function ($event) {
                      return _vm.organizationChanged(org)
                    },
                  },
                },
                [_c("v-list-item-title", [_vm._v(_vm._s(org.name))])],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }