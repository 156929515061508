var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-width" },
    [
      _vm._t("default"),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [_vm._v("Add Column")]),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { label: "Column Name" },
                    model: {
                      value: _vm.newColumn,
                      callback: function ($$v) {
                        _vm.newColumn = $$v
                      },
                      expression: "newColumn",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.addColumn },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-simple-table", {
        staticClass: "table-document-template",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _vm._l(_vm.columns, function (column, index) {
                        return _c(
                          "th",
                          {
                            key: index,
                            class: {
                              "small-column":
                                _vm.columnWidths[column] === "small",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between align-center",
                              },
                              [
                                _c("div", { staticClass: "text-h5" }, [
                                  _vm._v(" " + _vm._s(column) + " "),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("ResizeColumnButton", {
                                      attrs: { small: "", color: "secondary" },
                                      on: {
                                        resize: function ($event) {
                                          return _vm.resizeColumn(
                                            column,
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "ConfirmButton",
                                      {
                                        attrs: {
                                          small: "",
                                          icon: "",
                                          title: "Delete column",
                                          color: "error",
                                        },
                                        on: {
                                          "confirm-click": function ($event) {
                                            return _vm.deleteColumn(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiTrashCan) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _c(
                        "th",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                color: "secondary",
                                "data-cy": "FieldTable-addColumn",
                                title: "Add a new column",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = true
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.mdiPlus)),
                              ]),
                              _vm._v(" Add a Column "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.rows, function (row, rowIndex) {
                    return _c(
                      "tr",
                      { key: rowIndex },
                      [
                        _vm._l(_vm.columns, function (column, columnIndex) {
                          return _c(
                            "td",
                            {
                              key: columnIndex,
                              class: {
                                "small-column":
                                  _vm.columnWidths[column] === "small",
                                "medium-column":
                                  _vm.columnWidths[column] === "medium",
                                "large-column":
                                  _vm.columnWidths[column] === "large",
                              },
                            },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  dense: "",
                                  rows: "1",
                                  "auto-grow": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$emit("input", _vm.localValue)
                                  },
                                  change: function ($event) {
                                    return _vm.$emit("change", _vm.localValue)
                                  },
                                },
                                model: {
                                  value: row[column],
                                  callback: function ($$v) {
                                    _vm.$set(row, column, $$v)
                                  },
                                  expression: "row[column]",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        _c(
                          "td",
                          [
                            _c(
                              "ConfirmButton",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  small: "",
                                  color: "error",
                                  title: "Delete this row",
                                },
                                on: {
                                  "confirm-click": function ($event) {
                                    return _vm.deleteRow(rowIndex)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { staticClass: "mr-2" }, [
                                  _vm._v(_vm._s(_vm.mdiTrashCan)),
                                ]),
                                _vm._v(" Delete Row "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  }),
                  0
                ),
                _vm.columns.length > 0
                  ? _c("tfoot", [
                      _c("tr", [
                        _c("td", { attrs: { colspan: _vm.columns.length } }),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { small: "", color: "secondary" },
                                on: { click: _vm.addRow },
                              },
                              [
                                _c("v-icon", { staticClass: "mr-2" }, [
                                  _vm._v(_vm._s(_vm.mdiPlus)),
                                ]),
                                _vm._v(" Add a Row "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }