var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-btn",
        { attrs: { color: "primary" }, on: { click: _vm.generateInvite } },
        [_vm._v("Generate Invite")]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Invite Code")]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { label: "Invite URL" },
                    model: {
                      value: _vm.url,
                      callback: function ($$v) {
                        _vm.url = $$v
                      },
                      expression: "url",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }