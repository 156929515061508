var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("DataTable", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.clients,
          loading: _vm.isLoading,
          slots: [
            { name: "website" },
            { name: "email" },
            { name: "phone" },
            { name: "actions" },
            { name: "name" },
            { name: "imageUrl" },
          ],
        },
        on: { refresh: _vm.refresh },
        scopedSlots: _vm._u([
          {
            key: "new",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2",
                    attrs: {
                      color: "success",
                      "data-cy": "Client-button-new",
                      href: "https://app.hubspot.com/contacts/1883769/objects/0-2/views/all/list",
                      rel: "noopener noreferrer",
                      target: "_blank",
                    },
                  },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [
                      _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("common.buttons.new")) + " "),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "name",
            fn: function ({ item }) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "clients-EditClient",
                        query: { id: item.id },
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                ),
                item.website
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          small: "",
                          icon: "",
                          href: item.website,
                          target: "_blank",
                          rel: "noopener noreferrer",
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v(_vm._s(_vm.mdiOpenInNew)),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "imageUrl",
            fn: function ({ item }) {
              return [
                _c("v-img", {
                  attrs: { src: item.imageUrl, width: "128", contain: "" },
                }),
              ]
            },
          },
          {
            key: "email",
            fn: function ({ item }) {
              return [
                _c("a", { attrs: { href: `mailto:${item.email}` } }, [
                  _vm._v(" " + _vm._s(item.email) + " "),
                ]),
              ]
            },
          },
          {
            key: "phone",
            fn: function ({ item }) {
              return [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `tel:${item.phone}`,
                      rel: "noopener noreferrer",
                    },
                  },
                  [_vm._v(" " + _vm._s(item.phone) + " ")]
                ),
              ]
            },
          },
          {
            key: "website",
            fn: function ({ item }) {
              return [
                _c(
                  "a",
                  {
                    attrs: {
                      href: item.website,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [_vm._v(" " + _vm._s(item.website) + " ")]
                ),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item }) {
              return [
                _c("TableBasicButtons", {
                  attrs: {
                    editLink: {
                      name: "clients-EditClient",
                      query: { id: item.id },
                    },
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.remove(item)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }