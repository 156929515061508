<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-center" data-cy="FormClient-form">
      <v-row>
        <v-col cols="8">
          <v-text-field
            filled
            data-cy="FormClient-name"
            :label="$$t('name')"
            v-model="localClient.name"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            filled
            data-cy="FormClient-vatNumber"
            :label="$$t('vatNumber')"
            v-model="localClient.vatNumber"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            filled
            data-cy="FormClient-address"
            :label="$$t('address')"
            v-model="localClient.address"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            filled
            :items="industries"
            item-text="name"
            item-value="id"
            data-cy="FormClient-industry"
            :label="$$t('industry')"
            v-model="localClient.industry"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormClient-website"
            :label="$$t('website')"
            v-model="localClient.website"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            filled
            data-cy="FormClient-contact"
            :label="$$t('contact')"
            v-model="localClient.contact"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            filled
            data-cy="FormClient-email"
            :label="$$t('email')"
            v-model="localClient.email"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            filled
            data-cy="FormClient-phone"
            :label="$$t('phone')"
            v-model="localClient.phone"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormClient-linkedInUrl"
            :label="$$t('linkedInUrl')"
            v-model="localClient.linkedInUrl"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormClient-location"
            :label="$$t('location')"
            v-model="localClient.location"
          />
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            color="default"
            outlined
            class="ml-4 btn-cancel"
            @click="cancel"
            data-cy="FormClient-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            class="ml-4"
            :loading="isSaving"
            data-cy="FormClient-button-save">
            {{ $t('common.buttons.save') }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-img
            v-if="localClient.imageUrl"
            contain
            :src="localClient.imageUrl"
            :max-height="$vuetify.breakpoint.mobile ? 200 : 380"
          />
          <div class="mt-10" />
          <ImageUpload
            label="Upload the client Logo"
            hint="Drag and drop the client logo here"
            v-model="localClient.imageUrl"
            @url="localClient.imageUrl = $event"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import ImageUpload from '@/components/ImageUpload.vue';

export default {
  name: 'FormClient',
  module: 'Clients',
  components: {
    ImageUpload,
  },
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Client: {
      type: Object,
      default: undefined,
    },

    /**
     * Set the loading state. Default: false
     */
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localClient
      */
      localClient: {},
    };
  },
  computed: {
    ...mapState('industries', ['industries']),
  },
  watch: {
    Client() {
      this.localClient = this.Client;
    },
  },
  created() {
    this.localClient = this.Client;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localClient);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormClient example

'''js
<FormClient :Client="Client" />
'''
</docs>
