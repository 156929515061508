var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center", attrs: { "data-cy": "FormClient-form" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormClient-name",
                      label: _vm.$$t("name"),
                    },
                    model: {
                      value: _vm.localClient.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "name", $$v)
                      },
                      expression: "localClient.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormClient-vatNumber",
                      label: _vm.$$t("vatNumber"),
                    },
                    model: {
                      value: _vm.localClient.vatNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "vatNumber", $$v)
                      },
                      expression: "localClient.vatNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormClient-address",
                      label: _vm.$$t("address"),
                    },
                    model: {
                      value: _vm.localClient.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "address", $$v)
                      },
                      expression: "localClient.address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      filled: "",
                      items: _vm.industries,
                      "item-text": "name",
                      "item-value": "id",
                      "data-cy": "FormClient-industry",
                      label: _vm.$$t("industry"),
                    },
                    model: {
                      value: _vm.localClient.industry,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "industry", $$v)
                      },
                      expression: "localClient.industry",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormClient-website",
                      label: _vm.$$t("website"),
                    },
                    model: {
                      value: _vm.localClient.website,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "website", $$v)
                      },
                      expression: "localClient.website",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormClient-contact",
                      label: _vm.$$t("contact"),
                    },
                    model: {
                      value: _vm.localClient.contact,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "contact", $$v)
                      },
                      expression: "localClient.contact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormClient-email",
                      label: _vm.$$t("email"),
                    },
                    model: {
                      value: _vm.localClient.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "email", $$v)
                      },
                      expression: "localClient.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormClient-phone",
                      label: _vm.$$t("phone"),
                    },
                    model: {
                      value: _vm.localClient.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "phone", $$v)
                      },
                      expression: "localClient.phone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormClient-linkedInUrl",
                      label: _vm.$$t("linkedInUrl"),
                    },
                    model: {
                      value: _vm.localClient.linkedInUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "linkedInUrl", $$v)
                      },
                      expression: "localClient.linkedInUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormClient-location",
                      label: _vm.$$t("location"),
                    },
                    model: {
                      value: _vm.localClient.location,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "location", $$v)
                      },
                      expression: "localClient.location",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex justify-end", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4 btn-cancel",
                      attrs: {
                        color: "default",
                        outlined: "",
                        "data-cy": "FormClient-button-cancel",
                      },
                      on: { click: _vm.cancel },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("common.buttons.cancel")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        color: "primary",
                        type: "submit",
                        loading: _vm.isSaving,
                        "data-cy": "FormClient-button-save",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.localClient.imageUrl
                    ? _c("v-img", {
                        attrs: {
                          contain: "",
                          src: _vm.localClient.imageUrl,
                          "max-height": _vm.$vuetify.breakpoint.mobile
                            ? 200
                            : 380,
                        },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "mt-10" }),
                  _c("ImageUpload", {
                    attrs: {
                      label: "Upload the client Logo",
                      hint: "Drag and drop the client logo here",
                    },
                    on: {
                      url: function ($event) {
                        _vm.localClient.imageUrl = $event
                      },
                    },
                    model: {
                      value: _vm.localClient.imageUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.localClient, "imageUrl", $$v)
                      },
                      expression: "localClient.imageUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }