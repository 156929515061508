import dayjs from 'dayjs';
import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('notification', 'notifications');

const customActions = {
  async LOAD_notificationsForUser({ commit, rootGetters }, userId) {
    const today = dayjs().format('YYYY-MM-DD');
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('notifications')
      .query({
        // $expand: `Receipts($filter=UserId ne '${userId}')`,
        $filter: `IsDeleted eq false AND PublishDate le ${today} AND not Receipts/any(u:u/UserId eq '${userId}')`,
      });
    commit('SET_notifications', data);
    return data;
  },
  async LOAD_notifications({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('notifications')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Receipts($select=Id,UserId,Created)',
      });
    commit('SET_notifications', data);
    return data;
  },
};

export default { ...actions, ...customActions };
