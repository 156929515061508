var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", { attrs: { "primary-title": "" } }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.jobsRecurring,
              search: _vm.search,
              loading: _vm.isLoading,
              "sort-by": "nextExecution",
              "sort-desc": "",
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { cols: "8" } }),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex align-center justify-end",
                            attrs: { cols: "4" },
                          },
                          [
                            _c("v-text-field", {
                              attrs: { name: "Search", label: "Search" },
                              model: {
                                value: _vm.search,
                                callback: function ($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search",
                              },
                            }),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", icon: "" },
                                on: { click: _vm.refresh },
                              },
                              [_c("v-icon", [_vm._v(_vm._s(_vm.mdiRefresh))])],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.lastExecution",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatDateTime(item.lastExecution)) +
                        " " +
                        _vm._s(item.timeZoneId) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.nextExecution",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatDateTime(item.nextExecution)) +
                        " " +
                        _vm._s(item.timeZoneId) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { small: "", color: "default" },
                        on: {
                          click: function ($event) {
                            return _vm.run(item)
                          },
                        },
                      },
                      [_vm._v("Run Now")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }