export default {
  data() {
    return {
      userProfile: undefined,
    };
  },
  methods: {
    async loadProfile() {
      const { data } = await this.$http.get('/api/profile');
      this.userProfile = data;
    },
  },
};
