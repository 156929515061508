var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length > 0
    ? _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": true,
            "nudge-right": 40,
            transition: "scale-transition",
            "offset-y": "",
            left: "",
            "min-width": "290px",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g({ attrs: { color: "primary", dark: "" } }, on),
                      [_c("v-icon", [_vm._v(_vm._s(_vm.mdiSignLanguage))])],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            1737158742
          ),
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.items, function (response) {
              return _c(
                "v-list-item",
                {
                  key: response.message,
                  on: {
                    click: function ($event) {
                      return _vm.$emit("input", response.message)
                    },
                  },
                },
                [_c("v-list-item-title", [_vm._v(_vm._s(response.message))])],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }