import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('favourite', 'favourites');

const customActions = {
  async LOAD_favourites({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('favourites')
      .query();
    commit('SET_favourites', data);
    return data;
  },
  async DELETE_favourite({ rootGetters, dispatch }, id) {
    const token = await auth.getAccessToken();
    const i = odata.parseId(id);
    await odata.o(rootGetters, token)
      .delete(`favourites(${i})`)
      .query();

    dispatch('LOAD_favourites');
  },
};

export default { ...actions, ...customActions };
