/* eslint-disable no-param-reassign */
import { o } from 'odata';
import authHub from '@codehq/aurora-app-core/src/authHub';
import errorHub from '@codehq/aurora-app-core/src/errorHub';
import { useTrace } from '../use';

const tracer = useTrace();

const mixin = {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.from = from;
    });
  },
  data() {
    return {
      /**
       * From path for navigating when cancelled or saved. Default to Home,
       */
      from: { name: 'Home' },
      validation: {
        rules: {
          required: (value) => !!value || this.$t('common.validation.required'),
          email: [
            (v) => !!v || this.$t('common.validation.required'),
            (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || this.$t('common.validation.email'),
          ],
          min: [
            (v) => !!v || this.$t('common.validation.required'),
            (v) => (v.length <= 0 ? this.$t('common.validation.min') : true),
          ],
        },
      },
    };
  },
  computed: {
    /**
    * Get the i18n local prefix based on the module and name of the component
    */
    localePrefix() {
      return `${this.$options.module}.${this.$options.name}`;
    },
  },
  methods: {
    /**
    * Translate for module
    */
    $$t(name, obj) {
      return this.$t(`App.${this.$options.module}.${name}`, obj);
    },
    /**
    * Translate for common
    */
    $$tc(name, obj) {
      return this.$t(`common.${name}`, obj);
    },
    /**
    * Translate with pluralization for module
    */
    $$tp(name, obj) {
      return this.$tc(`App.${this.$options.module}.${name}`, obj);
    },
    /**
    * Translate with pluralization for module
    */
    $$d(name, obj) {
      return this.$d(`App.${this.$options.module}.${name}`, obj);
    },
  },
};

const log = {
  trace: (message) => {
    const span = tracer.startSpan('trace');
    span.setAttribute('message', message);
    span.end();
  },
  debug: (message) => {
    const span = tracer.startSpan('debug');
    span.setAttribute('message', message);
    span.end();
  },
  info: (message) => {
    const span = tracer.startSpan('info');
    span.setAttribute('message', message);
    span.end();
  },
  warn: (message) => {
    const span = tracer.startSpan('warn');
    span.setAttribute('message', message);
    span.setStatus('ERROR');
    span.end();
  },
  error: (message) => {
    const span = tracer.startSpan('error');
    span.setAttribute('message', message);
    span.setStatus('ERROR');
    span.end();
  },
};

export default {
  /**
  * Install the plugin
  */
  install(Vue, options) {
    const { store } = options;
    if (!store) {
      throw new Error('Vuex store option missing');
    }
    Vue.mixin(mixin);
    Vue.prototype.$log = log;
    // setup logging

    // setup odata for vuex
    store.$log = log;
    store.$o = {
      version: '0.0.1',
      store,
      client() {
        this.$log.debug('creating odata client');
        const authToken = this.store.getters['auth/getToken'];
        const organization = this.store.getters['organizations/getOrganization'];
        return o(`${process.env.VUE_APP_APIURL}/odata/`, {
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
            organizationid: `${organization?.id}`,
          }),
          onError: (x, e) => {
            if (e.status === 401) {
              authHub.$emit('user.unauthorized', e);
            } else {
              errorHub.$emit('network.error', e);
            }
          },
        });
      },
      parseId: (id) => {
        let i = id;
        if (Number.isNaN(id)) {
          i = `'${id}'`;
        }
        return i;
      },
    };
  },
};
