<template>
  <GChart
    type="BarChart"
    :data="chartData"
    :options="chartOptions"
    :resizeDebounce="500"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { GChart } from 'vue-google-charts/legacy';

export default {
  name: 'GraphJobListingHome',
  props: {
    jobListingCandidates: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    GChart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          title: 'Overview',
        },
        legend: 'none',
      },
    };
  },
  computed: {
    ...mapGetters('stages', ['orderedStages']),
    chartData() {
      const { orderedStages } = this;
      const data = [['Stage', 'Count']];
      // const amounts = [];
      orderedStages.forEach((stage) => {
        const amount = this.jobListingCandidates.filter(
          (jobListingCandidate) => jobListingCandidate.stageId === stage.id
          && (jobListingCandidate.isStop === false || jobListingCandidate.isStop === null),
        ).length;
        data.push([stage.name, amount]);
      });
      // data.push(['Count', ...amounts]);
      return data;
    },
  },
};
</script>
