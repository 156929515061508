var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.selected.length > 0
        ? _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { color: "primary" },
              on: { click: _vm.openDialog },
            },
            [_vm._v(" Mass Stop (" + _vm._s(_vm.selected.length) + ") ")]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.dialogTitle)),
              ]),
              _c(
                "v-card-text",
                { staticClass: "d-flex flex-column" },
                [
                  _c("div", { staticClass: "text-h6" }, [_vm._v("Reason:")]),
                  _c("v-select", {
                    attrs: {
                      "data-cy": "StopCandidates-reason",
                      clearable: "",
                      items: _vm.reasons,
                      "item-text": "name",
                      "item-value": "id",
                    },
                    model: {
                      value: _vm.form.reasonId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reasonId", $$v)
                      },
                      expression: "form.reasonId",
                    },
                  }),
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v("Explanation:"),
                  ]),
                  _c("v-select", {
                    attrs: {
                      "data-cy": "StopCandidates-explanation",
                      clearable: "",
                      items: _vm.explanations,
                      "item-text": "name",
                      "item-value": "id",
                    },
                    model: {
                      value: _vm.form.explanationId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "explanationId", $$v)
                      },
                      expression: "form.explanationId",
                    },
                  }),
                  _vm._v(" The following candidates will be stopped: "),
                  _c(
                    "ul",
                    _vm._l(_vm.selected, function (item) {
                      return _c("li", { key: item.id }, [
                        _vm._v(" " + _vm._s(item.candidate.name) + " "),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", loading: _vm.isSaving },
                      on: { click: _vm.updateCandidates },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }