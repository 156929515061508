<template>
  <v-container fluid>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-card class="mb-10" v-else>
      <v-tabs
        v-model="tab"
        color="primary"
        dark
        slider-color="primary"
      >
        <v-tab>
          Active
        </v-tab>
        <v-tab>
          Archived
        </v-tab>
        <v-spacer />
        <v-btn
          color="primary"
          icon
          class="mr-1 mt-1"
          @click="refresh"
        >
          <v-icon>{{ mdiRefresh }}</v-icon>
        </v-btn>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <ListFeedbackItems
            :showStaffFilter="true"
            :items="activeFeedbackItems"
            @refresh="refresh" />
        </v-tab-item>
        <v-tab-item>
          <ListFeedbackItems
            :showStaffFilter="true"
            :items="inActiveFeedbackItems"
            @refresh="refresh" />
        </v-tab-item>
      </v-tabs-items>
      <v-card-text />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  mdiRefresh,
} from '@mdi/js';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ListFeedbackItems from '../components/ListFeedbackItems.vue';

export default {
  name: 'FeedbackItems',
  components: {
    ListFeedbackItems,
    ProgressLinearTimeout,
  },
  mixins: [loading],
  module: 'common',
  data() {
    return {
      mdiRefresh,
      tab: undefined,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapGetters('feedbackItems', ['activeFeedbackItems', 'inActiveFeedbackItems']),
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('feedbackItems', ['LOAD_feedbackItems']),
    async refresh() {
      this.isLoading = true;
      await this.LOAD_feedbackItems();
      this.isLoading = false;
    },
  },
};
</script>
