var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("SourceEventLog", {
        attrs: {
          title: "Event Logs",
          "hide-search": false,
          filter: _vm.filter,
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      icon: "",
                      title: _vm.includeVerbose
                        ? "Hide Verbose"
                        : "Show Verbose",
                    },
                    on: {
                      click: function ($event) {
                        _vm.includeVerbose = !_vm.includeVerbose
                      },
                    },
                  },
                  [
                    _vm.includeVerbose
                      ? _c("v-icon", [_vm._v(_vm._s(_vm.mdiScriptTextPlay))])
                      : _c("v-icon", [
                          _vm._v(_vm._s(_vm.mdiScriptTextOutline)),
                        ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }