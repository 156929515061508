<template>
  <v-dialog
    v-model="dialog"
    :overlay="false"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        v-on="on"
      >
        Open In Teams
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Open In Teams</v-card-title>
      <v-card-text>
        <SelectTeam v-model="teamId" />
        <SelectTeamChannel
          v-if="teamId"
          v-model="channelId"
          :team-id="teamId" />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">Cancel</v-btn>
        <v-spacer />
        <v-btn @click="addTab" color="primary" target="_blank">Open</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectTeam from '@/components/SelectTeam.vue';
import SelectTeamChannel from '@/components/SelectTeamChannel.vue';
import teams from '@/mixins/teams';

export default {
  name: 'OpenInTeams',
  components: {
    SelectTeam,
    SelectTeamChannel,
  },
  mixins: [teams],
  props: {
    jobListing: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      teamId: undefined,
      channelId: undefined,
    };
  },
  computed: {
    url() {
      // You would typically fetch these values dynamically or have them configured
      const { channelId, teamId } = this;
      const label = 'Bright Future';
      const tenantId = process.env.VUE_APP_AZURE_IDENTITY_TENANT_ID;

      // Construct the deep link URL
      const teamsUrl = `https://teams.microsoft.com/l/channel/${channelId}/${encodeURIComponent(label)}?teamId=${teamId}&tenantId=${tenantId}`;

      return teamsUrl;
    },
  },
  mounted() {
    if (this.jobListing.teamId) {
      this.teamId = this.jobListing.teamId;
    }

    if (this.jobListing.teamChannelId) {
      this.channelId = this.jobListing.teamChannelId;
    }
  },
  methods: {
    async addTab() {
      try {
        this.dialog = false;
        const tabConfiguration = {
          displayName: 'Bright Future',
          'teamsApp@odata.bind': 'https://graph.microsoft.com/v1.0/appCatalogs/teamsApps/com.microsoft.teamspace.tab.web', // Bind to the web tab app
          configuration: {
            entityId: '', // You can leave this empty or use a unique identifier for your tab
            contentUrl: window.location.href,
            websiteUrl: window.location.href,
          },
        };
        await this.postGraph(
          `https://graph.microsoft.com/v1.0/teams/${this.teamId}/channels/${this.channelId}/tabs`,
          [
            'https://graph.microsoft.com/TeamsTab.ReadWrite.All',
          ],
          tabConfiguration,
        );
        this.$root.$emit('toast:notify', 'Web page tab added successfully!');
        window.open(this.url, '_blank');
      } catch (error) {
        this.$root.$emit('toast:error', 'Failed to add web page tab');
        this.$log.error(error);
      }
    },
  },
};
</script>

  <style scoped>
  /* Add any styles you want for your card */
  </style>
