var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.allNotifications, function (notification) {
      return _c(
        "v-alert",
        {
          key: notification.id,
          staticClass: "notification-message",
          attrs: {
            type: notification.notificationType,
            value: true,
            dense: "",
            dismissible: "",
          },
          on: {
            input: function ($event) {
              return _vm.remove(notification)
            },
          },
        },
        [_c("span", { domProps: { innerHTML: _vm._s(notification.message) } })]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }