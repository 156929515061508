<template>
  <v-container fluid>
    <v-tabs
      v-model="tab"
    >
      <v-tab ripple>
        <v-icon>{{ mdiCalendarTextOutline }}</v-icon>
        Events
      </v-tab>
      <v-tab ripple>
        <v-icon>{{ mdiMailbox }}</v-icon>
        <span class="mr-2">
          Inbound Email
        </span>
        <ExchangeHistory />
      </v-tab>
      <v-tab ripple>
        <v-icon>{{ mdiPhone }}</v-icon>
        Text Messages
      </v-tab>
      <v-tab ripple>
        <v-icon>{{ mdiWhatsapp }}</v-icon>
        WhatsApp Messages
      </v-tab>
      <v-tab-item>
        <SourceEventLog
          :hide-search="false"
          :filter="filter">
          >
          <template v-slot:buttons>
            <v-btn
              icon
              :title="includeVerbose ? 'Hide Verbose' : 'Show Verbose'"
              @click="includeVerbose = !includeVerbose"
            >
              <v-icon v-if="includeVerbose">{{ mdiScriptTextPlay }}</v-icon>
              <v-icon v-else>{{ mdiScriptTextOutline }}</v-icon>
            </v-btn>
          </template>
        </SourceEventLog>
      </v-tab-item>
      <v-tab-item>
        <ExchangeMails />
      </v-tab-item>
      <v-tab-item>
        <TextMessages />
      </v-tab-item>
      <v-tab-item>
        <WhatsAppMessages />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import {
  mdiMailbox,
  mdiPhone,
  mdiCalendarTextOutline,
  mdiWhatsapp,
  mdiScriptTextOutline,
  mdiScriptTextPlay,
} from '@mdi/js';
import ExchangeHistory from '@/components/ExchangeHistory.vue';
import SourceEventLog from '@/components/SourceEventLog.vue';
import ExchangeMails from '../components/ExchangeMails.vue';
import TextMessages from '../components/TextMessages.vue';
import WhatsAppMessages from '../components/WhatsAppMessages.vue';

export default {
  name: 'MessageExchange',
  components: {
    SourceEventLog,
    ExchangeHistory,
    ExchangeMails,
    TextMessages,
    WhatsAppMessages,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.messageExchange',
      },
      ],
  },
  data() {
    return {
      mdiCalendarTextOutline,
      mdiMailbox,
      mdiPhone,
      mdiWhatsapp,
      tab: 0,
      includeVerbose: false,
      mdiScriptTextOutline,
      mdiScriptTextPlay,
    };
  },
  computed: {
    filter() {
      const filter = 'Source eq \'Message\'';
      if (!this.includeVerbose) {
        return `${filter} AND EventType ne 'Verbose'`;
      }
      return filter;
    },
  },
};
</script>
