<template>
  <TableModalForm
    :headers="headers"
    :items="checklistTemplates"
    class="elevation-1"
    :loading="isLoading"
    name="Checklist Templates"
    ref="table"
    :slots="[{ name: 'actions' }, { name: 'order' }]"
    show-select
    single-select
    sort-by="order"
    @creating="creating"
    @refresh="refresh"
    @item-selected="itemSelected"
  >
    <template v-slot:form>
      <FormChecklistTemplate
        :ChecklistTemplate="editChecklistTemplate"
        :isSaving="isSaving"
        @save="save"
        @cancel="$refs.table.dialog = false" />
    </template>
    <template v-slot:actions="{ item }">
      <v-btn-toggle>
        <v-btn color="default" small @click="edit(item)">
          {{ $t('common.buttons.edit')}}
        </v-btn>
        <v-btn color="error" small @click="remove(item)">
          {{ $t('common.buttons.delete')}}
        </v-btn>
      </v-btn-toggle>
    </template>
  </TableModalForm>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiArrowUpBold, mdiArrowDownBold } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import TableModalForm from '@/components/TableModalForm.vue';
import FormChecklistTemplate from '../components/FormChecklistTemplate.vue';

export default {
  name: 'ListChecklistTemplate',
  module: 'ChecklistTemplates',
  components: {
    FormChecklistTemplate,
    TableModalForm,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiArrowUpBold,
      mdiArrowDownBold,
      /**
      * Get/Set editing ChecklistTemplate. Default to {}.
      */
      editChecklistTemplate: {},
      mode: 'add',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('checklistTemplates', ['checklistTemplates']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('checklistTemplates', [
      'LOAD_checklistTemplate',
      'CREATE_checklistTemplate',
      'UPDATE_checklistTemplate',
      'LOAD_checklistTemplates',
      'DELETE_checklistTemplate',
    ]),
    creating() {
      this.editChecklistTemplate = {};
      this.mode = 'add';
    },
    async edit(item) {
      this.editChecklistTemplate = { ...item };
      this.mode = 'edit';
      this.$refs.table.dialog = true;
    },
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_checklistTemplates();
      this.isLoading = false;
    },
    /** Delete ChecklistTemplate from the collection
     */
    async remove(checklistTemplate) {
      this.isLoading = true;
      await this.DELETE_checklistTemplate(checklistTemplate.id);
      this.isLoading = false;
    },
    /** Vuex methods
     */
    /** Method used to save the item
     */
    async save(checklistTemplate) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_checklistTemplate(checklistTemplate);
      } else {
        await this.UPDATE_checklistTemplate(checklistTemplate);
      }
      this.isSaving = false;
      this.$refs.table.dialog = false;
    },
    itemSelected(item) {
      this.$emit('selected', item);
    },
  },
};
</script>

<docs>
# ChecklistTemplate example

'''js
<ChecklistTemplate />
'''
</docs>
