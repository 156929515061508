// npm i @opentelemetry/auto-instrumentations-web @opentelemetry/context-zone @opentelemetry/exporter-trace-otlp-proto @opentelemetry/instrumentation @opentelemetry/propagator-b3 @opentelemetry/resources @opentelemetry/sdk-trace-base @opentelemetry/sdk-trace-web @opentelemetry/semantic-conventions

import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { B3Propagator } from '@opentelemetry/propagator-b3';
import { Resource } from '@opentelemetry/resources';
import { SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions';

const baseUrl = process.env.VUE_APP_OTELURL ?? 'http://localhost:4317';

const exporter = new OTLPTraceExporter({
  url: `${baseUrl}/ingest/otlp/v1/traces`,
  concurrencyLimit: 10,
});

const provider = new WebTracerProvider({
  resource: new Resource({
    [ATTR_SERVICE_NAME]: 'app',
  }),
});

provider.addSpanProcessor(new SimpleSpanProcessor(exporter));
provider.register({
  contextManager: new ZoneContextManager(),
  propagator: new B3Propagator(),
});

registerInstrumentations({
  instrumentations: [
    getWebAutoInstrumentations({
      '@opentelemetry/instrumentation-xml-http-request': {
        applyCustomAttributesOnSpan: (span, request) => {
          span.setAttribute('status', request.status);
          span.setAttribute('requestMethod', request.response.requestMethod);
        },
        clearTimingResources: true,
        enabled: false,
      },
      '@opentelemetry/instrumentation-document-load': {
        enabled: true,
      },
    }),
  ],
});
