<template>
  <div />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FavouriteService',
  mixins: [loading],
  async mounted() {
    await this.refresh();
    this.$root.$on('auth:login', this.refresh);
  },
  destroyed() {
    this.$root.$off('auth:login', this.refresh);
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('favourites', ['LOAD_favourites']),
    /**
     * Executes refresh
     */
    async refresh() {
      this.$log.debug('refreshing favourites');
      if (this.isLoggedIn) {
        this.$log.trace('loading favourites');
        await this.LOAD_favourites();
        this.$log.trace(`loaded ${this.count} favourites`);
      } else {
        this.$log.trace('skipping loading favourites for anonymous user');
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('favourites', ['count']),
  },
};
</script>
