var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "70vw" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      color: "secondary",
                      icon: "",
                      title: "Import Emails",
                    },
                  },
                  on
                ),
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiEmailSync))])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("h3", [_vm._v("Import Emails")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.emails,
                  "item-key": "id",
                  loading: _vm.isLoading,
                  search: _vm.search,
                  options: _vm.pagination,
                  "server-items-length": _vm.totalItems,
                  "hide-default-footer": _vm.search?.length > 0,
                  "footer-props": {
                    itemsPerPageOptions: [10, 50, 100, 250, 500],
                  },
                },
                on: {
                  "update:options": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-app-bar",
                          { attrs: { flat: "" } },
                          [
                            _c("v-spacer"),
                            _c("v-text-field", {
                              attrs: {
                                label: "Search",
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                                "append-icon": _vm.mdiMagnify,
                              },
                              model: {
                                value: _vm.search,
                                callback: function ($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.isRead",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-icon",
                          { attrs: { title: item.isRead ? "Read" : "Unread" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.isRead
                                    ? _vm.mdiEmail
                                    : _vm.mdiEmailOutline
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.receivedDateTime",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDateTime(item.receivedDateTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              icon: "",
                              title: "Import Mail",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.importEmail(item)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v(_vm._s(_vm.mdiEmailPlus))])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }