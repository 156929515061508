<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-icon>{{ mdiPencil }}</v-icon>
        <span class="ml-2">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mdiPencil } from '@mdi/js';

export default {
  name: 'EditPage',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiPencil,
    };
  },
};
</script>
