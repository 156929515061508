<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h1>Teams</h1>
        <v-btn class="ml-5" @click="refresh">
          <v-icon>{{ mdiRefresh }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="team in teams" :key="team.id">
              <v-list-item-content>
                <v-list-item-title>{{ team.displayName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-text>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="team in tasks" :key="team.id">
              <v-list-item-content>
                <v-list-item-title>{{ team.displayName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { InteractiveBrowserCredential } from '@azure/identity';
import { mdiRefresh } from '@mdi/js';

export default {
  data() {
    return {
      mdiRefresh,
      accessToken: null,
      graphClient: null,
      tasks: [],
      teams: [],
    };
  },
  async mounted() {
    await this.initializeGraphClient();
  },
  methods: {
    async fetchGraph(url) {
      const { accessToken } = this;
      this.$log.debug(accessToken);
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken.token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.value;
    },
    async refresh() {
      this.tasks = await this.fetchGraph('https://graph.microsoft.com/v1.0/me/todo/lists');
      this.teams = await this.fetchGraph('https://graph.microsoft.com/v1.0/me/joinedTeams');
      this.$log.debug(this.tasks);
    },
    async initializeGraphClient() {
      const credential = new InteractiveBrowserCredential({
        clientId: process.env.VUE_APP_AZURE_IDENTITY_CLIENT_ID,
        tenantId: process.env.VUE_APP_AZURE_IDENTITY_TENANT_ID,
        redirectUr: process.env.VUE_APP_APPURL,
      });
      const scope = 'https://graph.microsoft.com/Tasks.Read https://graph.microsoft.com/Team.ReadBasic.All'; // replace with your scope
      this.accessToken = await credential.getToken(scope);
    },
  },
};
</script>
