<template>
  <div>
    <v-menu
      v-model="localOpenModal"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          v-model="localDate"
          data-cy="SmallDatePicker-input"
          :label="label"
          :rules="rules"
          @input="$emit('input', localDate)"
          @click:append="localOpenModal = true"
          :filled="filled"
          :id="id"
          :dense="dense"
          v-mask="'####-##-##'"
          :append-icon="mdiCalendar"
          :background-color="backgroundColor"
          clearable
          ref="dateField"
        />
      </template>
      <v-date-picker
        v-model="localDate"
        @input="inputDate()"
      />
    </v-menu>
  </div>
</template>
<script>
import { mdiCalendar } from '@mdi/js';

export default {
  name: 'DatePicker',
  props: {
    id: {
      type: String,
      default: 'date-picker',
    },
    openModal: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'White',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Date',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localOpenModal: this.openModal,
      localDate: this.value,
      mdiCalendar,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.setLocalDate();
      },
    },
    localDate() {
      this.$emit('input', this.localDate);
    },
  },
  mounted() {
    this.setLocalDate();
  },
  methods: {
    inputDate() {
      this.localOpenModal = false;
      this.$emit('input', this.localDate);
    },
    setLocalDate() {
      if (this.value?.length > 10) {
        this.localDate = this.value.substring(0, 10);
      } else {
        this.localDate = this.value ?? '';
      }
    },
  },
};
</script>
