export default {
  data() {
    return {
      emails: undefined,
    };
  },
  methods: {
    parseMail(message, recipientCandidate) {
      const candidate = { ...recipientCandidate };
      const emailTemplate = this.userProfile?.emailTemplate ?? '';
      if (!candidate) {
        return '';
      }
      if (candidate.name?.includes(' ')) {
        // eslint-disable-next-line prefer-destructuring
        candidate.firstName = candidate.name.split(' ')[0];
        // eslint-disable-next-line prefer-destructuring
        candidate.lastName = candidate.name.split(' ')[1];
      } else {
        candidate.firstName = candidate.name;
        candidate.lastName = '';
      }
      let result = message
        .replace(/\{\{ firstName \}\}/g, candidate.firstName)
        .replace(/\{\{ lastName \}\}/g, candidate.lastName)
        .replace(/\{\{ email \}\}/g, candidate.email);

      result += `\n\n${emailTemplate}`;

      return result;
    },
  },
};
