var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        app: "",
        "data-cy": "snackbar-ToastSave",
        color: _vm.color,
        timeout: _vm.timeout,
        right: "",
        rounded: "pill",
        top: "",
        vertical: "",
        transition: "scroll-y-transition",
        text: "",
        "min-width": "0",
      },
      model: {
        value: _vm.snackbar,
        callback: function ($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar",
      },
    },
    [
      _c(
        "div",
        { staticClass: "text-h6" },
        [
          _c("v-icon", { attrs: { color: _vm.color } }, [
            _vm._v(_vm._s(_vm.mdiCheck)),
          ]),
          _vm._v(" Saved successfully "),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }