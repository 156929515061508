import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('completedChecklist', 'completedChecklists');

const customActions = {
  async LOAD_completedChecklists({ commit, rootGetters }, jobListingCandidateId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('completedChecklists')
      .query({ $filter: `IsDeleted eq false AND JobListingCandidateId eq ${jobListingCandidateId}` });
    commit('SET_completedChecklists', data);
    return data;
  },
};

export default { ...actions, ...customActions };
