<!-- eslint-disable vue/valid-v-html -->
<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="documentTemplateFields"
      class="elevation-1"
      :loading="isLoading"
    >
      <template #top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on">
                <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
                {{ $t('common.buttons.new') }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ $t('common.buttons.new') }}</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    v-model="name"
                    :rules="[v => !!v || 'Name is required']"
                    label="Name"
                    required
                  >
                    <template v-slot:prepend-inner>
                      {{ '{{' }}
                    </template>
                    <template v-slot:append-outer>
                      <span v-html="'}}'" />
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="description"
                    label="Description"
                    required
                  />
                  <v-text-field
                    v-model="hint"
                    label="Hint"
                    required
                  />
                  <v-select
                    v-model="fieldType"
                    :items="fieldTypes"
                    :rules="[v => !!v || 'Field type is required']"
                    label="Field Type"
                    required
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="closeDialog">Cancel</v-btn>
                <v-spacer />
                <v-btn :loading="isSaving" color="primary" @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.name="{ item }">
        {{ `{{${item.name}` }}<span>}}</span>
      </template>
      <template #item.product.name="{ item }">
        {{ item.product.model }}: {{ item.product.shortDescription }}
      </template>
      <template #item.fileUrl="{ item }">
        <a :href="item.fileUrl" target="_blank" rel="noopener noreferrer">{{ item.filename }}</a>
      </template>
      <template #item.actions="{ item }">
        <v-btn-toggle>
          <v-btn small @click="edit(item)">
            Edit
          </v-btn>
          <v-btn small @click="remove(item)" color="error">
            Delete
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListFields',
  module: 'Admin',
  mixins: [loading],
  props: {
    documentTemplateId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /**
       * Icons
       */
      mdiPlus,
      /**
       * Dialog
       */
      dialog: false,
      name: '',
      id: undefined,
      description: '',
      hint: '',
      fieldType: '',
      fieldTypes: ['Text', 'Table'],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('documentTemplateFields', ['documentTemplateFields']),

    /**
     * Get table column headers
     */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('description'),
          value: 'description',
        },
        {
          text: this.$$t('hint'),
          value: 'hint',
        },
        {
          text: this.$$t('fieldType'),
          value: 'fieldType',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  methods: {
    ...mapActions('documentTemplateFields', [
      'LOAD_documentTemplateFields',
      'CREATE_documentTemplateField',
      'UPDATE_documentTemplateField',
      'DELETE_documentTemplateField',
    ]),
    async edit(item) {
      this.id = item.id;
      this.name = item.name;
      this.fieldType = item.fieldType;
      this.description = item.description;
      this.hint = item.hint;
      this.dialog = true;
    },
    /** Method used to save the item
     */
    async save() {
      if (this.$refs.form.validate()) {
        const {
          name, fieldType, description, hint, id,
        } = this;
        this.isSaving = true;
        if (id) {
          const updatedField = {
            name,
            fieldType,
            description,
            hint,
            id,
          };
          await this.UPDATE_documentTemplateField(updatedField);
          this.isSaving = false;
          this.closeDialog();
          return;
        }
        const newField = {
          name,
          fieldType,
          documentTemplateId: this.documentTemplateId,
          description,
          hint,
        };
        await this.CREATE_documentTemplateField(newField);

        this.isSaving = false;
        this.closeDialog();
      }
    },

    /** Delete DocumentTemplate from the collection
     */
    async remove(documentTemplateField) {
      const res = await this.$confirm('Are you sure you want to delete this field?');
      if (!res) return;

      this.isLoading = true;
      await this.DELETE_documentTemplateField(documentTemplateField.id);
      this.isLoading = false;
    },
    closeDialog() {
      this.dialog = false;
      this.id = undefined;
      this.name = '';
      this.fieldType = '';
      this.description = '';
      this.hint = '';
      this.$refs.form.resetValidation();
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_documentTemplateFields(this.documentTemplateId);
    this.isLoading = false;
  },
};
</script>

<docs>
# DocumentTemplate example

'''js
<DocumentTemplate />
'''
</docs>
