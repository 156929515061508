import store from '../store';

export default (to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn'];
  // const org = store.getters['organizations/getOrganization'];
  // if route has requires an organization
  // if (to.meta.requiresOrg) {
  //   // if no organization is selected
  //   if (!org) {
  //     next({ name: 'app-Onboard' });
  //   } else {
  //     next(true);
  //   }
  //   // If route has a protection in metadata
  // } else

  if (to.meta.requiresAuth) {
    // If user has a token
    if (isLoggedIn) {
      // We allow to access page
      next(true);
    } else {
      // Else we redirect to login
      next('/auth/login');
    }
  } else {
    // Else if route hasn't any protection we allow to access page
    next(true);
  }
};
