<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Show Tasks ({{ jobListingTasks.length}})
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Job Listing Tasks
        </v-card-title>
        <v-card-text>
          <DisplayToDoTask
            v-for="task in jobListingTasks"
            :key="task.id"
            :task-id="task.taskId"
            :task-list-id="task.taskListId"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import validation from '@codehq/aurora-app-core/src/components/validation';
import DisplayToDoTask from './DisplayToDoTask.vue';

export default {
  name: 'DialogListTask',
  components: {
    DisplayToDoTask,
  },
  mixins: [validation],
  props: {
    jobListingId: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    dialog: false,
    name: '',
    taskListId: undefined,
    users: [],
    task: {
      title: '',
      linkedResources: [
        {
          webUrl: window.location.href,
          applicationName: 'BrightFuture',
          displayName: 'Bright Future',
        },
      ],
      assignedTo: undefined,
    },
  }),
  computed: {
    ...mapState('todo', ['tasks', 'taskLists']),
    ...mapState('jobListingTasks', ['jobListingTasks']),
    sortedUsers() {
      const { users } = this;
      return users.sort((a, b) => {
        if (a.userName < b.userName) {
          return -1;
        }
        if (a.userName > b.userName) {
          return 1;
        }
        return 0;
      });
    },
  },
  async mounted() {
    await Promise.all([
      (async () => {
        await this.LOAD_JobListingTasksByJobListingId(this.jobListingId);
      })(),
    ]);
  },
  methods: {
    ...mapActions('todo', ['getTasks', 'getTaskLists']),
    ...mapActions('jobListingTasks', ['LOAD_JobListingTasksByJobListingId']),
  },
};
</script>
