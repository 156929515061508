<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-center">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormExplanation-order"
            :label="$$t('order')"
            v-model="localExplanation.order"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormExplanation-name"
            :label="$$t('name')"
            v-model="localExplanation.name"
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-end mb-6">
        <v-btn
          color="default"
          outlined
          class="ml-4 btn-cancel"
          @click="cancel"
          data-cy="FormExplanation-button-cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          class="ml-4"
          :loading="isSaving"
          data-cy="FormExplanation-button-save">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'FormExplanation',
  module: 'Explanation',
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Explanation: {
      type: Object,
      default: undefined,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localExplanation
      */
      localExplanation: {},
    };
  },
  watch: {
    Explanation() {
      this.localExplanation = this.Explanation;
    },
  },
  created() {
    this.localExplanation = this.Explanation;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localExplanation);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormExplanation example

'''js
<FormExplanation :Explanation="Explanation" />
'''
</docs>
