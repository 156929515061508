var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-skeleton-loader",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    transition: "expand-transition",
                    type: "article",
                  },
                },
                [
                  _c("FormTwilioNumber", {
                    attrs: {
                      TwilioNumber: _vm.editTwilioNumber,
                      isSaving: _vm.isSaving,
                    },
                    on: { save: _vm.save, cancel: _vm.cancel },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }