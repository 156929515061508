export default {
  error: undefined,
  loading: false,
  token: undefined,
  refresh_token: undefined,
  token_expires_in: undefined,
  token_expires: undefined,
  username: undefined,
  userId: undefined,
  roles: [],
  routes: [],
  tables: [],
  isSaving: false,
  favouritesOnly: false,
  favouritesOnlyJobListing: false,
  jobListingTab: 0,
};
