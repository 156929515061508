<template>
  <div class="auto-grow-textarea-container">
    <textarea
      v-bind="$attrs"
      :style="{ height, width }"
      @input="handleInput"
      @change="handleChange"
      v-bind:value="value"
    />
  </div>
</template>

<script>
export default {
  name: 'AutoGrowTextArea',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      height: 'auto',
      width: 'auto',
    };
  },
  watch: {
    value() {
      this.$nextTick(this.adjustSize);
    },
  },
  methods: {
    handleInput($event) {
      this.$emit('input', $event.target.value);
      this.adjustSize();
    },
    handleChange($event) {
      this.$emit('change', $event.target.value);
      this.adjustSize();
    },
    adjustSize() {
      const textarea = this.$el.querySelector('textarea');
      if (!textarea) return;
      textarea.style.height = 'auto'; // Reset height to recalculate
      textarea.style.width = 'auto'; // Reset width to recalculate
      this.height = `${textarea.scrollHeight}px`;
      this.width = `${textarea.scrollWidth}px`;
    },
  },
  mounted() {
    this.adjustSize();
  },
};
</script>

<style scoped>
.auto-grow-textarea-container textarea {
  min-height: 50px; /* Initial minimum height */
  min-width: 100px; /* Initial minimum width */
  overflow: hidden; /* Hide scrollbar */
  resize: none; /* Disable resizing */
}
</style>
