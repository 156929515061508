<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="allNotifications"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-dialog
            v-model="dialog"
            max-width="70vw"
            transition="dialog-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="success"
                v-on="on"
                data-cy="Notification-button-new"
              >
                <v-icon class="mr-2">
                  {{ mdiPlus }}
                </v-icon>
                {{ $t('common.buttons.new') }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title primary-title>
                Notification
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="12" md="6" lg="3">
                      <v-select
                        v-model="editNotification.notificationType"
                        :items="alertIcons"
                        :label="$$t('notificationType'), "
                      />
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                      <DatePicker
                        :filled="false"
                        v-model="editNotification.publishDate"
                        :label="$$t('publishDate'), "
                      />
                    </v-col>
                  </v-row>
                  <vue-editor
                    v-model="editNotification.message"
                    :label="$$t('message')"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="dialog = false">
                  Cancel
                </v-btn>
                <v-spacer />
                <v-btn
                  text
                  color="success"
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer />
          <v-btn icon @click="refresh">
            <v-icon>{{ mdiRefresh }}</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.publishDate="{ item }">
        {{ formatDate(item.publishDate) }}
      </template>
      <template v-slot:item.message="{ item }">
        <div class="notification-message" v-html="item.message" />
      </template>
      <template v-slot:item.created="{ item }">
        {{ formatDate(item.created) }}
      </template>
      <template v-slot:item.receipts="{ item }">
        <v-menu v-if="item.receipts?.length > 0" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" small v-bind="attrs" v-on="on">
              {{ item.receipts.length }}
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-list two-line>
                <template v-for="(receipt, index) in item.receipts">
                  <v-list-item :key="index">
                    <v-list-item-content class="text-left">
                      <v-list-item-title>
                        {{ getUserName(receipt.userId) }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ formatDateTime(receipt.created) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn-toggle>
          <v-btn
            v-if="previewNotifications.includes(notifications.find((n) => n.id === item.id))"
            color="default"
            small
            @click="removePreview(item)"
          >
            Remove Preview
          </v-btn>
          <v-btn v-else color="default" small @click="preview(item)">
            {{ $t('common.buttons.preview')}}
          </v-btn>
          <v-btn color="default" small @click="edit(item)">
            {{ $t('common.buttons.edit')}}
          </v-btn>
          <v-btn color="error" small @click="remove(item)">
            {{ $t('common.buttons.delete')}}
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiPlus, mdiRefresh } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DatePicker from '../components/DatePicker.vue';

export default {
  name: 'ListNotifications',
  module: 'Notifications',
  components: {
    DatePicker,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiRefresh,
      dialog: false,
      editNotification: {
        message: '',
        publishDate: undefined,
      },
      notifications: [],
      alertIcons: [
        { text: 'Success', value: 'success' },
        { text: 'Error', value: 'error' },
        { text: 'Warning', value: 'warning' },
        { text: 'Info', value: 'info' },
      ],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('notifications', ['previewNotifications']),
    ...mapState('userManagement', ['users']),
    allNotifications() {
      return this.notifications.map((n) => ({
        ...n,
        message: n.message.length > 100 ? `${n.message.substring(0, 100)}...` : n.message,
      }));
    },
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('publishDate'),
          value: 'publishDate',
        },
        {
          text: this.$$t('message'),
          value: 'message',
        },
        {
          text: this.$$t('created'),
          value: 'created',
        },
        {
          text: this.$$t('createdBy'),
          value: 'createdBy',
        },
        {
          text: this.$$t('receipts'),
          value: 'receipts',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('userManagement', ['loadUsers']),
    ...mapActions('notifications', [
      'LOAD_notifications',
      'DELETE_notification',
      'CREATE_notification',
      'UPDATE_notification',
    ]),
    ...mapMutations('notifications', ['SET_previewNotifications']),
    edit(notification) {
      this.editNotification = this.notifications.find((n) => n.id === notification.id) || {};
      this.dialog = true;
    },
    preview(item) {
      const notification = this.notifications.find((n) => n.id === item.id);
      this.SET_previewNotifications([notification]);
    },
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          this.notifications = await this.LOAD_notifications();
        })(),
        (async () => {
          await this.loadUsers();
        })(),
      ]);
      this.isLoading = false;
    },
    removePreview(notification) {
      const { previewNotifications } = this;
      const index = previewNotifications.findIndex((n) => n.id === notification.id);
      if (index > -1) {
        previewNotifications.splice(index, 1);
      }
      this.SET_previewNotifications(previewNotifications);
    },
    /** Delete Notification from the collection
     */
    async remove(notification) {
      const res = await this.$confirm(this.$t('common.phrases.confirmDelete'));
      if (!res) return;
      this.isLoading = true;
      await this.DELETE_notification(notification.id);
      this.isLoading = false;
      await this.refresh();
    },
    async save() {
      this.isLoading = true;
      const { editNotification } = this;
      if (editNotification.id) {
        await this.UPDATE_notification({
          message: editNotification.message,
          notificationType: editNotification.notificationType,
          publishDate: editNotification.publishDate,
          id: editNotification.id,
        });
      } else {
        await this.CREATE_notification(editNotification);
      }
      this.isLoading = false;
      this.dialog = false;
      await this.refresh();
    },
    getUserName(userId) {
      const user = this.users?.find((u) => u.id === userId);
      return user ? user.username : '';
    },
  },
};
</script>

<docs>
# Notification example

'''js
<Notification />
'''
</docs>
