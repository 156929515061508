var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { loading: _vm.isLoading, flat: "" } },
    [
      _vm.jobListingCandidate
        ? _c(
            "v-card-text",
            [
              _c("SendMessage", {
                attrs: { jobListingCandidate: _vm.jobListingCandidate },
                on: {
                  comment: _vm.comment,
                  email: _vm.sendEmail,
                  text: _vm.sendText,
                  whatsApp: _vm.sendWhatsApp,
                  phoneCall: _vm.sendPhoneCall,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("v-divider"),
      !_vm.isLoading
        ? _c(
            "v-list",
            { attrs: { "two-line": "" } },
            [
              _vm._l(_vm.feedItems, function (item, index) {
                return [
                  _c(
                    "v-list-item",
                    { key: index },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          index !== 0
                            ? _c("v-divider", {
                                staticClass: "mb-5",
                                attrs: { inset: "" },
                              })
                            : _vm._e(),
                          item.attachments?.length > 0
                            ? _c(
                                "div",
                                { staticClass: "mb-3" },
                                _vm._l(
                                  item.attachments,
                                  function (attachment, index) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: index,
                                        staticClass: "mr-2 mb-2",
                                        attrs: {
                                          title:
                                            "Attachment, click to download",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadAttachment(
                                              attachment
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(attachment.name) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          item.messageType === "email"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center",
                                },
                                [
                                  _c("PreviewEmail", {
                                    attrs: {
                                      message: item,
                                      label: "Show more...",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.dialog = true
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "ml-2 d-block" }, [
                                    _vm._v("Subject: " + _vm._s(item.title)),
                                  ]),
                                ],
                                1
                              )
                            : item.messageType === "whatsApp" ||
                              item.messageType === "text"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-start align-center",
                                },
                                [
                                  _c("MessageHistory", {
                                    attrs: { message: item },
                                    on: {
                                      click: function ($event) {
                                        _vm.dialog = true
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "ml-2",
                                    domProps: {
                                      innerHTML: _vm._s(item.subtitle),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("div", {
                                domProps: { innerHTML: _vm._s(item.subtitle) },
                              }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-left d-flex justify-space-between mt-3",
                              staticStyle: { "font-size": "smaller" },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(item.formattedMessageType) + " "
                                ),
                                item.from?.length > 0
                                  ? _c(
                                      "span",
                                      [
                                        _vm._v(" from "),
                                        _c("DisplayName", {
                                          attrs: { user: item.from },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.createdBy
                                  ? _c("span", [
                                      _vm._v(" by "),
                                      _c(
                                        "strong",
                                        [
                                          _c("DisplayName", {
                                            attrs: { user: item.createdBy },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                item.messageType === "whatsApp" ||
                                item.messageType === "text"
                                  ? _c("span", { staticClass: "mr-1" }, [
                                      _vm._v(" (" + _vm._s(item.status) + ") "),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v(_vm._s(_vm.mdiClockOutline))]
                                  ),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.created),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          item.isRead || item.messageType === "comment"
                            ? _c(
                                "v-icon",
                                { attrs: { color: "grey lighten-1" } },
                                [_vm._v(" " + _vm._s(item.icon) + " ")]
                              )
                            : _c(
                                "v-icon",
                                {
                                  attrs: { color: "yellow darken-3" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.markAsRead(item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.icon) + " ")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }