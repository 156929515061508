<template>
  <v-dialog v-model="showDialog" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        data-cy="btn-add-candidate"
      >
        Add a candidate
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Select Candidate
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row no-gutters>
            <v-col cols="10">
              <v-autocomplete
                v-model="selectedUsers"
                data-cy="select-candidate"
                item-text="name"
                item-value="id"
                :items="candidates"
                multiple
                label="Select Candidates"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-spacer />
        <v-btn data-cy="add-candidate-submit" color="success" @click="submit">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddCandidate.vue',
  props: {
    /**
     * Holds the candidates value, with a default of default
     */
    candidates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDialog: false,
      selectedUsers: [],
    };
  },
  methods: {
    submit() {
      // do something with the selected users
      this.showDialog = false;
      this.$emit('selected', this.selectedUsers);
      this.selectedUsers = [];
    },
    cancel() {
      this.selectedUsers = [];
      this.showDialog = false;
    },
  },
};
</script>
