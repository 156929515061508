var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("DataTable", {
        staticClass: "elevation-1",
        attrs: {
          name: "Job Listings",
          headers: _vm.headers,
          items: _vm.items,
          loading: _vm.isLoading,
          slots: [
            { name: "actions" },
            { name: "name" },
            { name: "client.name" },
            { name: "teamChannelId" },
            { name: "created" },
            { name: "createdBy" },
            { name: "favourite" },
            { name: "status" },
            { name: "responsibleUserId" },
          ],
          "sort-by": "created",
          "sort-desc": true,
        },
        on: { refresh: _vm.refresh },
        scopedSlots: _vm._u([
          {
            key: "new",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2",
                    attrs: {
                      color: "success",
                      "data-cy": "JobListing-button-new",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "clients-EditJobListing",
                        })
                      },
                    },
                  },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [
                      _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("common.buttons.new")) + " "),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "filters",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Favourites" },
                          model: {
                            value: _vm.filterFavourites,
                            callback: function ($$v) {
                              _vm.filterFavourites = $$v
                            },
                            expression: "filterFavourites",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Archived" },
                          model: {
                            value: _vm.filterArchived,
                            callback: function ($$v) {
                              _vm.filterArchived = $$v
                            },
                            expression: "filterArchived",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.sortedResponsibleUserList,
                            label: _vm.$$t("responsibleUserId"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.filterResponsibleUserId,
                            callback: function ($$v) {
                              _vm.filterResponsibleUserId = $$v
                            },
                            expression: "filterResponsibleUserId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "favourite",
            fn: function ({ item }) {
              return [
                _c("Favourite", {
                  attrs: {
                    name: "JobListing",
                    identifier: `${_vm.userId}-${item.id}`,
                  },
                }),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleArchive(item)
                      },
                    },
                  },
                  [
                    item.isActive
                      ? _c("v-icon", [_vm._v(_vm._s(_vm.mdiArchive))])
                      : _c("v-icon", [_vm._v(_vm._s(_vm.mdiArchiveCancel))]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "created",
            fn: function ({ item }) {
              return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")]
            },
          },
          {
            key: "teamChannelId",
            fn: function ({ item }) {
              return [
                _c("TeamLink", {
                  attrs: {
                    teamChannelId: item.teamChannelId,
                    teamId: item.teamId,
                  },
                }),
              ]
            },
          },
          {
            key: "createdBy",
            fn: function ({ item }) {
              return [_c("DisplayName", { attrs: { user: item.createdBy } })]
            },
          },
          {
            key: "responsibleUserId",
            fn: function ({ item }) {
              return [
                _c("DisplayName", { attrs: { user: item.responsibleUserId } }),
              ]
            },
          },
          {
            key: "status",
            fn: function ({ item }) {
              return [
                _c(
                  "v-edit-dialog",
                  {
                    attrs: {
                      "return-value": item.status,
                      large: "",
                      persistent: "",
                    },
                    on: {
                      "update:returnValue": function ($event) {
                        return _vm.$set(item, "status", $event)
                      },
                      "update:return-value": function ($event) {
                        return _vm.$set(item, "status", $event)
                      },
                      save: function ($event) {
                        return _vm.updateStatus(item)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.jobListingStatuses,
                                  "item-text": "name",
                                  "item-value": "name",
                                  required: "",
                                  "data-cy": "ListJobListing-status",
                                  label: _vm.$$t("status"),
                                },
                                model: {
                                  value: item.status,
                                  callback: function ($$v) {
                                    _vm.$set(item, "status", $$v)
                                  },
                                  expression: "item.status",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_vm._v(" " + _vm._s(item.status) + " ")]
                ),
              ]
            },
          },
          {
            key: "name",
            fn: function ({ item, index }) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      "data-cy": `JobListing-table-name-${index}`,
                      to: {
                        name: "clients-ViewJobListing",
                        query: {
                          jobListingId: item.id,
                        },
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                ),
              ]
            },
          },
          {
            key: "client.name",
            fn: function ({ item, index }) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      "data-cy": `JobListing-table-client-name-${index}`,
                      to: {
                        name: "clients-EditClient",
                        query: {
                          id: item.client?.id,
                        },
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.client?.name) + " ")]
                ),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item }) {
              return [
                _c("TableBasicButtons", {
                  attrs: {
                    editLink: {
                      name: "clients-EditJobListing",
                      query: { id: item.id },
                    },
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.remove(item)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }