import axios from 'axios';
import { o } from 'odata';
import { useAuth } from './use';

const auth = useAuth();

/**
 * @class
 * @classdesc A client class that can make requests to a server
 */
export default class MicrosoftClient {
  /**
   * @constructor
   * @param {Object} options - The configuration options for the client
   * @param {String} options.apiClient - The API client to use for requests
   * @param {String} options.baseUrl - The base URL for requests
   * @param {Object} headers - Headers to send with request
   * @param {String} options.clientId - The client ID for authentication
   * @param {String} options.secret - The secret for authentication
   * @param {Number} options.timeout - The timeout for requests
   */
  constructor({
    apiClient, baseUrl, headers, timeout, onError, onStart,
  }) {
    this.apiClient = apiClient;

    this.$http = axios.create({
      baseURL: baseUrl,
      timeout,
    });
    this.$http.defaults.headers.common = headers;
    this.headers = headers;
    this.onStart = onStart;
    this.onError = onError;
    this.auth = auth;
  }

  getToken() {
    return this.auth.getAccessToken();
  }

  /**
   * Makes a GET request to the specified URL
   * @param {String} url - The URL for the request
   * @returns {Promise} A promise resolving to the response from the server
   */
  async get(url) {
    const bearerToken = await this.getToken();
    if (this.apiClient === 'o') {
      return o({
        headers: new Headers({
          ...this.headers,
          ...{ Authorization: `Bearer ${bearerToken}` },
        }),
        onError: this.onError,
        onStart: this.onStart,
      }).get(url);
    } if (this.apiClient === 'axios') {
      return this.$http.get(url, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
    }
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    });
  }

  /**
   * Makes a POST request to the specified URL
   * @param {String} url - The URL for the request
   * @param {Object} body - The body of the request
   * @returns {Promise} A promise resolving to the response from the server
   */
  async post(url, body) {
    const bearerToken = await this.getToken();
    if (this.apiClient === 'o') {
      return o({
        url,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        body,
      });
    } if (this.apiClient === 'axios') {
      return this.$http.post(url, body, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
    }
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      body,
    });
  }

  /**
   * Makes a PUT request to the specified URL
   * @param {String} url - The URL for the request
   * @param {Object} body - The body of the request
   * @returns {Promise} A promise resolving to the response from the server
   */
  async put(url, body) {
    const bearerToken = await this.getToken();
    if (this.apiClient === 'o') {
      return o({
        url,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        body,
      });
    } if (this.apiClient === 'axios') {
      return this.$http.put(url, body, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
    }
    return fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      body,
    });
  }

  /**
   * Makes a PATCH request to the specified URL
   * @param {String} url - The URL for the request
   * @param {Object} body - The body of the request
   * @returns {Promise} A promise resolving to the response from the server
   */
  async patch(url, body) {
    const bearerToken = await this.getToken();
    if (this.apiClient === 'o') {
      return o({
        url,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        body,
      });
    } if (this.apiClient === 'axios') {
      return this.$http.patch(url, body, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
    }
    return fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      body,
    });
  }

  /**
   * Makes a DELETE request to the specified URL
   * @param {String} url - The URL for the request
   * @returns {Promise} A promise resolving to the response from the server
   */
  async delete(url) {
    const bearerToken = await this.getToken();
    if (this.apiClient === 'o') {
      return o({
        url,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
      });
    } if (this.apiClient === 'axios') {
      return this.$http.delete(url, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
    }
    return fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    });
  }
}
