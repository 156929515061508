<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormJobListing
      :JobListing="editJobListing"
      :isSaving="isSaving"
      :addToFavourites.sync="addToFavourites"
      :mode="mode"
      @save="save"
      @cancel="$router.push({ name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormJobListing from '../components/FormJobListing.vue';

export default {
  components: {
    FormJobListing,
  },
  name: 'EditJobListing',
  module: 'JobListings',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing JobListing. Default to {}.
      */
      editJobListing: {},
      /**
       * Query Id for JobListing
       */
      jobListingId: this.$route?.query.id,
      addToFavourites: true,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('auth', ['userId']),
    ...mapState('jobListings', ['jobListings', 'jobListing']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.jobListingId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_clients();
      })(),
      (async () => {
        await this.LOAD_industries();
      })(),
    ]);
    if (this.mode === 'edit') {
      await this.LOAD_jobListing(this.jobListingId);
      this.editJobListing = this.jobListing;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('jobListings', ['LOAD_jobListing', 'CREATE_jobListing', 'UPDATE_jobListing']),
    ...mapActions('clients', ['LOAD_clients']),
    ...mapActions('favourites', ['CREATE_favourite']),
    ...mapActions('industries', ['LOAD_industries']),
    /** Method used to save the item
     */
    async save(jobListing) {
      this.isSaving = true;
      if (this.mode === 'add') {
        const newJobListing = await this.CREATE_jobListing(jobListing);
        if (this.addToFavourites) {
          this.$log.info('adding to favourites');
          const { userId } = this;
          const identifier = `${userId}-${newJobListing.id}`;
          await this.CREATE_favourite({
            name: 'JobListing',
            identifier,
          });
        }
      } else {
        await this.UPDATE_jobListing(jobListing);
      }
      this.isSaving = false;
      this.$router.push({ name: 'clients-ListJobListing' });
    },
  },
};
</script>

<docs>
# EditJobListing example

'''js
<EditJobListing :JobListing="JobListing" />
'''
</docs>
