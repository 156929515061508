var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.selected.length > 0
        ? _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { color: "primary" },
              on: { click: _vm.openDialog },
            },
            [_vm._v(" Mass Update (" + _vm._s(_vm.selected.length) + ") ")]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.dialogTitle)),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          "data-cy": "UpdateJoblistingCandidates-stage",
                          items: _vm.orderedStages,
                          disabled: _vm.orderedStages.length === 0,
                          "item-text": "name",
                          "item-value": "id",
                        },
                        model: {
                          value: _vm.form.stageId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "stageId", $$v)
                          },
                          expression: "form.stageId",
                        },
                      }),
                      _c("v-select", {
                        attrs: {
                          "data-cy": "UpdateJoblistingCandidates-task",
                          items: _vm.getTasksByStageId(_vm.form.stageId),
                          "item-text": "name",
                          "item-value": "id",
                        },
                        model: {
                          value: _vm.form.taskId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "taskId", $$v)
                          },
                          expression: "form.taskId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.isSaving,
                        disabled: !_vm.valid,
                      },
                      on: { click: _vm.updateTasks },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }