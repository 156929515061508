var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-btn", { attrs: { outlined: "" }, on: { click: _vm.openModal } }, [
        _vm._v("Move Candidate to another Project"),
      ]),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.modalOpen,
            callback: function ($$v) {
              _vm.modalOpen = $$v
            },
            expression: "modalOpen",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Move Candidate"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.jobListings,
                      "item-text": "name",
                      "item-value": "id",
                      label: "Select Job Listing",
                      outlined: "",
                    },
                    model: {
                      value: _vm.selectedProject,
                      callback: function ($$v) {
                        _vm.selectedProject = $$v
                      },
                      expression: "selectedProject",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.isSaving,
                        disabled: !_vm.selectedProject,
                      },
                      on: { click: _vm.moveCandidate },
                    },
                    [_vm._v("Move")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }