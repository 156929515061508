<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="documentTemplates"
      class="elevation-1"
      :loading="isLoading"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="DocumentTemplate-button-new"
            @click="$router.push({
              name: 'clients-EditDocumentTemplate',
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.product.name="{ item }">
        {{ item.product.model }}: {{ item.product.shortDescription }}
      </template>
      <template #item.fileUrl="{ item }">
        <a :href="item.fileUrl" target="_blank" rel="noopener noreferrer">
          {{ item.filename }}
        </a>
      </template>
      <template #item.actions="{ item }">
        <TableBasicButtons
          :edit-link="{
            name: 'clients-EditDocumentTemplate',
            query: { id: item.id },
          }"
          @delete="remove(item)"
        >
          <template v-slot:append>
            <v-btn color="primary" small @click="duplicate(item)">
              Duplicate
            </v-btn>
          </template>
        </TableBasicButtons>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListDocumentTemplate',
  module: 'Admin',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('documentTemplates', ['documentTemplates']),

    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('fileUrl'),
          value: 'fileUrl',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_documentTemplates();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('documentTemplates', ['LOAD_documentTemplates', 'DELETE_documentTemplate', 'DUPLICATE_documentTemplate']),

    /** Delete DocumentTemplate from the collection
     */
    async remove(documentTemplate) {
      this.isLoading = true;
      await this.DELETE_documentTemplate(documentTemplate.id);
      this.isLoading = false;
    },

    async duplicate(documentTemplate) {
      this.isLoading = true;
      await this.DUPLICATE_documentTemplate(documentTemplate);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# DocumentTemplate example

'''js
<DocumentTemplate />
'''
</docs>
