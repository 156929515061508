var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", "offset-sm2": "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("Profile"),
                        ]),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "black--text" },
                        [
                          _vm.userProfile
                            ? _c(
                                "v-form",
                                [
                                  _c("v-text-field", {
                                    staticClass: "black-disabled-input",
                                    attrs: { label: "Email", disabled: "" },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "black-disabled-input",
                                    attrs: { label: "User ID", disabled: "" },
                                    model: {
                                      value: _vm.userId,
                                      callback: function ($$v) {
                                        _vm.userId = $$v
                                      },
                                      expression: "userId",
                                    },
                                  }),
                                  _c("ClipboardTextField", {
                                    staticClass: "black-disabled-input",
                                    attrs: { label: "API Key", disabled: "" },
                                    model: {
                                      value: _vm.apiKey,
                                      callback: function ($$v) {
                                        _vm.apiKey = $$v
                                      },
                                      expression: "apiKey",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "black-disabled-input",
                                    attrs: {
                                      label: "Display Name",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.userProfile.displayName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userProfile,
                                          "displayName",
                                          $$v
                                        )
                                      },
                                      expression: "userProfile.displayName",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-h6 d-flex align-center justify-space-between mb-5",
                                    },
                                    [
                                      _c("span", [_vm._v(" Email Signature ")]),
                                      _c("PreviewEmail", {
                                        attrs: {
                                          label: "Preview",
                                          title:
                                            "Preview an example email message",
                                          message: _vm.emailMessagePreview,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("vue-editor", {
                                    attrs: { label: "Email Template" },
                                    model: {
                                      value: _vm.userProfile.emailTemplate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userProfile,
                                          "emailTemplate",
                                          $$v
                                        )
                                      },
                                      expression: "userProfile.emailTemplate",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-5",
                                          attrs: {
                                            color: "error",
                                            loading: _vm.isSaving,
                                          },
                                          on: { click: _vm.resetSignature },
                                        },
                                        [_vm._v(" Reset Signature ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-5",
                                          attrs: {
                                            color: "success",
                                            loading: _vm.isSaving,
                                          },
                                          on: { click: _vm.saveProfile },
                                        },
                                        [_vm._v(" Save ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }