<template>
  <v-card class="mx-auto" :loading="isLoading" flat>
    <v-expansion-panels v-if="!isLoading">
      <v-expansion-panel
        v-for="(jobListing, index) in candidateCommentsGroupedByJobListing"
        :key="index">
        <v-expansion-panel-header>
          <router-link
            :data-cy="`JobListing-link-${index}`"
            :to="{
              name: 'clients-ViewJobListing',
              query: {
                jobListingId: jobListing.id,
              },
            }"
          >
            {{ jobListing.name }} ({{ jobListing.stage }})
          </router-link>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list two-line>
            <template v-for="(item, index) in jobListing.comments">
              <v-list-item :key="index">
                <v-list-item-content class="text-left">
                  {{ item.subtitle }}
                  <div class="text-left d-block mt-3" style="font-size: smaller;">
                    <span v-html="item.createdBy" />
                    <v-icon small>{{ mdiClockOutline }}</v-icon>
                    <span v-html="item.created" />
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    v-if="item.isRead || item.messageType === 'comment'"
                    color="grey lighten-1"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <v-icon
                    v-else
                    color="yellow darken-3"
                    @click="markAsRead(item)"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <v-list-item-action-text />
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  mdiComment,
  mdiEmail,
  mdiMessageProcessing,
  mdiWhatsapp,
  mdiPhone,
  mdiClockOutline,
} from '@mdi/js';
import axios from 'axios';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import teams from '../../../mixins/teams';

dayjs.extend(relativeTime);

export default {
  name: 'JobListingCandidateFeed',
  mixins: [loading, teams],
  props: {
    candidateId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      mdiClockOutline,
      mdiMessageProcessing,
      mdiWhatsapp,
      mdiPhone,
      mdiComment,
      mdiEmail,
    };
  },
  computed: {
    ...mapGetters('jobListingCandidateComments', ['candidateCommentsGroupedByJobListing']),
    ...mapState('jobListingCandidateComments', ['jobListingCandidateComments']),
    ...mapState('messages', ['messages']),
    feedItems() {
      const messageItems = this.messages.map((message) => ({
        icon: this.getIcon(message.messageType),
        created: `${this.formatRelative(message.created)}`,
        createdDate: message.created,
        createdBy: `${this.formatMessageType(message.messageType)} by <strong>${message.createdBy}</strong>`,
        title: message.subject,
        subtitle: message.body,
        id: message.id,
        isRead: message.isRead,
        messageType: message.messageType,
      }));
      return [...messageItems].sort((a, b) => {
        if (a.createdDate > b.createdDate) {
          return -1;
        }
        if (a.createdDate < b.createdDate) {
          return 1;
        }
        return 0;
      });
    },
  },
  watch: {
    jobListingCandidate() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('jobListingCandidateComments', [
      'LOAD_jobListingCandidateCommentsByCandidate',
      'CREATE_jobListingCandidateComment',
    ]),
    ...mapActions('messages', [
      'LOAD_candidateMessages',
      'UPDATE_message',
    ]),
    ...mapMutations('jobListingCandidateComments', [
      'SET_jobListingCandidateComments',
    ]),
    ...mapMutations('messages', [
      'SET_messages',
    ]),
    formatRelative(dt) {
      return dayjs(dt).fromNow();
    },
    formatMessageType(messageType) {
      switch (messageType) {
        case 'email':
          return 'Email';
        case 'text':
          return 'Text';
        case 'whatsApp':
          return 'WhatsApp';
        case 'phoneCall':
          return 'Phone Call';
        default:
          return 'Message';
      }
    },
    getIcon(messageType) {
      switch (messageType) {
        case 'email':
          return mdiEmail;
        case 'text':
          return mdiMessageProcessing;
        case 'whatsApp':
          return mdiWhatsapp;
        case 'phoneCall':
          return mdiPhone;
        default:
          return mdiEmail;
      }
    },
    async addComment() {
      this.isSaving = true;
      await this.CREATE_jobListingCandidateComment({
        jobListingCandidateId: this.jobListingCandidate.id,
        message: this.message,
      });
      this.message = '';
      this.isSaving = false;
      this.$emit('refresh');
    },
    async markAsRead(item) {
      this.$log.debug(item);
      await this.UPDATE_message({
        id: item.id,
        isRead: true,
      });
      await this.refresh();
      this.$emit('refresh');
    },
    async refresh() {
      if (this.candidateId) {
        this.isLoading = true;
        await this.LOAD_jobListingCandidateCommentsByCandidate(this.candidateId);
        await this.LOAD_candidateMessages(this.candidateId);
        this.isLoading = false;
      }
    },
    async comment(form) {
      await this.CREATE_jobListingCandidateComment({
        jobListingCandidateId: this.jobListingCandidate.id,
        message: form.message,
      });
      await this.refresh();
      this.$emit('refresh');
    },
    async sendMessage(message) {
      await axios.post('api/messages/send', message);
      this.$root.$emit('toast:notify', 'Message sent successfully');
      await this.refresh();
    },
    async sendEmail(form) {
      await this.sendMessage({
        jobListingCandidateId: this.jobListingCandidate.id,
        recipient: form.email,
        subject: form.subject,
        body: form.message,
        messageType: 'email',
      });
      if (!form.saveOnly) {
        try {
          const token = await this.getToken('Mail.Send');
          const endpoint = 'https://graph.microsoft.com/v1.0/me/sendMail';
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const data = {
            message: {
              subject: form.subject,
              body: {
                contentType: 'HTML',
                content: form.message,
              },
              toRecipients: [
                {
                  emailAddress: {
                    address: form.email,
                  },
                },
              ],
            },
          };
          const response = await axios.post(endpoint, data, config);
          this.$log.debug(response);
          this.$root.$emit('toast:notify', 'Email sent successfully');
        } catch (error) {
          this.$log.error(error);
          this.$root.$emit('toast:error', 'Unable to send email');
        }
      }
    },
    async sendText(form) {
      await this.sendMessage({
        jobListingCandidateId: this.jobListingCandidate.id,
        recipient: form.phone,
        subject: form.subject,
        body: form.message,
        messageType: 'text',
      });
    },
    async sendWhatsApp(form) {
      await this.sendMessage({
        jobListingCandidateId: this.jobListingCandidate.id,
        recipient: form.phone,
        subject: form.subject,
        body: form.message,
        messageType: 'whatsApp',
      });
    },
    async sendPhoneCall(form) {
      await this.sendMessage({
        jobListingCandidateId: this.jobListingCandidate.id,
        recipient: form.phone,
        subject: form.subject,
        body: form.message,
        messageType: 'phoneCall',
      });
    },
  },
};
</script>
