<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h1>LinkedIn</h1>
        <v-btn v-if="!profile" class="ml-5" :href="url">
          <v-icon>{{ mdiLinkedin }}</v-icon>
        </v-btn>
        <v-btn class="ml-5" @click="refresh">
          <v-icon>{{ mdiRefresh }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="profile">
        <p>ID: {{ profile.id }}</p>
        <p>First Name: {{ profile.firstName.localized.en_US }}</p>
        <p>Last Name: {{ profile.lastName.localized.en_US }}</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { AuthClient } from 'linkedin-api-client';
import { mdiRefresh, mdiLinkedin } from '@mdi/js';
import axios from 'axios';

export default {
  data() {
    return {
      mdiRefresh,
      mdiLinkedin,
      url: undefined,
      restliClient: new AuthClient({
        clientId: process.env.VUE_APP_LINKEDIN_CLIENT_ID,
        secret: process.env.VUE_APP_LINKEDIN_CLIENT_SECRET,
        redirectUrl: `${process.env.VUE_APP_APPURL}/linked-in`,
      }),
      profile: undefined,
    };
  },
  async mounted() {
    await this.initUrl();
    this.$log.debug(this.$route.query.code);
    if (this.$route.query.code) {
      await this.initCode();
    }
    await this.refresh();
  },
  methods: {
    async initCode() {
      const { code } = this.$route.query;
      this.$log.debug(code);

      const response = await axios.post('/api/linked-in/OAuth2', {
        code,
        redirectUrl: `${process.env.VUE_APP_APPURL}/linked-in`,
      });
      // const response = await this.restliClient.exchangeAuthCodeForAccessToken(code);
      this.$log.debug(response);
    },
    async loadMessages() {
      const url = 'GET https://api.linkedin.com/v2/atsPurgedExportedCandidates?timeRange.start=1589747047000&timeRange.end=1592425447000&q=criteria';
      const response = await axios.get(`/api/linked-in/Proxy?url=${url}`);
      this.$log.debug(response);
      if (response.status === 200) {
        this.profile = response.data;
      }
    },
    async loadProfile() {
      const response = await axios.get('/api/linked-in/Proxy?url=https://api.linkedin.com/v2/me');
      this.$log.debug(response);
      if (response.status === 200) {
        this.profile = response.data;
      }
    },
    async initUrl() {
      this.url = this.restliClient.generateMemberAuthorizationUrl(
        ['r_liteprofile', 'r_emailaddress'],
        '123456789',
      );

      // const result = await restliClient.get({
      //   resourcePath: '/me',
      //   accessToken: this.token,
      // });
      // console.debug(result);
    },
    async refresh() {
      await this.loadProfile();
      await this.loadMessages();
    },
  },
};
</script>
