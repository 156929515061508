<template>
  <div>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-timeline
      v-else
      align-top
      dense
    >
      <v-timeline-item
        v-for="completedTask in completedTasks"
        :key="completedTask.id"
        small
      >
        <div class="font-weight-normal">
          <strong class="d-block">
            {{ completedTask.createdBy }}
          </strong>
          <div class="d-block">
            <v-icon x-small>{{ mdiClock }}</v-icon>
            <span class="caption">
              {{ formatDateTime(completedTask.created) }}
            </span>
          </div>
        </div>
        <div>{{ completedTask.description }}</div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiClock } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default {
  name: 'JobListingCandidateHistory',
  components: {
    ProgressLinearTimeout,
  },
  mixins: [loading],
  props: {
    jobListingCandidate: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      mdiClock,
      completedTasks: [],
    };
  },
  watch: {
    jobListingCandidate() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('completedTasks', [
      'LOAD_completedTasksByCandidate',
    ]),
    formatRelative(dt) {
      return dayjs(dt).fromNow();
    },
    async refresh() {
      if (this.jobListingCandidate) {
        this.isLoading = true;
        this.completedTasks = await this
          .LOAD_completedTasksByCandidate(this.jobListingCandidate.candidateId);
        this.isLoading = false;
      }
    },
  },
};
</script>
