var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-dialog", {
    attrs: { transition: "dialog-bottom-transition", "max-width": "600" },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on, attrs }) {
          return [
            _c(
              "v-btn",
              _vm._g(
                _vm._b(
                  { attrs: { color: "#1e0b4c" }, on: { click: _vm.open } },
                  "v-btn",
                  attrs,
                  false
                ),
                on
              ),
              [_c("v-icon", [_vm._v(_vm._s(_vm.mdiEmoticonHappyOutline))])],
              1
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "v-card",
              [
                _c("v-toolbar", { attrs: { color: "primary", dark: "" } }, [
                  _vm._v("Did you find this useful or not?"),
                ]),
                _c(
                  "v-card-text",
                  [
                    _c("v-textarea", {
                      attrs: {
                        label: "Let us know what you think",
                        textarea: "",
                      },
                      model: {
                        value: _vm.message,
                        callback: function ($$v) {
                          _vm.message = $$v
                        },
                        expression: "message",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "success", loading: _vm.isLoading },
                        on: { click: _vm.send },
                      },
                      [_vm._v("Send")]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _vm.screenshot
                      ? _c("v-img", { attrs: { src: _vm.screenshot } })
                      : _c(
                          "v-btn",
                          {
                            attrs: { loading: _vm.isLoadingScreenshot },
                            on: { click: _vm.saveScreenShot },
                          },
                          [_vm._v(" Add Screenshot ")]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v
      },
      expression: "dialog",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }