var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading ? _c("ProgressLinearTimeout") : _vm._e(),
      _c("v-file-input", {
        attrs: {
          label: _vm.label,
          loading: _vm.isLoading,
          accept: _vm.accept,
          "show-size": "",
          "error-messages": _vm.errors,
        },
        on: { change: _vm.fileChanged },
        model: {
          value: _vm.file,
          callback: function ($$v) {
            _vm.file = $$v
          },
          expression: "file",
        },
      }),
      _c(
        "v-card",
        {
          class: { "grey lighten-2": _vm.dragover },
          attrs: { "data-cy": "File-Upload" },
          on: {
            drop: function ($event) {
              $event.preventDefault()
              return _vm.onDrop($event)
            },
            dragover: function ($event) {
              $event.preventDefault()
              _vm.dragover = true
            },
            dragenter: function ($event) {
              $event.preventDefault()
              _vm.dragover = true
            },
            dragleave: function ($event) {
              $event.preventDefault()
              _vm.dragover = false
            },
          },
        },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex flex-column",
                  attrs: { dense: "", align: "center", justify: "center" },
                },
                [
                  _vm.isLoading
                    ? _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _c(
                        "v-icon",
                        {
                          class: [_vm.dragover ? "mt-2, mb-6" : "mt-5"],
                          attrs: { size: "60" },
                        },
                        [_vm._v(" " + _vm._s(_vm.mdiCloudUpload) + " ")]
                      ),
                  _c("p", [_vm._v(" " + _vm._s(_vm.hint) + " ")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }