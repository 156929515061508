var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("ProgressCircularTimeout")
        : _c("div", [
            _vm.channel && _vm.team
              ? _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.teamsUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.team.displayName) +
                        " / " +
                        _vm._s(_vm.channel.displayName) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }