var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "red lighten-2", dark: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Create Task ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Create a Task ")]),
              _c(
                "v-card-text",
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.taskLists,
                      label: "Task List",
                      required: "",
                      "item-text": "displayName",
                      "item-value": "id",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.taskListId,
                      callback: function ($$v) {
                        _vm.taskListId = $$v
                      },
                      expression: "taskListId",
                    },
                  }),
                  _c("v-autocomplete", {
                    attrs: {
                      label: "Assigned To",
                      items: _vm.sortedUsers,
                      "item-text": "userName",
                      "item-value": "userName",
                    },
                    model: {
                      value: _vm.task.assignedTo,
                      callback: function ($$v) {
                        _vm.$set(_vm.task, "assignedTo", $$v)
                      },
                      expression: "task.assignedTo",
                    },
                  }),
                  _c("DatePicker", {
                    attrs: { label: "Due Date", filled: false },
                    model: {
                      value: _vm.dueDateTime,
                      callback: function ($$v) {
                        _vm.dueDateTime = $$v
                      },
                      expression: "dueDateTime",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: { label: "Title", rules: [_vm.rules.required] },
                    model: {
                      value: _vm.task.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.task, "title", $$v)
                      },
                      expression: "task.title",
                    },
                  }),
                  _c("v-textarea", {
                    attrs: {
                      label: "Description",
                      "auto-grow": "",
                      rows: "5",
                      required: "",
                    },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "success" }, on: { click: _vm.create } },
                    [_vm._v(" Create ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }