<template>
  <v-container fill-height>
    <v-row class="background justify-center align-center mt-6" no-gutters>
      <v-col cols="12" lg="6" xl="6">
        <v-card flat v-if="isLoggingIn">
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" class="text-center">
                <div v-if="account" class="text-h4 d-block">
                  Welcome {{ account.name }}, we're just
                </div>
                <div class="text-h5 text-h4 d-block">{{ loginText }}...</div>
              </v-col>
              <v-col cols="12" class="text-center">
                <ProgressLinearTimeout />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-else flat class="ma-3 pa-4">
          <v-container>
            <v-row class="mt-3" justify="center" no-gutters>
              <v-col cols="12">
                <v-card flat>
                  <v-img class="ml-auto mr-auto" src="../assets/numaH_Logo_New.png" width="229" />
                  <v-card-text>
                    <v-btn block color="success" @click="login" :loading="isLoading">
                      <v-icon class="mr-2">{{ mdiMicrosoftOffice }}</v-icon>
                      Microsoft Login
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiOpenInNew, mdiMicrosoftOffice } from '@mdi/js';
import { mapActions, mapMutations } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import { useAuth, useTrace } from '../use';

const auth = useAuth();

export default {
  name: 'login',
  mixins: [loading],
  components: {
    ProgressLinearTimeout,
  },
  data() {
    return {
      mdiOpenInNew,
      mdiMicrosoftOffice,
      isLoggingIn: false,
      loginText: 'Logging you in',
      account: undefined,
    };
  },
  async mounted() {
    const tracer = useTrace();
    const span = tracer.startSpan('login');
    span.addEvent('Retrieving account');
    const account = auth.userAgentApplication.getAccount();
    this.account = account;
    if (account) {
      span.addEvent('Logging in');
      span.setAttribute('account', account.username);
      span.setAttribute('tenantId', account.tenantId);
      this.isLoggingIn = true;
      span.setAttribute('loggedIn', true);
      // register
      span.addEvent('logged in, registering user');
      this.loginText = 'Setting up user';
      const registerSpan = tracer.startSpan('register');
      try {
        registerSpan.spanContext().traceId = span?.spanContext()?.traceId;
        await this.$http.post('/api/external-account/register');
        registerSpan.setAttribute('registered', true);
        registerSpan.addEvent('logged in, registered user');
      } catch (error) {
        this.$root.$emit('toast:error', 'Login failed');
        registerSpan.addEvent('logged in, failed to register user');
        registerSpan.setAttribute('registered', false);
        registerSpan.setStatus('ERROR');
        this.SET_isLoggedIn(false);
        this.SET_username('');
        this.SET_userId('');
        this.clearAllCookiesAndSessionStorage();
        auth.logout();
        registerSpan.addEvent('logging out user');
        auth.userAgentApplication?.logout();
        registerSpan.addEvent('redirecting to login');
        this.$router.push('/');
        this.isLoggingIn = false;
        registerSpan.end();
        span.end();
        return;
      }

      this.loginText = 'Loading user info';
      // login
      span.addEvent('logged in, loading user info');
      const { data } = await this.$http.get('/api/org');

      this.loginText = 'Loading organizations';
      span.addEvent('logged in, loading organizations');
      // load orgs
      await this.LOAD_organizations();

      this.SET_isLoggedIn(true);
      span.setAttribute('orgs', data.organizations?.length);
      const roles = data.userRoles?.join(';');
      span.setAttribute('roles', roles);
      this.SET_username(data.user);
      span.setAttribute('username', data.user);
      this.SET_userId(data.userId);
      span.setAttribute('userId', data.userId);
      this.SET_roles(roles);
      this.SET_provider('azure');
      span.addEvent('logged in, loaded user info');
      span.setAttribute('provider', 'azure');
      span.end();
      this.$log.info('logged in, redirecting');
      this.loginText = 'Starting';
      await this.$nextTick();
      this.$router.push({ name: 'clients-ListJobListing' });
    }
  },
  methods: {
    ...mapActions('organizations', ['LOAD_organizations']),
    ...mapMutations('auth', ['SET_isLoggedIn', 'SET_roles', 'SET_username', 'SET_userId', 'SET_provider']),
    clearAllCookiesAndSessionStorage() {
      // Remove all cookies.
      const cookies = document.cookie.split(';');
      // eslint-disable-next-line no-restricted-syntax
      for (const cookie of cookies) {
        document.cookie = cookie.replace(/^.+?=\s*?(.*?);$/, '; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/');
      }

      // Remove all session storage items.
      sessionStorage.clear();
    },
    login() {
      this.isLoading = true;
      const loginSpan = useTrace().startSpan('login');
      try {
        loginSpan.addEvent('logging in');
        auth.login();
      } catch (error) {
        loginSpan.addEvent('login failed');
        loginSpan.setStatus('ERROR');
        loginSpan.setAttribute('error', error);
        if (error?.response?.data?.innerException?.exceptionMessage) {
          this.$root.$emit('toast:error', error?.response?.data?.innerException?.exceptionMessage);
        } else if (error?.response?.data?.exceptionMessage) {
          this.$root.$emit('toast:error', error?.response?.data?.exceptionMessage);
        } else {
          this.$root.$emit('toast:error', 'Unable to login');
        }
        this.$log.error(error);
      } finally {
        this.isLoading = false;
        loginSpan.end();
      }
    },
  },
};
</script>
