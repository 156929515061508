<template>
  <v-card class="mb-5">
    <v-card-title>{{ task.title }}</v-card-title>
    <v-card-subtitle class="d-flex justify-space-between">
      <span>Status: <b>{{ status }}</b></span>
      <span v-if="task.dueDateTime">
        Due: <b>{{ new Date(task.dueDateTime.dateTime).toLocaleDateString() }}</b>
      </span>
    </v-card-subtitle>
    <v-card-text v-html="task.body?.content" />
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="canComplete"
        color="success"
        :loading="isSaving"
        @click="complete"
      >
        Complete
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'DisplayToDoTask',
  mixins: [loading],
  props: {
    taskId: {
      type: String,
      required: true,
    },
    taskListId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      task: {},
    };
  },
  computed: {
    status() {
      switch (this.task.status) {
        case 'notStarted':
          return 'Not Started';
        case 'inProgress':
          return 'In Progress';
        case 'completed':
          return 'Completed';
        default:
          return 'Unknown';
      }
    },
    canComplete() {
      return this.task.status !== 'completed';
    },
  },
  async mounted() {
    const { taskId, taskListId } = this;
    this.task = await this.$store.dispatch('todo/getTask', {
      taskId,
      taskListId,
    });
  },
  methods: {
    async complete() {
      const { taskId, taskListId } = this;
      this.isSaving = true;
      await this.$store.dispatch('todo/completeTask', {
        taskId,
        taskListId,
      });
      this.task = await this.$store.dispatch('todo/getTask', {
        taskId,
        taskListId,
      });
      this.isSaving = false;
    },
  },
};
</script>
