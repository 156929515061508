import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('jobListingSearch', 'jobListingSearches');

const customActions = {
  async LOAD_jobListingSearchesById({ commit, rootGetters }, jobListingId) {
    const data = await odata.o(rootGetters)
      .get('jobListingSearches')
      .query({ $filter: `IsDeleted eq false AND JobListingId eq ${jobListingId}` });
    commit('SET_jobListingSearches', data);
    return data;
  },
};

export default { ...actions, ...customActions };
