import client from '../../axiosClient';

export default {
  // eslint-disable-next-line no-unused-vars
  async CREATE_userFile({ commit }, file) {
    const formData = new FormData();

    formData.append('file', file);
    const result = await client.post('/auroraApi/Files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return result.data;
  },
};
