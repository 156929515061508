<template>
  <v-card>
    <v-card-title primary-title class="d-flex justify-space-between align-center">
      <span>
        {{ title }}
      </span>
      <div
        class="d-flex"
        style="width: 30%"
        :class="{
          'justify-end': hideSearch,
          'justify-center': !hideSearch,
        }"
      >
        <v-text-field
          v-if="!hideSearch"
          v-model="searchValue"
          hide-details
          dense
          :append-icon="mdiMagnify"
          label="Search"
          clearable
          @keyup.enter="onSearchChanged"
          @click:append="onSearchChanged"
          @click:clear="onSearchClear"
        />
        <v-btn icon @click="refresh" title="Refresh">
          <v-icon>{{ mdiRefresh }}</v-icon>
        </v-btn>
        <slot name="buttons" />
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="eventLogs"
        :loading="isLoading"
        :options.sync="pagination"
        item-key="traceId"
        :footer-props="{
          itemsPerPageOptions: [10, 50, 100, 250, 500],
        }"
        :server-items-length="pagination.totalItems"
      >
        <template v-slot:item.utcDateTime="{ item }">
          {{ formatDateTimeSeconds(item.utcDateTime.replace('+02:00', '+00:00')) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMagnify, mdiRefresh } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ODataQueryBuilder from '../ODataQueryBuilder';

export default {
  name: 'SourceEventLogs',
  mixins: [loading],
  props: {
    hideSearch: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Activity',
    },
    filter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdiMagnify,
      mdiRefresh,
      eventLogs: [],
      // sources: [],
      // selectedSource: undefined,
      queryBuilder: undefined,
      headers: [
        { text: 'Date and Time', value: 'utcDateTime', searchable: false },
        { text: 'Source', value: 'source', searchable: true },
        // { value: 'logicalOperationStack', text: 'Logical Operation Stack', searchable: false },
        // { value: 'relatedActivityId', text: 'Related Activity Id', searchable: false },
        { text: 'Type', value: 'eventType', searchable: true },
        { text: 'Message', value: 'message', searchable: true },
        { text: 'Data', value: 'data', searchable: true },
      ],
      search: '',
      searchValue: '',
      pagination: {
        page: 0,
        itemsPerPage: 10,
        sortBy: ['utcDateTime'],
        sortDesc: [true],
        totalItems: -1,
      },
    };
  },
  watch: {
    pagination() {
      this.refresh();
    },
    filter() {
      this.refresh();
    },
    search() {
      this.refresh();
    },
  },
  async mounted() {
    const { headers } = this;
    this.queryBuilder = new ODataQueryBuilder('odata', 'diagnosticsTraces', headers);
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      const { filter, queryBuilder, search } = this;
      await Promise.all([
        (async () => {
          const {
            page, itemsPerPage, sortBy, sortDesc,
          } = this.pagination;

          const url = queryBuilder.buildUrl({
            top: itemsPerPage,
            filter,
            page,
            search,
            sortBy,
            sortDesc,
          });
          const { data } = await this.$http.get(url);
          this.pagination.totalItems = data['@odata.count'];
          this.eventLogs = data.value;
        })(),
        // (async () => {
        // eslint-disable-next-line max-len
        //   const { data } = await this.$http.get('odata/diagnosticsTraces?$apply=groupby((source))');
        //   this.sources = data.value.map((s) => s.source);
        // })(),
      ]);
      this.isLoading = false;
    },
    onSearchChanged() {
      this.search = this.searchValue;
    },

    onSearchClear() {
      this.searchValue = '';
      this.search = '';
    },
  },
};
</script>
