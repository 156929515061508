import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { locales as auroraAppCoreLocales } from '@codehq/aurora-app-core/src';
import { mergeDeep } from '@codehq/aurora-app-core/src/utils';
import modules from './modules';
import localLocales from './locales';

let msgs = mergeDeep(auroraAppCoreLocales, localLocales);

modules.forEach((m) => {
  msgs = mergeDeep(msgs, m.locales);
});

Vue.use(VueI18n);

const messages = { ...msgs };

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
});

export { i18n };
export { messages };
