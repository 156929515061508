var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("ProgressLinearTimeout")
        : _c(
            "v-timeline",
            { attrs: { "align-top": "", dense: "" } },
            _vm._l(_vm.candidateComments, function (comment) {
              return _c(
                "v-timeline-item",
                {
                  key: comment.id,
                  staticClass: "text-left",
                  attrs: { small: "" },
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "font-weight-normal" }, [
                      _c("strong", [_vm._v(_vm._s(comment.createdBy))]),
                      _vm._v(
                        " | " +
                          _vm._s(_vm.formatRelative(comment.created)) +
                          " "
                      ),
                    ]),
                    _c("div", [_vm._v(_vm._s(comment.message))]),
                  ]),
                ]
              )
            }),
            1
          ),
      _c("v-divider"),
      _c(
        "v-textarea",
        {
          attrs: { placeholder: "Add a comment", outlined: "", rows: "2" },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", loading: _vm.isSaving },
                      on: { click: _vm.addComment },
                    },
                    [_vm._v(" Add ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.message,
            callback: function ($$v) {
              _vm.message = $$v
            },
            expression: "message",
          },
        },
        [_vm._v(" > ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }