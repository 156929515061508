var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("ProgressLinearTimeout")
        : _c(
            "v-timeline",
            { attrs: { "align-top": "", dense: "" } },
            _vm._l(_vm.completedTasks, function (completedTask) {
              return _c(
                "v-timeline-item",
                { key: completedTask.id, attrs: { small: "" } },
                [
                  _c("div", { staticClass: "font-weight-normal" }, [
                    _c("strong", { staticClass: "d-block" }, [
                      _vm._v(" " + _vm._s(completedTask.createdBy) + " "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-block" },
                      [
                        _c("v-icon", { attrs: { "x-small": "" } }, [
                          _vm._v(_vm._s(_vm.mdiClock)),
                        ]),
                        _c("span", { staticClass: "caption" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDateTime(completedTask.created)
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [_vm._v(_vm._s(completedTask.description))]),
                ]
              )
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }