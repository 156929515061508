<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Create Task
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Create a Task
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="taskListId"
            :items="taskLists"
            label="Task List"
            required
            item-text="displayName"
            item-value="id"
            :rules="[rules.required]"
          />
          <v-autocomplete
            v-model="task.assignedTo"
            label="Assigned To"
            :items="sortedUsers"
            item-text="userName"
            item-value="userName"
          />
          <DatePicker
            label="Due Date"
            :filled="false"
            v-model="dueDateTime"
          />
          <v-text-field
            v-model="task.title"
            label="Title"
            :rules="[rules.required]"
          />
          <v-textarea
            v-model="description"
            label="Description"
            auto-grow
            rows="5"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            @click="create"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import validation from '@codehq/aurora-app-core/src/components/validation';
import DatePicker from '@/components/DatePicker.vue';

dayjs.extend(utc);

export default {
  name: 'CreateToDoTask',
  mixins: [validation],
  components: {
    DatePicker,
  },
  props: {
    jobListingId: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    dialog: false,
    description: '',
    dueDateTime: undefined,
    taskListId: undefined,
    users: [],
    task: {
      title: '',
      linkedResources: [
        {
          webUrl: window.location.href,
          applicationName: 'BrightFuture',
          displayName: 'Bright Future',
        },
      ],
      assignedTo: undefined,
    },
  }),
  computed: {
    ...mapState('todo', ['tasks', 'taskLists']),
    sortedUsers() {
      const { users } = this;
      return users.sort((a, b) => {
        if (a.userName < b.userName) {
          return -1;
        }
        if (a.userName > b.userName) {
          return 1;
        }
        return 0;
      });
    },
  },
  async mounted() {
    await Promise.all([
      (async () => {
        this.getTaskLists();
      })(),
      (async () => {
        const { data } = await this.$http.get('api/users');
        this.users = data;
      })(),
    ]);
  },
  methods: {
    ...mapActions('todo', ['getTasks', 'getTaskLists', 'createTask', 'createTaskList']),
    async create() {
      const { task } = this;
      const {
        taskListId, jobListingId, dueDateTime, description,
      } = this;

      if (dueDateTime) {
        task.dueDateTime = {
          dateTime: dayjs.utc(dueDateTime).format('YYYY-MM-DDTHH:mm:ss'),
          timeZone: 'UTC',
        };
      }
      task.body = {
        content: description,
      };
      await this.createTask({
        taskListId,
        task,
        jobListingId,
      });
      this.dialog = false;
    },
  },
};
</script>
