var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.documentTemplateFields,
          loading: _vm.isLoading,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "500px" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g({ attrs: { color: "success" } }, on),
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(_vm._s(_vm.mdiPlus)),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.buttons.new")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.dialog,
                          callback: function ($$v) {
                            _vm.dialog = $$v
                          },
                          expression: "dialog",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", [
                              _c("span", { staticClass: "headline" }, [
                                _vm._v(_vm._s(_vm.$t("common.buttons.new"))),
                              ]),
                            ]),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-form",
                                  { ref: "form" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        rules: [
                                          (v) => !!v || "Name is required",
                                        ],
                                        label: "Name",
                                        required: "",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "prepend-inner",
                                          fn: function () {
                                            return [
                                              _vm._v(" " + _vm._s("{{") + " "),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "append-outer",
                                          fn: function () {
                                            return [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s("}}"),
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                      model: {
                                        value: _vm.name,
                                        callback: function ($$v) {
                                          _vm.name = $$v
                                        },
                                        expression: "name",
                                      },
                                    }),
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Description",
                                        required: "",
                                      },
                                      model: {
                                        value: _vm.description,
                                        callback: function ($$v) {
                                          _vm.description = $$v
                                        },
                                        expression: "description",
                                      },
                                    }),
                                    _c("v-text-field", {
                                      attrs: { label: "Hint", required: "" },
                                      model: {
                                        value: _vm.hint,
                                        callback: function ($$v) {
                                          _vm.hint = $$v
                                        },
                                        expression: "hint",
                                      },
                                    }),
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.fieldTypes,
                                        rules: [
                                          (v) =>
                                            !!v || "Field type is required",
                                        ],
                                        label: "Field Type",
                                        required: "",
                                      },
                                      model: {
                                        value: _vm.fieldType,
                                        callback: function ($$v) {
                                          _vm.fieldType = $$v
                                        },
                                        expression: "fieldType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "" },
                                    on: { click: _vm.closeDialog },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      loading: _vm.isSaving,
                                      color: "primary",
                                    },
                                    on: { click: _vm.save },
                                  },
                                  [_vm._v(" Save ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.name",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(`{{${item.name}`)),
                _c("span", [_vm._v("}}")]),
              ]
            },
          },
          {
            key: "item.product.name",
            fn: function ({ item }) {
              return [
                _vm._v(
                  " " +
                    _vm._s(item.product.model) +
                    ": " +
                    _vm._s(item.product.shortDescription) +
                    " "
                ),
              ]
            },
          },
          {
            key: "item.fileUrl",
            fn: function ({ item }) {
              return [
                _c(
                  "a",
                  {
                    attrs: {
                      href: item.fileUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [_vm._v(_vm._s(item.filename))]
                ),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn-toggle",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(item)
                          },
                        },
                      },
                      [_vm._v(" Edit ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { small: "", color: "error" },
                        on: {
                          click: function ($event) {
                            return _vm.remove(item)
                          },
                        },
                      },
                      [_vm._v(" Delete ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }