var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.documentTemplates,
          loading: _vm.isLoading,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "success",
                          "data-cy": "DocumentTemplate-button-new",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "clients-EditDocumentTemplate",
                            })
                          },
                        },
                      },
                      [
                        _c("v-icon", { staticClass: "mr-2" }, [
                          _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("common.buttons.new")) + " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.product.name",
            fn: function ({ item }) {
              return [
                _vm._v(
                  " " +
                    _vm._s(item.product.model) +
                    ": " +
                    _vm._s(item.product.shortDescription) +
                    " "
                ),
              ]
            },
          },
          {
            key: "item.fileUrl",
            fn: function ({ item }) {
              return [
                _c(
                  "a",
                  {
                    attrs: {
                      href: item.fileUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [_vm._v(" " + _vm._s(item.filename) + " ")]
                ),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c("TableBasicButtons", {
                  attrs: {
                    "edit-link": {
                      name: "clients-EditDocumentTemplate",
                      query: { id: item.id },
                    },
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.remove(item)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.duplicate(item)
                                  },
                                },
                              },
                              [_vm._v(" Duplicate ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }