var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.jobListing
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { attrs: { "primary-title": "" } },
                [
                  _c("Favourite", {
                    attrs: {
                      name: "JobListing",
                      identifier: `${_vm.userId}-${_vm.jobListing.id}`,
                    },
                  }),
                  _c("div", { staticClass: "text-h4 mr-5" }, [
                    _vm._v(_vm._s(_vm.jobListing.client.name) + ":"),
                  ]),
                  _c("div", { staticClass: "text-h4 mr-5" }, [
                    _vm._v(_vm._s(_vm.jobListing.name)),
                  ]),
                  _vm._v(" (" + _vm._s(_vm.jobListing.status) + ") "),
                  !_vm.jobListing.isActive
                    ? _c(
                        "v-icon",
                        { staticClass: "ml-5", attrs: { title: "Archived" } },
                        [_vm._v(_vm._s(_vm.mdiArchive))]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      clearable: "",
                      "append-icon": _vm.mdiMagnify,
                      label: "Search",
                      "single-line": "",
                      hint: "Press enter to search or click the search icon",
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchCandidates.apply(null, arguments)
                      },
                      "click:append": _vm.searchCandidates,
                      "click:clear": _vm.clearSearch,
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v(_vm._s(_vm.jobListing.description)),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _vm.tab === 0
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _vm.jobListingCandidates.length > 0
                                ? _c("GraphJobListing", {
                                    attrs: {
                                      jobListingCandidates: _vm.graphCandidates,
                                      stoppedCandidates: _vm.stoppedCandidates,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.lastTab
                        ? _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("GraphFunnel", {
                                attrs: {
                                  jobId: `${_vm.jobListing.id}`,
                                  height: 220,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tab > 0
                        ? _c(
                            "v-col",
                            { attrs: { cols: _vm.lastTab ? "12" : "3" } },
                            [
                              _c("GraphJobListingIssues", {
                                attrs: {
                                  jobId: `${_vm.jobListing.id}`,
                                  hideExplanations: !_vm.lastTab,
                                  "is-vertical": !_vm.lastTab,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs",
                    {
                      attrs: { "slider-color": "secondary" },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _vm._l(_vm.tabs, function (tab, index) {
                        return _c("v-tab", { key: index }, [
                          _vm._v(" " + _vm._s(tab) + " "),
                        ])
                      }),
                      _vm._l(_vm.tabs, function (tab, index) {
                        return _c("v-tab-item", { key: index }, [
                          _c(
                            "div",
                            { staticStyle: { margin: "20px" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex align-center",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("AddCandidate", {
                                        attrs: {
                                          jobListing: _vm.jobListing,
                                          candidates: _vm.availableCandidates,
                                          tab: tab,
                                        },
                                        on: { selected: _vm.addCandidates },
                                      }),
                                      _c("div", { staticClass: "mr-2" }),
                                      _c("CreateToDoTask", {
                                        attrs: {
                                          "job-listing-id":
                                            _vm.$route.query.jobListingId,
                                        },
                                      }),
                                      _c("div", { staticClass: "mr-2" }),
                                      _c("DialogListTask", {
                                        attrs: {
                                          "job-listing-id":
                                            _vm.$route.query.jobListingId,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  index === 0
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("ImportExport", {
                                            attrs: {
                                              name: "Job Listing - Candidates",
                                              url: `api/data/jobListingCandidates(${_vm.$route.query.jobListingId})`,
                                            },
                                            on: { refresh: _vm.refresh },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("JobListingOverview", {
                                        attrs: {
                                          jobListingCandidates:
                                            _vm.visibleCandidates,
                                          jobListing: _vm.jobListing,
                                          loading: _vm.isLoading,
                                          showFilterStage: tab === 0,
                                          showReasons: _vm.lastTab,
                                          stage: _vm.stage,
                                          stageId: _vm.stage?.id,
                                        },
                                        on: {
                                          refresh: _vm.refresh,
                                          update: _vm.updateJobListingCandidate,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }