var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-file-input", {
        ref: "fileInput",
        staticStyle: { "min-width": "400px" },
        attrs: { label: "Add attachment" },
        on: { change: _vm.filesChanged },
        model: {
          value: _vm.file,
          callback: function ($$v) {
            _vm.file = $$v
          },
          expression: "file",
        },
      }),
      _vm._l(_vm.attachments, function (attachment, index) {
        return _c(
          "v-chip",
          {
            key: index,
            staticClass: "mr-2",
            attrs: { close: "" },
            on: {
              "click:close": function ($event) {
                return _vm.remove(attachment)
              },
            },
          },
          [_vm._v(" " + _vm._s(attachment.name) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }