<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-center" data-cy="FormCandidate-form">
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col cols="4">
              <v-select
                dense
                filled
                :items="['Mr', 'Mrs']"
                v-model="localCandidate.salutation"
                :label="$$t('salutation')"
              />
            </v-col>
            <v-col cols="8">
              <v-text-field
                filled
                dense
                data-cy="FormCandidate-name"
                :label="$$t('name')"
                v-model="localCandidate.name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                dense
                filled
                data-cy="FormCandidate-email"
                :label="$$t('email')"
                v-model="localCandidate.email"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                filled
                data-cy="FormCandidate-phone"
                :label="$$t('phone')"
                v-model="localCandidate.phone"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <DatePicker
                filled
                data-cy="FormCandidate-dateOfBirth"
                :label="$$t('dateOfBirth')"
                v-model="localCandidate.dateOfBirth"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                filled
                :items="['Not Say', 'Male', 'Female']"
                v-model="localCandidate.gender"
                :label="$$t('gender')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                filled
                data-cy="FormCandidate-currentEmployer"
                :label="$$t('currentEmployer')"
                v-model="localCandidate.currentEmployer"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                filled
                data-cy="FormCandidate-title"
                :label="$$t('title')"
                v-model="localCandidate.title"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                filled
                data-cy="FormCandidate-linkedInUrl"
                :label="$$t('linkedInUrl')"
                v-model="localCandidate.linkedInUrl"
                :append-icon="localCandidate.linkedInUrl ? mdiOpenInNew : ''"
                @click:append="openLinkedIn"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                filled
                data-cy="FormCandidate-location"
                :label="$$t('location')"
                v-model="localCandidate.location"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                filled
                data-cy="FormCandidate-country"
                :label="$$t('country')"
                v-model="localCandidate.country"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                dense
                filled
                v-model="localCandidate.industryId"
                :items="industries"
                item-text="name"
                item-value="id"
                :label="$$t('industryId')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                filled
                v-model="localCandidate.createdBy"
                :label="$$t('createdBy')"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-img
            v-if="localCandidate.imageUrl"
            contain
            :src="localCandidate.imageUrl"
            :max-height="$vuetify.breakpoint.mobile ? 200 : 380"
          />
          <ImageUpload
            label="Upload Profile Picture"
            hint="Drag and drop the candidate profile here"
            v-model="localCandidate.imageUrl"
            @url="localCandidate.imageUrl = $event"
          />
          <v-row>
            <v-col cols="4">
              <v-checkbox v-model="localCandidate.newsLetter" label="Newsletter" />
            </v-col>
            <v-col cols="8">
              <v-select
                :items="['English', 'French', 'German', 'Italian']"
                label="Language"
              />
            </v-col>
          </v-row>
          <v-row v-if="localCandidate.id">
            <v-col cols="12">
              <div class="text-h5 text-left">Comments</div>
              <CandidateComments :candidate="localCandidate" />
            </v-col>
            <v-col cols="12">
              <div class="text-h5 text-left">Documents</div>
              <CandidateDocuments :candidateId="localCandidate.id" />
            </v-col>
          </v-row>
          <h2 class="mt-10 mb-5">Candidate History</h2>
          <CandidateFeed :candidateId="localCandidate.id" />
        </v-col>
      </v-row>
      <div class="d-flex justify-end mb-6 mt-5">
        <v-btn
          color="default"
          outlined
          class="ml-4 btn-cancel"
          @click="cancel"
          data-cy="FormCandidate-button-cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          class="ml-4"
          :loading="isSaving"
          data-cy="FormCandidate-button-save">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import { mdiOpenInNew } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ImageUpload from '@/components/ImageUpload.vue';
import DatePicker from '../../../components/DatePicker.vue';
import CandidateDocuments from './CandidateDocuments.vue';
import CandidateFeed from './CandidateFeed.vue';
import CandidateComments from './CandidateComments.vue';

const countries = require('i18n-iso-countries/langs/en.json');

export default {
  name: 'FormCandidate',
  module: 'Candidates',
  components: {
    DatePicker,
    CandidateComments,
    CandidateDocuments,
    CandidateFeed,
    ImageUpload,
  },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Candidate: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localCandidate
      */
      localCandidate: {},
      mdiOpenInNew,
    };
  },
  computed: {
    ...mapState('industries', ['industries']),
    countries() {
      // eslint-disable-next-line no-unused-vars
      return Object.entries(countries.countries).map(([code, name]) => ({
        text: name,
        value: name,
      }));
    },
  },
  watch: {
    Candidate() {
      this.localCandidate = this.Candidate;
    },
  },
  created() {
    this.localCandidate = this.Candidate;
  },
  methods: {
    openLinkedIn() {
      if (this.localCandidate.linkedInUrl) {
        window.open(this.localCandidate.linkedInUrl, '_blank');
      }
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localCandidate);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormCandidate example

'''js
<FormCandidate :Candidate="Candidate" />
'''
</docs>
