var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("GChart", {
    attrs: {
      type: "BarChart",
      data: _vm.chartData,
      options: _vm.chartOptions,
      resizeDebounce: 500,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }