<template>
  <v-row>
    <v-col cols="12">
      <ProgressLinearTimeout v-if="isLoading" />
      <v-timeline
        align-top
        dense
        v-else
      >
        <v-timeline-item
          v-for="comment in feedbackComments"
          :key="comment.id"
          small
        >
          <div>
            <div class="font-weight-normal">
              <strong>{{ comment.createdBy }}</strong> | {{ formatRelative(comment.created) }}
            </div>
            <div>{{ comment.content }}</div>
          </div>
        </v-timeline-item>
      </v-timeline>
      <v-divider />
      <v-textarea
        v-model="content"
        placeholder="Add a comment"
        outlined
        rows="2">
        >
        <template v-slot:append>
          <v-btn
            small
            color="secondary"
            :loading="isSaving"
            @click="addComment"
          >
            Add
          </v-btn>
        </template>
      </v-textarea>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import dayjs from 'dayjs';

export default {
  name: 'FeedbackComments',
  mixins: [loading],
  components: {
    ProgressLinearTimeout,
  },
  props: {
    feedbackItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      content: '',
    };
  },
  computed: {
    ...mapState('feedbackComments', ['feedbackComments']),
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('feedbackComments', ['LOAD_feedbackComments', 'CREATE_feedbackComment']),
    async addComment() {
      this.isSaving = true;
      await this.CREATE_feedbackComment({
        feedbackItemId: this.feedbackItem.id,
        content: this.content,
      });
      this.content = '';
      this.isSaving = false;
      await this.refresh();
    },
    formatRelative(dt) {
      return dayjs(dt).fromNow();
    },
    async refresh() {
      this.isLoading = true;
      await this.LOAD_feedbackComments(this.feedbackItem.id);
      this.isLoading = false;
    },
  },
};
</script>
