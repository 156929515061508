<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    show-expand
    show-select
    v-model="selected"
    item-key="id"
    :expanded.sync="expanded"
    :single-expand="true"
    :custom-sort="customSort"
    :sort-by="sortField"
    :items-per-page="25"
    dense
    :footer-props="{
      'items-per-page-options': [25, 50, 100, 250, 500, -1],
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <SendMessages :selected="selected" @email="sendMails" />
        <StopCandidates :selected="selected" @refresh="selected = []; $emit('refresh')" />
        <UpdateJoblistingCandidates :selected="selected" />
        <v-toolbar-title>Candidates ({{ items.length }})</v-toolbar-title>
        <v-spacer />
        <OpenInTeams :job-listing="jobListing" />
        <v-checkbox
          class="mr-2 ml-2 mt-5"
          label="Favourites"
          dense
          v-model="filterFavourites" />
        <v-select
          class="mr-2 ml-2 mt-5"
          dense
          clearable
          :items="users"
          v-model="selectedCreatedBy"
          placeholder="Created By"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                <DisplayName :user="item" />
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ item }">
            <DisplayName :user="item" />
          </template>
        </v-select>
        <v-select
          class="mr-2 ml-2 mt-5"
          dense
          clearable
          v-if="!showFilterStage"
          :items="orderedStages"
          v-model="selectedStage"
          item-text="name"
          item-value="id"
          placeholder="Stage"
        />
        <v-select
          v-if="getTasksByStageId(selectedStage ?? stageId)?.length > 0"
          class="mr-2 ml-2 mt-5"
          dense
          clearable
          :items="getTasksByStageId(selectedStage ?? stageId)"
          v-model="selectedTask"
          item-text="name"
          item-value="id"
          placeholder="Task"
        />
        <v-select
          class="mr-2 ml-2 mt-5"
          label="Sort By"
          v-model="sortBy"
          :items="['Updated', 'Name', 'Created']" />
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ item, index }">
      <td colspan="4" class="expanded-column">
        <v-card class="elevation-1 mb-5 mt-5">
          <v-card-title class="text-h6">Candidate Info</v-card-title>
          <v-card-text>
            <ModalInlineTextField
              v-model="item.candidate.email"
              label="Email"
              @input="updateCandidate(item.candidate, 'email', $event)"
            />
            <ModalInlineTextField
              v-model="item.candidate.phone"
              label="Email"
              @input="updateCandidate(item.candidate, 'phone', $event)"
            />
          </v-card-text>
        </v-card>
        <v-card class="elevation-1 mb-5 mt-5">
          <v-card-title class="text-h6">Checklist</v-card-title>
          <v-card-actions>
            <JobListingCandidateChecklists :jobListingCandidate="item" />
          </v-card-actions>
        </v-card>
        <v-card class="elevation-1 mb-5">
          <v-card-title class="text-h6">History</v-card-title>
          <v-card-actions>
            <JobListingCandidateHistory :jobListingCandidate="item" />
          </v-card-actions>
        </v-card>
      </td>
      <td colspan="5" class="expanded-column">
        <v-card class="elevation-1 mb-5 mt-5">
          <v-card-title class="text-h6 d-flex justify-space-between pa-2">
            <span>
              Feed
            </span>
            <ImportMail
              :jobListingCandidate="item"
              @refresh="refresh"
            />
          </v-card-title>
          <JobListingCandidateFeed :jobListingCandidate="item" @refresh="refresh" />
        </v-card>
        <v-card class="mb-5" color="warning">
          <v-card-title>
            <h4 class="headline text--white">
              Danger Zone
            </h4>
          </v-card-title>
          <v-card-actions>
            <v-btn
              color="error"
              :data-cy="`JobListingOverview-action-${index}`"
              @click="remove(item)"
            >
              <v-icon small>{{ mdiTrashCan }}</v-icon>
              Remove Candidate from Project
            </v-btn>
            <v-spacer />
            <MoveCandidate :jobListingCandidate="item" @refresh="refresh" />
          </v-card-actions>
        </v-card>
      </td>
    </template>
    <template v-slot:item.candidate.name="{ item }">
      <CandidateModal v-if="item.candidate" :CandidateProp="item.candidate" />
      <span v-if="item.candidate?.jobListingCount > 1">
        ({{ item.candidate?.jobListingCount }})
      </span>
      <span class="caption" v-if="item.candidate?.currentEmployer">
        {{ item.candidate?.currentEmployer}}:
      </span>
      <span class="caption">{{ item.candidate?.title }}</span>
    </template>
    <template v-slot:item.notifications="{ item }">
      <CandidateUploadLink :candidate-id="item.candidateId" :candidate="item.candidate" />
      <v-btn
        v-if="item.candidate && item.candidate?.linkedInUrl"
        small
        icon
        :href="item.candidate?.linkedInUrl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <v-icon>{{ mdiLinkedin }}</v-icon>
      </v-btn>
      <v-badge
        class="mr-2"
        color="secondary"
        :content="`${item['comments@odata.count']}`"
      >
        <v-icon>{{ mdiComment }}</v-icon>
      </v-badge>
      <v-badge
        class="mr-2"
        color="secondary"
        :content="`${item['messages@odata.count']}`"
      >
        <v-icon>{{ mdiEmail }}</v-icon>
      </v-badge>
      <v-badge
        v-if="item.candidate?.jobListingCount > 1"
        color="secondary"
        :content="item.candidate?.jobListingCount"
      >
        <v-icon>{{ mdiBriefcaseOutline }}</v-icon>
      </v-badge>
    </template>
    <template v-slot:item.stop="{ item, index }">
      <div class="d-flex">
        <v-btn
          :color="item.isStop ? 'success' : 'error'"
          outlined
          small
          :data-cy="`JobListingOverview-action-stop-${index}`"
          @click="swopStop(item)"
          class="mr-2"
        >
          <v-icon small>{{ mdiCloseOctagonOutline }}</v-icon>
        </v-btn>
        <ModalPrint :jobListingCandidate="item" :jobListing="jobListing" />
      </div>
    </template>
    <template v-slot:item.favourite="{ item }">
      <Favourite name="JobListingCandidate" :identifier="`${userId}-${item.id}`" />
    </template>
    <template v-slot:item.stage.name="{ item, index }">
      <v-select
        v-if="item.isStop === false || !stageId"
        label="Stage"
        :data-cy="`JobListingOverview-stage-${index}`"
        :items="orderedStages"
        :disabled="orderedStages.length === 0"
        v-model="item.stageId"
        item-text="name"
        item-value="id"
        :loading="isSaving || isLoading"
        @change="updateStage(item, $event)"
      />
      <v-select
        v-if="item.isStop"
        :data-cy="`JobListingOverview-reason-${index}`"
        label="Reason"
        clearable
        :items="reasons"
        v-model="item.reasonId"
        item-text="name"
        item-value="id"
        :loading="isSaving || isLoading"
        @change="updateReason(item, $event)"
      />
    </template>
    <template v-slot:item.task.name="{ item, index }">
      <v-select
        v-if="item.isStop === false || !stageId"
        label="Task"
        :data-cy="`JobListingOverview-task-${index}`"
        :items="getItemTask(item)"
        v-model="item.taskId"
        item-text="name"
        item-value="id"
        :loading="isSaving || isLoading"
        @change="updateTask(item, $event)"
      />
      <v-select
        v-if="item.isStop"
        label="Explanation"
        :data-cy="`JobListingOverview-explanation-${index}`"
        clearable
        :items="explanations"
        v-model="item.explanationId"
        item-text="name"
        item-value="id"
        :loading="isSaving || isLoading"
        @change="updateExplanation(item, $event)"
      />
    </template>
    <template v-slot:item.reason.name="{ item, index }">
      <v-select
        :data-cy="`JobListingOverview-reason-${index}`"
        clearable
        :items="reasons"
        :value="item.reasonId"
        item-text="name"
        item-value="id"
        @change="updateReason(item, $event)"
      />
    </template>
    <template v-slot:item.initialComments="{ item }">
      <span class="d-block mt-5" v-if="item.updated || item.created">
        <v-icon v-if="isMoreThan14Days(item.updated ?? item.created)">
          {{ mdiAlert }}
        </v-icon>
        Last Update {{ formatDateTime(item.updated ?? item.created) }}
      </span>
      <v-timeline
        align-top
        dense
      >
        <v-timeline-item
          v-for="feedItem in getFeedItems(item)"
          :key="feedItem.id"
          small
          color="secondary"
        >
          <div>
            <div class="font-weight-normal">
              <strong v-if="feedItem.messageType === 'email'">
                <v-icon small>{{ feedItem.icon }}</v-icon>
                <DisplayName :user="feedItem.from" /> | {{ formatDateTime(feedItem.createdDate) }}
              </strong>
              <strong v-else-if="feedItem.messageType === 'whatsApp'">
                <v-icon small>{{ feedItem.icon }}</v-icon>
                {{ feedItem.from }} | {{ formatDateTime(feedItem.createdDate) }}
              </strong>
              <strong v-else>
                <v-icon small>{{ feedItem.icon }}</v-icon>
                <DisplayName :user="feedItem.createdBy" />
                | {{ formatDateTime(feedItem.createdDate) }}
              </strong>
            </div>
            <span class="comment">
              {{ feedItem.subtitle }}
            </span>
          </div>
        </v-timeline-item>
      </v-timeline>
    </template>
    <template v-slot:item.explanation.name="{ item, index }">
      <v-select
        :data-cy="`JobListingOverview-explanation-${index}`"
        clearable
        :items="explanations"
        :value="item.explanationId"
        item-text="name"
        item-value="id"
        @change="updateExplanation(item, $event)"
      />
    </template>
  </v-data-table>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  mdiComment, mdiLinkedin, mdiEmail, mdiTrashCan, mdiCloseOctagonOutline, mdiAlert,
  mdiMoveResizeVariant, mdiPrinter, mdiBriefcaseOutline, mdiEmailSync,
  mdiMessageProcessing, mdiWhatsapp, mdiPhone,
} from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';
import axios from 'axios';
import { useAuth } from '@/use';
import CandidateUploadLink from './CandidateUploadLink.vue';
import JobListingCandidateChecklists from './JobListingCandidateChecklists.vue';
import JobListingCandidateComments from './JobListingCandidateComments.vue';
import JobListingCandidateHistory from './JobListingCandidateHistory.vue';
import JobListingCandidateFeed from './JobListingCandidateFeed.vue';
import ModalInlineTextField from './ModalInlineTextField.vue';
import CandidateModal from '../../candidates/components/CandidateModal.vue';
import ImportMail from './ImportMail.vue';
import MoveCandidate from './MoveCandidate.vue';
import SendMessages from './SendMessages.vue';
import StopCandidates from './StopCandidates.vue';
import UpdateJoblistingCandidates from './UpdateJoblistingCandidates.vue';
import OpenInTeams from './OpenInTeams.vue';
import ModalPrint from './ModalPrint.vue';
import emails from '../../../mixins/emails';
import userProfile from '../../../mixins/userProfile';

const auth = useAuth();

export default {
  name: 'JobListingOverview',
  components: {
    CandidateModal,
    MoveCandidate,
    ImportMail,
    CandidateUploadLink,
    // eslint-disable-next-line vue/no-unused-components
    ModalPrint,
    OpenInTeams,
    JobListingCandidateChecklists,
    // eslint-disable-next-line vue/no-unused-components
    JobListingCandidateComments,
    JobListingCandidateHistory,
    JobListingCandidateFeed,
    SendMessages,
    StopCandidates,
    UpdateJoblistingCandidates,
    ModalInlineTextField,
  },
  mixins: [emails, loading, userProfile],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    jobListing: {
      type: Object,
      default: () => ({}),
    },
    jobListingCandidates: {
      type: Array,
      default: () => [],
    },
    showReasons: {
      type: Boolean,
      default: false,
    },
    showFilterStage: {
      type: Boolean,
      default: false,
    },
    stageId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      mdiAlert,
      mdiComment,
      mdiEmail,
      mdiBriefcaseOutline,
      mdiLinkedin,
      mdiTrashCan,
      mdiCloseOctagonOutline,
      mdiMoveResizeVariant,
      mdiPrinter,
      mdiEmailSync,
      expanded: [],
      sortBy: 'Updated',
      selectedStage: undefined,
      selectedTask: undefined,
      selectedCreatedBy: undefined,
      toggleFilters: 0,
      selected: [],
      items: [],
      firstSet: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isInRole']),
    ...mapGetters('tasks', ['getTasksByStageId']),
    ...mapGetters('reasons', ['getReasonByTask']),
    ...mapGetters('stages', ['orderedStages']),
    ...mapState('app', ['favouritesOnlyJobListing']),
    ...mapState('auth', ['userId']),
    ...mapState('explanations', ['explanations']),
    ...mapState('reasons', ['reasons']),
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
    headers() {
      if (this.showReasons) {
        return [
          {
            text: '', value: 'favourite', width: '16px', sortable: false,
          },
          {
            text: 'Candidate', value: 'candidate.name', width: '150px', sortable: true,
          },
          {
            text: '', value: 'notifications', width: '160px', sortable: false,
          },
          {
            text: '', value: 'stop', width: '32px', sortable: false,
          },
          {
            text: 'Stage', value: 'stage.name', width: '190px', sortable: false,
          },
          {
            text: 'Task', value: 'task.name', width: '120px', sortable: false,
          },
          // {
          //   text: 'Reason', value: 'reason.name', width: '120px', sortable: false,
          // },
          // {
          //   text: 'Explanation', value: 'explanation.name', width: '120px', sortable: false,
          // },
        ];
      }
      return [
        { text: '', value: 'favourite', width: '16px' },
        { text: 'Candidate', value: 'candidate.name', width: '150px' },
        { text: '', value: 'notifications', width: '160px' },
        { text: '', value: 'stop', width: '32px' },
        { text: '', value: 'stage.name', width: '190px' },
        { text: '', value: 'task.name', width: '120px' },
        { text: '', value: 'initialComments' },
      ];
    },
    /**
     * Computed property for the favouritesOnly vuex property
     */
    filterFavourites: {
      get() {
        return this.favouritesOnlyJobListing;
      },
      set(newValue) {
        this.SET_favouritesOnlyJobListing(newValue);
      },
    },
    sortField() {
      if (this.sortBy === 'Updated') {
        return ['updated'];
      }
      if (this.sortBy === 'Name') {
        return ['candidate.name'];
      }
      if (this.sortBy === 'Created') {
        return ['created'];
      }
      return [];
    },
    users() {
      return [...new Set(this.jobListingCandidates.map((i) => i.createdBy))];
    },
  },
  watch: {
    jobListingCandidates: {
      immediate: true,
      deep: true,
      handler() {
        this.items = this.filterItems();
      },
    },
    selectedStage() {
      this.items = this.filterItems();
    },
    selectedTask() {
      this.items = this.filterItems();
    },
    selectedCreatedBy() {
      this.items = this.filterItems();
    },
  },
  mounted() {
    this.loadProfile();
    this.filterItems();
  },
  methods: {
    ...mapActions('jobListingCandidates', [
      'UPDATE_jobListingCandidate',
      'DELETE_jobListingCandidate',
    ]),
    ...mapActions('candidates', ['UPDATE_candidate']),
    ...mapMutations('app', ['SET_isSaving', 'SET_favouritesOnlyJobListing']),
    customSort(items, cols, sortDesc) {
      let results = [];
      if (cols.length === 0) {
        return items;
      }
      if (cols.includes('stage.name')) {
        results = sortBy(items, (item) => item.stage.order);
      } else {
        results = sortBy(items, cols[0]);
      }
      if (sortDesc[0]) {
        results = results.reverse();
      }
      return results;
    },
    formatRelative(dt) {
      return dayjs(dt).fromNow();
    },
    formatMessageType(messageType) {
      switch (messageType) {
        case 'email':
          return 'Email';
        case 'text':
          return 'Text';
        case 'whatsApp':
          return 'WhatsApp';
        case 'phoneCall':
          return 'Phone Call';
        default:
          return 'Message';
      }
    },
    getIcon(messageType) {
      switch (messageType) {
        case 'email':
          return mdiEmail;
        case 'text':
          return mdiMessageProcessing;
        case 'whatsApp':
          return mdiWhatsapp;
        case 'phoneCall':
          return mdiPhone;
        default:
          return mdiEmail;
      }
    },
    getFeedItems(item) {
      const messageItems = item.messages.map((message) => ({
        icon: this.getIcon(message.messageType),
        created: `${this.formatRelative(message.created)}`,
        createdDate: message.created,
        createdBy: message.createdBy,
        formattedMessageType: this.formatMessageType(message.messageType),
        from: message.from,
        title: message.subject,
        subtitle: message.subject,
        id: message.id,
        isRead: message.isRead,
        messageType: message.messageType,
        attachments: message.attachments,
      }));
      const comments = item.comments.map((comment) => ({
        icon: mdiComment,
        created: `${this.formatRelative(comment.created)}`,
        createdDate: comment.created,
        createdBy: comment.createdBy,
        formattedMessageType: 'Comment',
        subtitle: comment.message,
        messageType: 'comment',
        attachments: [],
      }));
      const feedItems = [...messageItems, ...comments].sort((a, b) => {
        if (a.createdDate > b.createdDate) {
          return -1;
        }
        if (a.createdDate < b.createdDate) {
          return 1;
        }
        return 0;
      });
      if (feedItems.length > 3) {
        return feedItems.slice(0, 3);
      }
      return feedItems;
    },
    getItemTask(item) {
      return this.getTasksByStageId(item.stageId);
    },
    isMoreThan14Days(dt) {
      return dayjs(dt).isBefore(dayjs().subtract(13, 'day'));
    },
    async sendMessage(message) {
      const token = await auth.getAccessToken();
      await axios.post('api/messages/send', message, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    async sendMails(mails) {
      this.$log.debug('sendMails', mails);
      const token = await auth.getScopedAccessToken(['Mail.Send']);
      const endpoint = 'https://graph.microsoft.com/v1.0/me/sendMail';
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      mails.selected.forEach(async (jobListingCandidate) => {
        const content = this.parseMail(mails.message, jobListingCandidate.candidate);
        const data = {
          message: {
            subject: mails.subject,
            body: {
              contentType: 'HTML',
              content,
            },
            toRecipients: [
              {
                emailAddress: {
                  address: jobListingCandidate.candidate.email,
                },
              },
            ],
          },
        };
        const response = await axios.post(endpoint, data, config);
        await this.sendMessage({
          jobListingCandidateId: jobListingCandidate.id,
          recipient: jobListingCandidate.candidate.email,
          subject: mails.subject,
          body: content,
          messageType: 'email',
        });
        this.$log.debug(response);
        this.$root.$emit('toast:notify', 'Email sent successfully');
      });
      this.$emit('toast:success', 'Emails sent successfully');
    },
    async updateStage(jobListingCandidate, stageId) {
      this.isSaving = true;
      await this.UPDATE_jobListingCandidate({
        id: jobListingCandidate.id,
        stageId,
      });
      this.isSaving = false;
      const taskId = this.getTasksByStageId(stageId)[0].id;
      await this.updateTask(jobListingCandidate, taskId);
      const updatedJobListingCandidate = this.jobListingCandidates.find(
        (jlc) => jlc.id === jobListingCandidate.id,
      );
      updatedJobListingCandidate.stageId = stageId;
      updatedJobListingCandidate.taskId = taskId;
      // this.$emit('update', updatedJobListingCandidate);
      this.$root.$emit('update:jobListingCandidate', updatedJobListingCandidate);
    },
    async updateTask(jobListingCandidate, taskId) {
      this.isSaving = true;
      await this.UPDATE_jobListingCandidate({
        id: jobListingCandidate.id,
        taskId,
      });
      this.isSaving = false;
      this.$root.$emit('update:jobListingCandidate', jobListingCandidate);
    },
    async updateReason(jobListingCandidate, reasonId) {
      this.isSaving = true;
      await this.UPDATE_jobListingCandidate({
        id: jobListingCandidate.id,
        reasonId,
      });
      this.isSaving = false;
      this.$root.$emit('update:jobListingCandidate', jobListingCandidate);
    },
    async updateExplanation(jobListingCandidate, explanationId) {
      this.isSaving = true;
      await this.UPDATE_jobListingCandidate({
        id: jobListingCandidate.id,
        explanationId,
      });
      this.isSaving = false;
      this.$root.$emit('update:jobListingCandidate', jobListingCandidate);
    },
    async remove(jobListingCandidate) {
      const res = await this.$confirm('Area you sure you want to remove this candidate from the job listing? This action cannot be undone and all history will be deleted.');
      if (res) {
        this.isSaving = true;
        await this.DELETE_jobListingCandidate(jobListingCandidate.id);
        this.isSaving = false;
        await this.refresh();
        this.$root.$emit('update:jobListingCandidate', jobListingCandidate);
      }
    },
    async swopStop(jobListingCandidate) {
      this.isSaving = true;
      await this.UPDATE_jobListingCandidate({
        id: jobListingCandidate.id,
        isStop: !(jobListingCandidate.isStop ?? false),
      });
      const updatedJobListingCandidate = this.jobListingCandidates.find(
        (jlc) => jlc.id === jobListingCandidate.id,
      );
      updatedJobListingCandidate.isStop = !(jobListingCandidate.isStop ?? false);
      this.$emit('update', updatedJobListingCandidate);
      this.isSaving = false;
      this.$root.$emit('update:jobListingCandidate', jobListingCandidate);
    },
    refresh() {
      this.$log.debug('refresh');
      this.$emit('refresh');
    },
    async updateCandidate(candidate, field, value) {
      this.$log.debug({ candidate, field, value });
      const updatedCandidate = {
        id: candidate.id,
        [field]: value,
      };
      await this.UPDATE_candidate(updatedCandidate);
      this.$forceUpdate();
    },
    filterItems() {
      let filteredItems = [...this.jobListingCandidates];
      if (this.selectedStage) {
        filteredItems = filteredItems.filter((item) => this.selectedStage === item.stageId);
      }

      if (this.selectedTask) {
        filteredItems = filteredItems.filter(
          (item) => this.selectedTask === item.taskId,
        );
      }

      if (this.filterFavourites) {
        filteredItems = filteredItems.filter((jobListingCandidate) => {
          const identifier = `${this.userId}-${jobListingCandidate.id}`;
          return this.$store.getters['favourites/isFavourite']('JobListingCandidate', identifier);
        });
      }
      if (this.selectedCreatedBy) {
        filteredItems = filteredItems.filter(
          (item) => this.selectedCreatedBy === item.createdBy,
        );
      }
      return filteredItems;
    },
  },
};
</script>
<style scoped>
.comment {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to 1 paragraphs */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.expanded-column {
  vertical-align: top;
  padding: 10px;
}
.expanded-column > div {
  padding-left: 5px;
  padding-top: 5px;
}
</style>
