<template>
  <v-container>
    <v-btn color="primary" @click="generateInvite">Generate Invite</v-btn>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>Invite Code</v-card-title>
        <v-card-text>
          <v-text-field
            label="Invite URL"
            v-model="url"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      dialog: false,
      inviteCode: '',
    };
  },
  computed: {
    url() {
      return `${process.env.VUE_APP_APPURL}/#/invite/accept?inviteCode=${this.inviteCode}`;
    },
  },
  methods: {
    async generateInvite() {
      try {
        const response = await axios.post('/api/invite/generate', {});
        this.inviteCode = response.data;
        this.dialog = true;
      } catch (error) {
        this.$log.error(error);
      }
    },
  },
};
</script>
