<template>
  <v-btn
    icon
    :color="colour"
    :loading="isLoading"
    @click="toggle">
    <v-icon :color="colour">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiHeart, mdiHeartOutline } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'Favourite',
  mixins: [loading],
  props: {
    name: {
      type: String,
      default: 'Basic',
    },
    /**
     * Holds the identifier value, with a default of ''
     */
    identifier: {
      type: String,
      default: '',
    },
    /**
     * Holds the selectedColour value, with a default of 'primary'
     */
    selectedColour: {
      type: String,
      default: 'primary',
    },
    /**
     * Holds the deselectedColour value, with a default of ''
     */
    deselectedColour: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      /** icons
       */
      mdiHeart,
      mdiHeartOutline,
    };
  },
  computed: {
    ...mapState('favourites', ['favourites']),
    /**
     * Returns the colour
     */
    colour() {
      if (this.isSelected) {
        return this.selectedColour;
      }
      return this.deselectedColour;
    },
    /**
     * Returns the icon
     */
    icon() {
      if (this.isSelected) {
        return this.mdiHeart;
      }
      return this.mdiHeartOutline;
    },
    /**
     * Returns the isSelected property
     */
    isSelected() {
      return this.selectedItem !== null;
    },
    /**
     * Returns the selectedItem property
     */
    selectedItem() {
      const { name, identifier, favourites } = this;
      return favourites.find((f) => f.name === name && f.identifier === identifier) ?? null;
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('favourites', ['CREATE_favourite', 'DELETE_favourite']),
    /**
     * Executes add
     */
    async add() {
      const { name, identifier } = this;
      await this.networkLoad(this.CREATE_favourite, { name, identifier });
    },
    /**
     * Executes remove
     */
    async remove() {
      const { selectedItem } = this;
      this.$log.debug('remove', selectedItem.id);
      await this.DELETE_favourite(selectedItem.id);
    },
    /**
     * Executes toggle
     */
    toggle() {
      if (this.isSelected) {
        this.remove();
      } else {
        this.add();
      }
    },
  },
};
</script>
