import { groupBy } from 'lodash';
import { mdiComment } from '@mdi/js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default {
  getJobListingCandidateComment: (state) => state.jobListingCandidateComment,
  candidateCommentsGroupedByJobListing: (state) => {
    const grouped = groupBy(state.jobListingCandidateComments, (comment) => {
      const { jobListingCandidate } = comment;
      const { jobListing } = jobListingCandidate;
      const stage = jobListingCandidate.stage.name;
      const { id, name } = jobListing;
      return `${id}||${name}||${stage}||${jobListingCandidate.id}`;
    });
    const result = Object.entries(grouped).map(([key, comments]) => {
      const [id, name, stage, jobListingCandidateId] = key.split('||');
      return {
        id,
        name,
        stage,
        jobListingCandidateId,
        comments: comments.map((comment) => ({
          icon: mdiComment,
          created: `${dayjs(comment.created).fromNow()}`,
          createdDate: comment.created,
          createdBy: `Comment by <strong>${comment.createdBy}</strong>`,
          subtitle: comment.message,
          messageType: 'comment',
        })),
      };
    });
    return result;
  },
};
