<template>
  <v-data-table
    :headers="headers"
    :items="jobListingCandidates"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="JobListingCandidate-button-new"
          @click="$router.push({
            name: 'candidates-EditJobListingCandidate',
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons
        :editLink="{
          name: 'candidates-EditJobListingCandidate',
          query: { id: item.id },
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListJobListingCandidate',
  module: 'Candidates',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('jobListingCandidates', ['jobListingCandidates']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('candidateId'),
          value: 'candidateId',
        },
        {
          text: this.$$t('jobListingId'),
          value: 'jobListingId',
        },
        {
          text: this.$t('App.common.createdBy'),
          value: 'createdBy',
        },
        {
          text: this.$$t('stageId'),
          value: 'stageId',
        },
        {
          text: this.$$t('taskId'),
          value: 'taskId',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_jobListingCandidates();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('jobListingCandidates', ['LOAD_jobListingCandidates', 'DELETE_jobListingCandidate']),
    /** Delete JobListingCandidate from the collection
     */
    async remove(jobListingCandidate) {
      this.isLoading = true;
      await this.DELETE_jobListingCandidate(jobListingCandidate.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# JobListingCandidate example

'''js
<JobListingCandidate />
'''
</docs>
