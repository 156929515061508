<template>
  <v-snackbar
    app
    v-model="snackbar"
    data-cy="snackbar-ToastSave"
    :color="color"
    :timeout="timeout"
    right
    rounded="pill"
    top
    vertical
    transition="scroll-y-transition"
    text
    min-width="0"
  >
    <div class="text-h6">
      <v-icon :color="color">{{ mdiCheck }}</v-icon>
      Saved successfully
    </div>
  </v-snackbar>
</template>

<script>
import { VSnackbar } from 'vuetify/lib';
import { mdiCheck } from '@mdi/js';

/**
 * Aurora Snackbar Notifications
 *
 */
export default {
  components: {
    VSnackbar,
  },
  props: {
    /**
    * Set the mode for the snackbar messagel. Default: 'vertical',
    */
    mode: {
      type: String,
      default: 'vertical',
    },
    /**
    * Set the timeout for the snackbar message. Default: 500,
    */
    timeout: {
      type: Number,
      default: 750,
    },
    /**
    * Set the color for the snackbar message. Default: 'success' Should be 'success', 'danger', etc,
    */
    color: {
      type: String,
      default: 'success',
    },
  },
  data() {
    return {
      mdiCheck,
      snackbar: true,
    };
  },
  mounted() {
    this.$root.$on('saving', (newValue) => {
      this.snackbar = !newValue;
    });
  },
  destroyed() {
    this.$root.$off('saving');
  },
};
</script>
