import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('reason', 'reasons');

const customActions = {
  async LOAD_reasonsByTask({ commit, rootGetters }, taskId) {
    const data = await odata.o(rootGetters)
      .get('reasons')
      .query({ $filter: `IsDeleted eq false AND TaskId eq ${taskId}` });
    commit('SET_reasons', data);
    return data;
  },
};

export default { ...actions, ...customActions };
