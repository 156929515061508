<template>
  <div>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-file-input
      v-model="file"
      :label="label"
      :loading="isLoading"
      @change="fileChanged"
      :accept="accept"
      show-size
      :error-messages="errors"
    />
    <v-card
      data-cy="File-Upload"
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      :class="{ 'grey lighten-2': dragover }"
    >
      <v-card-text>
        <v-row class="d-flex flex-column" dense align="center" justify="center">
          <v-progress-circular v-if="isLoading" indeterminate color="primary" />
          <v-icon v-else :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
            {{ mdiCloudUpload }}
          </v-icon>
          <p>
            {{ hint }}
          </p>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import { mdiArrowUpBoldCircleOutline, mdiCloudUpload } from '@mdi/js';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  name: 'ImageUpload',
  components: {
    ProgressLinearTimeout,
  },
  mixins: [loading],
  props: {
    /** Set the Mimetype accept. Defaults to 'image/jpeg, image/png'*.
    */
    accept: {
      type: String,
      default: 'image/jpeg,image/gif,image/png',
    },
    /** Hint for displaying on the drag and drop interface
    */
    hint: {
      type: String,
      default: 'Drag and drop a file here',
    },
    /** Set the label. Defaults to image.
    */
    label: {
      type: String,
      default: 'Upload a File',
    },
  },
  data() {
    return {
      mdiCloudUpload,
      mdiArrowUpBoldCircleOutline,
      errors: [],
      dragover: false,
      file: null,
    };
  },
  methods: {
    ...mapActions('userFiles', ['CREATE_userFile']),
    fileChanged(file) {
      this.errors = [];
      if (file?.size > this.maxFileSize) {
        this.errors.push(this.errorFileSize);
      } else if (file) {
        this.file = file;
        this.upload();
      }
    },
    async upload() {
      const { file } = this;
      this.isLoading = true;
      const result = await this.CREATE_userFile(file);
      const url = `${baseUrl}/auroraApi/files/${result.id}`;
      this.$emit('input', {
        uploadedFile: result,
        url,
      });
      this.$emit('url', url);
      this.isLoading = false;
      this.file = undefined;
      this.$root.$emit('toast:notify', 'File uploaded successfully');
    },
    async onDrop(e) {
      this.dragover = false;
      // check if the file matches the accept prop
      if (this.accept.split(',').some((t) => e.dataTransfer.files[0].type.match(t.trim()))) {
        // eslint-disable-next-line prefer-destructuring
        this.file = e.dataTransfer.files[0];
        this.upload();
      } else {
        this.$root.$emit('toast:notify', 'Invalid file type, only JPG and PNG images are allowed');
        this.isLoading = false;
      }
      this.dragover = true;
    },
  },
};
</script>
