var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list",
            { staticClass: "mb-0", attrs: { dense: "", nav: "" } },
            _vm._l(_vm.items, function (menuItem) {
              return _c(
                "v-list-item",
                {
                  key: menuItem.name,
                  attrs: { link: "", to: { name: menuItem.pathName } },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      menuItem.icon
                        ? _c("v-icon", { attrs: { color: "#1e0b4c" } }, [
                            _vm._v(_vm._s(menuItem.icon)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t(menuItem.name))),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }