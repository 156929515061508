var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        overlay: false,
        "max-width": "500px",
        transition: "dialog-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b({ attrs: { small: "" } }, "v-btn", attrs, false),
                  on
                ),
                [_vm._v(" Open In Teams ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Open In Teams")]),
          _c(
            "v-card-text",
            [
              _c("SelectTeam", {
                model: {
                  value: _vm.teamId,
                  callback: function ($$v) {
                    _vm.teamId = $$v
                  },
                  expression: "teamId",
                },
              }),
              _vm.teamId
                ? _c("SelectTeamChannel", {
                    attrs: { "team-id": _vm.teamId },
                    model: {
                      value: _vm.channelId,
                      callback: function ($$v) {
                        _vm.channelId = $$v
                      },
                      expression: "channelId",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", target: "_blank" },
                  on: { click: _vm.addTab },
                },
                [_vm._v("Open")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }