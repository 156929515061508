<template>
  <v-dialog v-model="dialog" max-width="70vw">
    <template v-slot:activator="{ on }">
      <v-btn color="secondary" icon title="Import Emails" v-on="on">
        <v-icon>{{ mdiEmailSync }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h3>Import Emails</h3>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="emails"
          class="elevation-1"
          item-key="id"
          :loading="isLoading"
          :search="search"
          :options.sync="pagination"
          :server-items-length="totalItems"
          :hide-default-footer="search?.length > 0"
          :footer-props="{
            itemsPerPageOptions: [10, 50, 100, 250, 500],
          }"
        >
          <template v-slot:top>
            <v-app-bar flat>
              <v-spacer />
              <v-text-field
                v-model="search"
                label="Search"
                single-line
                hide-details
                clearable
                :append-icon="mdiMagnify"
              />
            </v-app-bar>
          </template>
          <template v-slot:item.isRead="{ item }">
            <v-icon :title="item.isRead ? 'Read' : 'Unread'">
              {{ item.isRead ? mdiEmail : mdiEmailOutline }}
            </v-icon>
          </template>
          <template v-slot:item.receivedDateTime="{ item }">
            {{ formatDateTime(item.receivedDateTime) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn color="primary" icon @click="importEmail(item)" title="Import Mail">
              <v-icon>{{ mdiEmailPlus }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import {
  mdiClose,
  mdiEmailSync,
  mdiEmailOutline,
  mdiEmail,
  mdiEmailPlus,
  mdiMagnify,
} from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { useAuth } from '../../../use';

const auth = useAuth();

export default {
  name: 'ImportMail',
  mixins: [loading],
  props: {
    jobListingCandidate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiClose,
      mdiEmailSync,
      mdiEmailOutline,
      mdiEmailPlus,
      mdiEmail,
      mdiMagnify,
      dialog: false,
      emails: [],
      search: '',
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['receivedDateTime'],
        sortDesc: [true],
        totalItems: 5000,
      },
      totalItems: 0,
    };
  },
  computed: {
    headers() {
      return [
        { text: '', value: 'isRead' },
        { text: 'From', value: 'from.emailAddress.name' },
        { text: 'Subject', value: 'subject' },
        { text: 'Body', value: 'bodyPreview' },
        { text: 'Received', value: 'receivedDateTime' },
        { text: '', value: 'actions' },
      ];
    },
    url() {
      let url = 'https://graph.microsoft.com/v1.0/me/messages?$select=from,subject,bodyPreview,body,receivedDateTime,isRead&$count=true';
      if (this.search?.length > 0) {
        return `${url}&$search="${this.search}"`;
      }
      const top = this.pagination.itemsPerPage;
      const skip = (this.pagination.page - 1) * this.pagination.itemsPerPage;
      url += `&$top=${top}&$skip=${skip}`;

      url += '&$orderby=receivedDateTime desc';
      return url;
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.refresh();
      }
    },
    search(newValue) {
      if (newValue?.length > 1) {
        this.refresh();
      } else if (!newValue) {
        this.refresh();
      }
    },
    pagination: {
      immediate: true,
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  methods: {
    async fetchGraph(url, scopes) {
      const accessToken = await auth.getScopedAccessToken(scopes);
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    },
    async importEmail(email) {
      this.dialog = false;
      const res = await this.$confirm(`Are you sure you want to import the email from ${email.from.emailAddress.name} into the candidate feed?`);
      if (!res) return;
      const { jobListingCandidate } = this;

      await this.sendMessage({
        jobListingCandidateId: jobListingCandidate.id,
        recipient: email.from.emailAddress.address, // Add cc here
        subject: email.subject,
        body: email.body.content,
        messageType: 'email',
      });
      this.$root.$emit('toast:notify', 'Email imported successfully');
      this.$emit('refresh');
    },
    async sendMessage(message) {
      const token = await auth.getAccessToken();
      const msg = await axios.post('api/messages/send', message, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.$root.$emit('toast:notify', 'Message sent successfully');
      await this.refresh();
      return msg;
    },
    async refresh() {
      this.isLoading = true;
      const { url } = this;
      const data = await this.fetchGraph(url, ['Mail.Read']);
      this.emails = data.value;
      this.totalItems = data['@odata.count'];
      this.isLoading = false;
    },
  },
};
</script>
