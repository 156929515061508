<template>
  <v-sheet>
    <div class="d-flex">
      <v-btn @click="index -= 1" :disabled="index === 0">
        <v-icon>{{ mdiArrowLeft }}</v-icon>
      </v-btn>
      <div class="text-h4 ml-5 mr-5">
        {{ index + 1 }} / {{ candidates.length }}
      </div>
      <v-btn @click="index += 1" :disabled="index === candidates.length - 1">
        <v-icon>{{ mdiArrowRight }}</v-icon>
      </v-btn>
    </div>
    <v-container>
      <div class="text-h5">
        Preview:
      </div>
      <div
        class="preview-message"
      >
        {{ candidate.candidate.email }}
      </div>
      <div
        class="preview-message"
      >
        {{ form.subject }}
      </div>
      <div class="preview-message" v-html="parsedMessage" />
    </v-container>
  </v-sheet>
</template>

<script>
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import emails from '../mixins/emails';
import userProfile from '../mixins/userProfile';

export default {
  name: 'EmailPreview',
  mixins: [emails, userProfile],
  data() {
    return {
      mdiArrowLeft,
      mdiArrowRight,
      index: 0,
    };
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    candidates: {
      type: Array,
      required: true,
    },
  },
  computed: {
    candidate() {
      return this.candidates[this.index];
    },
    parsedMessage() {
      return this.parseMail(this.form.message, this.candidate.candidate);
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      await this.loadProfile();
    },
  },
};
</script>
<style>
.preview-message {
  white-space: pre-wrap;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
.preview-textbox input {
  color: #000 !important;
}
.preview-textbox .v-input__slot {
  color: #000 !important;
  border: 1px solid #000;
  border-radius: 4px;
}
</style>
