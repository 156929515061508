import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('documentTemplate', 'documentTemplates');

const customActions = {
  async LOAD_documentTemplates({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('documentTemplates')
      .query({ $filter: 'IsDeleted eq false' });
    commit('SET_documentTemplates', data);

    return data;
  },
  async LOAD_documentTemplatesByProduct({ commit, rootGetters }, productId) {
    const data = await odata.o(rootGetters)
      .get('documentTemplates')
      .query({ $filter: `IsDeleted eq false AND ProductId eq ${productId}` });
    commit('SET_documentTemplates', data);

    return data;
  },

  async DUPLICATE_documentTemplate({ rootGetters, dispatch }, documentTemplate) {
    const token = await auth.getAccessToken();
    const newDocumentTemplate = await odata.o(rootGetters, token)
      .post('documentTemplates', {
        name: `Duplicate of ${documentTemplate.name}`,
      })
      .query();
    const documentTemplateId = odata.parseId(newDocumentTemplate.id);

    const existingDocumentTemplateFields = await odata.o(rootGetters, token)
      .get('documentTemplateFields')
      .query({ $filter: `DocumentTemplateId eq ${documentTemplate.id}` });

    const newDocumentTemplateFields = existingDocumentTemplateFields.map((field) => ({
      orgId: field.orgId,
      documentTemplateId,
      name: field.name,
      type: field.type,
      isRequired: field.isRequired,
      isDeleted: field.isDeleted,
      order: field.order,
    }));
    newDocumentTemplateFields.forEach(async (field) => {
      await odata.o(rootGetters, token)
        .post('documentTemplateFields', field)
        .query();
    });
    dispatch('LOAD_documentTemplates');
  },
};

export default { ...actions, ...customActions };
