var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-skeleton-loader",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    transition: "expand-transition",
                    type: "article",
                  },
                },
                [
                  _c("FormClient", {
                    attrs: { Client: _vm.editClient, isSaving: _vm.isSaving },
                    on: { save: _vm.save, cancel: _vm.cancel },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto",
                      attrs: { loading: _vm.isLoading },
                    },
                    [
                      _c("v-card-title", { attrs: { "primary-title": "" } }, [
                        _vm._v(" Existing Job Listings "),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-list",
                            _vm._l(
                              _vm.jobListings,
                              function (jobListing, index) {
                                return _c(
                                  "v-list-item-content",
                                  { key: index },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          "data-cy": `JobListing-link-${index}`,
                                          to: {
                                            name: "clients-ViewJobListing",
                                            query: {
                                              jobListingId: jobListing.id,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(jobListing.name) +
                                            " (" +
                                            _vm._s(jobListing.status) +
                                            ") "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }