<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-center">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormCompletedTask-name"
            :label="$$t('name')"
            v-model="localCompletedTask.name"
          />
        </v-col>
      </v-row>
      <!-- <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormCompletedTask-defaultValue"
              :label="$$t('defaultValue')"
              v-model="localCompletedTask.defaultValue"
            />
          </v-col>
        </v-row> -->
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormCompletedTask-defaultDueDate"
            label="Due in (days)"
            v-model="localCompletedTask.defaultDueDate"
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-end mb-6">
        <v-btn
          color="default"
          outlined
          class="ml-4 btn-cancel"
          @click="cancel"
          data-cy="FormCompletedTask-button-cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          class="ml-4"
          data-cy="FormCompletedTask-button-save">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormCompletedTask',
  module: 'CompletedTasks',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    CompletedTask: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localCompletedTask
      */
      localCompletedTask: {},
    };
  },
  watch: {
    CompletedTask() {
      this.localCompletedTask = this.CompletedTask;
    },
  },
  created() {
    this.localCompletedTask = this.CompletedTask;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localCompletedTask);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormCompletedTask example

'''js
<FormCompletedTask :CompletedTask="CompletedTask" />
'''
</docs>
