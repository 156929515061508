<template>
  <v-textarea
    :label="label"
    v-model="localValue"
    rows="4"
    auto-grow
    disabled
    outlined
  >
    <template v-slot:append>
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            small
          >
            <v-icon>{{ mdiPencil }}</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title primary-title>
            Edit
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="localValue"
              rows="4"
              auto-grow
              :label="label"
              outlined
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="updateValue"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-textarea>
</template>

<script>
import { mdiPencil } from '@mdi/js';

export default {
  name: 'ModalInlineTextArea',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      localValue: '',
      mdiPencil,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.localValue = value;
      },
    },
  },
  created() {
    this.localValue = this.value;
  },
  methods: {
    updateValue() {
      this.$emit('input', this.localValue);
      this.dialog = false;
    },
  },
};
</script>
