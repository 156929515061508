<template>
  <v-container
    fluid
    grid-list-xl
    fill-height
  >
    <v-layout
      justify-center
      align-center
    >
      <v-flex xs12>
        <v-skeleton-loader
          :loading="localConfig === undefined"
          transition="scale-transition"
          type="list-item-two-line"
        >
          <v-card>
            <v-card-text v-if="localConfig">
              <v-form
                :lazy-validation="false"
              >
                <v-text-field
                  label="From Address"
                  placeholder="email@email.com"
                  v-model="localConfig.fromAddress"
                />
                <v-text-field
                  label="Reply to Address"
                  placeholder="email@email.com"
                  v-model="localConfig.replyAddress"
                />
                <v-text-field
                  label="Host Name"
                  placeholder="yourdomain.name"
                  v-model="localConfig.host"
                />
                <v-checkbox label="Use SSL" v-model="localConfig.isSSL" />
                <v-text-field
                  label="Port"
                  type="number"
                  placeholder=""
                  v-model="localConfig.port"
                />
                <v-text-field
                  label="Username"
                  placeholder=""
                  v-model="localConfig.user"
                />
                <v-text-field
                  label="Password"
                  type="password"
                  placeholder=""
                  v-model="localConfig.password"
                />
                <v-btn @click="save">Save</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/**
  * View for managing the mail server settings
*/
import { mapActions, mapState } from 'vuex';

export default {
  name: 'MailServer',
  data() {
    return {
      localConfig: undefined,
    };
  },
  computed: {
    ...mapState('mailServerSettings', ['config']),
  },
  watch: {
    config() {
      this.localConfig = this.config;
    },
  },
  created() {
    this.loadConfig();
  },
  methods: {
    ...mapActions('mailServerSettings', ['loadConfig', 'saveConfig']),
    save() {
      this.saveConfig(this.localConfig).then(() => {
        this.$refs.auroraSnackbar.show('Saved successfully');
      });
    },
  },
};

</script>
