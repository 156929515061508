var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: {
            color: "primary",
            icon: "",
            small: "",
            loading: _vm.isLoading,
          },
          on: { click: _vm.takeScreenshot },
        },
        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiMonitorScreenshot))])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }