<template>
  <div
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <v-btn
      v-if="hover"
      v-bind="$props"
      :data-cy="dataCy"
      v-on="$listeners"
      class="fade-in"
    >
      <slot name="label">
        <span :class="{ 'is-visible': hover }">{{ label }}</span>
      </slot>
      <slot name="icon">
        <v-icon small :class="{ 'mr-2': hover }">{{ labelIcon }}</v-icon>
      </slot>
    </v-btn>
    <span v-else>{{ label }}</span>
  </div>
</template>

<script>
import { VBtn } from 'vuetify/lib';
import { mdiPlus } from '@mdi/js';

export default {
  name: 'HoverButton',
  extends: VBtn,
  props: {
    labelIcon: {
      type: String,
      default: mdiPlus,
    },
    label: {
      type: String,
      default: 'Add',
    },
    dataCy: {
      type: String,
      default: 'ExpandingButton',
    },
  },
  data() {
    return {
      mdiPlus,
      hover: false,
    };
  },
};
</script>
