<template>
  <v-btn
    v-bind="$props"
    :data-cy="dataCy"
    v-on="$listeners"
    @click.stop="onClick"
    class="confirm-button"
    :title="isConfirm ? 'Click again to confirm' : title"
    :class="{ 'is-confirming': isConfirm }"
  >
    <slot name="confirm" v-if="isConfirm">
      <v-progress-circular
        :class="{ 'mr-2': !icon }"
        :value="progressValue"
        :size="icon ? 26 : 20"
      >
        <span v-if="icon">?</span>
      </v-progress-circular>
      <span v-if="!icon">{{ confirmationText }}</span>
    </slot>
    <slot v-else />
  </v-btn>
</template>

<script>
import { VBtn } from 'vuetify/lib';
import { mdiAlertCircle } from '@mdi/js';

export default {
  name: 'ConfirmButton',
  extends: VBtn,
  props: {
    confirmationText: {
      type: String,
      default: 'Confirm?',
    },
    dataCy: {
      type: String,
      default: 'ConfirmButton',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Click to delete',
    },
  },
  data() {
    return {
      mdiAlertCircle,
      // Tracks if the confirmation has been made
      isConfirm: false,
      progressValue: 0,
      interval: {},
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    onClick() {
      if (!this.isConfirm) {
        // First click: ask for confirmation
        this.isConfirm = true;
        this.progressValue = 0;
        // Optionally, reset isConfirmed after a timeout
        setTimeout(() => {
          this.isConfirm = false;
          clearInterval(this.interval);
          this.progressValue = 0;
        }, 5000); // Reset after 5 seconds
        this.interval = setInterval(() => {
          this.progressValue += 10;
        }, 500);
      } else {
        // Second click: confirmation received, emit the click event
        this.$emit('confirm-click');
        this.isConfirm = false; // Reset the confirmation state
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style scoped>
.confirm-button {
  position: relative;
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
  animation: progressAnimation 3s linear;
}

@keyframes progressAnimation {
  from {
    background-size: 0 100%;
  }
  to {
    background-size: 100% 100%;
  }
}

.is-confirming .progress-bar {
  display: block;
}
</style>
