var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _vm.isLoading ? _c("ProgressLinearTimeout") : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.jobListingStatuses,
                      "item-text": "name",
                      "item-value": "name",
                      filled: "",
                      required: "",
                      "data-cy": "FormJobListing-status",
                      label: _vm.$$t("status"),
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.localJobListing.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.localJobListing, "status", $$v)
                      },
                      expression: "localJobListing.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      required: "",
                      "data-cy": "FormJobListing-name",
                      label: _vm.$$t("name"),
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.localJobListing.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.localJobListing, "name", $$v)
                      },
                      expression: "localJobListing.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormJobListing-projectNumber",
                      label: _vm.$$t("projectNumber"),
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.localJobListing.projectNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.localJobListing, "projectNumber", $$v)
                      },
                      expression: "localJobListing.projectNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormJobListing-responsibleUserId",
                      label: _vm.$$t("responsibleUserId"),
                      items: _vm.sortedUsers,
                      "item-text": "displayName",
                      "item-value": "email",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.localJobListing.responsibleUserId,
                      callback: function ($$v) {
                        _vm.$set(_vm.localJobListing, "responsibleUserId", $$v)
                      },
                      expression: "localJobListing.responsibleUserId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "data-cy": "FormJobListing-clientId",
                      filled: "",
                      items: _vm.availableCompanies,
                      "item-text": "name",
                      "item-value": "id",
                      label: _vm.$$t("contact"),
                      rules: [_vm.rules.required],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-inner",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: "",
                                  small: "",
                                  href: "https://app.hubspot.com/contacts/1883769/objects/0-2/views/all/list",
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                },
                              },
                              [_vm._v(" Add ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-list-item-icon",
                              [
                                item.type === "Client"
                                  ? _c("v-icon", [
                                      _vm._v(_vm._s(_vm.mdiOfficeBuilding)),
                                    ])
                                  : item.type === "HubSpot"
                                  ? _c("v-icon", [
                                      _vm._v(_vm._s(_vm.mdiHubspot)),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("v-list-item-content", [
                              _vm._v(" " + _vm._s(item.name) + " "),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedClientId,
                      callback: function ($$v) {
                        _vm.selectedClientId = $$v
                      },
                      expression: "selectedClientId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormJobListing-description",
                      label: _vm.$$t("description"),
                    },
                    model: {
                      value: _vm.localJobListing.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.localJobListing, "description", $$v)
                      },
                      expression: "localJobListing.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      filled: "",
                      items: _vm.industries,
                      "item-text": "name",
                      "item-value": "id",
                      label: _vm.$$t("industryId"),
                    },
                    model: {
                      value: _vm.localJobListing.industryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.localJobListing, "industryId", $$v)
                      },
                      expression: "localJobListing.industryId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("SelectTeam", {
                    model: {
                      value: _vm.localJobListing.teamId,
                      callback: function ($$v) {
                        _vm.$set(_vm.localJobListing, "teamId", $$v)
                      },
                      expression: "localJobListing.teamId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.localJobListing.teamId
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("SelectTeamChannel", {
                        attrs: { "team-id": _vm.localJobListing.teamId },
                        model: {
                          value: _vm.localJobListing.teamChannelId,
                          callback: function ($$v) {
                            _vm.$set(_vm.localJobListing, "teamChannelId", $$v)
                          },
                          expression: "localJobListing.teamChannelId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormJobListing-notes",
                      label: _vm.$$t("notes"),
                    },
                    model: {
                      value: _vm.localJobListing.notes,
                      callback: function ($$v) {
                        _vm.$set(_vm.localJobListing, "notes", $$v)
                      },
                      expression: "localJobListing.notes",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between mb-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-cancel",
                  attrs: {
                    color: "default",
                    outlined: "",
                    "data-cy": "FormJobListing-button-cancel",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.cancel")) + " ")]
              ),
              _vm.mode === "add"
                ? _c("v-checkbox", {
                    attrs: { label: "Add to favourites" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("update:addToFavourites", $event)
                      },
                    },
                    model: {
                      value: _vm.localAddToFavourites,
                      callback: function ($$v) {
                        _vm.localAddToFavourites = $$v
                      },
                      expression: "localAddToFavourites",
                    },
                  })
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    loading: _vm.isSaving,
                    "data-cy": "FormJobListing-button-save",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }