var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mb-10", attrs: { loading: _vm.isLoading } },
        [
          _c(
            "v-tabs",
            {
              attrs: { color: "primary", dark: "", "slider-color": "primary" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tab", [_vm._v(" Active ")]),
              _c("v-tab", [_vm._v(" Archived ")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1 mt-1",
                  attrs: { color: "primary", icon: "" },
                  on: { click: _vm.refresh },
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiRefresh))])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c("ListFeedbackItems", {
                    attrs: { items: _vm.activeFeedbackItems },
                    on: { refresh: _vm.refresh },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c("ListFeedbackItems", {
                    attrs: { items: _vm.inActiveFeedbackItems },
                    on: { refresh: _vm.refresh },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card-text"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }