<template>
  <v-tabs :loading="isLoading">
    <v-tab>
      <v-icon class="mr-1">{{ mdiComment }}</v-icon>
      Comment
    </v-tab>
    <v-tab>
      <v-icon class="mr-1">{{ mdiEmail }}</v-icon>
      Email
    </v-tab>
    <v-tab>
      <v-icon class="mr-1">{{ mdiMessageProcessing }}</v-icon>
      Text
    </v-tab>
    <v-tab>
      <v-icon class="mr-1">{{ mdiWhatsapp }}</v-icon>
      WhatsApp
    </v-tab>
    <v-tab>
      <v-icon class="mr-1">{{ mdiPhone }}</v-icon>
      Phone Call
    </v-tab>

    <v-tab-item>
      <v-form>
        <v-textarea
          v-model="form.message"
          label="Message"
          counter="2000"
          limit="2000"
        >
          <template v-slot:append>
            <AutoResponse @input="form.message = $event" />
          </template>
        </v-textarea>
        <v-btn
          :disabled="form.message?.length > 2000"
          @click="sendMessage('comment')">
          Comment
        </v-btn>
      </v-form>
    </v-tab-item>
    <v-tab-item>
      <v-form>
        <v-text-field
          v-model="form.email"
          label="Email Address"
        />
        <v-text-field
          v-model="form.subject"
          label="Subject"
        />
        <div class="d-flex justify-space-between mb-2">
          <EmailAttachments v-model="form.attachments" />
          <AutoResponse @input="insertHtmlAutoResponse" />
        </div>
        <vue-editor
          v-model="form.htmlMessage"
          class="black--text"
          label="Message"
          auto-grow
          rows="5"
        >
          <template v-slot:append />
        </vue-editor>
        <div class="d-flex justify-space-between mt-2">
          <PreviewEmail label="Preview" :message="emailMessage" />
          <v-btn @click="sendMail">
            <v-icon class="mr-2">
              {{ mdiEmailFast }}
            </v-icon>
            Send
          </v-btn>
        </div>
        <v-checkbox
          v-model="form.saveOnly"
          label="Save Only" />
      </v-form>

    </v-tab-item>

    <v-tab-item>
      <v-form>
        <v-select
          :items="twilioNumbers"
          :item-text="i => `${i.country} - ${i.number}`"
          :item-value="i => i.number"
          v-model="fromNumber"
          @change="form.from = fromNumber"
          label="From Number"
        />
        <v-text-field
          v-model="form.recipient"
          @change="onPhoneNumberChanged"
          label="Phone number"
          placeholder="Recipient's phone number"
        />
        <v-textarea
          v-model="form.message"
          auto-grow
          label="Message"
          placeholder="Body of your message"
        >
          <template v-slot:append>
            <AutoResponse @input="form.message = $event" />
          </template>
        </v-textarea>
        <v-btn :disabled="!fromNumber" @click="sendMessage('text')">
          Send
        </v-btn>
        <v-checkbox v-model="form.saveOnly" label="Save Only" />
      </v-form>
    </v-tab-item>

    <v-tab-item>
      <v-form>
        <v-select
          :items="twilioNumbers"
          v-model="fromNumber"
          @change="form.from = fromNumber"
          :item-text="i => `${i.country} - ${i.number}`"
          :item-value="i => i.number"
          label="From Number"
        />
        <v-text-field
          v-model="form.recipient"
          label="Phone number"
          placeholder="Recipient's phone number"
          @change="onPhoneNumberChanged"
        />
        <v-textarea
          v-model="form.message"
          auto-grow
          label="Message"
          placeholder="Body of your message"
        >
          <template v-slot:append>
            <AutoResponse @input="form.message = $event" />
          </template>
        </v-textarea>
        <v-btn :disabled="!fromNumber" @click="sendMessage('whatsApp')">
          Send
        </v-btn>
        <v-checkbox v-model="form.saveOnly" label="Save Only" />
      </v-form>
    </v-tab-item>

    <v-tab-item>
      <v-form>
        <v-textarea
          v-model="form.message"
          auto-grow
          label="Comments"
          placeholder="Comments on the call"
        >
          <template v-slot:append>
            <AutoResponse @input="form.message = $event" />
          </template>
        </v-textarea>
        <v-btn @click="sendMessage('phoneCall')">
          Send
        </v-btn>
        <v-checkbox label="Save Only" />
      </v-form>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import {
  mdiComment, mdiEmail, mdiMessageProcessing, mdiWhatsapp, mdiPhone, mdiEmailFast,
} from '@mdi/js';
import userProfile from '@/mixins/userProfile';
import AutoResponse from './AutoResponse.vue';
import EmailAttachments from './EmailAttachments.vue';
import PreviewEmail from './PreviewEmail.vue';
import baseTemplate from './EmailTemplate.txt';

export default {
  name: 'SendMessage',
  components: {
    AutoResponse,
    EmailAttachments,
    PreviewEmail,
  },
  props: {
    jobListingCandidate: {
      type: Object,
      default: undefined,
    },
  },
  mixins: [loading, userProfile],
  data() {
    return {
      mdiComment,
      mdiEmail,
      mdiEmailFast,
      mdiMessageProcessing,
      mdiWhatsapp,
      mdiPhone,
      form: {
        email: '',
        from: this.defaultFromPhoneNumber,
        subject: '',
        htmlMessage: '',
        message: '',
        recipient: '',
        saveOnly: false,
      },
    };
  },
  computed: {
    ...mapState('controls', ['defaultFromPhoneNumber']),
    ...mapState('twilioNumbers', ['twilioNumbers']),
    emailMessage() {
      const { subject, htmlMessage } = this.form;
      const emailMessage = baseTemplate
        .replace('{{ body }}', htmlMessage)
        .replace('{{ signature }}', this.userProfile?.emailTemplate);
      return {
        title: subject,
        subtitle: emailMessage,
      };
    },
    /**
     * Computed property for the language vuex property
     */
    fromNumber: {
      get() {
        return this.defaultFromPhoneNumber;
      },
      set(newValue) {
        this.SET_defaultFromPhoneNumber(newValue);
      },
    },
  },
  mounted() {
    this.loadProfile();
    this.form.email = this.jobListingCandidate?.candidate?.email ?? '';
    this.form.recipient = this.jobListingCandidate?.candidate?.phone ?? '';
  },
  methods: {
    ...mapMutations('controls', ['SET_defaultFromPhoneNumber']),
    ...mapActions('candidates', ['UPDATE_candidate']),
    insertAutoResponse(value) {
      this.form.message += value;
    },
    insertHtmlAutoResponse(value) {
      this.form.htmlMessage += value;
    },
    async onPhoneNumberChanged() {
      // update the recipient phone number
      const { recipient } = this.form;
      const { candidate } = this.jobListingCandidate;
      if (recipient !== candidate.phone) {
        await this.UPDATE_candidate({
          id: candidate.id,
          phone: recipient,
        });
      }
    },
    sendMessage(type) {
      this.form.from = this.fromNumber;
      this.$emit(type, this.form);
      this.form.message = '';
    },
    sendMail() {
      const {
        email, subject, saveOnly, attachments,
      } = this.form;
      const { emailMessage } = this;
      this.$emit('email', {
        email,
        subject,
        message: emailMessage.subtitle,
        attachments,
        saveOnly,
      });
      this.form.message = '';
      this.form.subject = '';
      this.form.attachments = [];
      this.form.htmlMessage = '';
    },
  },
};
</script>
