var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "mt-6", attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { lg: "8" } },
            [
              _c(
                "v-card",
                { staticClass: "rounded-0 ma-3 pa-4", attrs: { flat: "" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-3",
                          attrs: { justify: "center", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "6" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "text-center justify-center py-",
                                    },
                                    [
                                      _c(
                                        "h1",
                                        {
                                          staticClass:
                                            "font-weight-bold title mb-3 text-capitalize",
                                        },
                                        [_vm._v(" Password Reset ")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          filled: "",
                                          rules: [_vm.rules.required],
                                          label: "Email Address",
                                          name: "username",
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.username,
                                          callback: function ($$v) {
                                            _vm.username = $$v
                                          },
                                          expression: "username",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          filled: "",
                                          "append-icon": _vm.showPassword
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          rules: [
                                            _vm.rules.required,
                                            _vm.rules.passwordMin,
                                          ],
                                          type: _vm.showPassword
                                            ? "text"
                                            : "password",
                                          name: "password",
                                          label: "New Password",
                                          counter: "",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.showPassword = !_vm.showPassword
                                          },
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function ($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password",
                                        },
                                      }),
                                      _vm._l(_vm.errors, function (error) {
                                        return _c(
                                          "v-alert",
                                          {
                                            key: error,
                                            attrs: { type: "error" },
                                          },
                                          [_vm._v(" " + _vm._s(error) + " ")]
                                        )
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            block: "",
                                            loading: _vm.isLoading,
                                          },
                                          on: { click: _vm.reset },
                                        },
                                        [_vm._v(" Reset ")]
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }