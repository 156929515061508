<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-skeleton-loader
          :loading="isLoading"
          transition="expand-transition"
          type="article"
        >
          <FormTwilioNumber
            :TwilioNumber="editTwilioNumber"
            :isSaving="isSaving"
            @save="save"
            @cancel="cancel" />
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormTwilioNumber from '../components/FormTwilioNumber.vue';

export default {
  components: {
    FormTwilioNumber,
  },
  name: 'EditTwilioNumber',
  module: 'TwilioNumbers',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.mobileNumbers',
        to: { name: 'twilioNumbers-ListTwilioNumber' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing TwilioNumber. Default to {}.
      */
      editTwilioNumber: {},
      /**
       * Query Id for TwilioNumber
       */
      twilioNumberId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('twilioNumbers', ['twilioNumbers', 'twilioNumber']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.twilioNumberId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_industries();
    if (this.mode === 'edit') {
      await this.LOAD_twilioNumber(this.twilioNumberId);
      this.editTwilioNumber = this.twilioNumber;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('twilioNumbers', ['LOAD_twilioNumber', 'CREATE_twilioNumber', 'UPDATE_twilioNumber']),
    ...mapActions('industries', ['LOAD_industries']),
    /** Method used to save the item
     */
    async save(twilioNumber) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_twilioNumber(twilioNumber);
      } else {
        await this.UPDATE_twilioNumber(twilioNumber);
      }
      this.isSaving = false;
      if (this.from.name) {
        this.$router.push({ name: this.from.name, query: this.from.query });
      } else {
        this.$router.push({ name: 'twilioNumbers-ListTwilioNumber' });
      }
    },
    cancel() {
      if (this.from.name) {
        this.$router.push({ name: this.from.name, query: this.from.query });
      } else {
        this.$router.push({ name: 'twilioNumbers-ListTwilioNumber' });
      }
    },
  },
};
</script>

<docs>
# EditTwilioNumber example

'''js
<EditTwilioNumber :TwilioNumber="TwilioNumber" />
'''
</docs>
