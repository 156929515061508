import { odata } from '../../conf';
import Actions from '../../odata/actions';
import { useAuth } from '../../../use';

const auth = useAuth();

const actions = new Actions('candidate', 'candidates');

const customActions = {
  async LOAD_candidates({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('candidates')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Industry',
        $orderby: 'Name',
      });
    commit('SET_candidates', data);
    return data;
  },
  async LOAD_candidatesMin({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('candidates')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Industry',
        $orderby: 'Name',
        $select: 'Id,Name,Email,Phone,Location,Created,CreatedBy,Updated,LinkedInUrl',
      });
    commit('SET_candidates', data);
    return data;
  },
};

export default { ...actions, ...customActions };
