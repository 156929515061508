var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: { icon: "", color: _vm.colour, loading: _vm.isLoading },
      on: { click: _vm.toggle },
    },
    [
      _c("v-icon", { attrs: { color: _vm.colour } }, [
        _vm._v(" " + _vm._s(_vm.icon) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }