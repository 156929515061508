var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredItems,
          loading: _vm.isLoading,
          "show-expand": "",
          expanded: _vm.expanded,
          "sort-by": "created",
          "sort-desc": "",
        },
        on: {
          "update:expanded": function ($event) {
            _vm.expanded = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mt-5" },
                      [
                        _vm.showStaffFilter
                          ? _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    "data-cy": "ListFeedbackItems-staff",
                                    "prepend-icon": _vm.mdiFilter,
                                    label: "Staff",
                                    items: _vm.users,
                                    "item-text": "email",
                                    "item-value": "email",
                                    multiple: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.selectedStaff,
                                    callback: function ($$v) {
                                      _vm.selectedStaff = $$v
                                    },
                                    expression: "selectedStaff",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-select", {
                              attrs: {
                                "data-cy": "ListFeedbackItems-status",
                                label: "Status",
                                items: _vm.statuses,
                                multiple: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.selectedStatus,
                                callback: function ($$v) {
                                  _vm.selectedStatus = $$v
                                },
                                expression: "selectedStatus",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-select", {
                              attrs: {
                                "data-cy": "ListFeedbackItems-priority",
                                label: "Priority",
                                items: _vm.priorities,
                                multiple: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.selectedPriority,
                                callback: function ($$v) {
                                  _vm.selectedPriority = $$v
                                },
                                expression: "selectedPriority",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-select", {
                              attrs: {
                                "data-cy": "ListFeedbackItems-feedbackTypes",
                                label: "Type",
                                items: _vm.feedbackTypes,
                                multiple: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.selectedFeedbackType,
                                callback: function ($$v) {
                                  _vm.selectedFeedbackType = $$v
                                },
                                expression: "selectedFeedbackType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("ExportToExcelButton", {
                      attrs: {
                        name: "New Business Log",
                        data: _vm.exportItems,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.created",
            fn: function ({ item }) {
              return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")]
            },
          },
          {
            key: "item.appSection",
            fn: function (props) {
              return [
                _c(
                  "v-edit-dialog",
                  {
                    attrs: { large: "", "return-value": props.item.appSection },
                    on: {
                      "update:returnValue": function ($event) {
                        return _vm.$set(props.item, "appSection", $event)
                      },
                      "update:return-value": function ($event) {
                        return _vm.$set(props.item, "appSection", $event)
                      },
                      save: function ($event) {
                        return _vm.save(props.item)
                      },
                      cancel: _vm.cancel,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.appSections,
                                  label: "Module",
                                },
                                model: {
                                  value: props.item.appSection,
                                  callback: function ($$v) {
                                    _vm.$set(props.item, "appSection", $$v)
                                  },
                                  expression: "props.item.appSection",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_vm._v(" " + _vm._s(props.item.appSection) + " ")]
                ),
              ]
            },
          },
          {
            key: "item.feedbackType",
            fn: function (props) {
              return [
                _c(
                  "v-edit-dialog",
                  {
                    attrs: {
                      large: "",
                      "return-value": props.item.feedbackType,
                    },
                    on: {
                      "update:returnValue": function ($event) {
                        return _vm.$set(props.item, "feedbackType", $event)
                      },
                      "update:return-value": function ($event) {
                        return _vm.$set(props.item, "feedbackType", $event)
                      },
                      save: function ($event) {
                        return _vm.save(props.item)
                      },
                      cancel: _vm.cancel,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.feedbackTypes,
                                  label: "Type",
                                },
                                model: {
                                  value: props.item.feedbackType,
                                  callback: function ($$v) {
                                    _vm.$set(props.item, "feedbackType", $$v)
                                  },
                                  expression: "props.item.feedbackType",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_vm._v(" " + _vm._s(props.item.feedbackType) + " ")]
                ),
              ]
            },
          },
          {
            key: "item.status",
            fn: function (props) {
              return [
                _c(
                  "v-edit-dialog",
                  {
                    attrs: { large: "", "return-value": props.item.status },
                    on: {
                      "update:returnValue": function ($event) {
                        return _vm.$set(props.item, "status", $event)
                      },
                      "update:return-value": function ($event) {
                        return _vm.$set(props.item, "status", $event)
                      },
                      save: function ($event) {
                        return _vm.save(props.item)
                      },
                      cancel: _vm.cancel,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c("v-select", {
                                attrs: { items: _vm.statuses, label: "Status" },
                                model: {
                                  value: props.item.status,
                                  callback: function ($$v) {
                                    _vm.$set(props.item, "status", $$v)
                                  },
                                  expression: "props.item.status",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_vm._v(" " + _vm._s(props.item.status) + " ")]
                ),
              ]
            },
          },
          {
            key: "item.priority",
            fn: function (props) {
              return [
                _c(
                  "v-edit-dialog",
                  {
                    attrs: { large: "", "return-value": props.item.priority },
                    on: {
                      "update:returnValue": function ($event) {
                        return _vm.$set(props.item, "priority", $event)
                      },
                      "update:return-value": function ($event) {
                        return _vm.$set(props.item, "priority", $event)
                      },
                      save: function ($event) {
                        return _vm.save(props.item)
                      },
                      cancel: _vm.cancel,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.priorities,
                                  label: "Priority",
                                },
                                model: {
                                  value: props.item.priority,
                                  callback: function ($$v) {
                                    _vm.$set(props.item, "priority", $$v)
                                  },
                                  expression: "props.item.priority",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_vm._v(" " + _vm._s(props.item.priority) + " ")]
                ),
              ]
            },
          },
          {
            key: "item.estimatedDate",
            fn: function (props) {
              return [
                _c(
                  "v-edit-dialog",
                  {
                    attrs: {
                      large: "",
                      "return-value": props.item.estimatedDate,
                    },
                    on: {
                      "update:returnValue": function ($event) {
                        return _vm.$set(props.item, "estimatedDate", $event)
                      },
                      "update:return-value": function ($event) {
                        return _vm.$set(props.item, "estimatedDate", $event)
                      },
                      save: function ($event) {
                        return _vm.save(props.item)
                      },
                      cancel: _vm.cancel,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "pa-2" },
                                [
                                  _c("DatePicker", {
                                    attrs: {
                                      label: "Estimated Date",
                                      dense: "",
                                    },
                                    model: {
                                      value: props.item.estimatedDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          props.item,
                                          "estimatedDate",
                                          $$v
                                        )
                                      },
                                      expression: "props.item.estimatedDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    props.item.estimatedDate
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatDate(props.item.estimatedDate)) +
                              " "
                          ),
                        ])
                      : _c("span", [_vm._v(" No date ")]),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.createdBy",
            fn: function ({ item }) {
              return [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `mailto:${item.createdBy}?subject=${_vm.truncate(
                        item.message,
                        20
                      )}`,
                    },
                  },
                  [_vm._v(" " + _vm._s(item.createdBy) + " ")]
                ),
              ]
            },
          },
          {
            key: "item.comments",
            fn: function ({ item }) {
              return [
                _c(
                  "v-badge",
                  { attrs: { overlap: "", color: "secondary" } },
                  [
                    _c("span", { attrs: { slot: "badge" }, slot: "badge" }, [
                      _vm._v(_vm._s(item.comments.length)),
                    ]),
                    _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiComment) + " ")]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.screenshot",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", color: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.showScreenshot(item)
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.mdiImage))])],
                  1
                ),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", color: "default", small: "" },
                    on: {
                      click: function ($event) {
                        return _vm.completeItem(item)
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.mdiArchive))])],
                  1
                ),
              ]
            },
          },
          {
            key: "item.actions2",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { "x-small": "", color: "error", small: "" },
                    on: {
                      click: function ($event) {
                        return _vm.remove(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common.buttons.delete")) + " ")]
                ),
              ]
            },
          },
          {
            key: "expanded-item",
            fn: function ({ headers, item }) {
              return [
                _c(
                  "td",
                  { attrs: { colspan: headers.length } },
                  [_c("FeedbackComments", { attrs: { feedbackItem: item } })],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { transition: "dialog-bottom-transition" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { elevation: "3" } },
            [
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _c("img", { attrs: { src: _vm.screenshot } }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }