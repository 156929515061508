/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { ref, computed } from 'vue';
import * as Msal from 'msal';
import { useTrace } from './useTrace';

const tracer = useTrace();

const baseScope = ['api://302098e9-1ec8-438d-b444-1f65d1352158/BrightFuture.Read'];

export const useAuth = () => {
  const config = {
    auth: {
      clientId: process.env.VUE_APP_AZURE_IDENTITY_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_IDENTITY_TENANT_ID}`,
      redirectUri: `${process.env.VUE_APP_APPURL}/auth/login`,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    storeAuthStateInCookie: 'true',
  };

  const userAgentApplication = new Msal.UserAgentApplication(config);

  const isAuthenticated = ref(false);
  const user = ref(null);

  async function getScopedAccessToken(scopes) {
    const scopedAccessSpan = tracer.startSpan('getScopedAccessToken');
    const tokenRequest = {
      scopes,
    };

    const span = tracer.startSpan('acquireTokenSilent');
    span.spanContext().traceId = scopedAccessSpan?.spanContext()?.traceId;
    try {
      span.setAttribute('scopes', scopes);
      span.addEvent('acquireTokenSilent');
      const tokenResponse = await userAgentApplication.acquireTokenSilent(tokenRequest);
      if (tokenResponse) {
        window.localStorage.setItem('access_token', tokenResponse.accessToken);
        span.addEvent('acquireTokenSilent success');
        span.end();
        return tokenResponse.accessToken;
      }
    } catch (error) {
      span.setStatus('ERROR');
      span.addEvent('acquireTokenSilent failed');
      console.error(error);
      span.end();
      // acquireTokenSilent failed, fallback to acquireTokenRedirect
      const innerSpan = tracer.startSpan('acquireTokenRedirect');
      innerSpan.spanContext().traceId = scopedAccessSpan?.spanContext()?.traceId;
      try {
        innerSpan.addEvent('acquireTokenRedirect');
        tokenRequest.redirectUri = `${process.env.VUE_APP_APPURL}/auth/login`;
        const tokenResponseFallback = await userAgentApplication.acquireTokenRedirect(tokenRequest);
        window.localStorage.setItem('access_token', tokenResponseFallback.accessToken);
        innerSpan.addEvent('acquireTokenRedirect success');
        innerSpan.end();
        return tokenResponseFallback.accessToken;
      } catch (innerError) {
        innerSpan.setStatus('ERROR');
        innerSpan.addEvent('acquireTokenRedirect failed');
        innerSpan.end();
        console.error(error);
      }
    }
    console.warn('no token found, returning nothing');
    scopedAccessSpan.end();
    return null;
  }

  async function getAccessToken() {
    const accessTokenSpan = tracer.startSpan('getAccessToken');
    const tokenRequest = {
      scopes: baseScope,
      forceRefresh: false,
    };
    accessTokenSpan.setAttribute('scopes', baseScope);
    accessTokenSpan.addEvent('acquireTokenSilent');
    try {
      const tokenResponse = await userAgentApplication.acquireTokenSilent(tokenRequest);
      if (tokenResponse) {
        accessTokenSpan.addEvent('acquireTokenSilent success');
        window.localStorage.setItem('access_token', tokenResponse.accessToken);
        accessTokenSpan.end();
        return tokenResponse.accessToken;
      }
    } catch (error) {
      accessTokenSpan.setStatus('ERROR');
      accessTokenSpan.addEvent('acquireTokenSilent failed');
      accessTokenSpan.end();
      console.error(error);
      // acquireTokenSilent failed, fallback to acquireTokenRedirect
      const innerSpan = tracer.startSpan('acquireTokenRedirect');
      try {
        innerSpan.spanContext().traceId = accessTokenSpan?.spanContext()?.traceId;
        innerSpan.addEvent('acquireTokenRedirect');
        tokenRequest.redirectUri = `${process.env.VUE_APP_APPURL}/auth/login`;
        const tokenResponseFallback = await userAgentApplication.acquireTokenRedirect(tokenRequest);
        innerSpan.addEvent('acquireTokenRedirect success');
        innerSpan.end();
        window.localStorage.setItem('access_token', tokenResponseFallback.accessToken);
        return tokenResponseFallback.accessToken;
      } catch (innerError) {
        console.error(innerError);
        innerSpan.setStatus('ERROR');
        innerSpan.addEvent('acquireTokenRedirect failed');
        innerSpan.end();
        console.error(error);
      }
    }
    console.warn('no token found, returning nothing');
    return null;
  }

  async function handleLoginRedirect() {
    console.debug('handle redirect');
    const account = await userAgentApplication.handleRedirectPromise();
    console.debug(account);
    if (account) {
      isAuthenticated.value = true;
      user.value = account;
      return true;
    }

    return false;
  }

  function login() {
    const loginSpan = tracer.startSpan('login');
    try {
      const loginRequest = {
        scopes: ['openid', 'profile', 'User.Read', 'offline_access', 'api://302098e9-1ec8-438d-b444-1f65d1352158/BrightFuture.Read Team.ReadBasic.All'],
        redirectUri: `${process.env.VUE_APP_APPURL}/auth/login`,
      };
      loginSpan.addEvent('loginRedirect');
      loginSpan.setAttribute('scopes', loginRequest.scopes);
      userAgentApplication.loginRedirect(loginRequest);
      isAuthenticated.value = true;
      user.value = userAgentApplication.getAccount();
      loginSpan.addEvent('loginRedirect success');
      loginSpan.setAttribute('user', user.value);
    } catch (error) {
      loginSpan.setStatus('ERROR');
      loginSpan.addEvent('loginRedirect failed');
      console.error(error);
    } finally {
      loginSpan.end();
    }
  }

  async function logout() {
    const logoutSpan = tracer.startSpan('logout');
    logoutSpan.addEvent('logout');
    logoutSpan.setAttribute('user', user.value);
    userAgentApplication.logout();
    isAuthenticated.value = false;
    user.value = null;
    logoutSpan.addEvent('logout success');
    logoutSpan.end();
  }

  const accessToken = computed(async () => {
    if (userAgentApplication.getAccount()) {
      try {
        const response = await userAgentApplication.acquireTokenSilent({ scopes: config.scopes });
        return response.accessToken;
      } catch (error) {
        console.error(error);
        try {
          const response = await userAgentApplication.acquireTokenPopup({ scopes: config.scopes });
          return response.accessToken;
        } catch (innerError) {
          console.error(innerError);
        }
      }
    }
    return null;
  });

  return {
    isAuthenticated,
    user,
    login,
    logout,
    getAccessToken,
    getScopedAccessToken,
    accessToken,
    userAgentApplication,
    handleLoginRedirect,
  };
};
