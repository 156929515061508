<template>
  <TableModalForm
    :headers="headers"
    :items="checklists"
    class="elevation-1"
    :loading="isLoading"
    name="Checklists"
    ref="table"
    :slots="[{ name: 'actions' }, { name: 'order' }]"
    sort-by="order"
    @creating="creating"
    @refresh="refresh"
    @item-selected="itemSelected"
  >
    <template v-slot:form>
      <FormChecklist
        :Checklist="editChecklist"
        :isSaving="isSaving"
        @save="save"
        @cancel="$refs.table.dialog = false" />
    </template>
    <template v-slot:order="{ item, index }">
      <v-btn icon @click="moveUp(item)">
        <v-icon v-if="index > 0" class="mr-2">{{ mdiArrowUpBold }}</v-icon>
      </v-btn>
      {{ item.order }}
      <v-btn icon @click="moveDown(item)">
        <v-icon v-if="index < (checklists.length - 1)" class="mr-2">{{ mdiArrowDownBold }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:actions="{ item }">
      <v-btn-toggle>
        <v-btn color="default" small @click="edit(item)">
          {{ $t('common.buttons.edit')}}
        </v-btn>
        <v-btn color="error" small @click="remove(item)">
          {{ $t('common.buttons.delete')}}
        </v-btn>
      </v-btn-toggle>
    </template>
  </TableModalForm>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiArrowUpBold, mdiArrowDownBold } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import TableModalForm from '@/components/TableModalForm.vue';
import FormChecklist from '../components/FormChecklist.vue';

export default {
  name: 'ListChecklist',
  module: 'Checklists',
  props: {
    ChecklistTemplate: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    FormChecklist,
    TableModalForm,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiArrowUpBold,
      mdiArrowDownBold,
      /**
      * Get/Set editing Checklist. Default to {}.
      */
      editChecklist: {},
      mode: 'add',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('checklists', ['checklists']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('order'),
          value: 'order',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('checklists', [
      'LOAD_checklist',
      'CREATE_checklist',
      'UPDATE_checklist',
      'LOAD_checklists',
      'DELETE_checklist',
    ]),
    creating() {
      this.editChecklist = {
        checklistTemplateId: this.ChecklistTemplate.id,
        order: this.checklists.length + 1,
      };
      this.mode = 'add';
    },
    async edit(item) {
      this.editChecklist = { ...item };
      this.mode = 'edit';
      this.$refs.table.dialog = true;
    },
    /**
     * Moves an item up in the list
     */
    async moveDown(item) {
      const { checklists } = this;
      const next = checklists.find((s) => s.order === (item.order + 1));
      const current = checklists.find((s) => s.order === item.order);
      next.order = item.order;
      current.order = item.order + 1;
      await this.UPDATE_checklist(next);
      await this.UPDATE_checklist(current);
      await this.refresh();
    },
    /**
     * Moves an item down in the list
     */
    async moveUp(item) {
      const { checklists } = this;
      const prev = checklists.find((s) => s.order === (item.order - 1));
      const current = checklists.find((s) => s.order === item.order);
      prev.order = item.order;
      current.order = item.order - 1;
      await this.UPDATE_checklist(prev);
      await this.UPDATE_checklist(current);
      await this.refresh();
    },
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_checklists();
      this.isLoading = false;
    },
    /** Delete Checklist from the collection
     */
    async remove(checklist) {
      this.isLoading = true;
      await this.DELETE_checklist(checklist.id);
      this.isLoading = false;
    },
    /** Vuex methods
     */
    /** Method used to save the item
     */
    async save(checklist) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_checklist(checklist);
      } else {
        await this.UPDATE_checklist(checklist);
      }
      this.isSaving = false;
      this.$refs.table.dialog = false;
    },
    itemSelected(item) {
      this.$emit('selected', item);
    },
  },
};
</script>

<docs>
# Checklist example

'''js
<Checklist />
'''
</docs>
