var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      items: _vm.channels,
      loading: _vm.isLoading,
      "item-text": "displayName",
      "item-value": "id",
      label: "Select a Channel",
      filled: "",
    },
    scopedSlots: _vm._u([
      {
        key: "append-outer",
        fn: function () {
          return [
            _c(
              "v-btn",
              {
                attrs: { icon: "" },
                on: {
                  click: function ($event) {
                    return _vm.refresh(_vm.teamId)
                  },
                },
              },
              [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiRefresh) + " ")])],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "prepend-inner",
        fn: function () {
          return [
            _c(
              "v-dialog",
              {
                attrs: { fullscreen: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: { text: "", small: "" },
                                on: { click: _vm.checkAccess },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", [
                              _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.dialog,
                  callback: function ($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "text-h5 grey lighten-2" },
                      [_vm._v(" New Channel ")]
                    ),
                    _c(
                      "v-card-text",
                      [
                        _c("v-text-field", {
                          attrs: { label: "Channel Name" },
                          model: {
                            value: _vm.name,
                            callback: function ($$v) {
                              _vm.name = $$v
                            },
                            expression: "name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                _vm.dialog = false
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { loading: _vm.isSaving, color: "success" },
                            on: { click: _vm.create },
                          },
                          [_vm._v("Save")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.selectedChannel,
      callback: function ($$v) {
        _vm.selectedChannel = $$v
      },
      expression: "selectedChannel",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }