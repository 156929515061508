<template>
  <v-breadcrumbs
    :items="items"
    color="#1e0b4c"
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :disabled="!item.to"
        color="#1e0b4c"
      >
        {{ $t(item.text) }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  data() {
    return {
      items: [],
    };
  },
  watch: {
    $route() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      const { breadcrumb } = this.$route.meta;
      if (breadcrumb) {
        this.items = breadcrumb;
      } else {
        this.items = [];
      }
    },
  },
};
</script>
