<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="twilioNumbers"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="TwilioNumber-button-new"
            @click="$router.push({
              name: 'candidates-EditTwilioNumber',
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons
          :editLink="{
            name: 'candidates-EditTwilioNumber',
            query: { id: item.id },
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListTwilioNumber',
  module: 'Candidates',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('twilioNumbers', ['twilioNumbers']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('number'),
          value: 'number',
        },
        {
          text: this.$$t('country'),
          value: 'country',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_twilioNumbers();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('twilioNumbers', ['LOAD_twilioNumbers', 'DELETE_twilioNumber']),
    /** Delete TwilioNumber from the collection
     */
    async remove(twilioNumber) {
      this.isLoading = true;
      await this.DELETE_twilioNumber(twilioNumber.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# TwilioNumber example

'''js
<TwilioNumber />
'''
</docs>
