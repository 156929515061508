<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h1>Admin Consent</h1>
      </v-card-title>
      <v-card-text>
        <v-btn color="primary" @click="refresh">
          Consent
        </v-btn>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="team in teams" :key="team.id">
              <v-list-item-content>
                <v-list-item-title>{{ team.displayName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import teams from '../mixins/teams';

export default {
  name: 'Consent',
  mixins: [teams],
  data() {
    return {
      teams: [],
    };
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      try {
        this.$log.info('Getting teams');
        const scopes = [
          'https://graph.microsoft.com/Team.ReadBasic.All',
          'https://graph.microsoft.com/Team.Create',
          'https://graph.microsoft.com/Channel.Create',
          'https://graph.microsoft.com/Channel.ReadBasic.All',
        ];
        this.teams = await this.fetchGraph(
          'https://graph.microsoft.com/v1.0/me/joinedTeams',
          scopes,
        );
      } catch (error) {
        this.$root.$emit('toast:error', 'Unable to get teams');
        this.$log.error('Unable to get teams:', error);
      }
      this.isLoading = false;
    },
  },
};
</script>
