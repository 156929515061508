<template>
  <v-container fluid>
    <v-card v-if="jobListing">
      <v-card-title primary-title>
        <Favourite name="JobListing" :identifier="`${userId}-${jobListing.id}`" />
        <div class="text-h4 mr-5">{{ jobListing.client.name }}:</div>
        <div class="text-h4 mr-5">{{ jobListing.name }}</div> ({{ jobListing.status }})
        <v-icon class="ml-5" v-if="!jobListing.isActive" title="Archived">{{ mdiArchive }}</v-icon>
        <v-spacer />
        <v-text-field
          dense
          clearable
          v-model="search"
          :append-icon="mdiMagnify"
          label="Search"
          single-line
          @keyup.enter="searchCandidates"
          @click:append="searchCandidates"
          @click:clear="clearSearch"
          hint="Press enter to search or click the search icon"
        />
      </v-card-title>
      <v-card-text>
        <span style="display: block">{{ jobListing.description }}</span>
        <v-row dense>
          <v-col cols="6" v-if="tab === 0">
            <GraphJobListing
              v-if="jobListingCandidates.length > 0"
              :jobListingCandidates="graphCandidates"
              :stoppedCandidates="stoppedCandidates"
            />
          </v-col>
          <v-col cols="4" v-if="!lastTab">
            <GraphFunnel :jobId="`${jobListing.id}`" :height="220" />
          </v-col>
          <v-col v-if="tab > 0" :cols="lastTab ? '12' : '3'">
            <GraphJobListingIssues
              :jobId="`${jobListing.id}`"
              :hideExplanations="!lastTab"
              :is-vertical="!lastTab"
            />
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          slider-color="secondary"
        >
          <v-tab v-for="(tab, index) in tabs" :key="index">
            {{ tab }}
          </v-tab>
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <div style="margin: 20px">
              <v-row>
                <v-col cols="6" class="d-flex align-center">
                  <AddCandidate
                    :jobListing="jobListing"
                    @selected="addCandidates"
                    :candidates="availableCandidates"
                    :tab="tab" />
                  <div class="mr-2" />
                  <CreateToDoTask :job-listing-id="$route.query.jobListingId" />
                  <div class="mr-2" />
                  <DialogListTask :job-listing-id="$route.query.jobListingId" />
                </v-col>
                <v-col cols="6" v-if="index === 0" class="text-right">
                  <ImportExport
                    name="Job Listing - Candidates"
                    :url="`api/data/jobListingCandidates(${$route.query.jobListingId})`"
                    @refresh="refresh"
                  />
                </v-col>
                <v-col cols="12">
                  <JobListingOverview
                    :jobListingCandidates="visibleCandidates"
                    :jobListing="jobListing"
                    :loading="isLoading"
                    :showFilterStage="tab === 0"
                    :showReasons="lastTab"
                    :stage="stage"
                    :stageId="stage?.id"
                    @refresh="refresh"
                    @update="updateJobListingCandidate" />
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { mdiArchive, mdiFilePdfBox, mdiMagnify } from '@mdi/js';
import CreateToDoTask from '@/components/CreateToDoTask.vue';
import DialogListTask from '@/components/DialogListTask.vue';
import ImportExport from '@/components/ImportExport.vue';
import GraphFunnel from '@/modules/clients/components/GraphFunnel.vue';
import GraphJobListingIssues from '@/modules/clients/components/GraphJobListingIssues.vue';
import AddCandidate from '../components/AddCandidate.vue';
import GraphJobListing from '../components/GraphJobListing.vue';
import JobListingOverview from '../components/JobListingOverview.vue';

export default {
  name: 'ViewJobListing',
  components: {
    AddCandidate,
    CreateToDoTask,
    DialogListTask,
    GraphJobListing,
    GraphJobListingIssues,
    GraphFunnel,
    JobListingOverview,
    ImportExport,
  },
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.jobListings',
        to: { name: 'clients-ListJobListing' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      mdiArchive,
      mdiFilePdfBox,
      mdiMagnify,
      jobListingCandidates: [],
      visibleCandidates: [],
      search: '',
    };
  },
  computed: {
    ...mapState('app', ['jobListingTab']),
    ...mapState('auth', ['userId']),
    ...mapState('candidates', ['candidates']),
    ...mapState('jobListings', ['jobListing']),
    ...mapState('stages', ['stages']),
    ...mapGetters('stages', ['orderedStages']),
    /**
     * Computed property for the tab vuex property
     */
    tab: {
      get() {
        return this.jobListingTab;
      },
      set(newValue) {
        this.SET_jobListingTab(newValue);
      },
    },
    availableCandidates() {
      return this.candidates
        .filter((candidate) => {
          const jobListingCandidate = this.jobListingCandidates.find(
            (j) => j.candidateId === candidate.id,
          );
          return !jobListingCandidate;
        })
        .filter((candidate) => candidate.name);
    },
    graphCandidates() {
      return this.jobListingCandidates.filter(
        (jobListingCandidate) => jobListingCandidate.isStop === false
          || jobListingCandidate.isStop === null,
      );
    },
    stoppedCandidates() {
      return this.jobListingCandidates.filter(
        (jobListingCandidate) => jobListingCandidate.isStop === true,
      );
    },
    stage() {
      return this.orderedStages[this.tab - 1];
    },
    lastTab() {
      return this.tab === this.tabs.length - 1;
    },
    tabs() {
      const { orderedStages } = this;
      return ['Overview', ...orderedStages.map((stage) => stage.name), 'Stopped'];
    },
  },
  watch: {
    tab() {
      this.setVisibleCandidates();
    },
  },
  created() {
    this.SET_jobListingCandidates([]);
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('candidates', ['LOAD_candidatesMin']),
    ...mapActions('industries', ['LOAD_industries']),
    ...mapActions('jobListings', ['LOAD_jobListingExpanded']),
    ...mapActions('explanations', ['LOAD_explanations']),
    ...mapActions('stages', ['LOAD_stages']),
    ...mapActions('tasks', ['LOAD_tasks']),
    ...mapActions('reasons', ['LOAD_reasons']),
    ...mapActions('twilioNumbers', ['LOAD_twilioNumbers']),
    ...mapActions('documentTemplates', ['LOAD_documentTemplates']),
    ...mapActions('jobListingCandidates', [
      'LOAD_jobListingCandidatesByJobListingId',
      'CREATE_jobListingCandidate',
      'UPDATE_jobListingCandidate',
    ]),
    ...mapMutations('jobListingCandidates', ['SET_jobListingCandidates']),
    ...mapMutations('app', ['SET_jobListingTab']),
    async addCandidates(candidates) {
      candidates.forEach(async (candidate) => {
        await this.CREATE_jobListingCandidate({
          candidateId: candidate,
          jobListingId: this.$route.query.jobListingId,
          stageId: this.stages[0].id,
        });
        await this.refresh();
      });
    },
    clearSearch() {
      this.search = '';
      this.setVisibleCandidates();
    },
    searchCandidates() {
      this.$log.debug('searchCandidates', this.search);
      if (this.search === '') {
        this.setVisibleCandidates();
      } else {
        this.visibleCandidates = this.jobListingCandidates?.filter(
          (jobListingCandidate) => {
            const candidate = this.candidates.find(
              (c) => c.id === jobListingCandidate.candidateId,
            );
            if (!candidate) {
              return false;
            }
            return candidate.name?.toLowerCase().includes(this.search?.toLowerCase());
          },
        );
      }
    },
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.LOAD_stages();
        })(),
        (async () => {
          await this.LOAD_documentTemplates();
        })(),
        (async () => {
          await this.LOAD_tasks();
        })(),
        (async () => {
          await this.LOAD_explanations();
        })(),
        (async () => {
          await this.LOAD_reasons();
        })(),
        (async () => {
          await this.LOAD_candidatesMin();
        })(),
        (async () => {
          await this.LOAD_twilioNumbers();
        })(),
        (async () => {
          await this.LOAD_industries();
        })(),
        (async () => {
          await this.LOAD_jobListingExpanded(this.$route.query.jobListingId);
        })(),
        (async () => {
          const { jobListingId } = this.$route.query;
          // eslint-disable-next-line max-len
          this.jobListingCandidates = await this.LOAD_jobListingCandidatesByJobListingId(jobListingId);
          this.setVisibleCandidates();
        })(),
      ]);
      this.isLoading = false;
    },
    updateJobListingCandidate(jobListingCandidate) {
      const index = this.visibleCandidates.findIndex(
        (j) => j.id === jobListingCandidate.id,
      );
      setTimeout(() => {
        this.visibleCandidates.splice(index, 1, jobListingCandidate);
        this.$forceUpdate();
      }, 250);
    },
    setVisibleCandidates() {
      if (this.tabs.length === 0) {
        this.visibleCandidates = [];
        return;
      }
      if (this.tab === this.tabs.length - 1) {
        this.visibleCandidates = this.jobListingCandidates.filter(
          (jobListingCandidate) => jobListingCandidate.isStop === true,
        );
        return;
      }
      if (this.tab === 0) {
        this.visibleCandidates = this.jobListingCandidates;
        return;
      }
      if (this.stage) {
        this.visibleCandidates = this.jobListingCandidates.filter(
          (jobListingCandidate) => jobListingCandidate.stageId === this.stage.id
            && (jobListingCandidate.isStop === false || jobListingCandidate.isStop === null),

        );
        return;
      }
      this.visibleCandidates = this.jobListingCandidates.filter(
        (jobListingCandidate) => jobListingCandidate.isStop !== true,
      );
    },
  },
};
</script>
