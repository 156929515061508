var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ListStage", { on: { selected: _vm.stageSelected } }),
      _c("div", { staticClass: "mt-10" }),
      _vm.selectedStage
        ? _c("ListTask", { attrs: { Stage: _vm.selectedStage } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }