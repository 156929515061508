var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-icon", [_vm._v(_vm._s(_vm.mdiPencil))]),
              _c("span", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.title))]),
            ],
            1
          ),
          _c("v-card-text", [_vm._t("default")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }