/* eslint-disable no-param-reassign */
import MicrosoftClient from './MicrosoftClient';

export default {
  /**
  * Install the plugin
  * @param {Object} options - The configuration options for the client
  * @param {String} options.apiClient - The API client to use for requests
  * @param {String} options.baseUrl - The base URL for requests
  * @param {String} options.clientId - The client ID for authentication
  * @param {String} options.secret - The secret for authentication
  * @param {Number} options.timeout - The timeout for requests
  */
  install(Vue, options) {
    Vue.prototype.$http = new MicrosoftClient(options);
  },
};
