<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-center">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormChecklist-name"
            :label="$$t('name')"
            v-model="localChecklist.name"
          />
        </v-col>
      </v-row>
      <!-- <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormChecklist-defaultValue"
              :label="$$t('defaultValue')"
              v-model="localChecklist.defaultValue"
            />
          </v-col>
        </v-row> -->
      <div class="d-flex justify-end mb-6">
        <v-btn
          color="default"
          outlined
          class="ml-4 btn-cancel"
          @click="cancel"
          data-cy="FormChecklist-button-cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          class="ml-4"
          data-cy="FormChecklist-button-save">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormChecklist',
  module: 'Checklists',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Checklist: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localChecklist
      */
      localChecklist: {},
    };
  },
  watch: {
    Checklist() {
      this.localChecklist = this.Checklist;
    },
  },
  created() {
    this.localChecklist = this.Checklist;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localChecklist);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormChecklist example

'''js
<FormChecklist :Checklist="Checklist" />
'''
</docs>
