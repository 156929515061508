<template>
  <v-container fluid>
    <SourceEventLog title="Event Logs" :hide-search="false" :filter="filter">
      <template v-slot:buttons>
        <v-btn
          icon
          :title="includeVerbose ? 'Hide Verbose' : 'Show Verbose'"
          @click="includeVerbose = !includeVerbose"
        >
          <v-icon v-if="includeVerbose">{{ mdiScriptTextPlay }}</v-icon>
          <v-icon v-else>{{ mdiScriptTextOutline }}</v-icon>
        </v-btn>
      </template>
    </SourceEventLog>
  </v-container>
</template>

<script>
import { mdiScriptTextOutline, mdiScriptTextPlay } from '@mdi/js';
import SourceEventLog from '../components/SourceEventLog.vue';

export default {
  name: 'AdminEventLog',
  components: {
    SourceEventLog,
  },
  data() {
    return {
      dialog: false,
      includeVerbose: false,
      mdiScriptTextOutline,
      mdiScriptTextPlay,
    };
  },
  computed: {
    filter() {
      const filter = '';
      if (!this.includeVerbose) {
        return 'EventType ne \'Verbose\'';
      }
      return filter;
    },
  },
};
</script>
