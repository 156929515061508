var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_c("h1", [_vm._v("Admin Consent")])]),
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.refresh } },
                [_vm._v(" Consent ")]
              ),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item-group",
                    _vm._l(_vm.teams, function (team) {
                      return _c(
                        "v-list-item",
                        { key: team.id },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(team.displayName)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }