var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "align-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-skeleton-loader",
                {
                  attrs: {
                    loading: _vm.localConfig === undefined,
                    transition: "scale-transition",
                    type: "list-item-two-line",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _vm.localConfig
                        ? _c(
                            "v-card-text",
                            [
                              _c(
                                "v-form",
                                { attrs: { "lazy-validation": false } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "From Address",
                                      placeholder: "email@email.com",
                                    },
                                    model: {
                                      value: _vm.localConfig.fromAddress,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localConfig,
                                          "fromAddress",
                                          $$v
                                        )
                                      },
                                      expression: "localConfig.fromAddress",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Reply to Address",
                                      placeholder: "email@email.com",
                                    },
                                    model: {
                                      value: _vm.localConfig.replyAddress,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localConfig,
                                          "replyAddress",
                                          $$v
                                        )
                                      },
                                      expression: "localConfig.replyAddress",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Host Name",
                                      placeholder: "yourdomain.name",
                                    },
                                    model: {
                                      value: _vm.localConfig.host,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localConfig, "host", $$v)
                                      },
                                      expression: "localConfig.host",
                                    },
                                  }),
                                  _c("v-checkbox", {
                                    attrs: { label: "Use SSL" },
                                    model: {
                                      value: _vm.localConfig.isSSL,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localConfig, "isSSL", $$v)
                                      },
                                      expression: "localConfig.isSSL",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Port",
                                      type: "number",
                                      placeholder: "",
                                    },
                                    model: {
                                      value: _vm.localConfig.port,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localConfig, "port", $$v)
                                      },
                                      expression: "localConfig.port",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Username",
                                      placeholder: "",
                                    },
                                    model: {
                                      value: _vm.localConfig.user,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localConfig, "user", $$v)
                                      },
                                      expression: "localConfig.user",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Password",
                                      type: "password",
                                      placeholder: "",
                                    },
                                    model: {
                                      value: _vm.localConfig.password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localConfig,
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression: "localConfig.password",
                                    },
                                  }),
                                  _c("v-btn", { on: { click: _vm.save } }, [
                                    _vm._v("Save"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }