var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("UserManagement", {
                        ref: "userManagement",
                        scopedSlots: _vm._u([
                          {
                            key: "top-left",
                            fn: function () {
                              return [
                                _c("OrgInvite"),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: { width: "500" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ml-10",
                                                    attrs: { color: "success" },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(_vm._s(_vm.mdiPlus)),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common.userManagement.addNew"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.dialog,
                                      callback: function ($$v) {
                                        _vm.dialog = $$v
                                      },
                                      expression: "dialog",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c("v-card-title", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "common.userManagement.addNew"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-form",
                                              {
                                                ref: "form",
                                                staticStyle: { width: "100%" },
                                                on: {
                                                  keydown: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.submit.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  submit: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.adminRegister.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label:
                                                      _vm.$t("common.username"),
                                                    outlined: "",
                                                    "prepend-inner-icon":
                                                      _vm.mdiAccount,
                                                    rules: _vm.rules.required,
                                                    "data-cy": "login-username",
                                                    autocomplete: "username",
                                                  },
                                                  model: {
                                                    value: _vm.form.username,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "username",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.username",
                                                  },
                                                }),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label:
                                                      _vm.$t("register.email"),
                                                    outlined: "",
                                                    "prepend-inner-icon":
                                                      _vm.mdiMail,
                                                    rules: _vm.rules.required,
                                                    type: "email",
                                                    autocomplete: "email",
                                                  },
                                                  model: {
                                                    value: _vm.form.email,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "email",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.email",
                                                  },
                                                }),
                                                _c("PasswordField", {
                                                  staticClass: "mb-0",
                                                  attrs: {
                                                    label:
                                                      _vm.$t("common.password"),
                                                    rules: _vm.rules.password,
                                                  },
                                                  model: {
                                                    value: _vm.form.password,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "password",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.password",
                                                  },
                                                }),
                                                _vm._l(
                                                  _vm.errors,
                                                  function (error) {
                                                    return _c(
                                                      "v-alert",
                                                      {
                                                        key: error,
                                                        attrs: {
                                                          type: "error",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(error) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "primary mb-2 rounded-0 text-capitalize block",
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      loading: _vm.isLoading,
                                                      type: "submit",
                                                      "data-cy":
                                                        "register-adminRegister",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.buttons.save"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("div", { staticClass: "mb-10" }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }