<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <TableModalForm
          :headers="headers"
          :items="reasons"
          class="elevation-1"
          :loading="isLoading"
          name="Reasons"
          ref="table"
          :slots="[{ name: 'actions' }, { name: 'order' }]"
          show-select
          single-select
          sort-by="order"
          @creating="creating"
          @refresh="refresh"
          @item-selected="itemSelected"
        >
          <template v-slot:form>
            <FormReason
              :Reason="editReason"
              :isSaving="isSaving"
              @save="save"
              @cancel="$refs.table.dialog = false" />
          </template>
          <template v-slot:order="{ item, index }">
            <v-btn icon @click="moveUp(item)">
              <v-icon v-if="index > 0" class="mr-2">{{ mdiArrowUpBold }}</v-icon>
            </v-btn>
            {{ item.order }}
            <v-btn icon @click="moveDown(item)">
              <v-icon v-if="index < (reasons.length - 1)" class="mr-2">
                {{ mdiArrowDownBold }}
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:actions="{ item }">
            <v-btn-toggle>
              <v-btn color="default" small @click="edit(item)">
                {{ $t('common.buttons.edit')}}
              </v-btn>
              <v-btn color="error" small @click="remove(item)">
                {{ $t('common.buttons.delete')}}
              </v-btn>
            </v-btn-toggle>
          </template>
        </TableModalForm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiArrowUpBold, mdiArrowDownBold } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import TableModalForm from '@/components/TableModalForm.vue';
import FormReason from '../components/FormReason.vue';

export default {
  name: 'ListReason',
  module: 'Reason',
  components: {
    FormReason,
    TableModalForm,
  },
  mixins: [loading],
  data() {
    return {
      /**
       * Icons
       */
      mdiPlus,
      mdiArrowUpBold,
      mdiArrowDownBold,
      /**
       * Get/Set editing Reason. Default to {}.
       */
      editReason: {},
      mode: 'add',
    };
  },
  computed: {
    /** Vuex states
      */
    ...mapState('reasons', ['reasons']),
    /**
     * Get table column headers
     */
    headers() {
      return [
        {
          text: this.$$t('order'),
          value: 'order',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  watch: {
    Task() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
      */
    ...mapActions('reasons', [
      'LOAD_reason',
      'CREATE_reason',
      'UPDATE_reason',
      'LOAD_reasons',
      'DELETE_reason',
    ]),
    creating() {
      this.editReason = {
        order: this.reasons.length + 1,
        name: '',
      };
      this.mode = 'add';
    },
    async edit(item) {
      this.editReason = { ...item };
      this.mode = 'edit';
      this.$refs.table.dialog = true;
    },
    /**
      * Moves an item up in the list
      */
    async moveDown(item) {
      const { reasons } = this;
      const next = reasons.find((s) => s.order === (item.order + 1));
      const current = reasons.find((s) => s.order === item.order);
      next.order = item.order;
      current.order = item.order + 1;
      await this.UPDATE_reason(next);
      await this.UPDATE_reason(current);
      await this.refresh();
    },
    /**
      * Moves an item down in the list
      */
    async moveUp(item) {
      const { reasons } = this;
      const prev = reasons.find((s) => s.order === (item.order - 1));
      const current = reasons.find((s) => s.order === item.order);
      prev.order = item.order;
      current.order = item.order - 1;
      await this.UPDATE_reason(prev);
      await this.UPDATE_reason(current);
      await this.refresh();
    },
    /**
      * Executes refresh
      */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_reasons();
      this.isLoading = false;
    },
    /** Delete Reason from the collection
      */
    async remove(reason) {
      this.isLoading = true;
      await this.DELETE_reason(reason.id);
      this.isLoading = false;
    },
    /** Vuex methods
      */
    /** Method used to save the item
      */
    async save(reason) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_reason(reason);
      } else {
        await this.UPDATE_reason(reason);
      }
      this.isSaving = false;
      this.$refs.table.dialog = false;
    },
    itemSelected(item) {
      this.$emit('selected', item);
    },
  },
};
</script>

 <docs>
 # Reason example

 '''js
 <Reason />
 '''
 </docs>
