var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        {
          staticClass: "d-flex justify-space-between align-center",
          attrs: { "primary-title": "" },
        },
        [
          _c("span", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
          _c(
            "div",
            {
              staticClass: "d-flex",
              class: {
                "justify-end": _vm.hideSearch,
                "justify-center": !_vm.hideSearch,
              },
              staticStyle: { width: "30%" },
            },
            [
              !_vm.hideSearch
                ? _c("v-text-field", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      "append-icon": _vm.mdiMagnify,
                      label: "Search",
                      clearable: "",
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.onSearchChanged.apply(null, arguments)
                      },
                      "click:append": _vm.onSearchChanged,
                      "click:clear": _vm.onSearchClear,
                    },
                    model: {
                      value: _vm.searchValue,
                      callback: function ($$v) {
                        _vm.searchValue = $$v
                      },
                      expression: "searchValue",
                    },
                  })
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", title: "Refresh" },
                  on: { click: _vm.refresh },
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiRefresh))])],
                1
              ),
              _vm._t("buttons"),
            ],
            2
          ),
        ]
      ),
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.eventLogs,
              loading: _vm.isLoading,
              options: _vm.pagination,
              "item-key": "traceId",
              "footer-props": {
                itemsPerPageOptions: [10, 50, 100, 250, 500],
              },
              "server-items-length": _vm.pagination.totalItems,
            },
            on: {
              "update:options": function ($event) {
                _vm.pagination = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.utcDateTime",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatDateTimeSeconds(
                            item.utcDateTime.replace("+02:00", "+00:00")
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }