<template>
  <v-text-field
    ref="textField"
    v-bind="$props"
    :data-cy="dataCy"
    v-on="$listeners"
    @focus="selectAllText"
  >
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
    <template #append>
      <v-tooltip
        bottom
      >
        <template #activator="{ on }">
          <v-icon v-on="on" @click="copyToClipboard">
            {{ mdiContentCopy }}
          </v-icon>
        </template>
        {{ title }}
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib';
import { mdiContentCopy } from '@mdi/js';

export default {
  name: 'ClipboardTextField',
  extends: VTextField,
  props: {
    dataCy: {
      type: String,
      default: 'CurrencyTextField',
    },
  },
  data() {
    return {
      mdiContentCopy,
      title: 'Copy to clipboard',
    };
  },
  methods: {
    async copyToClipboard() {
      const textField = this.$refs.textField.$el.querySelector('input');
      textField.select();
      try {
        await navigator.clipboard.writeText(textField.value);
        this.$root.$emit('toast:notify', 'Copied to clipboard');
        this.title = 'Copied!';
        setTimeout(() => {
          this.title = 'Copy to clipboard';
        }, 3000);
      } catch (error) {
        this.$root.$emit('toast:error', 'Failed to copy to clipboard');
        this.$log.error('Failed to copy to clipboard:', error);
      } finally {
        textField.blur();
      }
    },
    selectAllText() {
      this.$refs.textField.$el.querySelector('input').select();
    },
  },
};
</script>
