var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(" " + _vm._s(_vm.$$t("title")) + " "),
          ]),
          _c("v-card-text", {
            domProps: { innerHTML: _vm._s(_vm.$$t("text")) },
          }),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                { attrs: { color: "success", to: { name: "auth-Login" } } },
                [_vm._v(" " + _vm._s(_vm.$$t("login")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }