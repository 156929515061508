var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      icon: "",
                      color: _vm.color,
                      small: _vm.small,
                      title: "Resize column",
                    },
                  },
                  on
                ),
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiArrowSplitVertical))])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("resize", "small")
                },
              },
            },
            [_c("v-list-item-title", [_vm._v("Small Column")])],
            1
          ),
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("resize", "medium")
                },
              },
            },
            [_c("v-list-item-title", [_vm._v("Medium Column")])],
            1
          ),
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("resize", "large")
                },
              },
            },
            [_c("v-list-item-title", [_vm._v("Large Column")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }