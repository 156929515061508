<template>
  <div />
</template>

<script>
import axios from 'axios';
import {
  mapGetters, mapMutations, mapState,
} from 'vuex';
import { authHub } from '@codehq/aurora-app-core/src';
import { useAuth } from '../use';

const auth = useAuth();

export default {
  name: 'AzureAuth',
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapState('app', ['isSaving']),
    ...mapState('organizations', ['organization']),
  },
  created() {
    this.$root.$on('auth:logoff', () => {
      this.$log.debug('logging off and clearing local storage');
      this.SET_isLoggedIn(false);
      this.SET_roles('');
      this.SET_username('');
      this.SET_userId('');
      auth.logout();
      this.$log.info('logged off');
      this.$router.push({ name: 'auth-Login' });
    });
    authHub.$on('network:unauthorized', async () => {
      this.$log.debug('unauthorized request, logging off');
      this.SET_isLoggedIn(false);
      this.SET_roles('');
      this.SET_username('');
      this.SET_userId('');
      this.$router.push({ name: 'auth-Login' });
    });

    axios.interceptors.request.use((config) => {
      this.$log.debug(`intercepting request and setting org to: ${this.organization?.id}`);
      const { organization } = this;
      const result = { ...config };
      result.headers.organizationid = `${organization?.id}`;
      return config;
    });
    axios.interceptors.response.use((response) => response, async (error) => {
      if (error) {
        if (error?.response?.status === 401) {
          authHub.$emit('network:unauthorized');
        }
      } else {
        this.$log.error('Error not found');
      }
      return Promise.reject(error);
    });
  },
  methods: {

    ...mapMutations('auth', ['SET_isLoggedIn', 'SET_roles', 'SET_username', 'SET_userId', 'SET_provider']),
  },
};
</script>
