var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ListChecklistTemplate", {
        on: { selected: _vm.checklistTemplateSelected },
      }),
      _c("div", { staticClass: "mt-10" }),
      _vm.selectedChecklistTemplate
        ? _c("ListChecklist", {
            attrs: { ChecklistTemplate: _vm.selectedChecklistTemplate },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }